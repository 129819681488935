import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@/components';
import { useModal } from '@/hooks';
import { theme } from '@/components/App';
import forwardIconBlue from '@/static/images/forward-icon-blue.svg';
import forwardIconGray from '@/static/images/forward-icon-gray.svg';
import { SelectSchedule } from '../SelectSchedule';
import { ScheduleTypeEnum } from '../../types';
import { CombinationStatus, CombinationType } from '@/containers/Agenda/types';
import { useFields } from '@/containers/Agenda/hooks/useFields';

type DateSelectorProps = {
  combination: CombinationType;
  allowDelete: boolean;
  combinationIndex: number;
  serviceDuration: number;
  allCombinations: CombinationType[];
  handleCombination: (combination: CombinationType[]) => void;
};

export const DateSelector: FunctionComponent<DateSelectorProps> = ({
  combination,
  allowDelete,
  combinationIndex,
  serviceDuration,
  handleCombination,
  allCombinations
}) => {
  const [scheduleType, setScheduleType] = useState<ScheduleTypeEnum>(ScheduleTypeEnum.HOUR);
  const { show, onClose, onOpen } = useModal();

  const {
    handleSchedule,
    handleSaveSchedules,
    deleteCombination,
    handleInitialSchedules,
    daysOptions,
    recurringDays
  } = useFields({
    combinationIndex,
    serviceDuration,
    currentCombination: combination,
    handleCombination,
    allCombinations,
    closeModal: onClose
  });

  const hasSelectedDays =
    daysOptions.find((day) => {
      return day.isSelected;
    }) !== undefined;

  const selectedDaysText = hasSelectedDays
    ? daysOptions
        .filter((day) => {
          return day.isSelected;
        })
        .map((day) => day.item.label.substring(0, 2))
        .join(', ')
    : '';

  const addDays = () => {
    if (!hasSelectedDays) {
      setScheduleType(ScheduleTypeEnum.DAY);
      onOpen();
    }
  };

  const addHours = () => {
    if (hasSelectedDays) {
      setScheduleType(ScheduleTypeEnum.HOUR);
      onOpen();
    }
  };

  return (
    <>
      <Container>
        <FirstRow>
          <AddDays hasSelectedDays={hasSelectedDays} onClick={addDays}>
            {!hasSelectedDays ? 'Agregar días' : selectedDaysText}
            {!hasSelectedDays && <ForwardIconBlue src={forwardIconBlue} alt="forward-icon-blue" />}
          </AddDays>
          {allowDelete && (
            <CloseButton onClick={deleteCombination}>
              <Icon name="close" width="13px" height="13px" color={theme.colors.white as string} />
            </CloseButton>
          )}
        </FirstRow>
        {combination.status === CombinationStatus.CONFIRMED ? (
          <SchedulesContainer>
            {combination.selectedSchedules
              ?.sort((a, b) => a.value.localeCompare(b.value))
              .map((schedule, index) => (
                <Bullet key={index}>{schedule.label}</Bullet>
              ))}
          </SchedulesContainer>
        ) : (
          <AddSchedules onClick={addHours} disabled={!hasSelectedDays}>
            Agregar horarios
            <ForwardIconBlue src={forwardIconGray} alt="forward-icon-gray" />
          </AddSchedules>
        )}
      </Container>
      <SelectSchedule
        show={show}
        scheduleType={scheduleType}
        daysOptions={daysOptions}
        recurringDays={recurringDays}
        schedules={combination.schedules}
        handleSchedule={handleSchedule}
        handleSaveSchedules={handleSaveSchedules}
        closeModal={onClose}
        handleInitialSchedules={handleInitialSchedules}
      />
    </>
  );
};

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AddDays = styled.p<{ hasSelectedDays: boolean }>`
  cursor: pointer;
  display: inline-block;
  line-height: 18px;
  font-size: 15px;
  ${({ hasSelectedDays, theme }) =>
    hasSelectedDays
      ? css`
          color: ${theme.colors.black};
          text-decoration-line: none;
          font-weight: bold;
          cursor: default;
        `
      : css`
          color: ${theme.colors.blue[500]};
          text-decoration-line: underline;
          cursor: pointer;
        `}
`;

const AddSchedules = styled.p<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-top: 16px;
`;

const ForwardIconBlue = styled.img`
  height: 9px;
  display: inline;
  margin-left: 4px;
  padding-top: 1px;
`;

const CloseButton = styled.button`
  padding: 6px;
  line-height: 0;
  margin-left: auto;
  margin-right: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const SchedulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const Bullet = styled.div`
  background: ${({ theme }) => theme.colors.blue[300]};
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.white};
  padding: 6px 10px;
  font-size: 0.8rem;
  margin-right: 5px;
  margin-bottom: 4px;
`;
