import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { scrollCSS } from '@/components/App';
import { useOnClickOutside } from '@/hooks';
import { AgoraLinkTypeE } from '@/types/cyclone/models';

export type SelectOptionProps = {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  isSelected: boolean;
};

type PositionDropdownType = 'bottom' | 'top';

export interface OptionLinkI {
  text: string;
  type: AgoraLinkTypeE;
  isDisabled?: boolean;
}

type SelectButtonProps = {
  value?: string;
  options: OptionLinkI[];
  helpText?: string;
  error?: string;
  positionDropdown?: PositionDropdownType;
  fullWidth?: boolean;
  button: JSX.Element;
  selectTypeLink: (type: AgoraLinkTypeE) => void;
};

export const ButtonSelectLink: FunctionComponent<SelectButtonProps> = ({
  value,
  options,
  helpText,
  error,
  positionDropdown = 'top',
  fullWidth = false,
  button,
  selectTypeLink
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(containerRef, closeDropdown);

  useEffect(() => {
    if (value) closeDropdown();
  }, [value, closeDropdown]);

  return (
    <Container ref={containerRef}>
      <div onClick={() => setIsOpen(!isOpen)}>{button}</div>

      {isOpen && (
        <DropdownContainer hasHelpText={!!helpText} positionDropdown={positionDropdown} fullWidth={fullWidth}>
          <Dropdown ref={dropdownRef}>
            {options.map(({ text, type, isDisabled }, i) => {
              return (
                <Option
                  key={i}
                  onClick={() => {
                    setIsOpen(false);
                    selectTypeLink(type);
                  }}
                  disabled={isDisabled}
                >
                  {text}
                </Option>
              );
            })}
          </Dropdown>
        </DropdownContainer>
      )}
      {(helpText || error) && <BottomText error={!!error}>{error || helpText}</BottomText>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div<{
  hasHelpText: boolean;
  fullWidth: boolean;
  positionDropdown: PositionDropdownType;
}>`
  ${scrollCSS};
  position: absolute;
  z-index: 1;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey[400]};
  background: ${({ theme }) => theme.colors.white};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  // this change can fuck up things a bit (example: create a new event on mobile)
  // width: max-content;

  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  ${({ positionDropdown }) =>
    positionDropdown === 'top'
      ? `bottom: 0px;
	margin-bottom: 40px;`
      : ''}
`;

const Dropdown = styled.div<{ hasScroll?: boolean }>`
  display: block;
  overflow-y: auto;
  padding-right: ${({ hasScroll }) => hasScroll && '0.7rem'};
  ${scrollCSS};
`;

const Option = styled.button<{ isSelected?: boolean }>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  padding-top: 10px;
  text-align: left;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 10px;
  }
  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          background-color: ${theme.colors.blue[500]};
          color: ${theme.colors.white};
        `
      : css`
          &:hover {
            background-color: ${theme.colors.grey[200]};
          }
        `};

  &:disabled {
    background: none;
  }
`;

const BottomText = styled.span<{ error: boolean }>`
  display: block;
  color: ${({ theme, error }) => (error ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-top: 0.4rem;
  margin-left: 1rem;
`;
