import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface PaletteColorPropsI {
  selectedColorsTheme: {
    background: string;
    title: string;
    button: string;
    text: string;
  };
  colorsTheme: {
    background: string;
    title: string;
    button: string;
    text: string;
  };
  setColorsTheme: React.Dispatch<
    React.SetStateAction<{
      background: string;
      title: string;
      button: string;
      text: string;
    }>
  >;
}

export const PaletteColor: FunctionComponent<PaletteColorPropsI> = ({
  selectedColorsTheme,
  colorsTheme,
  setColorsTheme
}) => {
  const sameColors = () =>
    selectedColorsTheme.background == colorsTheme.background &&
    selectedColorsTheme.title == colorsTheme.title &&
    selectedColorsTheme.button == colorsTheme.button &&
    selectedColorsTheme.text == colorsTheme.text;

  return (
    <ContentPalette selected={sameColors()} onClick={() => setColorsTheme(colorsTheme)}>
      <Color color={colorsTheme.background} />
      <Color color={colorsTheme.title} />
      <Color color={colorsTheme.button} />
      <Color color={colorsTheme.text} />
    </ContentPalette>
  );
};

const ContentPalette = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  background: #fafafa;
  padding: 10px;
  max-width: 135px;
  justify-content: space-between;
  margin-right: 10px;
  flex: 1;
  border-radius: 4px;
  ${({ selected, theme }) => (selected ? `border: 1px solid ${theme.colors.blue[500]};` : '')};

  ${({ theme }) => theme.breakpoint('xl')} {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

const Color = styled.div<{ color: string }>`
  width: 25px;
  height: 25px;
  background: ${({ color }) => color};
  border-radius: 50%;
  border: 1px solid #eceef0;
  ${({ theme }) => theme.breakpoint('xl')} {
    width: 35px;
    height: 35px;
  }
`;
