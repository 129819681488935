import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@/components';
import { mastercardLogo, visaLogo } from '@/static/images';
import { useWindowResize } from '@/hooks';
import { theme } from '@/components/App';
import { CardInfo } from '../types';

type PaymentMethodProps = {
  card: CardInfo;
  onChangeCard: () => void;
};

export const PaymentMethod: FunctionComponent<PaymentMethodProps> = ({ card }) => {
  const { isMobileSize } = useWindowResize(900);
  const paymentError = false;

  const getSourceName = () => {
    if (card.card_type === 'master') return 'Mastercard';
    else return 'Visa';
  };

  return (
    <Container mobile={isMobileSize}>
      <PaymentMethodHeader mobile={isMobileSize}>
        <SubHeader mobile={isMobileSize}>Método de pago</SubHeader>
        {paymentError && (
          <PaymentError mobile={isMobileSize}>
            <IconContainer>
              <Icon
                name="wallet"
                color={theme.colors.red[200]}
                width={isMobileSize ? '12px' : '16px'}
                height={isMobileSize ? '12px' : '16px'}
              />
            </IconContainer>
            Error al procesar el pago
          </PaymentError>
        )}
      </PaymentMethodHeader>
      <PaymentInformation>
        <>
          {isMobileSize ? (
            <CardInformationMobile>
              <MobileColumn>
                <MastercardLogo src={true ? mastercardLogo : visaLogo} />
                <Button disabled={true} size="sm" color={theme.colors.grey[500]}>
                  <DeleteButtonText>Eliminar</DeleteButtonText>
                </Button>
              </MobileColumn>
              <MobileColumn>
                <CardData>
                  <BoldedText error={paymentError}>{getSourceName()}</BoldedText>
                  <Text>{`... ${card.card_last_code}`}</Text>
                </CardData>
                {/* <Button onClick={onChangeCard} size="sm">
                    <ChangeButtonText>Cambiar</ChangeButtonText>
                  </Button> */}
              </MobileColumn>
            </CardInformationMobile>
          ) : (
            <>
              <CardInformation>
                <MastercardLogo src={true ? mastercardLogo : visaLogo} />
                <CardData>
                  <BoldedText error={paymentError}>{getSourceName()}</BoldedText>
                  <Text>{`... ${card.card_last_code}`}</Text>
                </CardData>
              </CardInformation>
              <PaymentActions>
                {/* ToDo: Hook this with the logic to delete or change a payment method */}
                {/* <DeleteCardButton>
                    <Button
                      onClick={onChangeCard}
                      size="sm"
                      paddingHorizontal={16}
                      color={theme.colors.grey[700]}
                      hoverColor={theme.colors.grey[800]}
                    >
                      <ChangeButtonText>Eliminar</ChangeButtonText>
                    </Button>
                  </DeleteCardButton>
                  <Button onClick={onChangeCard} size="sm" paddingHorizontal={16}>
                    <ChangeButtonText>Cambiar</ChangeButtonText>
                  </Button> */}
              </PaymentActions>
            </>
          )}
        </>
      </PaymentInformation>
    </Container>
  );
};

const Container = styled.div<{ mobile: boolean }>`
  flex: 1;
  margin-left: ${({ mobile }) => (mobile ? 0 : '10px')};
  margin-top: ${({ mobile }) => (mobile ? '22px' : 0)};
  padding: ${({ mobile }) => (mobile ? '14px 16px' : '30px 32px')};
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  ${({ theme }) => theme.breakpoint('xl')} {
    padding: 24px 12px;
  }
  ${({ theme }) => theme.breakpoint('md')} {
    padding: 14px 16px;
  }
`;

const PaymentMethodHeader = styled.div<{ mobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: ${({ mobile }) => (mobile ? '15px' : '30px')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
`;

const SubHeader = styled.h2<{ mobile: boolean }>`
  font-size: ${({ mobile }) => (mobile ? '14px' : '20px')};
  font-weight: ${({ mobile }) => (mobile ? 'normal' : 'bold')};
  line-height: 23px;
  color: ${({ theme }) => theme.colors.black};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

const PaymentError = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ mobile }) => (mobile ? '12px' : '16px')};
  color: ${({ theme }) => theme.colors.red[200]};
`;

const CardInformationMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MobileColumn = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
`;

const PaymentInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 16px;
`;

const CardInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CardData = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  ${({ theme }) => theme.breakpoint('xl')} {
    margin-top: 0px;
  }
`;

const MastercardLogo = styled.img`
  width: 117px;
  height: 77px;
`;

const Text = styled.p<{ error?: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme, error }) => (error ? theme.colors.red[200] : theme.colors.black)};
`;

const BoldedText = styled(Text)`
  font-weight: bold;
  margin-bottom: 4px;
`;

const DeleteButtonText = styled.p`
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.white};
  display: inline;
  color: ${({ theme }) => theme.colors.grey[700]};
`;

const PaymentActions = styled.div`
  display: flex;
  flex-direction: row;
`;
