import classNames from 'classnames';
import React from 'react';
import { Row } from '../Row';
import PI from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ReactPhoneInput = PI;

type Props = {
  error?: boolean;
  id?: string;
  disabled?: boolean;
  inputPhoneProps?: Partial<React.ComponentProps<typeof ReactPhoneInput>>;
  callbackDropdownOpen?: (isOpen: boolean) => void;
  code?: string;
};

export const InputPhone: React.FC<Props> = ({
  error = false,
  disabled,
  inputPhoneProps,
  id,
  callbackDropdownOpen,
  code
}) => {
  const phoneInputRef = React.useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);

  React.useEffect(() => {
    const handleDropdownClassChange = () => {
      const dropdown = phoneInputRef.current?.querySelector('.flag-dropdown');
      const isOpen = dropdown?.classList.contains('open') ?? false;
      setDropdownOpen(isOpen);
    };
    const observer = new MutationObserver(handleDropdownClassChange);
    if (phoneInputRef.current) {
      observer.observe(phoneInputRef.current, { childList: true, subtree: true });
    }
    return () => observer.disconnect();
  }, []);

  React.useEffect(() => {
    if (callbackDropdownOpen) callbackDropdownOpen(isDropdownOpen);
  }, [isDropdownOpen, callbackDropdownOpen]);

  return (
    <Row
      align="center"
      ref={phoneInputRef}
      id={id}
      justify="space-between"
      gap={4}
      className={classNames({
        'w-full h-[40px] bg-clip-padding border border-solid border-[#0000005c] rounded transition ease-in-out m-0 focus:outline-none':
          true,
        'border-red-500': error,
        'cursor-not-allowed bg-gray-100': disabled,
        'bg-white focus-within:text-gray-700 focus-within:border-[#0072FB]': !disabled
      })}
    >
      <ReactPhoneInput
        disabled={disabled}
        {...inputPhoneProps}
        containerStyle={{ width: '100%', border: 'none', height: '38px' }}
        inputStyle={{ width: '100%', border: 'none', height: '38px' }}
        buttonStyle={{ border: 'none', borderRight: '1px solid #0000005c', height: '38px' }}
        onlyCountries={['ar', 'uy', 'cl', 'br']}
        country={code || 'ar'}
      />
    </Row>
  );
};
