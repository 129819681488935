import React, { FunctionComponent, useState, useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DragUpdate
} from '@hello-pangea/dnd';
import { Icon, InputMultipleFile } from '@/components';
import { theme } from '@/components/App';
import { addImage } from '@/static/images';
import { Image } from '@/containers/ActionUniqueEvent/utils';
import { ImageActionButton, ImageGaleryCropper } from './components';

const reorder = (list: Array<Image>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type ImageGaleryProps = {
  images: Image[];
  setImages: Dispatch<SetStateAction<Image[]>>;
  title: JSX.Element;
};

export const ImageGalery: FunctionComponent<ImageGaleryProps> = ({ images, setImages, title }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();

  const nextImageToFill = useMemo(() => images.findIndex((image) => !!!image.url), [images]);

  const onDragEnd = useCallback(
    (result: DragUpdate) => {
      if (!result.destination || (nextImageToFill - 1 < result.destination.index && nextImageToFill > 0)) {
        return;
      }

      const items = reorder(images, result.source.index, result.destination.index);

      setImages(items);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [nextImageToFill, setImages, images]
  );

  const handleCrop = useCallback(
    (image: any) => {
      setShowModal(true);
      setSelectedImage(image);
    },
    [setShowModal, setSelectedImage]
  );

  const handleDelete = useCallback(
    (image: any) => {
      let deletedImageIndex = 0;
      const newImages = images.map((img, index) => {
        if (img.id === image.id) {
          deletedImageIndex = index;
          return {
            id: image.id
          };
        }
        return img;
      });

      const firstFullImage = images.length - 1 - images.reverse().findIndex((image) => !!image.url);
      let items = newImages;
      if (firstFullImage >= deletedImageIndex) {
        items = reorder(newImages, deletedImageIndex, images.length - 1);
      }
      setImages(items);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [setImages, images]
  );

  const handleFile = useCallback(
    (newImgs: any) => {
      let iterator = 0;
      const newImages = images.map((image) => {
        if (!image.url && newImgs?.[iterator]) {
          const selectedImg = newImgs[iterator];
          iterator++;
          return {
            id: image.id,
            url: URL.createObjectURL(selectedImg),
            blob: selectedImg
          };
        }
        return image;
      });

      setImages(newImages);
    },
    [images, setImages]
  );

  return (
    <>
      {showModal && selectedImage && (
        <ImageGaleryCropper
          selectedImage={selectedImage}
          onClose={() => setShowModal(false)}
          setImages={setImages}
          images={images}
        />
      )}
      {title}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction="horizontal" droppableId="droppable">
          {(provided: DroppableProvided) => (
            <Grid {...provided.droppableProps} ref={provided.innerRef}>
              <AddNewImageButton>
                <InputMultipleFile
                  multiple
                  reference="add-image"
                  disabled={nextImageToFill === -1}
                  handleFile={handleFile}
                  styles={addImageCss}
                >
                  <AddNewImage src={addImage} />
                  <p>Agregar imágenes</p>
                </InputMultipleFile>
              </AddNewImageButton>
              {images.map((item: Image, index: number) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!!!item.url}>
                  {(provided: DraggableProvided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        display: 'flex',
                        flex: 1,
                        ...provided.draggableProps.style
                      }}
                    >
                      <ImagePreview src={item.url}>
                        {!!!item.url ? (
                          <Icon name="image" width="50px" height="57px" color={theme.colors.white + '80'} />
                        ) : (
                          <ImageActionButton
                            handleCrop={() => handleCrop(item)}
                            handleDelete={() => handleDelete(item)}
                          />
                        )}
                      </ImagePreview>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  ${({ theme }) => theme.breakpoint('md')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  ${({ theme }) => theme.breakpoint('sm')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const AddNewImage = styled.img`
  margin-bottom: 11px;
`;

const addImageCss = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AddNewImageButton = styled.div`
  height: 150px;
  width: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 5px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.blue[500]};
  }
`;

const ImagePreview = styled.div<{ src?: string }>`
  height: 150px;
  width: 150px;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  display: flex;
  flex: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ src }) =>
    !!src &&
    css`
      background-image: url('${src}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}
`;
