import {
  UniqueEventI,
  VendorBlockedHoursI,
  VendorOnDemandHoursI,
  VendorOnDemandHoursMergedI
} from '@/types/cyclone/models';
import { GetEventsWithVirtualI, VendorWorkingHourI, VendorWorkingHoursI } from '@/types/cyclone/requests';
import { getDayWithTime, getTime, getNewId, roundToNearestFiveMinutes } from '@/utils';
import {
  WorkingHourWithDate,
  WorkingHour,
  CombinationType,
  ScheduleType,
  ScheduleStatus,
  CombinationStatus
} from './types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const associateWorkingHoursWithDates = (
  workingHours: VendorWorkingHoursI,
  startDate: Date,
  endDate: Date
): WorkingHourWithDate[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate).subtract(1, 'day'); // Subtract one day from the end date
  const workingHourSlots: WorkingHourWithDate[] = [];

  // Iterate through each day in the range
  for (let day = start; day.isSameOrBefore(end, 'day'); day = day.add(1, 'day')) {
    const dayOfWeek = day.day(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

    const workingHour = workingHours.find((wh) => wh.day === dayOfWeek);

    if (workingHour && workingHour.schedules.length > 0) {
      workingHour.schedules.forEach((schedule) => {
        workingHourSlots.push({
          date: day.format('YYYY-MM-DD'),
          startAt: schedule.start_time,
          endAt: schedule.end_time
        });
      });
    }
  }

  return workingHourSlots;
};

// Given an array of working hours and one of event instances, generate the working hour
// slots that shouldn't be taken up by events
export const getAllowedWorkingHours = (
  workingHours: WorkingHourWithDate[],
  eventInstances: GetEventsWithVirtualI
): WorkingHourWithDate[] => {
  const finalWorkingHours: WorkingHourWithDate[] = [];

  // Group working hours by date
  const workingHoursByDate = workingHours.reduce((acc, workingHour) => {
    if (!acc[workingHour.date]) {
      acc[workingHour.date] = [];
    }
    acc[workingHour.date].push(workingHour);
    return acc;
  }, {} as Record<string, WorkingHourWithDate[]>);

  Object.entries(workingHoursByDate).forEach(([date, dateWorkingHours]) => {
    const matchingDayEvents = eventInstances
      .filter(
        (eventInstance) =>
          date === eventInstance.start_at && eventInstance.start_time && eventInstance.end_time
      )
      .sort((a, b) => a.start_time.localeCompare(b.start_time));

    let availableSlots: WorkingHourWithDate[] = dateWorkingHours;

    matchingDayEvents.forEach((event) => {
      availableSlots = availableSlots.flatMap((slot) => {
        if (event.start_time >= slot.endAt || event.end_time <= slot.startAt) {
          return [slot];
        }
        const beforeSlot =
          event.start_time > slot.startAt
            ? {
                date,
                startAt: slot.startAt,
                endAt: event.start_time
              }
            : null;
        const afterSlot =
          event.end_time < slot.endAt
            ? {
                date,
                startAt: event.end_time,
                endAt: slot.endAt
              }
            : null;
        return [beforeSlot, afterSlot].filter(Boolean) as WorkingHourWithDate[];
      });
    });

    finalWorkingHours.push(...availableSlots);
  });

  // Remove the " hs" suffix, merge adjacent slots, and filter out zero-duration slots
  const cleanedAndMergedHours = finalWorkingHours.reduce((acc, curr) => {
    const cleanedCurr = {
      ...curr,
      startAt: curr.startAt.replace(' hs', ''),
      endAt: curr.endAt.replace(' hs', '')
    };

    // Skip zero-duration slots
    if (cleanedCurr.startAt === cleanedCurr.endAt) {
      return acc;
    }

    if (acc.length === 0) return [cleanedCurr];
    const last = acc[acc.length - 1];
    if (last.date === cleanedCurr.date && last.endAt === cleanedCurr.startAt) {
      last.endAt = cleanedCurr.endAt;
    } else {
      acc.push(cleanedCurr);
    }
    return acc;
  }, [] as WorkingHourWithDate[]);

  // Additional step to ensure no zero-duration slots
  const finalFilteredHours = cleanedAndMergedHours.filter((slot) => slot.startAt !== slot.endAt);

  return finalFilteredHours;
};

// Receives an array of hours and generates time slots basing out of time skips
export const generateWorkingHoursSlots = (hoursArray: string[]): WorkingHour[] => {
  const slicedWorkingHours: WorkingHour[] = [];

  if (hoursArray.length > 1) {
    const begginingOfArray = hoursArray[0];
    const endOfArray = hoursArray[hoursArray.length - 1];
    const timeSkips: {
      hour: string;
      status: 'endAt' | 'startAt';
    }[] = [];

    // For each hour minute interval, we'll check the previous and next minute interval
    // on the array and check that they are correct (in example: if the current hour...
    // ...is 1:30 , the previous interval should be 1:29 and the next one 1:31), if
    // these intervals don't match correctly, a time skip has been found and we add it to
    // the timeSkips array.
    //
    // There are some special cases to pay attention and handle; the first and last
    // elements on the array do not count with both a nextMinute and previousMinute,
    // and hours that end on the minutes 0 or 59 need to be handled a little bit
    // differently to account for metric time.

    // The 'startAt' and 'endAt' statuses with add with each timeSkip are just
    // subjective tags that help us in order to decide when to start and end a time slot.
    hoursArray.forEach((hour, index) => {
      const minute = Number(hour.split(':')[1].split(' ')[0]);
      const nextMinute = hoursArray[index + 1] && Number(hoursArray[index + 1].split(':')[1].split(' ')[0]);
      const previousMinute = index !== 0 && Number(hoursArray[index - 1].split(':')[1].split(' ')[0]);

      if (nextMinute !== undefined) {
        if (minute === 59) {
          if (nextMinute !== 0) {
            timeSkips.push({
              hour: hour,
              status: 'endAt'
            });
          }
        } else if (minute === 0) {
          if (previousMinute !== 59) {
            timeSkips.push({
              hour: hour,
              status: 'startAt'
            });
          }
        } else if (minute + 1 !== nextMinute) {
          timeSkips.push({
            hour: hour,
            status: 'endAt'
          });
        } else if (minute - 1 !== previousMinute) {
          timeSkips.push({
            hour: hour,
            status: 'startAt'
          });
        }
      }
    });

    // If there were not time skips within the hoursArray, return a time slot with
    // just the first and last hours
    if (timeSkips.length === 0) {
      return [
        {
          startAt: begginingOfArray,
          endAt: endOfArray
        }
      ];
    } else {
      timeSkips.forEach((timeSkip, index) => {
        const nextTimeSkip = timeSkips[index + 1];
        const previousTimeSkip = timeSkips[index - 1];

        // If a previousTimeSkip doesn't exist, we are at the first element of the array
        // and if this time skip has an 'endAt' status, the beginning hour of this time slot
        // should be the first hour of the array
        if (previousTimeSkip === undefined && timeSkip.status === 'endAt') {
          slicedWorkingHours.push({
            startAt: begginingOfArray,
            endAt: timeSkip.hour
          });
          // Similar case to the previous one, if a nextTimeSkip doesn't exist, we are at
          // the last element of the array and if this time skip has an 'startAt' status
          // final hour of this time skip should be the last hour of the array
        } else if (nextTimeSkip === undefined && timeSkip.status === 'startAt') {
          slicedWorkingHours.push({
            startAt: timeSkip.hour,
            endAt: endOfArray
          });
        } else {
          // If the current timeSkip status is 'startAt' and the next time skip status
          // is 'endAt', we have all the data we need to create a time slot
          if (timeSkip.status === 'startAt' && nextTimeSkip.status === 'endAt') {
            slicedWorkingHours.push({
              startAt: timeSkip.hour,
              endAt: nextTimeSkip.hour
            });
          }
        }
      });
    }
  }

  return slicedWorkingHours;
};

// Formats a WorkingHourWithDate object to match with a CalendarEvent object
export const mapWorkingHourToCalendarEvent = (workingHour: WorkingHourWithDate, index: number) => {
  return {
    id: `workingHour-${index.toString()}`,
    start: getDayWithTime(workingHour.date, workingHour.startAt),
    end: getDayWithTime(workingHour.date, workingHour.endAt),
    title: 'Disponible',
    className: 'working-hour',
    timeDuration: `${roundToNearestFiveMinutes(workingHour.startAt)} a ${roundToNearestFiveMinutes(
      workingHour.endAt
    )}`,
    status: 'bookable',
    slots: 0,
    participants: 0,
    slotType: 'workingHour',
    customProp: 'custom',
    isIndividual: false,
    isWorkingHour: true,
    extendedProps: {}
  };
};

export const mapOnDemandHourToCalendarEvent = (
  workingHour: WorkingHourWithDateAndServices,
  index: number
) => {
  return {
    id: `onDemandHour-${index.toString()}`,
    start: getDayWithTime(workingHour.date, workingHour.startAt),
    end: getDayWithTime(workingHour.date, workingHour.endAt),
    title: 'Disponible',
    className: 'ondemand-hour',
    timeDuration: `${roundToNearestFiveMinutes(workingHour.startAt)} a ${roundToNearestFiveMinutes(
      workingHour.endAt
    )}`,
    status: 'bookable',
    slots: 0,
    participants: 0,
    slotType: 'onDemandHour',
    customProp: 'custom',
    isIndividual: false,
    isWorkingHour: false,
    isOnDemandHour: true,
    serviceIds: workingHour.serviceIds,
    extendedProps: { id: workingHour.id }
  };
};

export const mergeOnDemandHours = (onDemandHours: VendorOnDemandHoursI[]): VendorOnDemandHoursMergedI[] => {
  const mergedMap = new Map<string, VendorOnDemandHoursMergedI>();

  onDemandHours.forEach((hour) => {
    // We can have multiple on demand hours at the same slots but with difference service_id
    // in postgres is in this way to mantain the relation between on demand hours and services
    // but for the frontend we need to merge them into a single on demand hour with multiple service_ids
    // this function is responsible for that

    const key = `${hour.vendor_id}-${hour.start_at}-${JSON.stringify(hour.working_hours)}`;

    if (!mergedMap.has(key)) {
      mergedMap.set(key, {
        id: hour.id,
        vendor_id: hour.vendor_id,
        service_ids: hour.service_id ? [hour.service_id] : [],
        start_at: hour.start_at,
        working_hours: hour.working_hours,
        vendor: hour.vendor
      });
    } else {
      const existing = mergedMap.get(key)!;
      if (hour.service_id && !existing.service_ids?.includes(hour.service_id)) {
        existing.service_ids = [...(existing.service_ids || []), hour.service_id];
      }
    }
  });

  return Array.from(mergedMap.values());
};

interface WorkingHourWithDateAndServices extends WorkingHourWithDate {
  id: number;
  serviceIds: number[];
  vendorId: number;
}

export const associateOnDemandHoursWithDates = (
  onDemandHours: VendorOnDemandHoursI[]
): WorkingHourWithDateAndServices[] => {
  const workingHourSlots: WorkingHourWithDateAndServices[] = [];

  onDemandHours.forEach((onDemandHour) => {
    onDemandHour.working_hours.forEach((workingHour) => {
      workingHourSlots.push({
        id: onDemandHour.id,
        date: onDemandHour.start_at,
        startAt: workingHour.start_time,
        endAt: workingHour.end_time,
        serviceIds: onDemandHour.service_id ? [onDemandHour.service_id] : [],
        vendorId: onDemandHour.vendor_id
      });
    });
  });

  // Merge slots with the same date, start time, and end time
  const mergedSlots = workingHourSlots.reduce((acc, current) => {
    const existingSlot = acc.find(
      (slot) =>
        slot.date === current.date &&
        slot.startAt === current.startAt &&
        slot.endAt === current.endAt &&
        slot.vendorId === current.vendorId
    );

    if (existingSlot) {
      existingSlot.serviceIds = [...new Set([...existingSlot.serviceIds, ...current.serviceIds])];
    } else {
      acc.push(current);
    }

    return acc;
  }, [] as WorkingHourWithDateAndServices[]);

  return mergedSlots;
};

export const mapUniqueEventToCalendarEvent = (uniqueEvent: UniqueEventI, index: number) => {
  return uniqueEvent.unique_events_schedules.map((schedule) => {
    // convert start_at and end_at to date and delete +3 hours to match with the UTC time
    const start = dayjs(schedule.start_at).utc().toDate();
    const end = dayjs(schedule.end_at).utc().toDate();
    return {
      id: `uniqueEvent-${index.toString()}`,
      start,
      end,
      title: uniqueEvent.name,
      className: 'unique-event',
      timeDuration: `${dayjs(schedule.start_at).format('HH:mm')} a ${dayjs(schedule.end_at).format('HH:mm')}`,
      status: 'bookable',
      slots: 0,
      participants: 0,
      slotType: 'uniqueEvent',
      customProp: 'custom',
      isIndividual: false,
      isWorkingHour: false,
      isUniqueEvent: true,
      extendedProps: uniqueEvent
    };
  });
};

export const mapBlockedHourToCalendarEvent = (blockedHour: VendorBlockedHoursI, index: number) => {
  return {
    id: `blockedHour-${index.toString()}`,
    start: getDayWithTime(blockedHour.start_at.toString(), blockedHour.start_time),
    end: getDayWithTime(blockedHour.end_at.toString(), blockedHour.end_time),
    title: 'Horario bloqueado',
    className: 'blocked-hour',
    timeDuration: `${getTime(blockedHour.start_time)} a ${getTime(blockedHour.end_time)}`,
    status: 'blocked',
    slots: 0,
    participants: 0,
    slotType: 'blockedHour',
    customProp: 'custom',
    isIndividual: false,
    isWorkingHour: false,
    isUniqueEvent: false,
    isBlockedHour: true,
    extendedProps: blockedHour
  };
};

export const getInitialCombination = (serviceDuration: number): CombinationType => {
  let currentSchedule = dayjs().set('hour', 5).set('minutes', 45).set('seconds', 0);
  const endSchedule = dayjs().set('hour', 23).set('minutes', 0).set('seconds', 0);

  const schedulesMap: ScheduleType[] = [];
  while (endSchedule.isAfter(currentSchedule)) {
    currentSchedule = currentSchedule.add(15, 'minutes');
    const label = currentSchedule.format('HH:mm');
    const value = currentSchedule.format('HH:mm:ss');

    const currentEndSchedule = currentSchedule.add(serviceDuration, 'minutes');
    const isTaken = currentEndSchedule.isAfter(endSchedule);

    schedulesMap.push({
      label,
      value,
      date: currentSchedule,
      status: ScheduleStatus[isTaken ? 'TAKEN' : 'FREE']
    });
  }

  const status = CombinationStatus.PENDING;

  const id = getNewId();

  return { selectedDays: undefined, schedules: schedulesMap, selectedSchedules: [], status, id };
};

export const getInitialSchedules = (
  serviceDuration: number,
  selectedSchedules: ScheduleType[]
): ScheduleType[] => {
  const intervalsTaken = selectedSchedules.map(({ date }) => {
    const from = date.subtract(serviceDuration, 'minutes');
    const to = date.add(serviceDuration, 'minutes');

    return { from, to };
  });

  let currentSchedule = dayjs().set('hour', 5).set('minutes', 45).set('seconds', 0);
  const endSchedule = dayjs().set('hour', 23).set('minutes', 0).set('seconds', 0);

  const schedulesMap: ScheduleType[] = [];
  while (endSchedule.isAfter(currentSchedule)) {
    currentSchedule = currentSchedule.add(15, 'minutes');
    const label = currentSchedule.format('HH:mm');
    const value = currentSchedule.format('HH:mm:ss');

    const currentEndSchedule = currentSchedule.add(serviceDuration, 'minutes');
    const isEndTaken = currentEndSchedule.isAfter(endSchedule);

    const isTakenFromOther = intervalsTaken.some(
      (interval) => currentSchedule.isAfter(interval.from) && currentSchedule.isBefore(interval.to)
    );

    const isTaken = isEndTaken || isTakenFromOther;

    schedulesMap.push({
      label,
      value,
      date: currentSchedule,
      status: ScheduleStatus[isTaken ? 'TAKEN' : 'FREE']
    });
  }

  return schedulesMap;
};

export function filterWorkingHours(
  workingHours: VendorWorkingHourI[],
  blockedHours: VendorBlockedHoursI[],
  referenceDate: Date
): VendorWorkingHourI[] {
  const processedWorkingHours = Array.from({ length: 7 }, (_, index) => {
    const dayIndex = index;
    const dayDate = new Date(referenceDate);
    dayDate.setDate(referenceDate.getDate() + ((dayIndex + 7 - referenceDate.getDay()) % 7));

    const workingHour = workingHours.find((hour) => hour.day === dayIndex);

    if (workingHour) {
      // Process each working hour schedule
      const processedSchedules = workingHour.schedules.flatMap((schedule) => {
        const scheduleStart = dayjs(`${dayDate.toISOString().slice(0, 10)}T${schedule.start_time}`);
        const scheduleEnd = dayjs(`${dayDate.toISOString().slice(0, 10)}T${schedule.end_time}`);
        let availablePeriods = [{ start: scheduleStart, end: scheduleEnd }];

        // Filter out times that fall within the blocked hours
        blockedHours.forEach((blocked) => {
          const blockedStart = dayjs(`${blocked.start_at}T${blocked.start_time}`);
          const blockedEnd = dayjs(`${blocked.end_at}T${blocked.end_time}`);

          availablePeriods = availablePeriods.flatMap(({ start, end }) => {
            if (blockedStart.isBefore(end) && blockedEnd.isAfter(start)) {
              const newPeriods = [];
              if (blockedStart.isAfter(start)) {
                newPeriods.push({ start, end: blockedStart });
              }
              if (blockedEnd.isBefore(end)) {
                newPeriods.push({ start: blockedEnd, end });
              }
              return newPeriods;
            }
            return [{ start, end }];
          });
        });

        return availablePeriods.map((period) => ({
          start_time: period.start.format('HH:mm'),
          end_time: period.end.format('HH:mm')
        }));
      });

      return { ...workingHour, schedules: processedSchedules };
    } else {
      return { day: dayIndex, schedules: [] };
    }
  });

  return processedWorkingHours.filter((day) => day.schedules.length > 0);
}

export const fullCalendarStyles = (view: string) => {
  let styles = `
    .fc-timegrid-event-harness-inset {
      inset-inline-start: 0% !important;
      z-index: initial !important;
      width: 100%;
    }
    .agora-event {
      border: 0px !important;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 5 !important;
      cursor: pointer;
    }
    
    .working-hour {
      border: 0px !important;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      cursor: pointer;
    }
    .ondemand-hour {
      border: 0px !important;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      cursor: pointer;
    }
    .unique-event {
      border: 0px !important;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1 !important;
      cursor: pointer;
    }
    .blocked-hour {
      border: 0px !important;
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 4 !important;
      cursor: pointer;
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
      border: 0px !important;
      box-shadow: none !important;
      width: 100%;
    }
    .fc-event-main {
      padding: 0px !important;
    }
    tr {
      height: 45px;
    }
    .fc-day-today .fc-timegrid-now-indicator-container {
      background-color: #fafafa !important;
    }
    .fc-view-harness .fc-view-harness-active {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .fc-col-header-cell-cushion {
      text-transform: capitalize;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
    }
    .fc-timegrid-slot-label-cushion {
      font-size: 12px;
      line-height: 15px;
      color: #858585;
      position: relative;
      bottom: 14px;
      vertical-align: sub;
    }
    .fc-view-harness {
      max-width: 1536px;
      width: 100%;
      margin: 0px auto;
    }
    t {
      border: 1px solid green !important;
    }
    .fc-toolbar {
      width: 100%;
      margin: 0 auto;
    }
    .fc-col-header tr {
      height: 57px;
    }
    .fc-col-header th {
      vertical-align: middle;
    }
    .fc-timegrid-now-indicator-container {
      border-left: 0px !important;
    }
    .fc-timegrid-slot-label {
      vertical-align: top !important;
      border-width: 0px !important;
    }
    th {
      border-right: 0px !important;
      border-left: 0px !important;
      border-top: 0px !important;
    }
    .fc-theme-standard .fc-scrollgrid {
      border-right: 0px;
      border-left: 0px;
      border-top: 0px;
    }
    .fc-timegrid-axis {
      border: 0px !important;
    }
    .fc-col-header {
      background: #fbfbfb;
    }
    .fc .fc-timegrid-slot {
      height: 80px !important;
    }
    .fc-day-today {
      color: #0072FB !important;
    }
    .fc-day-past {
      color: #868686 !important;
    }
  `;

  if (view === 'listWeek') {
    styles += `
    .fc-list-day-text {
      text-transform: capitalize;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
    }
    .fc-list-day-cushion {
      background-color: white !important;
      padding: 16px 25px;
    }
    .fc-list-day th {
      border-bottom-width: 0px !important;
    }
    .fc-list-event-title {
      border-top-width: 0px !important;
    }
    .fc-list-event-graphic,
    .fc-list-event-time {
      display: none;
    }
    .listview {
      font-size: 14px;
      padding: 8px 4px !important;
    }
    .agora-event, .working-hour, .unique-event, .blocked-hour, .ondemand-hour {
      padding: 16px 25px 16px 25px !important;
    } 
    `;
  }

  return <style>{styles}</style>;
};

export const removeQueryParam = (url: string, param: string) => {
  // Crear un objeto URL a partir de la URL proporcionada
  const urlObj = new URL(url);
  // Obtener los parámetros de la URL
  const params = urlObj.searchParams;
  // Eliminar el parámetro especificado
  params.delete(param);
  // Reconstruir la URL sin el parámetro eliminado
  urlObj.search = params.toString();
  // Devolver la URL modificada
  return urlObj.toString();
};
