import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts';
import { Layout } from '../Layout';
import usePageTracking from '@/hooks/usePageTracking';

type PrivateRouteProps = {
  layout?: boolean;
  children?: React.ReactNode;
};

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ children, layout = true }) => {
  usePageTracking();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return <Navigate replace to="/" />;

  return layout ? (
    <>
      <Layout>{children}</Layout>
    </>
  ) : (
    <>{children}</>
  );
};
