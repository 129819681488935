import React, { FunctionComponent } from 'react';
import { GetServiceI } from '@/types/cyclone/requests';
import { Column, Row } from '@/components';
import { getFormatDuration } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { formatCurrency } from '@/utils/money';
import { useAuth } from '@/contexts';

type ServiceProps = {
  service: GetServiceI;
  selectService: (serviceId: number) => void;
  onClick?: () => void;
};

export const Service: FunctionComponent<ServiceProps> = ({ service, selectService }) => {
  const { name, id, duration, price, down_payment } = service;
  const { session } = useAuth();

  const country = session ? session!.vendor!.country : SupportedCountriesE.ARGENTINA;

  return (
    <Column
      key={id}
      gap={4}
      className="w-full py-2 first:border-t border-b hover:bg-[#E9E9E9] transition-colors duration-200 cursor-pointer px-4"
      onClick={() => {
        selectService(id);
      }}
    >
      <div className="flex w-full">
        <p className="text-left text-lg font-bold w-full leading-tight truncate">{name}</p>
      </div>
      <Row align="baseline" gap={4} className="text-sm w-full h-auto">
        <FontAwesomeIcon icon={faClock} className="text-[#868686]" size="xs" />
        <span className="text-[#868686] whitespace-nowrap">{getFormatDuration(duration)}</span>
        <span className="text-[#868686]">{' · '}</span>
        <span className="text-[#868686]">{formatCurrency(price, country)}</span>
        {down_payment && (
          <>
            <span className="text-[#868686]">{' · '}</span>
            <span className="text-[#868686]">{`Seña: ${
              down_payment.amount_discount
                ? `${down_payment.amount_discount * 100}% del total`
                : formatCurrency(down_payment.amount, country)
            }`}</span>
          </>
        )}
      </Row>
    </Column>
  );
};
