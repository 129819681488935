import React, { ChangeEvent, FunctionComponent } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input } from '@/components';
import closeIcon from '@/static/images/close-icon.svg';
import { defaultZone, areaColors } from '../../utils';
import { ZoneType, AreaColorsType } from '../../types';

type ZoneProps = {
  zone: ZoneType;
  index: number;
  zones: ZoneType[];
  setZones: (zones: ZoneType[]) => void;
};

export const Zone: FunctionComponent<ZoneProps> = ({ zone, zones, setZones, index }) => {
  const { name, color, isSaved, id, data } = zone;

  return (
    <Container>
      <ZoneHeader>
        <ZoneTitle>
          <ZoneColor color={color} />
          <ZoneName>{name}</ZoneName>
        </ZoneTitle>
        <CloseIcon
          src={closeIcon}
          onClick={() => {
            setZones(
              zones.filter((zone, i) => {
                return i !== index;
              })
            );
          }}
        />
      </ZoneHeader>
      {isSaved === false && (
        <ZoneBody>
          <SetColor>
            <LabelColumn>Color</LabelColumn>
            <SetValueColumn>
              {Object.keys(areaColors).map((key: string) => (
                <ZoneColor
                  isClickable
                  key={key}
                  color={areaColors[key as keyof AreaColorsType]}
                  onClick={() => {
                    setZones(
                      zones.map((zone: ZoneType, zoneIndex: number) => {
                        if (zoneIndex === index) {
                          return {
                            ...zone,
                            color: areaColors[key as keyof AreaColorsType]
                          };
                        } else {
                          return zone;
                        }
                      })
                    );
                  }}
                />
              ))}
            </SetValueColumn>
          </SetColor>
          <SetZoneName>
            <LabelColumn>Nombre</LabelColumn>
            <SetValueColumn>
              <Input
                fullWidth
                variant="input"
                inputId={`input-${id}`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setZones(
                    zones.map((zone, zoneIndex) => {
                      if (zoneIndex === index) {
                        return {
                          ...zone,
                          name: e.target.value
                        };
                      } else {
                        return zone;
                      }
                    })
                  );
                }}
                value={name}
              />
            </SetValueColumn>
          </SetZoneName>
          <ActionButtons>
            <ZoneButton>
              <Button
                disabled={data === undefined}
                fullWidth
                size="sm"
                onClick={() => {
                  const newZones = zones.map((zone, zoneIndex) => {
                    if (zoneIndex === index) {
                      return {
                        ...zone,
                        isSaved: true
                      };
                    } else {
                      return zone;
                    }
                  });

                  setZones([
                    ...newZones,
                    { ...defaultZone, name: 'Zona ' + (zones.length + 1), id: uuidv4() }
                  ]);
                }}
              >
                Guardar
              </Button>
            </ZoneButton>
            <ZoneButton>
              <Button
                fullWidth
                size="sm"
                disabled={data === undefined}
                onClick={() => {
                  setZones(
                    zones.map((zone, zoneIndex) => {
                      if (zoneIndex === index) {
                        return {
                          ...zone,
                          isSaved: false,
                          data: undefined
                        };
                      } else {
                        return zone;
                      }
                    })
                  );
                }}
              >
                Eliminar
              </Button>
            </ZoneButton>
          </ActionButtons>
        </ZoneBody>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 24px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background: #fff;
  padding: 14px 16px 14px 20px;
`;

const ZoneHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ZoneTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ZoneColor = styled.div<{ color: string; isClickable?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid ${({ color }) => color};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  margin-right: ${({ isClickable }) => (isClickable ? '16px' : 0)};
`;

const ZoneName = styled.div`
  margin-left: 9px;
`;

const CloseIcon = styled.img`
  height: 19px;
  display: inline;
  cursor: pointer;
`;

const ZoneBody = styled.div``;

const SetColor = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
`;
const SetZoneName = styled.div`
  margin-top: 21px;
  display: flex;
  flex-direction: row;
`;
const ActionButtons = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ZoneButton = styled.div`
  width: 40%;
`;

const LabelColumn = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
`;

const SetValueColumn = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
