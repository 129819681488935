import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { formatCurrencyWithLocale } from '@/utils/money';
import dayjs from 'dayjs';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import classNames from 'classnames';
import CustomTooltip from './CustomTooltip';

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

interface SalesReportProps {
  data: { date: string; actual: number; previous: number; previousDate: string }[];
  title: string;
  accumulatedValue: number;
  isLoading?: boolean;
  previousPeriod?: string;
}

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center h-[300px] text-center">
    <FontAwesomeIcon icon={faChartBar} className="text-6xl text-gray-300 mb-4" />
    <h4 className="text-lg font-semibold text-gray-700 mb-2">No hay datos disponibles</h4>
    <div className="flex items-center bg-blue-50 text-blue-700 text-sm p-3 rounded-md">
      <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
      <span>Los datos se actualizarán automáticamente cuando estén disponibles.</span>
    </div>
  </div>
);

const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-1/4 mb-4" />
    <div className="h-6 bg-gray-200 rounded w-1/3 mb-6" />
    <div className="h-[240px] bg-gray-200 rounded" />
  </div>
);

const VIEW_OPTIONS = [
  { label: 'Trimestral', value: 'quarterly' },
  { label: 'Mensual', value: 'monthly' },
  { label: 'Semanal', value: 'weekly' },
  { label: 'Diario', value: 'daily' }
];

const SalesReportChart: React.FC<SalesReportProps> = ({
  data,
  title,
  accumulatedValue,
  isLoading = false,
  previousPeriod
}) => {
  const [view, setView] = React.useState<string>('daily');

  const getFilteredViewOptions = () => {
    if (!data || data.length === 0) return [];

    const firstDate = dayjs(data[0].date);
    const lastDate = dayjs(data[data.length - 1].date);
    const totalWeeks = lastDate.diff(firstDate, 'week');
    const totalMonths = lastDate.diff(firstDate, 'month');

    return VIEW_OPTIONS.filter((option) => {
      if (option.value === 'quarterly' && totalMonths === 12) return true;
      if (option.value === 'monthly' && totalMonths >= 2) return true;
      if (option.value === 'weekly' && totalWeeks >= 2) return true;
      if (option.value === 'daily') return true;
      return false;
    });
  };

  const filteredViewOptions = getFilteredViewOptions();

  const formatDate = (date: string) => dayjs(date).format('D MMM. YYYY');

  const getPeriod = () => {
    const initialPeriodFirstDate = dayjs(data[0].date);
    const initialPeriodLastDate = dayjs(data[data.length - 1].date);
    if (initialPeriodFirstDate.isSame(initialPeriodLastDate)) {
      return initialPeriodFirstDate.format('D MMM YYYY');
    } else {
      const initialPeriod = `${initialPeriodFirstDate.format('D MMM YYYY')} - ${initialPeriodLastDate.format(
        'D MMM YYYY'
      )}`;

      return `${initialPeriod}`;
    }
  };

  const getData = () => {
    if (!data || data.length === 0) return [];

    const first = data[0];
    const last = data[data.length - 1];

    if (view === 'quarterly') {
      const quarterlyData = data.reduce((acc, { date, actual, previous, previousDate }) => {
        const quarter = `Q${dayjs(date).quarter()}`;
        if (!acc[quarter]) {
          acc[quarter] = { actual: 0, previous: 0, date: '', previousDate: '' };
        }
        acc[quarter].actual += actual;
        acc[quarter].previous += previous;
        acc[quarter].date = date;
        acc[quarter].previousDate = previousDate;
        return acc;
      }, {} as Record<string, { actual: number; previous: number; date: string; previousDate: string }>);

      const result = Object.keys(quarterlyData).map((quarter) => ({
        date: formatDate(quarterlyData[quarter].date),
        previousDate: formatDate(quarterlyData[quarter].previousDate),
        actual: quarterlyData[quarter].actual,
        previous: quarterlyData[quarter].previous
      }));

      ensureFirstAndLast(result, first, last);

      return result;
    }

    if (view === 'monthly') {
      const monthlyData = data.reduce((acc, { date, actual, previous, previousDate }) => {
        const month = dayjs(date).format('MMM');
        if (!acc[month]) {
          acc[month] = { actual: 0, previous: 0, date: '', previousDate: '' };
        }
        acc[month].actual += actual;
        acc[month].previous += previous;
        acc[month].date = date;
        acc[month].previousDate = previousDate;
        return acc;
      }, {} as Record<string, { actual: number; previous: number; date: string; previousDate: string }>);

      const result = Object.keys(monthlyData).map((month) => ({
        date: formatDate(monthlyData[month].date),
        previousDate: formatDate(monthlyData[month].previousDate),
        actual: monthlyData[month].actual,
        previous: monthlyData[month].previous
      }));

      ensureFirstAndLast(result, first, last);

      return result;
    }

    if (view === 'weekly') {
      const weeklyData = data.reduce((acc, { date, actual, previous, previousDate }) => {
        const week = `W${dayjs(date).week()}`;
        if (!acc[week]) {
          acc[week] = { actual: 0, previous: 0, date: '', previousDate: '' };
        }
        acc[week].actual += actual;
        acc[week].previous += previous;
        acc[week].date = date;
        acc[week].previousDate = previousDate;
        return acc;
      }, {} as Record<string, { actual: number; previous: number; date: string; previousDate: string }>);

      const result = Object.keys(weeklyData).map((week) => ({
        date: formatDate(weeklyData[week].date),
        previousDate: formatDate(weeklyData[week].previousDate),
        actual: weeklyData[week].actual,
        previous: weeklyData[week].previous
      }));

      ensureFirstAndLast(result, first, last);

      return result;
    }

    if (view === 'daily') {
      const result = data.map(({ date, actual, previous, previousDate }) => ({
        date: formatDate(date),
        previousDate: formatDate(previousDate),
        actual,
        previous
      }));

      ensureFirstAndLast(result, first, last);

      return result;
    }
  };

  const ensureFirstAndLast = (result: any[], first: any, last: any) => {
    if (!result.find((entry) => entry.date === formatDate(first.date))) {
      result.unshift({
        date: formatDate(first.date),
        previousDate: formatDate(first.previousDate),
        actual: first.actual,
        previous: first.previous
      });
    }

    if (!result.find((entry) => entry.date === formatDate(last.date))) {
      result.push({
        date: formatDate(last.date),
        previousDate: formatDate(last.previousDate),
        actual: last.actual,
        previous: last.previous
      });
    }
  };

  const dataToShow = getData();

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg border border-[#E9E9E9] p-6">
        <SkeletonLoader />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-[#E9E9E9] sm:p-6 p-4 animate-fadeIn">
      <div className="flex justify-between sm:items-center mb-4">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-[60%]">
          <h3 className="flex w-max whitespace-nowrap text-lg sm:text-xl font-semibold">{title}</h3>
          <Select disabled={data.length === 0} value={view} onValueChange={(value) => setView(value)}>
            <SelectTrigger className="sm:!w-[30%] !h-9">
              <FontAwesomeIcon size="sm" icon={faChartLine} color="#626262" fixedWidth className="mr-2" />
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="max-h-[200px]">
              {filteredViewOptions.map((option) => {
                const isSelected = option.value === view;
                return (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    className={classNames({
                      'bg-blue text-white': isSelected,
                      'hover:bg-gray-100': !isSelected
                    })}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        <div className="text-right">
          <span className="text-sm text-gray-500">Acumulado:</span>
          <span className="text-lg font-medium ml-2">{formatCurrencyWithLocale(accumulatedValue)}</span>
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={dataToShow} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <defs>
                <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0072FF" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#0072FF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                interval="preserveStartEnd"
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#626262', fontSize: 12 }}
                minTickGap={20}
                allowDuplicatedCategory={false}
              />
              <YAxis hide={true} />
              <Tooltip content={<CustomTooltip view={view} />} />
              <Area
                type="monotone"
                dataKey="actual"
                stroke="#0072FF"
                fillOpacity={1}
                fill="url(#colorActual)"
                strokeWidth={2}
              />
              <Area
                type="monotone"
                dataKey="previous"
                stroke="#85B6FF"
                fillOpacity={1}
                fill="url(#colorPrevious)"
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className="flex flex-col sm:flex-row sm:gap-4 mt-4 text-sm text-gray-500">
            <span className="flex items-center gap-1">
              <div className="w-2 h-2 mt-0.5 mask-mask-circle rounded-full bg-[#0072FF]" />
              {getPeriod()}
            </span>
            <span className="flex items-center gap-1">
              <div className="w-2 h-2 mt-0.5 mask-mask-circle rounded-full bg-[#85B6FF]" />
              Comparación {previousPeriod}
            </span>
          </div>
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default SalesReportChart;
