import { useState } from 'react';
import { SelectOptionProps } from '@/components';
import { ServiceStatusE, SubscriptionStatusE } from '@/types/cyclone/models';

const itemsActive = [
  { label: 'Activo', value: true },
  { label: 'Inactivo', value: false }
];

type UseActiveReturnType = {
  isActive: boolean;
  optionsActive: SelectOptionProps[];
  handleActive: (status: SubscriptionStatusE | ServiceStatusE) => void;
};

export const useActive = (): UseActiveReturnType => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const optionsActive: SelectOptionProps[] = itemsActive.map(({ label, value }) => ({
    label: label,
    onClick: () => setIsActive(value),
    isSelected: isActive === value
  }));

  const handleActive = (status: SubscriptionStatusE | ServiceStatusE) => {
    if (status === SubscriptionStatusE.ACTIVE || status === ServiceStatusE.ACTIVE) setIsActive(true);
    else setIsActive(false);
  };

  return { isActive, optionsActive, handleActive };
};
