import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type MobileCardProps = {
  items: Array<string | null | number | JSX.Element>;
};

export const MobileCard: FunctionComponent<MobileCardProps> = ({ items }) => (
  <Container>
    <Top>
      <Professional>{items[0]}</Professional>
      <Price>{items[1]}</Price>
    </Top>
    <Middle>
      <Service>{items[3]}</Service>
    </Middle>
    <Bottom>
      <BottomLeft>
        <Time>{items[2]}</Time>
        <Time>{items[4]}</Time>
      </BottomLeft>
      <Payment>{items[5]}</Payment>
    </Bottom>
  </Container>
);

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  margin: 16px 18px 0px 0px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Professional = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 18px;
  display: flex;
  align-content: center;
`;

const Price = styled.span`
  color: ${({ theme }) => theme.colors.grey[800]};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 18px;
`;

const Middle = styled(Top)`
  margin-bottom: 16px;
`;

const Service = styled(Professional)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 18px;
`;

const Bottom = styled(Middle)`
  display: flex;
  margin-bottom: 0;
  align-items: center;
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLeft = styled.div`
  color: ${({ theme }) => theme.colors.grey[700]};
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
`;

const Time = styled.span`
  margin-right: 16px;
`;
