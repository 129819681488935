import { trackGenericEvent } from '@/analytics';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { Column, ResponsiveContainer, Row } from '@/components';
import { useAuth } from '@/contexts';
import { mercadopago } from '@/static/images';
import { SupportedCountriesE, WalletTypeE } from '@/types/cyclone/models';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faBank, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import WhatsAppFloatButton from './WhatsappButton';

const ActionButton = ({ isEnabled, onClick }: { isEnabled: boolean; onClick: () => void }) => (
  <div className="relative w-full cursor-pointer flex justify-end" onClick={onClick}>
    <span className="hidden sm:inline text-blue font-bold text-sm pr-4">
      {isEnabled ? 'Administrar' : 'Habilitar'}
    </span>
    <span className="sm:hidden w-8 h-8 rounded-full bg-blue text-white flex items-center justify-center">
      <FontAwesomeIcon icon={isEnabled ? faPencil : faPlus} />
    </span>
  </div>
);

export const PaymentGateways: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways');
  const { session } = useAuth();
  const navigate = useNavigate();
  const country = session?.vendor?.country || '';

  const paymentGateways = session?.vendor?.payment_gateways || [];
  const isMercadoPagoAvailable = country !== SupportedCountriesE.CHILE;
  const isWalletAssociated = paymentGateways.includes(WalletTypeE.MERCADO_PAGO);
  const isCashEnabled = session?.vendor?.is_payment_cash_enabled;
  const isBankTransferEnabled = session?.vendor?.is_payment_bank_transfer_enabled;

  return (
    <ResponsiveContainer>
      <section className="my-2">
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <p className="text-xl sm:text-3xl">Medios de pago</p>
            <p className="text-sm text-[#828282]">
              Gestioná los medios de pago aceptados en tu negocio.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
        </Row>
        <Column align="center" justify="center" gap={16} className="w-full">
          {isMercadoPagoAvailable && (
            <article className="max-w-3xl w-full flex gap-4 shadow-[0px_1px_4px_0px_#00000040] p-4">
              <div className="w-10 h-10 pt-1">
                <img src={mercadopago} className="object-contain" />
              </div>
              <Column gap={8} className="w-full">
                <h2 className="font-medium">Mercado Pago</h2>
                <Row gap={8} className="text-sm text-[#626262]">
                  <span>•</span>
                  <span>Pagos con tarjetas o saldo en Mercado Pago, anticipados en tu billetera</span>
                </Row>
                <Row gap={8} className="text-sm text-[#626262]">
                  <span>•</span>
                  <span>Comisiones por procesamiento</span>
                </Row>
              </Column>
              <div className="pl-4 flex flex-col justify-center">
                <ActionButton
                  isEnabled={isWalletAssociated}
                  onClick={() => navigate('/ajustes/medios-de-pago/mercadopago')}
                />
              </div>
            </article>
          )}
          <article className="max-w-3xl w-full flex gap-4 shadow-[0px_1px_4px_0px_#00000040] p-4">
            <div className="text-xl md:text-2xl">
              <FontAwesomeIcon icon={faBank} color="#9e9a82" />
            </div>
            <Column gap={8} className="w-full">
              <h2 className="font-medium">Pagos con transferencia</h2>
              <Row gap={8} className="text-sm text-[#626262]">
                <span>•</span>
                <span>Pagos manuales con transferencia bancaria o desde billeteras virtuales</span>
              </Row>
              <Row gap={8} className="text-sm text-[#626262]">
                <span>•</span>
                <span>Sin comisión</span>
              </Row>
            </Column>
            <div className="pl-4 flex flex-col justify-center">
              <ActionButton
                isEnabled={isBankTransferEnabled || false}
                onClick={() => navigate('/ajustes/medios-de-pago/transferencia')}
              />
            </div>
          </article>

          <article className="max-w-3xl w-full flex gap-4 shadow-[0px_1px_4px_0px_#00000040] p-4">
            <div className="text-xl md:text-2xl">
              <FontAwesomeIcon icon={faMoneyBill1} color="#1A652A" />
            </div>
            <Column gap={8} className="w-full">
              <h2 className="font-medium">Pagos en efectivo</h2>
              {/* <ul className="text-sm text-[#626262] list-disc list-outside">
                <li>Pagos manuales en efectivo</li>
                <li>Sin comisión</li>
              </ul> */}
              <Row gap={8} className="text-sm text-[#626262]">
                <span>•</span>
                <span>Pagos manuales en efectivo</span>
              </Row>
              <Row gap={8} className="text-sm text-[#626262]">
                <span>•</span>
                <span>Sin comisión</span>
              </Row>
            </Column>
            <div className="pl-4 flex flex-col justify-center">
              <ActionButton
                isEnabled={isCashEnabled || false}
                onClick={() => navigate('/ajustes/medios-de-pago/efectivo')}
              />
            </div>
          </article>
        </Column>
      </section>
      <WhatsAppFloatButton />
    </ResponsiveContainer>
  );
};
