import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Column, Input, Row } from '@/components';
import { Button } from '@/components/Button';
import { changePassword } from '@/auth';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup.string().email('Mail inválido').required('Campo requerido')
});

type ForgotPasswordProps = {
  navigateToSignIn: () => void;
};

export const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({ navigateToSignIn }) => {
  const [authError, setAuthError] = useState<auth0.Auth0Error | null>(null);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ resolver: yupResolver(schema), mode: 'all' });
  const [loading, setLoading] = useState(false);

  const errorHandler = (err: auth0.Auth0Error | null) => setAuthError(err);

  const successHandler = () => {
    toast.success('Se ha solicitado el cambio de contraseña. Por favor, revisá tu correo electrónico.');
    navigateToSignIn();
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    changePassword(data.email, errorHandler, successHandler);
    setLoading(true);
  };

  return (
    <form>
      <Row
        className="text-xl md:text-2xl pt-12 pb-6 border-b border-gray-300 h-full"
        align="center"
        justify="center"
      >
        ¿Olvidaste tu contraseña?
      </Row>
      <Column className="px-10 lg:px-16 xl:px-[100px] pt-16">
        <ForgotPasswordText>
          Ingresá el email al cual se encuentra asociado tu cuenta en Ágora:
        </ForgotPasswordText>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...field } }) => (
            <Input
              inputId="email"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const formattedValue = e.target.value.trim().toLowerCase();
                onChange(formattedValue);
              }}
              placeholder="Email"
              helpText="Ingresá tu email"
              error={errors?.email?.message as string}
              {...field}
            />
          )}
        />
        <Spacer spacing="45" />
        <SubmitButton loading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          <PrimaryButtonContent>Recuperar contraseña</PrimaryButtonContent>
        </SubmitButton>
        {authError !== null && (
          <ErrorContainer>
            <ErrorMessage>
              El email o la contraseña que ingresaste son incorrectos, por favor intentalo nuevamente.
            </ErrorMessage>
          </ErrorContainer>
        )}
        <Spacer spacing="28" />
        <Button variant="link" onClick={navigateToSignIn}>
          Volver
        </Button>
      </Column>
    </form>
  );
};

const Spacer = styled.div<{ spacing: string }>`
  margin-top: ${({ spacing }) => `${spacing}px`};
`;

const PrimaryButtonContent = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.white};
`;

const ForgotPasswordText = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-bottom: 34px;
  font-weight: 500;
`;

const ErrorContainer = styled.div`
  margin-top: 2px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.red[200]};
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;
