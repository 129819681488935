import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface SectionContentPropsI {
  children: React.ReactNode;
  header?: JSX.Element | string;
  maxWidth?: number;
  borderBottom?: boolean;
  subtitle?: string;
}

export const SectionContent: FunctionComponent<SectionContentPropsI> = ({
  children,
  maxWidth,
  header,
  borderBottom = true,
  subtitle
}) => {
  return (
    <div
      className={classNames('pb-5 mb-5', { 'border-b border-[#ECEEF0]': borderBottom })}
      style={{ maxWidth }}
    >
      {header && (
        <div
          className={classNames('text-lg  mb-5', { 'text-[#212121]': subtitle, 'text-[#858585]': !subtitle })}
        >
          {header}
        </div>
      )}
      {subtitle && <div className={classNames('text-[13px]  mb-5 text-[#858585]')}>{subtitle}</div>}
      {children}
    </div>
  );
};
