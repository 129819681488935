import React, { ChangeEvent, FunctionComponent } from 'react';
import classNames from 'classnames';

type VariantType = 'input' | 'textarea';

interface LinkInputPropsI {
  placeholder?: string;
  value?: string;
  name: string;
  onChange: ((e: ChangeEvent<HTMLInputElement>) => void) | ((e: ChangeEvent<HTMLTextAreaElement>) => void);
  error?: string;
  variant?: VariantType;
  id: string;
  maxLength?: number;
  showCount?: boolean;
}

export const LinkInput: FunctionComponent<LinkInputPropsI> = ({
  placeholder = '',
  value = '',
  name,
  onChange,
  error,
  variant = 'input',
  id,
  maxLength,
  showCount
}) => {
  const validateOnChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (!maxLength || e.target.value.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <div className="mb-6">
      <div
        className={classNames(
          'flex items-center bg-white rounded px-5 border',
          variant === 'input' ? 'h-12' : 'py-2'
        )}
      >
        {variant === 'input' ? (
          <input
            type="text"
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            className="w-full bg-transparent border-none outline-none"
            onChange={validateOnChange}
          />
        ) : (
          <textarea
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            className="w-full h-40 bg-transparent border-none outline-none"
            onChange={validateOnChange}
          />
        )}
      </div>
      <div className="flex justify-between mt-1">
        {error && <span className="text-red-500 text-sm ml-4">{error}</span>}
        {maxLength && showCount && (
          <span className="text-blue-500 text-xs">
            {value?.length || 0}/{maxLength}
          </span>
        )}
      </div>
    </div>
  );
};
