import { LabelValue } from '@/types';
import { SelectOptionProps } from '.';

export const buildSelectOptions = (
  array: LabelValue[],
  state: LabelValue | undefined,
  setState: (value: LabelValue | undefined) => void,
  callback?: () => void
): SelectOptionProps[] =>
  array.map(({ label, value, key, icon }) => ({
    label,
    onClick: () => {
      setState({ label, value });
      callback !== undefined ? callback() : null;
    },
    isSelected: value === state?.value && key === state.key,
    ...(icon && { icon })
  }));
