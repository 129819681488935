import React, { Dispatch, FunctionComponent } from 'react';
import { Grid, Row } from '@/components';
import { ItemTab } from './components';
import { trackGenericEvent } from '@/analytics';

interface HeaderTabPropsI {
  setSelectTab: Dispatch<React.SetStateAction<string>>;
  selectTab: string;
  handleSubmit: () => Promise<void>;
  isEnableSave: boolean;
}

export const HeaderTab: FunctionComponent<HeaderTabPropsI> = ({ selectTab, setSelectTab }) => {
  return (
    <Grid
      as="div"
      columns={1}
      className="h-[59px] w-full bg-[white] lg:bg-[#f5f5f5] shadow-md lg:shadow-none lg:border-b lg:border-[#dcdcdc] sticky top-[48px] z-10"
    >
      <Row justify="space-between" align="center">
        <Row className="h-[59px] w-full lg:w-auto">
          <ItemTab
            text="Contenido"
            isSelected={selectTab == 'contenido'}
            onClick={() => {
              trackGenericEvent('Storefront Tab Clicked', {
                name: 'Contenido'
              });
              setSelectTab('contenido');
            }}
          />
          <ItemTab
            text="Plantillas"
            isSelected={selectTab == 'plantillas'}
            onClick={() => {
              trackGenericEvent('Storefront Tab Clicked', {
                name: 'Plantillas'
              });
              setSelectTab('plantillas');
            }}
          />
          <ItemTab
            text="Estilos"
            isSelected={selectTab == 'estilos'}
            onClick={() => {
              trackGenericEvent('Storefront Tab Clicked', {
                name: 'Estilos'
              });
              setSelectTab('estilos');
            }}
          />
        </Row>
        {/* {isLg && selectTab !== 'contenido' && (
          <div className="mr-[30px]">
            <button
              className="bg-blue text-white w-[142px] h-[39px] rounded flex justify-center items-center disabled:opacity-50"
              onClick={handleSubmit}
              disabled={!isEnableSave}
            >
              <Icon
                name="save"
                width="14px"
                height="14px"
                marginRight="10px"
                color={theme.colors.white as string}
              />{' '}
              <p className="pb-[3px]">Guardar</p>
            </button>
          </div>
        )} */}
      </Row>
    </Grid>
  );
};
