import React, { FunctionComponent } from 'react';
import { Logo } from '@/components';
import { Link } from 'react-router-dom';

type AgoraLogoProps = {
  href: string;
};

export const AgoraLogo: FunctionComponent<AgoraLogoProps> = ({ href }) => (
  <Link className="leading-none" to={href}>
    <Logo type="horizontal" width="100px" height="48px" />
  </Link>
);
