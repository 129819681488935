import React, { FunctionComponent } from 'react';
import { Row } from '@/components';
import classNames from 'classnames';

interface ItemTabPropsI {
  isSelected: boolean;
  text: string;
  onClick: () => void;
}

export const ItemTab: FunctionComponent<ItemTabPropsI> = ({ isSelected, text, onClick }) => {
  return (
    <Row
      className={classNames('w-full mx-[30px] cursor-pointer', {
        'border-b-2 border-blue': isSelected
      })}
      align="center"
      justify="center"
      flex={1}
      onClick={onClick}
    >
      <p
        className={classNames('text-[16px] font-medium text-center tracking-[0.1px]', {
          'text-blue': isSelected,
          'text-[#858585] mb-[2px]': !isSelected
        })}
      >
        {text}
      </p>
    </Row>
  );
};
