import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { servicesCheck } from '@/static/images';
import { IconLabel } from '@/components/IconLabel';
import { FlexWidth, theme } from '@/components/App';

type DescriptionProps = {
  isEmptyServices: boolean;
};

export const Description: FunctionComponent<DescriptionProps> = ({ isEmptyServices }) => (
  <Container>
    <img src={servicesCheck} />
    <FlexWidth>
      <Title>Servicios incluidos</Title>
    </FlexWidth>
    <Text>Asociá servicios existentes a tu plan mensual. </Text>
    <Text>
      Podés agregar también servicios extra que ofrecés por fuera de Ágora, para aumentar el atractivo de la
      misma (Por ejemplo: acceso a tu canal de Discord, Youtube, etc)
    </Text>
    {isEmptyServices && (
      <EmptyAlert
        icon="exclamation"
        label="Tu plan mensual debe incluir al menos un servicio"
        small
        color={theme.colors.red[200]}
        iconColor={theme.colors.red[200]}
        iconSize="14px"
      />
    )}
  </Container>
);

const Container = styled.div`
  width: 25%;
  margin-left: 3rem;
  ${({ theme }) => theme.breakpoint('xl')} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.span`
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0.5rem 0 1rem 0;
`;

const Text = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-bottom: 1rem;
  font-size: 0.8rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const EmptyAlert = styled(IconLabel)`
  margin-top: 2rem;
  > svg {
    opacity: 0.5;
  }
`;
