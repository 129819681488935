import React, { Dispatch, FunctionComponent } from 'react';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import styled from 'styled-components';
import { FAQItemI } from './types';

interface PropsFaqItemI {
  faq: FAQItemI;
  selectPositionFaqEdit: Dispatch<React.SetStateAction<number>>;
  handlerDelete: (id: number) => void;
  position: number;
}

export const FaqItem: FunctionComponent<PropsFaqItemI> = ({
  faq,
  selectPositionFaqEdit,
  handlerDelete,
  position
}) => {
  return (
    <Container>
      <Text>{faq.name}</Text>
      <ContentButtons>
        <ButtonIcon onClick={() => selectPositionFaqEdit(position)}>
          <Icon name="pencil" width="15px" height="15px" color={theme.colors.blue[500]} />
        </ButtonIcon>
        <ButtonIcon onClick={() => handlerDelete(position)}>
          <Icon name="thrash" width="15px" height="15px" color={theme.colors.red[200]} />
        </ButtonIcon>
        <ButtonIcon>
          <Icon name="draggable" width="15px" height="15px" color={theme.colors.grey[700]} />
        </ButtonIcon>
      </ContentButtons>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 100%;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
  }
`;

const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    justify-content: end;
    margin-top: 10px;
  }
`;

const ButtonIcon = styled.button`
  width: 30px;
  height: 30px;
  background: #eceef0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

const Text = styled.p`
  padding-top: 5px;
`;
