import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';

const buttonVariants = (
  variant: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'muted' | 'link',
  size?: 'default' | 'sm' | 'lg' | 'icon',
  isLoading?: boolean,
  rounded?: 'none' | 'sm' | 'md' | 'lg' | 'full'
) => {
  const baseClasses =
    'inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 relative overflow-hidden';

  const variantClasses = {
    default: 'bg-blue text-white hover:bg-blue/90',
    destructive: 'bg-[#FF4658] text-white hover:bg-[#FF4658]/90',
    outline: 'border border-input text-[#0072FB] hover:bg-gray-100',
    secondary: 'bg-[#212121] text-white hover:bg-[#212121]/80',
    ghost: 'bg-background text-muted-foreground',
    muted: '!font-normal bg-[#F4F4F4] text-[#868686]',
    link: 'text-blue underline-offset-4 hover:underline'
  };

  const roundedClasses = {
    none: 'rounded-none',
    sm: 'rounded-sm',
    md: 'rounded-md',
    lg: 'rounded-lg',
    full: 'rounded-full'
  };

  const sizeClasses = {
    default: 'h-10 px-4 py-2',
    sm: 'h-9 rounded-md px-3',
    lg: 'h-11 rounded-md px-8',
    icon: 'h-10 w-10'
  };

  return classNames(
    baseClasses,
    variantClasses[variant],
    sizeClasses[size || 'default'],
    isLoading && 'cursor-not-allowed',
    roundedClasses[rounded || 'md']
  );
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'muted' | 'link';
  size?: 'default' | 'sm' | 'lg' | 'icon';
  rounded?: 'none' | 'sm' | 'md' | 'lg' | 'full';
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'default',
      size = 'default',
      rounded = 'md',
      asChild = false,
      isLoading = false,
      onClick,
      ...props
    },
    ref
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isLoading) {
        e.preventDefault();
        return;
      }
      onClick?.(e);
    };

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={classNames(
          buttonVariants(variant, size, isLoading, rounded),
          className,
          isLoading && 'relative'
        )}
        ref={ref}
        onClick={handleClick}
        disabled={isLoading}
        {...props}
      >
        {isLoading && (
          <span
            className="absolute inset-x-0 top-0 h-full bg-gradient-to-r from-transparent via-white/30 to-transparent animate-loading"
            style={{
              animationDuration: '1.5s',
              animationTimingFunction: 'ease-in-out',
              animationIterationCount: 'infinite'
            }}
          />
        )}
        <span className={isLoading ? 'opacity-50' : ''}>{props.children}</span>
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
