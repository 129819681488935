import { Column, Row } from '@/components';
import { useWindowResize } from '@/hooks';
import { SubCategoryI } from '@/types/cyclone/models';
import classNames from 'classnames';
import React from 'react';
import { useOnboarding } from '../contexts';

export const SubCategory: React.FC = () => {
  const { isMobileSize } = useWindowResize();
  const { fatherCategory, handleChildCategory, childCategories } = useOnboarding();

  const isSelected = (subCategory: SubCategoryI) => {
    return childCategories.some((s) => s.name === subCategory.name);
  };

  const orderedSubCategories = fatherCategory?.childrens.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)] max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
          !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-3xl mt-16">Datos de tu negocio</h2>
      <p className="text-sm sm:text-base text-[#626262] font-medium mt-2">
        Seleccioná los tipos de servicio que ofrecés en tu negocio:
      </p>
      <Row className="gap-4 flex-wrap mt-8 sm:max-w-[600px] w-full">
        {orderedSubCategories?.map((subCategory, i) => (
          <Column
            key={i}
            align="center"
            justify="center"
            className={classNames(
              'bg-white shadow-[0_0_10px_0_#62626233] border transition-colors rounded cursor-pointer py-2 px-4',
              {
                'shadow-[0_0_10px_0_#0072FB33] border-blue': isSelected(subCategory),
                'hover:shadow-[0_0_10px_0_#0072FB33] hover:border-blue/20': !isSelected(subCategory)
              }
            )}
            onClick={() => handleChildCategory(subCategory)}
          >
            <span className="text-base sm:text-lg">{subCategory.name}</span>
          </Column>
        ))}
      </Row>
    </Column>
  );
};
