import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

type MetaTagsSEOProps = {
  title: string;
};

export const MetaTagsSEO: FunctionComponent<MetaTagsSEOProps> = ({ title }) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:url" content="https://pro.agora.red" />
    <meta property="og:site_name" content={title} />
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content="Reinventamos el futuro del trabajo. Llegá a más clientes y ofrecé una experiencia simple para contratar tus servicios."
    />
    <meta
      property="og:image"
      itemProp="image"
      content="https://d3p2i4pt6coq3o.cloudfront.net/landing/agora-meta.png"
    />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="300" />
    <meta property="og:image:height" content="300" />
  </Helmet>
);
