import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from 'react-query';
import { useClient } from '@/hooks';
import { Column, IconLabelV2, Row } from '@/components';
import { SubscriptionI, SubscriptionStatusE, SupportedCountriesE } from '@/types/cyclone/models';
import { Menu } from '..';
import { useOnClickOutside } from '@/hooks';
import { useAuth, useConfirmation } from '@/contexts';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorI } from '@/types/cyclone/requests';
import { toast } from 'react-toastify';
import { formatCurrency } from '@/utils/money';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { theme } from '@/components/App';

type CardProps = {
  subscription: SubscriptionI;
  refetch: () => void;
};
type EmptyObject = {
  [K in string]: never;
};

export const Card: FunctionComponent<CardProps> = ({
  subscription: { id, status, name, price, cover_url, service_subscriptions },
  refetch
}) => {
  const navigate = useNavigate();
  const { client } = useClient();
  const { session } = useAuth();
  const { confirm } = useConfirmation();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  const closePopUp = () => {
    if (showPopUp) setShowPopUp(false);
  };

  const openPopUp = () => {
    if (!showPopUp) setShowPopUp(true);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;

  const mutation = useMutation<EmptyObject, ErrorI, SubscriptionStatusE>(
    (status) =>
      client(`subscriptions/${id}/status`, 'PUT', {
        isAuthRequired: true,
        data: { status }
      }),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );
  const mutationDelete = useMutation<EmptyObject, ErrorI>(
    () =>
      client(`subscriptions/${id}`, 'DELETE', {
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success('Se ha eliminado exitosamente');
      },
      onError: (e: any) => {
        if (e.code && e.code === 'G-001') {
          toast.error('Este plan mensual tiene usuarios activos');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const closeMenu = useCallback(() => {
    if (showMenu) setShowMenu(false);
  }, [showMenu]);

  useOnClickOutside(menuRef, closeMenu);

  const confirmStatusChange = () => {
    if (status === SubscriptionStatusE.ACTIVE) {
      return confirm({
        status: 'warning',
        content: '¿Cambiar el estado a este plan mensual?',
        title: 'Archivar plan mensual',
        confirmText: 'Si, pausar',
        onConfirm: () => handleStatusChange()
      });
    } else {
      handleStatusChange();
    }
  };

  const handleStatusChange = () => {
    const newStatus =
      status === SubscriptionStatusE.ACTIVE ? SubscriptionStatusE.PAUSED : SubscriptionStatusE.ACTIVE;
    mutation.mutate(newStatus);
  };
  const handleDelete = () => {
    mutationDelete.mutate();
  };

  const confirmDelete = () =>
    confirm({
      status: 'danger',
      content: '¿Querés eliminar este plan mensual?',
      title: 'Eliminar plan mensual',
      confirmText: 'Si, eliminar',
      onConfirm: () => handleDelete()
    });

  const buildCombinedList = () => {
    const list =
      service_subscriptions?.map((serviceSubscription) => ({
        id: serviceSubscription.service_id,
        name: serviceSubscription.service.name
      })) || [];

    return list;
  };

  const listElement = (
    <>
      {buildCombinedList()
        .slice(0, 3)
        .map((item) => (
          <IconLabelV2
            icon={faCheck}
            gap="5px"
            label={item.name}
            key={item.id}
            // @ts-ignore
            iconColor={theme.colors.white}
            // @ts-ignore
            color={theme.colors.white}
            small={true}
          />
        ))}
    </>
  );
  const popUpContent = (
    <>
      {buildCombinedList()
        .slice(3)
        .map((item, i) => (
          <PopUpContent key={i}>{item.name}</PopUpContent>
        ))}
    </>
  );

  return (
    <Link to={`/planesmensuales/${id}/editar`}>
      <div className="relative rounded-xl cursor-pointer w-80 h-80">
        <div
          style={{ backgroundImage: `url(${cover_url})` }}
          className="bg-cover bg-no-repeat bg-center w-80 h-80 rounded-xl"
        >
          <Column className="bg-gradient-to-t from-black z-2 rounded-xl py-5 px-4 w-80 h-80">
            <div className="h-[180px]" />
            <Column className="h-full" gap={15} justify="space-between">
              <span
                className="text-white p-0.5 align-bottom font-medium text-xl text-ellipsis overflow-hidden leading-[1.2rem] h-10"
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                  height: 'auto'
                }}
              >
                {name}
              </span>
              <span className="text-sm">{listElement}</span>
              <Row justify="space-between">
                <div className="relative text-sm">
                  {buildCombinedList().length > 3 && (
                    <button onMouseEnter={openPopUp} onMouseLeave={closePopUp}>
                      <IconLabelV2
                        icon={faPlus}
                        gap="5px"
                        label={`Ver más...`}
                        // @ts-ignore
                        color={theme.colors.white}
                        // @ts-ignore
                        iconColor={theme.colors.white}
                      />
                    </button>
                  )}
                  {showPopUp && <PopUp>{popUpContent}</PopUp>}
                </div>
                <Row>
                  <p className="text-white font-medium text-2xl">{formatCurrency(price, country)}</p>
                  <p className="text-white font-medium text-base mt-2">/mes</p>
                </Row>
              </Row>
            </Column>
          </Column>
          <div className="absolute top-3 right-3 cursor-pointer">
            <Menu
              onEdit={() => navigate(`/planesmensuales/${id}/editar`)}
              onStatusChange={confirmStatusChange}
              onDelete={confirmDelete}
              disableCopy
              status={status}
            >
              <Row align="center" justify="center" className="w-8 h-8 rounded-full shadow-sm bg-[#fff]">
                <FontAwesomeIcon icon={faEllipsisVertical} color="#858585" fixedWidth size="lg" />
              </Row>
            </Menu>
          </div>
        </div>
      </div>
    </Link>
  );
};

const PopUpContent = styled.li`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.grey[200]};
      font-weight: ${theme.font.weight.book};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.xl};
    `;
  }}
`;

const PopUp = styled.ul`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.grey[200]};
      color: ${({ theme }) => theme.colors.black};
    `;
  }}
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
  display: table;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  width: max-content;
  height: 100%;
  left: 82px;
  transform: translate(0, -50%);
  z-index: 1;
`;
