export const REGEX_VALID_URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
export const REGEX_INSTAGRAM_VALID_URL =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
export const REGEX_FACEBOOK_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.me)\/(\w+)/;
export const REGEX_YOUTUBE_VALID_URL =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?(youtube(?:-nocookie)?\.com|youtu.be)(\/(?:@)?[\w\-]+|\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
export const REGEX_LINKED_IN_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com)\/(\w+)/;
export const REGEX_TWITTER_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/(\w+)/;
export const REGEX_TWITCH_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:witch.tv)\/(\w+)/;
export const REGEX_SPOTIFY_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:open.spotify.com)\/(\w+)/;
export const REGEX_TIKTOK_VALID_URL = /(?:(?:http|https):\/\/)?(?:www\.)?(?:tiktok\.com)\/@?(\w+)/;
export const REGEX_SNAPCHAT_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:snapchat.com\/add)\/(\w+)/;
export const REGEX_REDDIT_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:reddit.com\/r)\/(\w+)/;
export const REGEX_PINTEREST_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:pinterest.com)\/(\w+)/;
export const REGEX_MEDIUM_VALID_URL = /(?:(?:http|https):\/\/)?(?:www.)?(?:medium.com)\/(^|\W)/;

export const REGEX_NO_SPECIAL_CHARACTERS = /^[a-zA-Z0-9!@#$%^&*()+=._-]+$/g;

export const REGEX_VALID_EMAIL = /\S+@\S+\.\S+/;
