import React, { FunctionComponent, useRef, useState, useCallback, useEffect, ChangeEvent } from 'react';
import Cropper from 'react-cropper';
import styled from 'styled-components';
import 'cropperjs/dist/cropper.css';
import { Button, ModalV2, Spinner } from '@/components';
import { theme } from '@/components/App';
import { toast } from 'react-toastify';

type ImageCropperProps = {
  image: File | undefined;
  prevImage: string | undefined;
  setImage: (image: File | undefined) => void;
  onClose: () => void;
  aspectRatio?: number;
  validExts?: string[];
};

export const ImageCropper: FunctionComponent<ImageCropperProps> = ({
  image,
  prevImage,
  setImage,
  onClose,
  aspectRatio = 1,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG']
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageCropped, setImageCropped] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      setImageCropped(undefined); // This worked for me
      setLoading(true);
    };
  }, []);

  const onCrop = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob: File) => {
      const myBlob = blob ? Object.assign(blob) : null;
      if (myBlob) {
        myBlob.lastModified = new Date();
        myBlob.name = image?.name;
        setImageCropped(myBlob);
      }
    });
  }, [setImageCropped, image]);

  const onSave = useCallback(() => {
    setImage(imageCropped);
    onClose();
  }, [imageCropped, setImage, onClose]);

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.keyCode === 13) onSave();
    };
    window.addEventListener('keydown', handleEnter);

    return () => window.removeEventListener('keydown', handleEnter);
  }, [onSave]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const type = file.name.split('.').pop();
    const isValidFile = type && validExts.includes(type);
    if (isValidFile) setImage(file);
    else toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');

    e.target.value = '';
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <ModalV2 title="Ajustá tu imagen" show onClose={onClose}>
      <ModalContent>
        {loading && (
          <Absolute>
            <Spinner size={23} background={theme.colors.grey[400]} />
          </Absolute>
        )}
        <Cropper
          src={prevImage}
          style={{
            height: 300,
            width: 300,
            margin: 'auto',
            ...(loading && { visibility: 'hidden' })
          }}
          // Cropper.js options
          aspectRatio={aspectRatio}
          autoCropArea={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          ready={() => {
            setLoading(false);
          }}
          checkCrossOrigin={false}
        />
      </ModalContent>
      <ModalFooter>
        <Button size="md" variant="outlined" onClick={handleClick}>
          Usar otra
          <Input ref={inputRef} type="file" onChange={onChange} />
        </Button>
        <Button size="md" onClick={onSave}>
          Guardar
        </Button>
      </ModalFooter>
    </ModalV2>
  );
};

const Absolute = styled.div`
  position: absolute;
  margin: auto auto;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ModalFooter = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  > button {
    height: 40px;
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
`;

const Input = styled.input`
  display: none;
`;
