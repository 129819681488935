import React, { FunctionComponent, useEffect } from 'react';
import { useLocalStorage } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { parseHash } from '@/auth';
import { Spinner } from '@/components';
import { useAuth0 } from '@auth0/auth0-react';
import { trackGenericEvent } from '@/analytics';
import { toast } from 'react-toastify';
import { useAuth } from '@/contexts';

export const Auth0Callback: FunctionComponent = () => {
  const { storedValue, deleteValue } = useLocalStorage('redirectUri', {});
  const navigate = useNavigate();
  const { session } = useAuth();
  const { user } = useAuth0();

  const isNewUser = user && user['https://agora.red/is_new'];

  const errorHandler = (err: auth0.Auth0Error | null) => {
    toast.error(err?.errorDescription);
    return navigate('/login');
  };

  const successHandler = () => {
    // GTM purposes to track an user registered event
    if (isNewUser) return navigate('/new/user');
    trackGenericEvent('User Logged In');

    redirectToDashboard();
  };

  const redirectToDashboard = () => {
    if (Object.keys(storedValue).length !== 0) {
      navigate(storedValue);
      return deleteValue('redirectUri');
    }

    if (session?.is_staff) {
      return navigate('/agenda');
    }

    return navigate('/');
  };

  useEffect(() => {
    parseHash(errorHandler, successHandler);
  }, []);

  return (
    <div className="w-full h-full">
      <Spinner fullHeight />
    </div>
  );
};
