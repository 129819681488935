import React, { useMemo, createContext, FunctionComponent, useContext, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage, useClient } from '@/hooks';
import { Spinner } from '@/components';
import { SessionI } from '@/types/cyclone/requests';
import { trackGenericEvent } from '@/analytics';
import { deleteCookie } from '@/utils/cookies';

const FLASH_URL = import.meta.env.VITE_FLASH_URL;

export interface AuthContextAuthenticatedI {
  isAuthenticated: true;
  isLoading: boolean;
  session: SessionI | undefined;
  reloadSession: () => void;
  logOut: () => void;
  logIn: ({ message, redirectUri }: { message: string; redirectUri?: string }) => void;
  isAuth0Loading: boolean;
  isFetching: boolean;
}
//
export interface AuthContextNotAuthenticatedI {
  isAuthenticated: false;
  isLoading: boolean;
  session: null;
  reloadSession: () => void;
  logOut: () => void;
  logIn: ({ message, redirectUri }: { message: string; redirectUri?: string }) => void;
  isAuth0Loading: boolean;
  isFetching: boolean;
}

export type AuthContextI = AuthContextAuthenticatedI | AuthContextNotAuthenticatedI;

const AuthContext = createContext<AuthContextI>({
  isAuthenticated: false,
  isAuth0Loading: false,
  isLoading: false,
  isFetching: false,
  session: null,
  reloadSession: () => null,
  logOut: () => null,
  logIn: () => null
});
AuthContext.displayName = 'AuthContext';

interface AuthProviderPropsI {
  children?: React.ReactNode;
}

export const AuthProvider: FunctionComponent<AuthProviderPropsI> = ({ children }) => {
  const { isAuthenticated, logout, loginWithRedirect, isLoading: isAuth0Loading } = useAuth0();
  const { client } = useClient();
  const redirectUri = useLocalStorage<string | null>('redirectUri', null);

  const { isLoading, refetch, data, isFetching } = useQuery(
    ['session'],
    async () => await client<SessionI>('sessions', 'GET', { isAuthRequired: true }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isAuthenticated,
      // FIXME: Handle onError case
      onError: () => null,
      select: (data) => {
        const categoryAssets = data?.vendor?.category_assets;

        // check if belongs to beauty category, need to check that none of the category belongs to id [333, 321, 522]
        const isBeautyCategory = categoryAssets?.every(
          (category) => ![333, 321, 522].includes(category.category_id)
        );

        return {
          ...data,
          is_beauty_category: isBeautyCategory
        };
      }
    }
  );

  const logOut = useCallback(() => {
    localStorage.removeItem('auth');
    trackGenericEvent('User Logged Out');
    logout({ returnTo: `${FLASH_URL}/login` });
    deleteCookie('authInfo');
  }, [logout]);

  const logIn = useCallback(
    (args) => {
      if (args.redirectUri) {
        redirectUri.setValue(
          args.redirectUri ? args.redirectUri : window.location.pathname + window.location.search
        );
        loginWithRedirect({ application: 'flash' });
      }
      loginWithRedirect({ application: 'flash' });
    },
    [redirectUri, loginWithRedirect]
  );

  const value = useMemo((): AuthContextI => {
    const contextValue = {
      isAuthenticated: true,
      isLoading,
      isFetching,
      session: data,

      logOut,
      logIn,
      reloadSession: refetch,
      isAuth0Loading
    };
    if (!isAuthenticated) {
      const result: AuthContextNotAuthenticatedI = { ...contextValue, isAuthenticated: false, session: null };
      return result;
    } else {
      const result: AuthContextAuthenticatedI = {
        ...contextValue,
        isAuthenticated: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        session: data
      };
      return result;
    }
  }, [data, isAuthenticated, isFetching, isLoading, logOut, logIn, isAuth0Loading, refetch]);

  if (isLoading) {
    document.body.style.margin = '0';
    return <Spinner fullHeight />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextI => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
