import React, { FunctionComponent } from 'react';
import { Icon, Row } from '@/components';
import { theme } from '@/components/App';
import styled from 'styled-components';
import { FAQItemI } from '../../types';

interface PropsFaqItemI {
  faq: FAQItemI;
  selectPositionFaqEdit: (pos: number) => void;
  handlerDelete: (id: number) => void;
  position: number;
}

export const FaqItem: FunctionComponent<PropsFaqItemI> = ({
  faq,
  selectPositionFaqEdit,
  handlerDelete,
  position
}) => {
  return (
    <Row
      align="center"
      justify="space-between"
      className="h-12 px-2 w-full bg-white rounded shadow-[0px_4px_23px_#00000011] mb-5"
    >
      <p className="text-base truncate w-3/5">{faq.name}</p>
      <Row align="center" justify="flex-end" gap={8} className="w-2/5">
        <ButtonIcon onClick={() => selectPositionFaqEdit(position)}>
          <Icon name="pencil" width="15px" height="15px" color={theme.colors.blue[500]} />
        </ButtonIcon>
        <ButtonIcon onClick={() => handlerDelete(position)}>
          <Icon name="thrash" width="15px" height="15px" color={theme.colors.red[200]} />
        </ButtonIcon>
        <ButtonIcon>
          <Icon name="draggable" width="15px" height="15px" color={theme.colors.grey[700]} />
        </ButtonIcon>
      </Row>
    </Row>
  );
};

const ButtonIcon = styled.button`
  width: 30px;
  height: 30px;
  background: #eceef0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;
