import React from 'react';
import { DayPicker } from 'react-day-picker';
import { buttonVariants } from '@/components/Button/ButtonV2';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { es } from 'react-day-picker/locale';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      locale={es}
      disabled={{ after: new Date() }}
      showOutsideDays={showOutsideDays}
      className={cn('relative capitalize mx-auto', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row',
        month_caption: 'flex justify-center pt-1 relative items-center',
        weekdays: 'flex flex-row',
        weekday: 'text-muted-foreground w-8 font-normal text-[0.8rem]',
        month: 'space-y-4 mr-4 last:mr-0',
        caption_label: 'text-sm font-medium truncate',
        button_next: cn(
          buttonVariants('outline'),
          'z-10 absolute right-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        button_previous: cn(
          buttonVariants('outline'),
          'z-10 absolute left-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav: 'flex items-start space-x-1',
        month_grid: 'm-1',
        week: 'flex mt-2',
        day: 'flex items-center justify-center size-8 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-[#CCE3FF]/50 [&:has([aria-selected])]:bg-[#CCE3FF] first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button: cn(buttonVariants('ghost'), 'h-9 w-9 p-0 font-normal aria-selected:opacity-100'),
        range_start: 'day-range-start rounded-s-md',
        range_end: 'day-range-end rounded-e-md',
        selected: 'bg-blue text-white hover:!bg-blue/80 hover:text-white',
        today: 'bg-blue/50 text-white',
        outside:
          'day-outside text-black opacity-50 aria-selected:bg-[#CCE3FF]/50 aria-selected:text-black aria-selected:opacity-30',
        disabled: 'text-black opacity-50',
        range_middle:
          'aria-selected:bg-[#CCE3FF] hover:aria-selected:!bg-[#CCE3FF] rounded-none aria-selected:text-black hover:aria-selected:text-black',
        hidden: 'invisible'
      }}
      components={{
        Chevron: ({ orientation, className }) => {
          const Icon = orientation === 'left' ? faChevronLeft : faChevronRight;
          return <FontAwesomeIcon icon={Icon} className={cn(className, 'h-4 w-4')} />;
        }
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
