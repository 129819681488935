import React, { FunctionComponent } from 'react';
import { AgoraThemeColorI, VendorHeaderInfoI } from '@/types/cyclone/models';
import styled, { css } from 'styled-components';

type HeaderSohoProps = {
  colors: AgoraThemeColorI;
  vendor: VendorHeaderInfoI;
  isMobile?: boolean;
};

export const HeaderSoho: FunctionComponent<HeaderSohoProps> = ({ colors, vendor, isMobile }) => {
  const { name, professional_title, avatar_url } = vendor;

  return (
    <div className="soho-font">
      <Container colors={colors}>
        <Flex>
          <Avatar colors={colors} avatarUrl={`${avatar_url}`} />
          <Username colors={colors} isMobile={isMobile}>
            {name}
          </Username>
          <VendorTitle isMobile={isMobile} colors={colors}>
            {professional_title}
          </VendorTitle>
        </Flex>
      </Container>
    </div>
  );
};

const Container = styled.div<{ colors: AgoraThemeColorI }>`
  background-color: ${({ colors }) => colors.background};
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Avatar = styled.div<{ avatarUrl?: string; colors: AgoraThemeColorI }>`
  width: 140px;
  height: 140px;
  background: ${({ theme }) => theme.colors.whiteSmoke};
  background-image: ${({ avatarUrl }) => `url(${avatarUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 300px;
  margin-top: 50px;
  border: 5px solid ${({ colors }) => colors.background};
  ${({ theme }) => theme.breakpoint('md')} {
    width: 168px;
    height: 168px;
  }
`;

const Username = styled.p<{ colors: AgoraThemeColorI; isMobile?: boolean }>`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 5px;
  color: ${({ colors }) => colors.title};
  padding: 0px 20px;
  text-align: center;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        font-size: 1.2rem;
        line-height: 1.2rem;
      `;
    }
  }}
`;

const VendorTitle = styled.p<{ colors: AgoraThemeColorI; isMobile?: boolean }>`
  font-size: 1.1rem;
  margin-top: 10px;
  font-weight: ${({ theme }) => theme.font.weight.book};
  color: ${({ colors }) => colors.title};
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        font-size: 0.8rem;
        line-height: 0.8rem;
      `;
    }
  }}
`;
