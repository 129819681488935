import styled, { css } from 'styled-components';

export const SpinnerDisableScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background: ${({ theme }) => theme.colors.white + '80'};
  position: absolute;
`;

export const FlexWidth = styled.div`
  display: flex;
`;

export const Title = styled.span<{ marginBottom?: boolean }>`
  font-size: 2.1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 37px;
  margin-bottom: ${({ marginBottom = true }) => (marginBottom ? '2rem' : 0)};
`;

export const sectionShadow = css`
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
`;

export const scrollCSS = css`
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey[400]};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey[200]};
    border-radius: 4px;
  }
`;

export const Card = styled.div<{ margin?: string; padding?: string; align?: string; justify?: string }>`
  ${sectionShadow};
  display: flex;
  flex: 1;
  width: 100%;
  ${({ margin }) => `margin: ${margin}`};
  ${({ padding }) => `padding: ${padding}`};
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'center'};
  ${({ theme }) => theme.breakpoint('md')} {
    box-shadow: none;
    margin: 0px;
    padding: 0px 10px;
  }
`;

export const Grid = styled.div<{
  flex?: string;
  direction?: string;
  align?: string;
  justify?: string;
  margin?: string;
}>`
  display: flex;
  width: 100%;
  flex: ${({ flex }) => flex ?? '1'};
  flex-direction: ${({ direction }) => direction ?? 'row'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  ${({ align }) => `align-items: ${align}`};
  ${({ margin }) => `margin: ${margin}`};
`;

export const InfoBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 20px 50px;
  width: 90%;
  margin: auto;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.black} !important;
  opacity: 0.7;
  font-size: 16px;
  line-height: 19px;
  ul {
    padding-left: 20px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.breakpoint('md')} {
    width: 100%;
    padding: 20px 18px;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const scrollBarTW =
  'scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full';
