import React, { FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { Icon, IconName, Spinner, Row } from '@/components';
import { theme, scrollCSS } from '@/components/App';
import { useOnClickOutside } from '@/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export type SelectOptionProps = {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  isSelected: boolean;
};

type PositionDropdownType = 'right' | 'left';

type SelectProps = {
  value?: string;
  placeholder?: string;
  options: SelectOptionProps[];
  disabled?: boolean;
  helpText?: string;
  error?: string;
  styles?: CSSProp;
  positionDropdown?: PositionDropdownType;
  fullWidth?: boolean;
  borderless?: boolean;
  callback?: () => void;
  hidePlaceholder?: boolean;
  hideArrowIcon?: boolean;
  iconName?: IconName;
  minimumScroll?: boolean;
  rightPlaceholder?: boolean;
  isLoading?: boolean;
  showBackground?: boolean;
};

const DROPDOWN_HEIGHT = 253;

export const Select: FunctionComponent<SelectProps> = ({
  value,
  placeholder,
  options,
  disabled,
  helpText,
  error,
  styles = '',
  positionDropdown = 'right',
  fullWidth = false,
  borderless = false,
  hidePlaceholder = false,
  hideArrowIcon = false,
  callback,
  iconName,
  minimumScroll = false,
  isLoading,
  rightPlaceholder = false,
  showBackground = true
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownHeight, setDropdownHeight] = useState<number | undefined>(undefined);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      setDropdownHeight(dropdownRef.current?.offsetHeight);
    }
  }, [isOpen]);

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(containerRef, closeDropdown);

  useEffect(() => {
    if (value) closeDropdown();
  }, [value, closeDropdown]);

  return (
    <Container ref={containerRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        hasValue={!!value}
        styles={styles}
        error={!!error}
        borderless={borderless}
        showBackground={showBackground}
      >
        <Row align="center" className="w-full">
          {iconName && (
            <Icon
              name={iconName}
              width="18px"
              height="18px"
              marginRight="8px"
              color={theme.colors.grey[800]}
            />
          )}
          {!hidePlaceholder && value && placeholder && !rightPlaceholder && (
            <Placeholder>{placeholder}</Placeholder>
          )}
          {!rightPlaceholder && <span className="truncate">{value || placeholder}</span>}
          {rightPlaceholder && (
            <Row align="center">
              <span>{value}</span>
            </Row>
          )}
        </Row>
        {rightPlaceholder && (
          <Row gap={5} align="center" justify="space-between">
            <span>{placeholder}</span>
            <FontAwesomeIcon icon={faChevronDown} fixedWidth size="xs" color={theme.colors.grey[700]} />
          </Row>
        )}
        {!hideArrowIcon && !rightPlaceholder && (
          <FontAwesomeIcon icon={faChevronDown} fixedWidth size="xs" color={theme.colors.grey[700]} />
        )}
      </Button>
      {isOpen && (
        <DropdownContainer hasHelpText={!!helpText} positionDropdown={positionDropdown} fullWidth={fullWidth}>
          <Dropdown
            ref={dropdownRef}
            hasScroll={dropdownHeight === DROPDOWN_HEIGHT}
            minimumScroll={minimumScroll}
          >
            {isLoading ? (
              <div className="w-full px-3 py-2">
                <Spinner />
              </div>
            ) : (
              options.map(({ label, onClick, disabled, isSelected }, i) => {
                const handleClick = () => {
                  onClick();
                  !isSelected && callback?.();
                };

                return (
                  <Option key={i} onClick={handleClick} disabled={disabled} isSelected={isSelected}>
                    {label}
                  </Option>
                );
              })
            )}
          </Dropdown>
        </DropdownContainer>
      )}
      {(helpText || error) && <BottomText error={!!error}>{error || helpText}</BottomText>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Placeholder = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  font-size: 0.72rem;
  top: -8px;
  left: 7px;
  padding: 0 5px;
`;

const Button = styled.button<{
  hasValue: boolean;
  styles: CSSProp;
  error: boolean;
  borderless: boolean;
  showBackground: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  color: ${({ theme, hasValue }) => (hasValue ? theme.colors.black : theme.colors.grey[700])};
  background: ${({ showBackground, theme }) => (showBackground ? theme.colors.white : 'transparent')};
  width: 100%;
  height: 40px;
  padding: 1rem;
  border: ${({ borderless, error }) =>
    borderless ? 'none' : `1px solid ${error ? theme.colors.red[200] : 'rgba(0, 0, 0, 0.36)'}`};
  &:focus-within {
    border: ${({ borderless }) =>
      borderless ? `1px solid transparent` : `1px solid ${theme.colors.blue[500]}`};
    ${Placeholder} {
      color: ${({ theme }) => theme.colors.blue[500]};
    }
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.whiteSmoke};
    border: 1px solid transparent;
  }
  ${({ styles }) => styles};
`;

const DropdownContainer = styled.div<{
  hasHelpText: boolean;
  fullWidth: boolean;
  positionDropdown: PositionDropdownType;
}>`
  position: absolute;
  z-index: 2;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey[400]};
  background: ${({ theme }) => theme.colors.white};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  // this change can fuck up things a bit (example: create a new event on mobile)
  // width: max-content;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  right: ${({ positionDropdown }) => positionDropdown === 'left' && '0'};
`;

const Dropdown = styled.div<{ hasScroll: boolean; minimumScroll?: boolean }>`
  max-height: ${({ minimumScroll }) => (minimumScroll && minimumScroll ? 90 : DROPDOWN_HEIGHT)}px;
  display: block;
  overflow-y: auto;
  padding-right: ${({ hasScroll, minimumScroll }) => (hasScroll || minimumScroll) && '0.7rem'};
  ${scrollCSS};
`;

const Option = styled.button<{ isSelected: boolean }>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 4px;
  text-align: left;
  &:last-child {
    margin-bottom: 0;
  }
  border-radius: 8px;
  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          background-color: ${theme.colors.blue[500]};
          color: ${theme.colors.white};
        `
      : css`
          &:hover {
            background-color: ${theme.colors.grey[200]};
          }
        `};

  &:disabled {
    background: none;
  }
`;

const BottomText = styled.span<{ error: boolean }>`
  display: block;
  color: ${({ theme, error }) => (error ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-top: 0.4rem;
  margin-left: 1rem;
`;
