import { ModalV2 } from '@/components';
import { theme } from '@/components/App';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { PaletteColor } from './components';

interface PaletteColorPropsI {
  colorsTheme: {
    background: string;
    title: string;
    button: string;
    text: string;
  };
  setColorsTheme: React.Dispatch<
    React.SetStateAction<{
      background: string;
      title: string;
      button: string;
      text: string;
    }>
  >;
}

export const Palette: FunctionComponent<PaletteColorPropsI> = ({ colorsTheme, setColorsTheme }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ModalV2
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <TitleModal>Seleccioná un tema</TitleModal>
        <Flex>
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#FACECD',
              title: '#E64145',
              button: '#FFFFFF',
              text: '#E64145'
            }}
            setColorsTheme={setColorsTheme}
          />
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#BCCAB7',
              title: '#212121',
              button: '#FFFFFF',
              text: '#212121'
            }}
            setColorsTheme={setColorsTheme}
          />
        </Flex>
        <Flex>
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#B8C6D1',
              title: '#212121',
              button: '#CBD9E3',
              text: '#212121'
            }}
            setColorsTheme={setColorsTheme}
          />
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#7B61FF',
              title: '#FFFFFF',
              button: '#716C8D',
              text: '#FFFFFF'
            }}
            setColorsTheme={setColorsTheme}
          />
        </Flex>
        <Flex>
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#E0BFFF',
              title: '#212121',
              button: '#FFFFFF',
              text: '#212121'
            }}
            setColorsTheme={setColorsTheme}
          />
          <PaletteColor
            selectedColorsTheme={colorsTheme}
            colorsTheme={{
              background: '#F7BF2A',
              title: '#212121',
              button: '#FFFFFF',
              text: '#212121'
            }}
            setColorsTheme={setColorsTheme}
          />
        </Flex>
      </ModalV2>
      <Flex>
        <PaletteColor
          selectedColorsTheme={colorsTheme}
          colorsTheme={{
            background: '#FACB9D',
            title: '#212121',
            button: '#FFFFFF',
            text: '#212121'
          }}
          setColorsTheme={setColorsTheme}
        />
        <PaletteColor
          selectedColorsTheme={colorsTheme}
          colorsTheme={{
            background: '#E0BFFF',
            title: '#212121',
            button: '#FFFFFF',
            text: '#212121'
          }}
          setColorsTheme={setColorsTheme}
        />
        <PaletteColor
          selectedColorsTheme={colorsTheme}
          colorsTheme={{
            background: '#BCCAB7',
            title: '#212121',
            button: '#FFFFFF',
            text: '#212121'
          }}
          setColorsTheme={setColorsTheme}
        />

        <Pill
          style={{
            color: theme.colors.white as string,
            backgroundColor: theme.colors.blue[500]
          }}
          onClick={() => setShow(true)}
        >
          <p>Ver todos</p>
        </Pill>
      </Flex>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`;

const Pill = styled.div`
  cursor: pointer;
  display: flex;
  width: 81px;
  height: 21px;
  border-radius: 14px;
  justify-content: center;
  p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const TitleModal = styled.p`
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
`;
