import { useWindowResize } from '@/hooks';
import { faChevronLeft, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { Column } from '../Column';
import { Row } from '../Row';
import { Button } from '../Button';

type ModalV2Props = {
  children?: React.ReactNode;
  show: boolean;
  onClose: () => void;
  onBackButton?: () => void;
  title?: string;
  description?: string;
  onSubmitButton?: () => void;
  submitButtonText?: string;
  submitButtonIsDisabled?: boolean;
  isForConfirmation?: boolean;
};

export const ModalV2: React.FC<ModalV2Props> = ({
  children,
  show,
  onClose,
  onBackButton,
  title,
  description,
  onSubmitButton,
  submitButtonText,
  submitButtonIsDisabled = false,
  isForConfirmation = false
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { isMobileSize } = useWindowResize();
  const [isOpen, setIsOpen] = useState<boolean>(show);

  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  useEffect(() => {
    if (isOpen && dialogRef.current) {
      dialogRef.current.showModal();
      document.addEventListener('keydown', handleKeyDown);
    } else {
      dialogRef.current?.close();
      document.removeEventListener('keydown', handleKeyDown);
      onClose();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isForConfirmation)
    return (
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box relative modal-shadow rounded-md bg-[#fffd]">{children}</div>
        <div className="modal-backdrop h-full cursor-default bg-[#b3b5b799] backdrop-grayscale-0 backdrop-blur-sm">
          <button className="cursor-default" onClick={closeModal} />
        </div>
      </dialog>
    );

  if (isMobileSize)
    return (
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box relative modal-shadow w-full max-h-screen h-mobilefix rounded-none bg-white">
          <Row align="center" justify="space-between">
            <button
              onClick={onBackButton}
              className={classNames(
                'mb-2 flex items-center justify-center gap-2 hover:text-[#0072fb] transition-colors',
                {
                  invisible: typeof onBackButton === 'undefined',
                  visible: typeof onBackButton !== 'undefined'
                }
              )}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="#868686" size="lg" />
              <p className="text-base text-[#868686]">Atrás</p>
            </button>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="cursor-pointer text-[#CECECE] hover:text-[#0072fb] transition-colors"
              onClick={onClose}
              size="xl"
            />
          </Row>
          <Column>
            <p className="text-lg font-bold text-[#212121]">{title}</p>
            <p className="text-sm text-[#868686]">{description}</p>
          </Column>
          <Column
            className={classNames('justify-between w-full', {
              'h-[calc(100vh-102px)]': onSubmitButton,
              'h-[calc(100vh-135px)]': !onSubmitButton
            })}
          >
            <div className="my-6 px-1 scrollbar-thin overflow-y-scroll modal-scroll scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              {children}
            </div>
            {onSubmitButton ? (
              <Button disabled={submitButtonIsDisabled} fullWidth onClick={onSubmitButton}>
                {submitButtonText}
              </Button>
            ) : null}
          </Column>
        </div>
      </dialog>
    );

  return (
    <dialog ref={dialogRef} className="modal">
      <div className="modal-box relative modal-shadow max-h-[80vh] rounded-md bg-[#fffd]">
        <Row align="center" justify="space-between">
          <button
            onClick={onBackButton}
            className={classNames(
              'mb-2 flex items-center justify-center gap-2 hover:text-[#0072fb] transition-colors',
              { invisible: !onBackButton, visible: onBackButton }
            )}
          >
            <FontAwesomeIcon icon={faChevronLeft} color="#868686" size="lg" />
            <p className="text-base text-[#868686]">Atrás</p>
          </button>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="cursor-pointer text-[#CECECE] hover:text-[#0072fb] transition-colors"
            onClick={onClose}
            size="xl"
          />
        </Row>
        <Column>
          <p className="text-lg font-bold text-[#212121]">{title}</p>
          <p className="text-sm text-[#868686]">{description}</p>
        </Column>
        <div
          className={classNames(
            'my-6 px-1 scrollbar-thin overflow-y-scroll modal-scroll scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full scrollbar-track-rounded-full',
            {
              'max-h-[44vh]': onSubmitButton,
              'max-h-[52vh]': !onSubmitButton
            }
          )}
        >
          {children}
        </div>
        {onSubmitButton ? (
          <Button disabled={submitButtonIsDisabled} fullWidth onClick={onSubmitButton}>
            {submitButtonText}
          </Button>
        ) : null}
      </div>
      <div className="modal-backdrop h-full cursor-default bg-[#b3b5b799] backdrop-grayscale-0 backdrop-blur-sm">
        <button className="cursor-default" onClick={closeModal} />
      </div>
    </dialog>
  );
};
