import classNames from 'classnames';
import React, { useState } from 'react';

interface TextAreaProps {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  autoExpand?: boolean;
  error?: string;
  showCount?: boolean;
  maxLength?: number;
  disabled?: boolean;
  onBlur?: () => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  placeholder = 'Escribe aquí...',
  value = '',
  onChange,
  autoExpand = true,
  error,
  showCount,
  maxLength,
  disabled,
  onBlur
}) => {
  const [text, setText] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setText(newText);
    if (onChange) onChange(newText);

    if (autoExpand) {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!autoExpand && event.key === 'Enter') {
      event.preventDefault();
    }

    if (!autoExpand && event.key === ' ' && text.length > 0 && text.charAt(text.length - 1) === ' ') {
      event.preventDefault();
    }
  };

  return (
    <div className="flex flex-col w-full pb-1">
      <textarea
        className={classNames(
          'w-full py-2 px-4 shadow-sm resize-none bg-white overflow-hidden bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:outline-none',
          {
            'border-[#0000005c]': !error,
            'border-red-500': error,
            'opacity-50': disabled
          }
        )}
        rows={autoExpand ? 1 : 2}
        maxLength={maxLength}
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={autoExpand ? { height: 'auto' } : { height: 'auto', maxHeight: '80px' }}
        disabled={disabled}
        onBlur={onBlur}
      />
      <div className="flex items-center justify-between mt-2">
        {error && <span className="text-red-500 text-sm">{error}</span>}
        {showCount && maxLength && (
          <span className="text-blue text-xs ml-auto">{`${text.length}/${maxLength}`}</span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
