import React, { FunctionComponent } from 'react';
import { cash, mercadopago, transfer } from '@/static/images';
import { PaymentTypeE } from '@/types/cyclone/models';

interface IconPaymentPropsI {
  type: PaymentTypeE | undefined;
}

export const IconPayment: FunctionComponent<IconPaymentPropsI> = ({ type }) => {
  switch (type) {
    case PaymentTypeE.WALLET:
      return <img src={mercadopago} alt="" width={25} />;
    case PaymentTypeE.CASH:
      return <img src={cash} alt="" />;
    case PaymentTypeE.BANK_TRANSFER:
      return <img src={transfer} alt="" />;
    case PaymentTypeE.VENDOR_INVITE:
      return <>Invitacion</>;
    default:
      return <>{type || ''}</>;
  }
};
