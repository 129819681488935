import React, { FunctionComponent, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { useClient } from '@/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import { Button } from '@/components/Button';
import { InfoBox, SpinnerDisableScreen } from '@/components/App';
import { About, Service } from './components';
import { getBase64, getNewId } from '@/utils';
import { useAbout, useActive, useServices } from './hooks';
import {
  ErrorI,
  PostSubscriptionI,
  GetSubscriptionI,
  GetSubscriptionParticipantsI
} from '@/types/cyclone/requests';
import { SubscriptionI } from '@/types/cyclone/models';
import { ResponsiveContainer, Select, Spinner } from '@/components';
import { ServiceType } from './types';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { trackGenericEvent } from '@/analytics';
import { theme } from '@/components/App';
import { toast } from 'react-toastify';
import { useAuth } from '@/contexts';
import { Faq } from './components/FAQ';
import { FAQItemI } from './components/FAQ/types';

type MatchParams = {
  id: string | undefined;
};

export const ActionSubscriptionRaw: FunctionComponent<MatchParams> = ({ id }) => {
  const [faqs, setFaqs] = useState<FAQItemI[]>([]);
  const navigate = useNavigate();
  const { client } = useClient();
  const { reloadSession } = useAuth();

  const subscriptionId = id;
  const isEdit = !!subscriptionId;

  const { isActive, optionsActive, handleActive } = useActive();

  const {
    handleSubmit,
    errors,
    control,
    coverImage,
    handleCoverImage,
    aboutErrors,
    reset: handleFormValues,
    description,
    handleDescription,
    checkErrors
  } = useAbout();

  const { services, handleServices, isEmptyServices } = useServices();

  const { data: subscription } = useQuery(
    ['subscription', subscriptionId],
    async () =>
      await client<GetSubscriptionI>(`me/vendor/subscriptions/${subscriptionId}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit,
      onSuccess: (subscription) => {
        handleFormValues({
          name: subscription.name,
          price: subscription.price,
          price_usd: subscription.price_usd
          // availability: subscription.slots
        });
        handleDescription(subscription.description);
        handleActive(subscription.status);

        if (subscription.faqs) {
          setFaqs(subscription.faqs);
        }

        const defaultServices: ServiceType[] =
          subscription.service_subscriptions?.map((sub) => ({
            id: getNewId(),
            name: sub.service.name,
            limit: sub.limit,
            serviceId: sub.service_id,
            key: 'service',
            is_limited: sub.is_limited
          })) || [];

        handleServices(defaultServices);
      }
    }
  );

  const { data: bookings } = useQuery(
    ['bookings', 'subscription', subscriptionId],
    async () =>
      await client<GetSubscriptionParticipantsI>(
        `bookings/subscriptions/${subscriptionId}/participants`,
        'GET',
        {
          isAuthRequired: true
        }
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit
    }
  );

  const mutationActionsCallbacks = () => {
    return {
      onSuccess: () => {
        toast.success('Plan mensual guardado');
        reloadSession();
        navigate('/planesmensuales');
      },
      onError: (e: ErrorI) => {
        if (e.code && e.code === 'VE-001') {
          toast.error('Para crear un plan mensual tiene que tener su wallet asociada');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    };
  };

  const mutationCreate = useMutation<SubscriptionI, ErrorI>(
    (subscription) =>
      client('subscriptions', 'POST', {
        isAuthRequired: true,
        data: subscription
      }),
    {
      ...mutationActionsCallbacks()
    }
  );

  const mutationEdit = useMutation<SubscriptionI, ErrorI>(
    (subscription) =>
      client(`subscriptions/${subscriptionId}`, 'PUT', {
        isAuthRequired: true,
        data: subscription
      }),
    {
      ...mutationActionsCallbacks()
    }
  );

  if (isEdit && (!subscription || !bookings)) return <Spinner />;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const isAboutErrors = Object.values({
      coverImage: !coverImage && !subscription?.cover_url,
      description: !description || description === ''
    })
      .map((error) => error)
      .includes(true);

    if (isAboutErrors || isEmptyServices) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return toast.error('Encontramos un error. Por favor, verificar los datos ingresados');
    }

    const { name, price, price_usd } = data;

    const servicesSubscriptions = services.map(({ serviceId, limit, is_limited }) => ({
      id: serviceId.toString(),
      limit: is_limited && limit ? limit : 0,
      is_limited: is_limited
    }));

    const submitData: PostSubscriptionI = {
      name,
      price: parseFloat(price),
      price_usd: price_usd || 0,
      description,
      slots: 0,
      cover_url: coverImage ? ((await getBase64(coverImage)) as string) : subscription!.cover_url,
      ...(servicesSubscriptions.length !== 0 && { services_subscriptions: servicesSubscriptions }),
      ...(faqs && { faqs })
    };

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    isEdit ? mutationEdit.mutate(submitData as any) : mutationCreate.mutate(submitData as any);
  };

  const fullSubmit = () => {
    checkErrors(subscription);
    handleSubmit(onSubmit, () => {
      toast.error('Por favor verifique que sus datos sean correctos');
    })();
  };

  return (
    <Container>
      <ResponsiveContainer>
        {(mutationCreate.isLoading || mutationEdit.isLoading) && <SpinnerDisableScreen />}
        <Header>
          <Title>{isEdit ? 'Editar' : 'Nueva'} plan mensual</Title>
          {isEdit && (
            <Select
              value={isActive ? 'Activo' : 'Inactivo'}
              placeholder=""
              options={optionsActive}
              positionDropdown="left"
              styles={getPillStyles(isActive)}
            />
          )}
        </Header>
        {!isEdit && (
          <InfoBox>
            <>
              <p>Armá tus ABONOS o PLANES MENSUALES.</p>
              <p>
                <br />
                Los planes mensuales brindan acceso a uno o más de tus servicios. Podrás indicar una cantidad
                de reservas limitada o abono libre para cada servicio incluido.
              </p>
              <ul>
                <li>
                  Ej. 1: “Abono Mensual de Yoga Para Principiantes”: incluye acceso ilimitado durante 30 días
                  al Servicio “Clases de Yoga Para Principiantes”.
                </li>
                <li>
                  Ej. 2: “Plan Mensual de Yoga y Alimentación”, incluye 4 reservas para el Servicio “Clases de
                  Yoga Para Principiantes”“ y 1 reserva para “Taller Mensual de Alimentación”.
                </li>
              </ul>
            </>
          </InfoBox>
        )}
        <About
          control={control}
          errors={errors}
          coverImage={coverImage}
          handleCoverImage={handleCoverImage}
          aboutErrors={aboutErrors}
          defaultImage={
            subscription?.cover_url ? `${subscription?.cover_url}?t=${subscription?.updated_at}` : ''
          }
          description={description || ''}
          handleDescription={handleDescription}
          isEdit={isEdit}
        />
        <Service services={services} handleServices={handleServices} isEmptyServices={isEmptyServices} />
        <Faq faqs={faqs} setFaqs={setFaqs} />
        <ButtonsContainer>
          <CancelButton onClick={() => navigate('/planesmensuales')}>Cancelar</CancelButton>
          <FlexDiv>
            <SubmitButton
              loading={mutationCreate.isLoading || mutationEdit.isLoading}
              type="submit"
              onClick={() => {
                trackGenericEvent('Button Save Asset Clicked', { type: 'Subscription' }), fullSubmit();
              }}
            >
              {isEdit ? 'Guardar cambios' : 'Publicar'}
            </SubmitButton>
          </FlexDiv>
        </ButtonsContainer>
      </ResponsiveContainer>
    </Container>
  );
};

export const ActionSubscription: FunctionComponent = () => {
  const { id } = useParams();
  const isEdit = !!id;
  useMixpanelTrackPage('Action Subscription', {
    mode: isEdit ? 'Edit' : 'Create',
    subscription_id: id
  });

  return <ActionSubscriptionRaw id={id} />;
};

const FlexDiv = styled.div`
  display: flex;
  width: 30%;
  ${({ theme }) => theme.breakpoint('md')} {
    width: 100%;
  }
`;

const Container = styled.div`
  margin: 3rem 0 4rem 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
  }
`;

const CancelButton = styled(Button)`
  opacity: 0.5;
  margin-right: 2rem;
  width: 30%;
  ${({ theme }) => theme.breakpoint('md')} {
    width: 100%;
    order: 2;
    margin-right: 0;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  ${({ theme }) => theme.breakpoint('md')} {
    width: 100%;
    order: 1;
    margin-bottom: 1rem;
  }
`;

export const getPillStyles = (isActive: boolean): FlattenSimpleInterpolation => css`
  border-radius: 5px;
  border: none;
  padding: 0;
  width: 122px;
  height: 32px;
  text-align: center;
  justify-content: center;
  background-color: ${isActive ? theme.colors.blue[200] : theme.colors.pink};
  color: ${isActive ? theme.colors.green[200] : theme.colors.red[200]};
  &:focus-within {
    border: none;
  }
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxl};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 37px;
`;
