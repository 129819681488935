import { Button, ModalV2, Header as HeaderApp, Column, Row, Logo } from '@/components';
import { useClient, useWindowResize } from '@/hooks';
import { scanQr } from '@/static/images';
import { Icon } from '@/components/Icon';
import { GetUniqueEventI } from '@/types/cyclone/requests';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BookingUniqueEventI, UniqueEventStatusE } from '@/types/cyclone/models';
import { useAuth } from '@/contexts';
import { InputV2 } from '@/components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

const FLASH_URL = import.meta.env.VITE_FLASH_URL || 'https://pro.agora.red';

type ErrorI = {
  statusCode: string;
  error: string;
  message: string;
  code: string;
  internal_message: string;
  booking: BookingUniqueEventI;
};

export const InfoTicket: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isMobileSize } = useWindowResize(900);

  const eventId = id;
  const codeParam = location.search.replace('?code=', '').toLowerCase();

  useEffect(() => {
    if (codeParam) {
      setCode(codeParam);
      navigate(`/eventos/${eventId}/registro`, { replace: true, state: '' });
    }
  }, []);

  const [bookingFetched, setBookingFetched] = useState<BookingUniqueEventI | null>(null);
  const [showScan, setShowScan] = useState(false);
  const [code, setCode] = useState('');
  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [quantityTickets, setQuantityTickets] = useState(1);
  const { session } = useAuth();
  const { client } = useClient();

  const { data: event, refetch } = useQuery(
    ['unique_event', eventId],
    async () =>
      await client<GetUniqueEventI>(`unique_events/${eventId}`, 'GET', {
        isAuthRequired: false
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (event && event.status !== UniqueEventStatusE.ACTIVE) navigate('/');
  }, [event]);

  const cleanState = () => {
    setCode('');
    setQuantityTickets(1);
  };

  const mutation = useMutation<BookingUniqueEventI, ErrorI, { code: string; slots: number }>(
    (data) =>
      client(`unique_events/${eventId}/validate`, 'POST', {
        isAuthRequired: false,
        data
      }),
    {
      onSuccess: (booking) => {
        setStatus('success');
        setBookingFetched(booking);
        toast.success('Ticket canjeado exitosamente');
        refetch();
        cleanState();
      },
      onError: (error) => {
        if (error.code && error.code === 'UE-002') {
          toast.error('El codigo no existe en el sistema, revisar los datos nuevamente');
        } else if (error.code && error.code === 'UE-003') {
          setStatus('error');
          setBookingFetched(error.booking);
        } else if (error.code && error.code === 'BO-007') {
          toast.error('La reserva no esta confirmada, no se puede validar');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const updateQuantityTicket = (count: number) => {
    if (quantityTickets + count > 0) {
      setQuantityTickets(quantityTickets + count);
    }
  };

  const getTotalRedeemedSlots = () => {
    let total = 0;
    event?.unique_events_tickets?.forEach(({ redeemed_slots }) => {
      total += redeemed_slots;
    });
    return total;
  };

  const getTotalSoldSlots = () => {
    let total = 0;
    event?.unique_events_tickets?.forEach(({ total_slots, slots }) => {
      const soldSlots = total_slots - slots;
      total += soldSlots;
    });
    return total;
  };

  const onSubmit = () => {
    const data = {
      code,
      slots: quantityTickets
    };

    mutation.mutate(data);
  };

  const getQRReaderJSX = () => {
    return (
      <QrReader
        videoId="scan"
        scanDelay={500}
        constraints={{ facingMode: 'environment' }}
        onResult={(result: any, error: any) => {
          if (!!result) {
            const code = result?.text.replace(`${FLASH_URL}/eventos/${eventId}/registro?code=`, '');
            setCode(code);
            setShowScan(false);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        className="scan-qr"
      />
    );
  };

  const getContentJSX = () => {
    if (status === null) {
      return (
        <>
          <div className="p-4 border-b">
            <p className="text-center font-medium text-xl">Registrar nuevo ingreso</p>
          </div>
          <Row
            align="center"
            justify="center"
            gap={30}
            className="border-b cursor-pointer p-4"
            onClick={() => setShowScan(true)}
          >
            <img src={scanQr} alt="" /> <p>Escanear código QR</p>
          </Row>
          <Column gap={16} className="p-4">
            <p>O ingresar el código único por entrada</p>
            <InputV2
              floatLabel
              id="code"
              placeholder="Código"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
              error={''}
              value={code || ''}
            />
            <p>Indicá la cantidad de entradas a registrar</p>
            <Row align="center" justify="space-between">
              <p>Cantidad de entradas:</p>
              <Row gap={10} align="center">
                {quantityTickets > 1 && (
                  <FontAwesomeIcon
                    icon={faMinusSquare}
                    onClick={() => updateQuantityTicket(-1)}
                    className="cursor-pointer text-[#626262] text-xl"
                  />
                )}
                <p className="text-lg">x{quantityTickets}</p>
                {!(event?.is_limited_tickets && event.max_tickets_quantity <= quantityTickets) ? (
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    onClick={() => updateQuantityTicket(1)}
                    className="cursor-pointer text-[#626262] text-xl"
                  />
                ) : (
                  <div className="w-[35px] h-35px"></div>
                )}
              </Row>
            </Row>
          </Column>
          <div className="p-4">
            <Button fullWidth onClick={onSubmit} disabled={code === ''}>
              Validar
            </Button>
          </div>
        </>
      );
    } else if (status === 'success') {
      return (
        <>
          {isMobileSize ? (
            <HeaderModalSuccess>
              <p> ¡Ingreso registrado correctamente! </p>
            </HeaderModalSuccess>
          ) : (
            <HeaderModalSuccess>
              <p> ¡Ingreso registrado correctamente!</p>
              <br />
              <Icon name={'circle_check'} width="31px" height="31px" />
            </HeaderModalSuccess>
          )}
          <Wrapper>
            <InfoSuccess>
              <VendorName>
                <p>{`${bookingFetched?.booking.user?.first_name} ${bookingFetched?.booking.user?.last_name}`}</p>
              </VendorName>
              <LineSuccess />
              <WrapperTextInfo>
                <ContentTextInfoSuccess>
                  <TextDataSuccess>Tipo de entrada:</TextDataSuccess>
                  <CountDataSuccess>{`${bookingFetched?.unique_event_ticket.name}`}</CountDataSuccess>
                </ContentTextInfoSuccess>
                <ContentTextInfo>
                  <TextDataSuccess>Entradas usadas</TextDataSuccess>
                  <CountDataSuccess>x{bookingFetched?.redeemed_slots}</CountDataSuccess>
                </ContentTextInfo>
                <ContentTextInfo>
                  <TextDataSuccess>Entradas restantes</TextDataSuccess>
                  <CountDataSuccess>
                    x{(bookingFetched?.quantity || 0) - (bookingFetched?.redeemed_slots || 0)}
                  </CountDataSuccess>
                </ContentTextInfo>
              </WrapperTextInfo>
              <CustomButtonSuccess fullWidth onClick={() => setStatus(null)}>
                Volver
              </CustomButtonSuccess>
            </InfoSuccess>
          </Wrapper>
        </>
      );
    } else {
      return (
        <>
          {isMobileSize ? (
            <HeaderModalFail>
              <ErrorText>¡Error!</ErrorText>
              <CountDataFailError>Todas las entradas ya fueron usadas</CountDataFailError>
            </HeaderModalFail>
          ) : (
            <HeaderModalFail>
              <ErrorText>¡Error!</ErrorText>
              <br />
              <Icon name={'x_error'} width="31px" height="31px" />
            </HeaderModalFail>
          )}
          <Wrapper>
            <InfoSuccess>
              <VendorName>
                <p>{`${bookingFetched?.booking.user?.first_name} ${bookingFetched?.booking.user?.last_name}`}</p>
              </VendorName>
              <LineSuccess />
              <WrapperTextInfo>
                <ContentTextInfoSuccess>
                  <TextDataSuccess>Tipo de entrada:</TextDataSuccess>
                  <CountDataSuccess>{`${bookingFetched?.unique_event_ticket.name}`}</CountDataSuccess>
                </ContentTextInfoSuccess>
                <ContentTextInfo>
                  <TextDataSuccess>Entradas usadas</TextDataSuccess>
                  <CountDataSuccess>x{bookingFetched?.redeemed_slots}</CountDataSuccess>
                </ContentTextInfo>
                <ContentTextInfo>
                  <TextDataSuccess>Entradas restantes</TextDataSuccess>
                  <CountDataFail>
                    x{(bookingFetched?.quantity || 0) - (bookingFetched?.redeemed_slots || 0)}
                  </CountDataFail>
                </ContentTextInfo>
              </WrapperTextInfo>
              <CountDataFailError>Error: todas las entradas ya fueron usadas</CountDataFailError>
              <CustomButtonSuccess fullWidth onClick={() => setStatus(null)}>
                Volver
              </CustomButtonSuccess>
            </InfoSuccess>
          </Wrapper>
        </>
      );
    }
  };

  return (
    <>
      {session && <HeaderApp />}
      {event && event.status === UniqueEventStatusE.ACTIVE && (
        <>
          <Column className="sm:h-full h-dvh">
            <div className="h-[200px] bg-gray-200">
              <h1 className="font-medium text-lg sm:text-2xl text-[#212121] text-center pt-4 mb-2">
                {event?.name || ''}
              </h1>
              <p className="text-base sm:text-xl text-center text-[#212121] pb-4">
                {dayjs(event?.unique_events_schedules[0].start_at).format('DD/MM/YYYY') || ''}
              </p>
            </div>
            <div className="-mt-[100px] m-auto max-w-[90%] sm:max-w-[500px] w-full h-auto bg-white shadow-md">
              {getContentJSX()}
            </div>
            <Row
              align="center"
              justify="space-evenly"
              gap={20}
              className="my-4 sm:max-w-[500px] w-full m-auto"
            >
              <Column gap={16} align="center">
                <p className="font-medium text-sm sm:text-lg text-center text-[#626262]">Entradas vendidas</p>
                <p className="font-medium text-xl text-center">{getTotalSoldSlots()}</p>
              </Column>
              <Column gap={16} align="center">
                <p className="font-medium text-lg text-center text-[#626262]">Ingresos</p>
                <p className="font-medium text-xl text-center">{getTotalRedeemedSlots()}</p>
              </Column>
            </Row>
          </Column>
          <div className="flex items-center justify-center py-4">
            <Logo type="vertical" width="50px" height="50px" />
          </div>
          <ModalV2
            show={showScan}
            onClose={() => {
              setShowScan(false);
            }}
          >
            <TitleModal>Escanea el código QR</TitleModal>
            <div>{getQRReaderJSX()}</div>
          </ModalV2>
        </>
      )}
    </>
  );
};

const HeaderModalSuccess = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.green[200]};
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    font-weight: 420;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.white};
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    flex-direction: row;
    ${({ theme }) => theme.breakpoint('sm')} {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;
    }
  }
`;

const HeaderModalFail = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.red[200]};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ErrorText = styled.p`
  text-align: center;
  font-weight: 450;
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.white};
  padding-left: 3rem;
  padding-right: 3rem;
`;

const CountDataFailError = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: #ff4658;
  display: flex;
  align-self: center;
  font-weight: 500;
  ${({ theme }) => theme.breakpoint('sm')} {
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
  }
`;

const TitleModal = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`;

const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
  ${({ theme }) => theme.breakpoint('sm')} {
    padding: 0 20px;
  }
`;
const WrapperTextInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${({ theme }) => theme.breakpoint('sm')} {
    margin-bottom: 4rem;
  }
`;

const CustomButtonSuccess = styled(Button)`
  margin-top: 36px;
  width: 70%;
  display: flex;
  align-self: center;
  margin-bottom: 36px;
`;

const VendorName = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 10rem;
  margin-bottom: 2rem;
  color: #212121;
  ${({ theme }) => theme.breakpoint('sm')} {
    font-size: 15px;
    line-height: 18px;
  }
`;

const TextDataSuccess = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: #757575;
  display: flex;
  flex-direction: row;
`;

const CountDataSuccess = styled.p`
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 18px;
  color: #212121;
  ${({ theme }) => theme.breakpoint('sm')} {
    font-size: 15px;
    line-height: 18px;
  }
`;

const CountDataFail = styled.p`
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 18px;
  color: #ff4658;
  ${({ theme }) => theme.breakpoint('sm')} {
    font-size: 15px;
    line-height: 18px;
  }
`;

const InfoSuccess = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const LineSuccess = styled.div`
  height: 2px;
  width: 100%;
  background: #e9e9e9;
`;

const ContentTextInfo = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  ${({ theme }) => theme.breakpoint('sm')} {
    width: 120px;
  }
`;

const ContentTextInfoSuccess = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
  ${({ theme }) => theme.breakpoint('sm')} {
    width: 120px;
  }
`;
