import React, { FunctionComponent } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import styled, { ThemeProvider } from 'styled-components';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AuthProvider, ConfirmationProvider } from '@/contexts';
import { theme } from '@/components/App';
import { ToastContainer } from 'react-toastify';

const CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || 'XXXXXXX';
const DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || 'devagora.us.auth0.com';
const REDIRECT_URL = import.meta.env.VITE_AUTH0_REDIRECT_URL || 'http://localhost:3001';
const AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE || 'http://auth.agora.dev';

const queryClient = new QueryClient();

interface GlobalProvidersPropsI {
  children?: React.ReactNode;
}

export const GlobalProviders: FunctionComponent<GlobalProvidersPropsI> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={DOMAIN}
          clientId={CLIENT_ID}
          redirectUri={REDIRECT_URL}
          audience={AUDIENCE}
          useRefreshTokens
          cacheLocation="localstorage"
        >
          <AuthProvider>
            <ConfirmationProvider>{children}</ConfirmationProvider>
          </AuthProvider>
        </Auth0Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--success {
    background: #0ad6a1;
  }
  .Toastify__toast--warning {
    background: #ffe066;
  }
  .Toastify__toast--error {
    background: #ff4658;
  }
`;
