import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { EditFaq, IconLabel } from '@/components';
import { DragDropContext, Droppable, Draggable, DraggableProvided, DragUpdate } from '@hello-pangea/dnd';
import styled from 'styled-components';
import { FaqItem } from './FAQItem';
import { useModal } from '@/hooks';
import { FAQItemI } from './types';

interface FaqI {
  faqs: FAQItemI[];
  setFaqs: (items: FAQItemI[]) => void;
}

const reorder = (list: FAQItemI[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const Faq: FunctionComponent<FaqI> = ({ faqs, setFaqs }) => {
  const [position, setPosition] = useState<number>(-1);
  const { show, onClose, onOpen } = useModal();

  useEffect(() => {
    if (position >= 0) onOpen();
  }, [position]);

  const onDragEnd = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(faqs, result.source.index, result.destination.index);

      setFaqs(items);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [setFaqs, faqs]
  );

  const addFaq = (name: string, description: string) => {
    setFaqs([...faqs, { name, description, order: faqs.length + 1 }]);
    return;
  };

  const deleteFaq = (order: number) => {
    setFaqs(faqs.filter((_faq, index) => index != order));
    return;
  };

  const editFaq = (question: string, answer: string, order: number) => {
    setFaqs(
      faqs.map((faq, index) => {
        if (order === index)
          return {
            ...faq,
            name: question,
            description: answer
          };
        return faq;
      })
    );
  };

  return (
    <>
      <EditFaq
        show={show}
        editFaq={editFaq}
        onClose={onClose}
        faq={position > -1 ? faqs[position] : null}
        position={position}
        addFaq={addFaq}
        setPosition={setPosition}
      />
      <Container>
        <Row>
          <Info>
            <h3>Preguntas frecuentes</h3>
            <p>
              Nadie conoce tu negocio mejor que vos. Ayudá a tus clientes a despejar sus dudas creando una
              sección acerca de las preguntas y respuestas más frecuentes de tu servicio.
            </p>
          </Info>

          <FaqList>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {faqs.map((item, index) => (
                      <Draggable
                        key={item.order.toString()}
                        draggableId={item.order.toString()}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided: DraggableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              display: 'flex',
                              flex: 1,
                              ...provided.draggableProps.style
                            }}
                          >
                            <FaqItem
                              faq={item}
                              selectPositionFaqEdit={setPosition}
                              handlerDelete={deleteFaq}
                              position={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button onClick={onOpen} disabled={false}>
              <IconLabel icon="plus_circle" label="Agregar nueva" />
            </Button>
          </FaqList>
        </Row>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-bottom: 4rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoint('lg')} {
    width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 4rem;
  > p {
    font-size: 16px;
    line-height: 19px;
    margin-top: 18px;
    color: ${({ theme }) => theme.colors.grey[700]};
  }
  ${({ theme }) => theme.breakpoint('md')} {
    padding-right: 0px;
    h3 {
      font-size: 16px;
      line-height: 19px;
    }
    margin-bottom: 20px;
  }
`;

const FaqList = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
`;

const Button = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 1rem 1.5rem;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 51px;
  padding-bottom: 30px;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
    padding-top: 41px;
    padding-bottom: 0px;
  }
`;
