import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon, Table } from '@/components';
import { MobileCard } from '.';
import { emptyActivities } from '@/static/images';
import dayjs from 'dayjs';
import { Image, ProfileImageContainer, ActivityUser } from '../../styles';
import { theme } from '@/components/App';
import { BookingI } from '@/types/cyclone/models';
import { useWindowResize } from '@/hooks';
import { formatCurrencyFromCoin } from '@/utils/money';

const headers = ['Usuario', 'Fecha', 'Objetivo', 'Monto', 'Mensaje'];

type TableBookingDonationProps = {
  bookingDonations: BookingI[];
};

export const TableBookingDonations: FunctionComponent<TableBookingDonationProps> = ({ bookingDonations }) => {
  const { isMobileSize } = useWindowResize();

  const body =
    bookingDonations.map((booking) => {
      const { id, created_at, booking_donation, payments, user } = booking;
      const userName = user ? `${user?.first_name} ${user?.last_name}` : 'Anónimo';

      return [
        <ProfileImageContainer key={id}>
          {user?.avatar_url ? (
            <Image src={user?.avatar_url} />
          ) : (
            <Icon
              name="user_circle"
              width="30px"
              height="30px"
              marginRight="15px"
              color={theme.colors.grey[700]}
            />
          )}
          <ActivityUser>{userName}</ActivityUser>
        </ProfileImageContainer>,
        dayjs(created_at).format('DD/MM/YYYY'),
        booking_donation?.crowdfunding_plan?.title || '-',
        payments ? formatCurrencyFromCoin(payments[0].amount, payments[0].currency) : '0',
        booking_donation?.comment || ''
      ];
    }) || [];

  if (!bookingDonations.length)
    return (
      <div className="text-center py-8">
        <img src={emptyActivities} alt="No hay objetivos" className="mx-auto" />
        <p className="mt-6 text-gray-700 max-w-md mx-auto text-sm sm:text-base p-2 rounded-lg">
          Todavía no tenés actividad para mostrar
        </p>
      </div>
    );

  if (isMobileSize)
    return <>{body?.map((item, i) => (item.length ? <MobileCard items={item} key={i} /> : null))}</>;

  return (
    <TableContainer>
      <Table headers={headers} body={body} />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[500]};
  padding-top: 16px;
`;
