import { BookingI, BookingTypeE } from '@/types/cyclone/models';

export const getBookingDescription = (booking: BookingI): string => {
  switch (booking.type) {
    case BookingTypeE.SUBSCRIPTION:
      return booking.booking_subscription?.subscription.name || '';
    case BookingTypeE.EVENT_INSTANCE:
      return booking.booking_event_instance?.event_instance.event.service.name || '';
    case BookingTypeE.PACK:
      return booking.booking_pack?.service?.name || '';
    case BookingTypeE.UNIQUE_EVENT:
      return booking.booking_unique_event?.unique_event.name || '';
    case BookingTypeE.DIGITAL_CONTENT:
      return booking.booking_digital_content?.digital_content.name || '';
    case BookingTypeE.DONATION:
      return '-';
    default:
      throw new Error('This should never happen');
  }
};
