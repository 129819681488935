import { FunctionComponent, useState } from 'react';
import { MobileMenu, ProfileDropdownMenu, Burger, AgoraLogo, MainMenu } from './components';
import { NavbarItem } from '.';
import { ResponsiveContainer } from '../ResponsiveContainer';
import { Row } from '../Row';
import { useWindowResize } from '@/hooks';
import { Notifications } from './components/Notifications';

type NavbarProps = {
  onHome: string;
  avatar?: string;
  vendor?: string;
  mainItems?: NavbarItem[];
  dropdownItems?: NavbarItem[];
};

export const Navbar: FunctionComponent<NavbarProps> = ({
  onHome,
  avatar,
  mainItems = [],
  dropdownItems = []
}) => {
  const { isMobileSize } = useWindowResize();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-white z-50">
      <ResponsiveContainer>
        <Row align="center" justify="space-between" className="w-full h-[48px]">
          <AgoraLogo href={onHome} />
          {isMobileSize ? (
            <Row align="center" gap={24}>
              <Notifications />
              <Burger
                openMobileMenu={() => setShowMobileMenu(true)}
                closeMobileMenu={() => setShowMobileMenu(false)}
                isOpen={showMobileMenu}
              />
              <MobileMenu
                showMobileMenu={showMobileMenu}
                topItems={mainItems}
                bottomItems={dropdownItems}
                handleItemClick={() => setShowMobileMenu(false)}
              />
            </Row>
          ) : (
            <>
              <MainMenu items={mainItems} />
              <Row align="center" gap={24}>
                <Notifications />
                <ProfileDropdownMenu avatar={avatar} dropdownItems={dropdownItems} />
              </Row>
            </>
          )}
        </Row>
      </ResponsiveContainer>
    </header>
  );
};
