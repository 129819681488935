import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import { VirtualEventInstancesI } from '@/types/cyclone/models';
import { ContactModal } from './ContactModal';
import { BookingModal } from '.';

interface EventActionsProps {
  onSubmitContactAll: (message: string) => void;
  onSubmitBookUser: (email: string, modality: string) => void;
  eventInstance: VirtualEventInstancesI;
}
export const EventActionsMobile: FunctionComponent<EventActionsProps> = ({
  onSubmitContactAll,
  onSubmitBookUser,
  eventInstance
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showBookingModal, setShowBookingModal] = useState<boolean>(false);
  return (
    <>
      {showModal && <ContactModal setShowModal={setShowModal} onSubmit={onSubmitContactAll} />}
      {showBookingModal && (
        <BookingModal
          setShowModal={setShowBookingModal}
          onSubmit={onSubmitBookUser}
          eventInstance={eventInstance}
        />
      )}
      <Container>
        <EventActionsMobileContainer>
          {/* <Icon name="videocam" width="21px" height="21px" marginRight="7px" color={theme.colors.red[200] + '80'} />
        <button>Abrir sesión</button>
        <Icon name="add_user" width="21px" height="21px" marginRight="7px" color={theme.colors.blue[500] + '80'} />
        <button>Invitar</button>
        <Icon
          name="calendar_check"
          width="21px"
          height="21px"
          marginRight="7px"
          color={theme.colors.blue[500] + '80'}
        /> */}
          <button onClick={() => setShowBookingModal(true)}>Reservar</button>
          <Icon
            name="message"
            width="21px"
            height="21px"
            marginRight="7px"
            color={theme.colors.blue[500] + '80'}
          />
          <button onClick={() => setShowModal(true)}>Contactar a todos</button>
        </EventActionsMobileContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.breakpoint('md')} {
    overflow-x: auto;
    width: 3000px;
  }

  scroll-container {
    display: block;
    width: 350px;
    height: 200px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: white;
  }
  scroll-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 5em;
  }
  nav {
    width: 339px;
    padding: 5px;
    border: 1px solid white;
    background-color: white;
  }
`;

const EventActionsMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 89px;
  align-items: center;
  height: 25px;
  button {
    font-weight: 600;
    margin-right: 34px;
    font-size: 18px;
    line-height: 23px;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.blue[500]};
  }
  /* button:first-of-type {
    color: ${({ theme }) => theme.colors.red[200]};
  } */
  ${({ theme }) => theme.breakpoint('md')} {
    height: 82px;
  }
`;
