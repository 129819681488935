import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface BadgeProps {
  value: number | string | JSX.Element;
  color: string;
  textColor?: string;
  maintainSizeMobile?: boolean;
}

export const Badge: FunctionComponent<BadgeProps> = ({
  value,
  color,
  textColor = 'white',
  maintainSizeMobile = false
}) => (
  <BadgeContainer color={color} textColor={textColor} maintainSizeMobile={maintainSizeMobile}>
    {value}
  </BadgeContainer>
);

const BadgeContainer = styled.span<{ color: string; textColor: string; maintainSizeMobile: boolean }>`
  background-color: ${({ color }) => color};
  border-radius: 13.5px;
  padding: 2px 10px 2px 10px;
  font-size: 15px;
  line-height: 19px;
  color: ${({ textColor }) => textColor};
  ${({ theme }) => theme.breakpoint('md')} {
    ${({ maintainSizeMobile }) =>
      !maintainSizeMobile &&
      css`
        font-size: 10px;
        line-height: 13px;
        padding: 1px 4px;
        margin: 0px 4px 0px 4px;
      `}
  }
`;
