import { SupportedCountriesE } from '@/types/cyclone/models';

export interface SelectProps {
  label: string;
  value: string;
}

export const countryOptions: SelectProps[] = [
  {
    value: SupportedCountriesE.ARGENTINA,
    label: '🇦🇷 Argentina'
  },
  {
    value: SupportedCountriesE.CHILE,
    label: '🇨🇱 Chile'
  },
  {
    value: SupportedCountriesE.URUGUAY,
    label: '🇺🇾 Uruguay'
  }
];

export const timezoneOptions: Record<SupportedCountriesE, SelectProps[]> = {
  [SupportedCountriesE.ARGENTINA]: [
    {
      value: 'America/Argentina/Buenos_Aires',
      label: 'Argentina - Buenos Aires (UTC-3)'
    }
  ],
  [SupportedCountriesE.URUGUAY]: [
    {
      value: 'America/Montevideo',
      label: 'Uruguay - Montevideo (UTC-3)'
    }
  ],
  [SupportedCountriesE.CHILE]: [
    {
      value: 'America/Santiago',
      label: 'Chile - Santiago (UTC-4)'
    },
    {
      value: 'America/Punta_Arenas',
      label: 'Chile - Punta Arenas (UTC-3)'
    }
  ]
};

export const statesOptions: Record<SupportedCountriesE, SelectProps[]> = {
  [SupportedCountriesE.ARGENTINA]: [
    { value: 'AR-B', label: 'Buenos Aires' },
    { value: 'AR-C', label: 'Ciudad Autónoma de Buenos Aires' },
    { value: 'AR-K', label: 'Catamarca' },
    { value: 'AR-H', label: 'Chaco' },
    { value: 'AR-U', label: 'Chubut' },
    { value: 'AR-X', label: 'Córdoba' },
    { value: 'AR-W', label: 'Corrientes' },
    { value: 'AR-E', label: 'Entre Ríos' },
    { value: 'AR-P', label: 'Formosa' },
    { value: 'AR-Y', label: 'Jujuy' },
    { value: 'AR-L', label: 'La Pampa' },
    { value: 'AR-F', label: 'La Rioja' },
    { value: 'AR-M', label: 'Mendoza' },
    { value: 'AR-N', label: 'Misiones' },
    { value: 'AR-Q', label: 'Neuquén' },
    { value: 'AR-R', label: 'Río Negro' },
    { value: 'AR-A', label: 'Salta' },
    { value: 'AR-J', label: 'San Juan' },
    { value: 'AR-D', label: 'San Luis' },
    { value: 'AR-Z', label: 'Santa Cruz' },
    { value: 'AR-S', label: 'Santa Fe' },
    { value: 'AR-G', label: 'Santiago del Estero' },
    { value: 'AR-V', label: 'Tierra del Fuego' },
    { value: 'AR-T', label: 'Tucumán' }
  ],
  [SupportedCountriesE.URUGUAY]: [
    { value: 'UY-AR', label: 'Artigas' },
    { value: 'UY-CA', label: 'Canelones' },
    { value: 'UY-CL', label: 'Cerro Largo' },
    { value: 'UY-CO', label: 'Colonia' },
    { value: 'UY-DU', label: 'Durazno' },
    { value: 'UY-FS', label: 'Flores' },
    { value: 'UY-FD', label: 'Florida' },
    { value: 'UY-LA', label: 'Lavalleja' },
    { value: 'UY-MA', label: 'Maldonado' },
    { value: 'UY-MO', label: 'Montevideo' },
    { value: 'UY-PA', label: 'Paysandú' },
    { value: 'UY-RN', label: 'Río Negro' },
    { value: 'UY-RV', label: 'Rivera' },
    { value: 'UY-RO', label: 'Rocha' },
    { value: 'UY-SA', label: 'Salto' },
    { value: 'UY-SJ', label: 'San José' },
    { value: 'UY-SO', label: 'Soriano' },
    { value: 'UY-TA', label: 'Tacuarembó' },
    { value: 'UY-TT', label: 'Treinta y Tres' }
  ],
  [SupportedCountriesE.CHILE]: [
    { value: 'CL-AI', label: 'Aisén' },
    { value: 'CL-AN', label: 'Antofagasta' },
    { value: 'CL-AP', label: 'Arica y Parinacota' },
    { value: 'CL-AT', label: 'Atacama' },
    { value: 'CL-BI', label: 'Biobío' },
    { value: 'CL-CO', label: 'Coquimbo' },
    { value: 'CL-AR', label: 'La Araucanía' },
    { value: 'CL-LR', label: 'Los Ríos' },
    { value: 'CL-LL', label: 'Los Lagos' },
    { value: 'CL-MA', label: 'Magallanes' },
    { value: 'CL-ML', label: 'Maule' },
    { value: 'CL-RM', label: 'Región Metropolitana de Santiago' },
    { value: 'CL-TA', label: 'Tarapacá' },
    { value: 'CL-VS', label: 'Valparaíso' },
    { value: 'CL-NB', label: 'Ñuble' }
  ]
};
