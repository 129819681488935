import { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/es';
import { Button, Icon } from '@/components';
import { trackGenericEvent } from '@/analytics';
import { theme } from '@/components/App';

dayjs.locale('es');
dayjs.extend(localeData);

interface HeaderProps {
  firstName: string;
}

export const Header: FunctionComponent<HeaderProps> = ({ firstName }) => {
  return (
    <div className="content-center flex justify-between flex-col my-4">
      <div>
        <div className="flex flex-row justify-between items-center mb-3 mr-5 md:justify-start">
          <div className="text-lg text-[#212121]">{`Buen día ${firstName}!`}</div>
          <Button
            variant="link"
            onClick={() => {
              trackGenericEvent('Button Help Center Clicked', { name: 'Help Center' }),
                window.open('https://ayuda.agora.red/es/');
            }}
          >
            <Icon
              marginLeft="10px"
              name={'help'}
              width={'15px'}
              height={'15'}
              color={theme.colors.blue[500]}
            />
          </Button>
        </div>
        <div className="flex flex-row items-center mb-3 mr-5 justify-start">
          <div className="text-xs leading-[15px] color-[#858585]">
            {dayjs().locale('es').format('dddd DD [de] MMMM')}
          </div>
        </div>
      </div>
    </div>
  );
};
