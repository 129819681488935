import classNames from 'classnames';
import React from 'react';

interface Props {
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  as?: 'div' | 'section';
  children: React.ReactNode;
  className?: string;
  flex?: number;
  gap?: number;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  id?: string;
  style?: React.CSSProperties;
  isReversed?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Column = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const Tag: 'div' | 'section' = `${props.as || 'div'}`;

  return (
    <Tag
      className={classNames(props.className, {
        flex: true,
        'flex-col': !props.isReversed,
        'flex-col-reverse': props.isReversed,

        'items-start': props.align === 'flex-start',
        'items-end': props.align === 'flex-end',
        'items-center': props.align === 'center',
        'items-baseline': props.align === 'baseline',
        'items-stretch': props.align === 'stretch',

        'justify-start': props.justify === 'flex-start',
        'justify-end': props.justify === 'flex-end',
        'justify-center': props.justify === 'center',
        'justify-between': props.justify === 'space-between',
        'justify-around': props.justify === 'space-around',
        'justify-evenly': props.justify === 'space-evenly'
      })}
      id={props.id}
      key={props.id}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      ref={ref}
      style={{
        flex: props.flex ?? undefined,
        gap: props.gap ? `${props.gap}px` : undefined,
        ...(props.style || {})
      }}
    >
      {props.children}
    </Tag>
  );
});
