import { trackGenericEvent } from '@/analytics';
import { Column, ResponsiveContainer, Row } from '@/components';
import { clipboardToast } from '@/constants';
import { useService } from '@/containers/ActionServiceV2/context';
import { TabE } from '@/containers/ActionServiceV2/utils';
import { useAuth } from '@/contexts';
import { useWindowResize } from '@/hooks';
import { faCheckCircle, faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';

const tabs = [
  {
    label: 'Principal',
    value: TabE.MAIN
  },
  {
    label: 'Precios',
    value: TabE.PRICES
  },
  {
    label: 'Contenido',
    value: TabE.CONTENT
  },
  {
    label: 'Opciones avanzadas',
    value: TabE.OPTIONS
  }
];

export const Header: FunctionComponent = () => {
  const { session } = useAuth();
  const [copied, setCopied] = React.useState(faCopy);
  const { isMobileSize } = useWindowResize();
  const { activeTab, handleActiveTab, serviceSlug } = useService();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClipboard = () => {
    navigator.clipboard.writeText(`https://agora.red/${session?.vendor?.username}/servicios/${serviceSlug}`);
    setCopied(faCheckCircle);
    clipboardToast();
  };

  const onChangeTab = (tab: TabE) => {
    handleActiveTab(tab);
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('section');
    setSearchParams(newParams);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (copied === faCheckCircle) {
      setTimeout(() => {
        setCopied(faCopy);
      }, 2000);
    }
  }, [copied]);

  const copyComponent = (
    <Row
      align="center"
      className="text-sm font-medium underline underline-offset-2 cursor-pointer"
      onClick={handleClipboard}
    >
      <span>Compartir</span>
      <FontAwesomeIcon fixedWidth size="sm" icon={copied} className="ml-2" />
    </Row>
  );

  return (
    <Row className="bg-[#FFF] pt-4 sm:pt-8 w-full border-b border-[##DADBDD] shadow-md sticky left-0 right-0 top-[48px] z-10">
      <ResponsiveContainer className="w-full">
        <Column className="w-full" gap={16}>
          <Row gap={12} align="center" justify="space-between">
            <h1 className="text-xl font-medium">Detalles del servicio</h1>
            {isMobileSize && copyComponent}
          </Row>
          <Row gap={25} align="flex-start" justify="space-between" className="w-full">
            <Row align="flex-start" gap={25}>
              {tabs.map((tab) => (
                <Column
                  key={tab.value}
                  gap={8}
                  className="cursor-pointer"
                  onClick={() => {
                    trackGenericEvent('Services Tab Clicked', {
                      name: tab.label
                    });
                    onChangeTab(tab.value);
                  }}
                >
                  <p
                    className={classNames({
                      'text-sm font-medium': true,
                      'text-[#0072FB] transition-all duration-300': activeTab === tab.value
                    })}
                  >
                    {tab.label}
                  </p>
                  <div
                    className={classNames({
                      'h-0.5 bg-[#0072FB] transition-all duration-300': activeTab === tab.value
                    })}
                  />
                </Column>
              ))}
            </Row>
            {!isMobileSize && copyComponent}
          </Row>
        </Column>
      </ResponsiveContainer>
    </Row>
  );
};
