import dayjs from 'dayjs';
import 'dayjs/locale/es';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);
dayjs.locale('es');

interface TooltipProps {
  active?: boolean;
  payload?: any[];
  view?: string;
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, view }) => {
  if (!active || !payload || !payload.length) return null;

  const currentData = payload[0]?.payload;

  //need to do this because the date is in spanish format
  const monthsMap: Record<string, string> = {
    'ene.': '01',
    'feb.': '02',
    'mar.': '03',
    'abr.': '04',
    'may.': '05',
    'jun.': '06',
    'jul.': '07',
    'ago.': '08',
    'sep.': '09',
    'oct.': '10',
    'nov.': '11',
    'dic.': '12'
  };

  const parseDateSafely = (dateInput: string | number | Date | null | undefined) => {
    if (!dateInput) return null;

    if (typeof dateInput === 'string') {
      const parts = dateInput.split(' ');
      if (parts.length === 3) {
        const [day, monthAbbr, year] = parts;
        const monthKey = monthAbbr.toLowerCase() as keyof typeof monthsMap;
        const month = monthsMap[monthKey];
        if (month) {
          const standardDate = `${year}-${month}-${day.padStart(2, '0')}`;
          return dayjs(standardDate);
        }
      }
    }

    const parsed = dayjs(dateInput);
    return parsed.isValid() ? parsed : null;
  };

  const formatDate = (date: dayjs.Dayjs | null, viewType?: string) => {
    if (!date) return 'Fecha inválida';

    try {
      switch (viewType) {
        case 'quarterly': {
          const quarter = date.quarter();
          const startOfQuarter = date.startOf('quarter');
          const endOfQuarter = date.endOf('quarter');
          return `Q${quarter} (${startOfQuarter.format('D MMM')} - ${endOfQuarter.format('D MMM YYYY')})`;
        }

        case 'monthly': {
          const startOfMonth = date.startOf('month');
          const endOfMonth = date.endOf('month');
          const monthName = date.format('MMMM').charAt(0).toUpperCase() + date.format('MMMM').slice(1);
          return `${monthName} (${startOfMonth.format('D')} - ${endOfMonth.format('D MMM YYYY')})`;
        }

        case 'weekly': {
          const startOfWeek = date.startOf('week');
          const endOfWeek = date.endOf('week');
          return `${startOfWeek.format('dddd D MMM')} - ${endOfWeek.format('dddd D MMM YYYY')}`;
        }

        case 'daily':
          return date.format('D MMM YYYY');

        default:
          return date.format('D MMM YYYY');
      }
    } catch (error) {
      return 'Error en formato';
    }
  };

  const firstDate = parseDateSafely(currentData.date);
  const secondDate = parseDateSafely(currentData.previousDate);

  const firstFormattedDate = formatDate(firstDate, view);
  const secondFormattedDate = formatDate(secondDate, view);

  return (
    <div className="bg-white p-2 shadow-md rounded-md border border-gray-200">
      <div className="flex items-baseline gap-1 mb-2">
        <div className="w-2 h-2 mt-0.5 rounded-full bg-[#0072FF]" />
        <div className="flex flex-col text-xs">
          <strong>Ventas: ${payload[0].value.toLocaleString('es-AR', { maximumFractionDigits: 1 })}</strong>
          <span>{firstFormattedDate}</span>
        </div>
      </div>

      {payload[1] && (
        <div className="flex items-baseline gap-1 text-xs">
          <div className="w-2 h-2 mt-0.5 rounded-full bg-[#85B6FF]" />
          <div className="flex flex-col text-xs">
            <strong>Ventas: ${payload[1].value.toLocaleString('es-AR', { maximumFractionDigits: 1 })}</strong>
            <span>{secondFormattedDate}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
