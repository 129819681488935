import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconLabel, Icon } from '@/components';
import { theme } from '@/components/App';
import { ServiceType } from '../../types';

type ListProps = {
  services?: ServiceType[];
  deleteService: (id: number) => void;
};

export const List: FunctionComponent<ListProps> = ({ services, deleteService }) => {
  if (!services) return null;

  return (
    <Container>
      {services?.map(({ name, limit, id, key }) => (
        <Item key={id}>
          <InnerContainer>
            <Name>{name}</Name>
            <Details>
              {key !== 'service_exception' && limit !== undefined && (
                <IconLabel
                  icon="calendar_check"
                  label={limit ? `${limit} reservas` : 'Abono libre'}
                  gap="16px"
                  iconColor={theme.colors.blue[500]}
                />
              )}
            </Details>
          </InnerContainer>
          <CancelButton onClick={() => deleteService(id)}>
            <Icon name="thrash" width="12px" height="12px" color={theme.colors.white as string} />
          </CancelButton>
        </Item>
      ))}
    </Container>
  );
};
const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.breakpoint('xl')} {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    position: relative;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 1.5rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  width: 100%;
  ${({ theme }) => theme.breakpoint('xl')} {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    flex-direction: column;
    width: 100%;
    margin-right: 0;
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 700;
  margin-right: 1rem;
  //flex: 0.6;
  ${({ theme }) => theme.breakpoint('xl')} {
    margin-right: 0;
    flex: 1;
    align-self: flex-start;
    margin-bottom: 0.5rem;
    width: 50%;
  }
  ${({ theme }) => theme.breakpoint('md')} {
    width: 100%;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //flex: 1;
  ${({ theme }) => theme.breakpoint('xl')} {
    align-self: flex-start;
    width: 50%;
  }
`;

const CancelButton = styled.button`
  line-height: 0;
  padding: 9px;
  border-radius: 50px;
  background-color: rgba(104, 104, 104, 0.35);
  ${({ theme }) => theme.breakpoint('xl')} {
    position: absolute;
    top: 0.8rem;
    right: 1.5rem;
    padding: 4px;
  }
`;
