import { CurrencyE, SupportedCountriesE } from '@/types/cyclone/models';

export const formatCurrency = (value: number, _country: SupportedCountriesE, isUsd?: boolean): string => {
  let locale: string;
  let currencySymbol: string;

  const country = _country || SupportedCountriesE.ARGENTINA;

  // Determine the locale based on the country
  switch (country) {
    case SupportedCountriesE.ARGENTINA:
      locale = 'es-AR';
      currencySymbol = isUsd ? 'US$' : '$';
      break;
    case SupportedCountriesE.URUGUAY:
      locale = 'es-UY';
      currencySymbol = isUsd ? 'US$' : '$';
      break;
    case SupportedCountriesE.CHILE:
      locale = 'es-CL';
      currencySymbol = isUsd ? 'US$' : '$';
      break;
    default:
      locale = 'es-AR';
      currencySymbol = '$'; // Default case, assuming Argentina for simplicity
  }

  // Format the number with Intl.NumberFormat to ensure correct locale-specific number formatting
  const formattedNumber = new Intl.NumberFormat(locale, {
    minimumFractionDigits: isUsd ? 2 : 0,
    maximumFractionDigits: isUsd ? 2 : 0
  }).format(value);

  // Concatenate the currency symbol with the formatted number
  return `${currencySymbol}${formattedNumber}`;
};

// .

export const formatCurrencyFromCoin = (value: number, _currency?: CurrencyE): string => {
  let locale: string;
  let currencySymbol: string;

  const currency = _currency || CurrencyE.ARS;

  switch (currency) {
    case CurrencyE.ARS:
      locale = 'es-AR';
      currencySymbol = '$';
      break;
    case CurrencyE.USD:
      locale = 'en-US';
      currencySymbol = 'US$';
      break;
    case CurrencyE.UYU:
      locale = 'es-UY';
      currencySymbol = '$';
      break;
    case CurrencyE.CLP:
      locale = 'es-CL';
      currencySymbol = '$';
      break;
    default: // Default to Argentine Peso settings
      locale = 'es-AR';
      currencySymbol = '$';
      break;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: currency === CurrencyE.USD ? 2 : 0,
    maximumFractionDigits: currency === CurrencyE.USD ? 2 : 0
  });

  const formattedValue = formatter.format(value);
  const valueWithoutCurrencyCode = formattedValue.replace(/[^\d,.-]/g, '');

  return `${currencySymbol}${valueWithoutCurrencyCode}`;
};

export const handleCommission = (commission: number, sales: number, country: SupportedCountriesE) => {
  const res = (commission * sales) / 100;
  return formatCurrency(res, country);
};

export const getCurrencySymbolFromCountry = (country: SupportedCountriesE): string => {
  switch (country) {
    case SupportedCountriesE.ARGENTINA:
      return '$';
    case SupportedCountriesE.URUGUAY:
      return '$';
    case SupportedCountriesE.CHILE:
      return '$';
    default:
      return '$';
  }
};

export const formatCurrencyWithLocale = (number: number): string => {
  const formattedNumber = number.toLocaleString('es-AR', { maximumFractionDigits: 1 });
  return `$${formattedNumber}`;
};
