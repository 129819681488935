import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV == 'production') {
      ReactGA.initialize('UA-177234032-1');
      ReactPixel.init('750381646381609', undefined, {
        autoConfig: true,
        debug: false
      });

      ReactGA.pageview(location.pathname + location.search);
      ReactPixel.pageView();
    }
  }, [location]);
};

export default usePageTracking;
