import { useState, useEffect } from 'react';

export const usePreviousImage = (avatar: File | undefined): string | undefined => {
  const [previousImg, setPreviousImg] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!avatar || !avatar.type) {
      setPreviousImg(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(avatar);
    setPreviousImg(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [avatar]);

  return previousImg;
};
