import { useStoreFront } from '@/containers/StoreFrontBuilder/context';
import React, { FunctionComponent } from 'react';
import { DraggableList, SectionContent } from '../components';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

export const SectionBio: FunctionComponent = () => {
  const {
    bio,
    handlerBio,
    certifications,
    experiences,
    removeExperience,
    removeCertification,
    addExperience,
    addCertification,
    handlerExperiences,
    handlerCertifications,
    saveBio
  } = useStoreFront();
  return (
    <div className="mt-5">
      <SectionContent>
        <RichTextEditor
          placeholder="Contale a tus clientes sobre vos y tu carrera profesional"
          content={bio}
          limit={2000}
          onChange={handlerBio}
        />
        <button
          className="text-base font-normal leading-9 text-white bg-blue cursor-pointer text-center rounded w-full mt-3"
          onClick={saveBio}
        >
          Guardar
        </button>
      </SectionContent>
      <SectionContent
        header="Certificaciones"
        subtitle="Comparti tus certificaciones o títulos profesionales. Podrás agregar otros o modificarlos más adelante."
      >
        <DraggableList
          items={certifications || []}
          removeItem={removeCertification}
          addItem={addCertification}
          handlerItems={handlerCertifications}
          textAddButton="Agregar certificación"
          titleModal="Agregar certificación"
          subtitleModal="Certificaciones"
          placeHolderTitleInput="Título del certificado"
          placeHolderDescriptionInput="Breve descripción"
          isRichText
        />
      </SectionContent>
      <SectionContent
        header="Experiencia"
        subtitle="Compartí tu experiencia profesional o la de tu equipo. Podrás agregar otras y modificarlas más adelante."
      >
        <DraggableList
          items={experiences || []}
          removeItem={removeExperience}
          addItem={addExperience}
          handlerItems={handlerExperiences}
          textAddButton="Agregar experiencia"
          titleModal="Agregar experiencia"
          subtitleModal="Experiencia"
          placeHolderTitleInput="Título de la experiencia"
          placeHolderDescriptionInput="Breve descripción"
          isRichText
        />
      </SectionContent>
    </div>
  );
};
