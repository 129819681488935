import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button } from '@/components';
import { LinkItem } from '../components/LinkItem';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { AgoraLinkTypeE } from '@/types/cyclone/models';
import { DragDropContext, Draggable, DraggableProvided, DragUpdate, Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { LinkI, useStoreFront } from '@/containers/StoreFrontBuilder/context/storefront';
import { LinksShowModal } from './LinksShowModal';
import { LinksEditModal } from './LinksEditModal';
import { ButtonSelectLink } from '../components';
import { REGEX_VALID_URL, REGEX_YOUTUBE_VALID_URL } from '@/constants/regex';

interface SectionLinksPropsI {
  linksTemplate: LinkI[] | undefined;
  removeLink: (position: number) => void;
  addLink: (newLink: LinkI) => void;
  handlerLinks: (links: LinkI[]) => void;
}

const schema = yup.object().shape({
  title: yup.string(),
  url: yup.string(),
  description: yup.string()
});

const reorder = (list: LinkI[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const optionLinks = [
  { text: 'Link personalizado', type: AgoraLinkTypeE.CUSTOM, isDisabled: false },
  { text: 'Video o canal de Youtube', type: AgoraLinkTypeE.YOUTUBE, isDisabled: false }
  // { text: 'Lista o canción de Spotify', type: AgoraLinkTypeE.EVENT, isDisabled: false }
];

export const SectionLinks: FunctionComponent<SectionLinksPropsI> = ({
  linksTemplate,
  removeLink,
  addLink,
  handlerLinks
}) => {
  const [type, setType] = useState<AgoraLinkTypeE | null>(null);
  const [positionLink, setPositionLink] = useState<number | undefined>(undefined);
  const { handleSubmit: handleSubmitForm, control, reset } = useForm({ resolver: yupResolver(schema) });
  const title: string = useWatch({ name: 'title', control });
  const url: string = useWatch({ name: 'url', control });

  const [errors, setErrors] = useState<{ title?: string; url?: string }>({ title: '', url: '' });

  const { handleModalLink, showModalLink, modalLinkType, setShowModalLink } = useStoreFront();

  const validation = () => {
    const validatesErrors = {
      title: title ? '' : 'Campo requerido',
      url:
        type !== AgoraLinkTypeE.YOUTUBE && type !== AgoraLinkTypeE.CUSTOM
          ? ''
          : url
          ? type !== AgoraLinkTypeE.YOUTUBE
            ? REGEX_VALID_URL.test(url || '')
              ? ''
              : 'Error: es una url inválida'
            : REGEX_YOUTUBE_VALID_URL.test(url || '')
            ? ''
            : 'Error: es una url inválida'
          : 'Campo requerido'
    };
    setErrors(validatesErrors);
    return validatesErrors.title || validatesErrors.url;
  };

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    if (validation()) return;
    if (!type) return;
    if (positionLink !== undefined) {
      const links =
        linksTemplate &&
        linksTemplate.map((link: LinkI, index: number) => {
          if (index === positionLink) {
            return { ...link, type: type, title: data.title, url: data.url, description: data.description };
          }
          return link;
        });

      handlerLinks(links || []);
    } else {
      const newLink = {
        type: type,
        title: data.title,
        url: data.url,
        description: data.description,
        order: linksTemplate ? linksTemplate.length : 0,
        isHidden: false
      };
      addLink(newLink);
    }
    setPositionLink(undefined);
    setType(null);
    reset({
      title: '',
      url: '',
      description: ''
    });
  };

  const editLink = (position: number) => {
    if (!linksTemplate) return;
    const link = linksTemplate[position];
    reset({
      title: link.title,
      url: link.url,
      description: link.description,
      isHidden: false
    });
    setType(link.type);
    setPositionLink(position);
  };

  const selectTypeLink = (type: AgoraLinkTypeE) => {
    setType(type);
  };

  const getOptionsLinks = () => {
    return optionLinks.map((link) => {
      return {
        ...link,
        isDisabled:
          link.type === AgoraLinkTypeE.YOUTUBE &&
          linksTemplate?.some((link) => link.type === AgoraLinkTypeE.YOUTUBE)
      };
    });
  };
  const onDragEnd = useCallback(
    (result: DragUpdate) => {
      if (!result.destination || !linksTemplate) {
        return;
      }

      const items = reorder(linksTemplate, result.source.index, result.destination.index);

      handlerLinks(items);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [linksTemplate, handlerLinks]
  );

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.keyCode === 13)
        handleSubmitForm(handleSubmit, () => {
          // Handle error
        });
    };
    window.addEventListener('keydown', handleEnter);

    return () => window.removeEventListener('keydown', handleEnter);
  });

  useEffect(() => {
    if (type === null) {
      reset({
        title: '',
        url: '',
        description: ''
      });
      setErrors({ title: '', url: '' });
    }
  }, [type]);
  return (
    <div className="py-2">
      <LinksShowModal type={modalLinkType} show={showModalLink} onClose={() => setShowModalLink(false)} />
      <LinksEditModal
        type={type}
        setType={setType}
        control={control}
        errors={errors}
        onSubmit={handleSubmitForm(handleSubmit, () => {
          // Handle error
        })}
      />
      <div className="mt-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {linksTemplate &&
                  linksTemplate.map((link, index) => (
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            display: 'flex',
                            flex: 1,
                            ...provided.draggableProps.style
                          }}
                          className="flex flex-col w-full pb-4 mb-4"
                        >
                          <LinkItem
                            key={index}
                            link={link}
                            position={index}
                            removeLink={removeLink}
                            editLink={editLink}
                            handleModalLink={handleModalLink}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <ButtonSelectLink
        fullWidth
        options={getOptionsLinks()}
        selectTypeLink={selectTypeLink}
        button={
          <Button fullWidth>
            <FontAwesomeIcon icon={faPlus} color="#fff" size="xs" />
            <p className="ml-2 text-base text-[#FFF]">Agregar link</p>
          </Button>
        }
      />
    </div>
  );
};
