import React, { FunctionComponent } from 'react';

type PositionType = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  children: React.ReactNode;
  content: string | JSX.Element;
  position?: PositionType;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({ children, content, position = 'top' }) => {
  const positionClasses = {
    top: 'tooltip-top',
    right: 'tooltip-right',
    bottom: 'tooltip-bottom',
    left: 'tooltip-left'
  };

  return (
    <div className={`tooltip ${positionClasses[position]}`} data-tip={content}>
      {children}
    </div>
  );
};
