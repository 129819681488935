import { Fragment, FunctionComponent } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Row } from '../Row';
import { scrollBarTW } from '../App';
import { Float } from '@headlessui-float/react';

export interface SelectProps {
  label: string | JSX.Element;
  value: string | string[];
  name?: string;
}

interface Props {
  options: SelectProps[];
  selected: SelectProps | null;
  setSelected: (value: SelectProps | null) => void;
  className?: string;
  disabled?: boolean;
  postfix?: string;
}

export const SelectV2: FunctionComponent<Props> = ({
  options,
  selected,
  setSelected,
  className,
  disabled,
  postfix
}) => {
  return (
    <div className={classNames(className, { 'w-full': true })}>
      <Listbox value={selected} onChange={setSelected} disabled={disabled}>
        <Float
          adaptiveWidth
          enter="transition duration-200 ease-out"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition duration-150 ease-in"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
          tailwindcssOriginClass
          placement="bottom"
        >
          <Listbox.Button
            className={classNames(
              `w-full relative cursor-pointer rounded bg-white h-[40px] px-4 text-left shadow-sm focus:outline-none sm:text-sm border border-solid border-[#0000005c]`,
              { 'opacity-50': disabled }
            )}
          >
            {postfix ? (
              <Row gap={8} align="center" justify="space-between" className="w-full">
                <Row gap={8} align="center" justify="space-between" className="w-auto cursor-pointer">
                  <span className="block truncate">{selected?.label || 'Seleccione una opción'}</span>
                  <FontAwesomeIcon icon={faChevronDown} color="#828282" />
                </Row>
                <span className="text-[#828282] w-fit">{postfix}</span>
              </Row>
            ) : (
              <>
                <span className="block truncate">{selected?.label || 'Seleccione una opción'}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FontAwesomeIcon icon={faChevronDown} color="#828282" />
                </span>
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className={`w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${scrollBarTW}`}
            >
              {options.map((option, key) => (
                <Listbox.Option
                  key={key}
                  className={({ active }) =>
                    `cursor-default select-none py-2 px-4 ${
                      active ? 'bg-gray-100 text-gray-700' : 'text-gray-900'
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {option.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Float>
      </Listbox>
    </div>
  );
};
