'use client';
import React, { useState, useRef, useEffect } from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/Command/Command';
import { useClient, useDebounce } from '@/hooks';
import { PlaceType } from '@/types';
import { useQuery } from 'react-query';
import { GetPlacesI } from '@/types/cyclone/requests';
import { Row } from '../Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

interface InputAddressProps {
  selectedPlace?: string;
  onSelect: (place?: string) => void;
  disabled?: boolean;
  country: string | null;
  state?: string;
  isOnboarding?: boolean;
}

export const InputAddress: React.FC<InputAddressProps> = ({
  selectedPlace,
  disabled = false,
  country,
  state,
  onSelect,
  isOnboarding
}) => {
  const { client } = useClient();
  const [value, setValue] = useState(selectedPlace || '');
  const [fetchLocations, setFetchLocations] = useState(false);
  const [isEdition, setIsEdition] = useState(!selectedPlace);
  const inputRef = useRef<HTMLInputElement>(null);

  const capitalizeAddress = (text: string) => {
    return text
      .split(' ')
      .map((word) => {
        const lowerCaseWords = ['de', 'del', 'la', 'las', 'los', 'y', 'e', 'o', 'u'];
        if (lowerCaseWords.includes(word.toLowerCase())) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  const toggleFetchLocations = () => setFetchLocations(!fetchLocations);
  const locationDebounce = useDebounce(toggleFetchLocations, 300);

  const onChangeLocationInput = (place: string) => {
    setValue(place);
    locationDebounce();
  };

  useEffect(() => {
    if (isEdition && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEdition]);

  useEffect(() => {
    if (selectedPlace) {
      setValue(selectedPlace);
    }
  }, [selectedPlace]);

  React.useLayoutEffect(() => {
    if (selectedPlace) {
      setIsEdition(false);
    }
  }, [selectedPlace]);

  const { data: locationResponses } = useQuery(
    ['location', fetchLocations],
    async () => await client<GetPlacesI>(`google/places/${encodeURIComponent(value)}?country=${country}`),
    {
      enabled: !!value,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleSelectPlace = (place: string) => {
    const capitalizedPlace = capitalizeAddress(place);
    setIsEdition(false);
    onSelect(capitalizedPlace);
    setValue(capitalizedPlace);
    setFetchLocations(false);
  };

  const handleEditPlace = () => {
    setIsEdition(true);
    setFetchLocations(true);
    setValue(selectedPlace || '');
  };

  const handleCancelEdition = () => {
    setIsEdition(false);
    setFetchLocations(false);
    setValue(selectedPlace || '');
  };

  const handleDelete = () => {
    setIsEdition(true);
    setFetchLocations(false);
    setValue('');
    onSelect(undefined);
  };

  useEffect(() => {
    if (isOnboarding && value === '') {
      handleDelete();
    }
  }, [value]);

  const filteredByState = locationResponses?.filter(({ description }) => {
    if (!state) return true;

    const normalizedDescription = description.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const normalizedState = state.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const stateRegex = new RegExp(`\\b${normalizedState}\\b`, 'i');
    return stateRegex.test(normalizedDescription);
  });

  const places: PlaceType[] =
    filteredByState?.map(({ description }) => ({
      address: description
    })) || [];

  if (!isEdition && !disabled) {
    return (
      <Row align="flex-start" justify="space-between" gap={16} className="w-full">
        <div className="flex items-center opacity-50 text-black w-[calc(100%-56px)] !text-base !rounded bg-white h-[40px] px-4 text-left shadow-sm focus:outline-none border border-solid border-[#0000005c]">
          <p className="whitespace-nowrap truncate w-[99%]">{selectedPlace}</p>
        </div>
        <div
          onClick={handleEditPlace}
          className="w-10 h-10 bg-white border text-[#212121] hover:text-white hover:bg-blue transition-colors duration-150 rounded-full cursor-pointer flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faPencil} size="sm" fixedWidth />
        </div>
      </Row>
    );
  }

  return (
    <Command shouldFilter={false} onKeyDown={() => null} className="overflow-visible">
      <div className="w-full flex items-center relative gap-4">
        <CommandInput
          ref={inputRef}
          value={value}
          onValueChange={(search) => onChangeLocationInput(search)}
          placeholder="Ingresa la dirección"
          className={classNames(
            '!text-base !rounded bg-white h-[40px] px-4 text-left shadow-sm focus:outline-none border border-solid border-[#0000005c]',
            { '!w-[calc(100%-56px)]': selectedPlace, 'w-full': !selectedPlace }
          )}
          disabled={disabled}
        />
        {selectedPlace && !disabled && (
          <div
            onClick={handleCancelEdition}
            className="w-10 h-10 bg-white border text-[#212121] hover:text-white hover:bg-blue transition-colors duration-150 rounded-full cursor-pointer flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faXmark} size="sm" fixedWidth />
          </div>
        )}
      </div>
      {!disabled && value.length > 0 && places.length > 0 && (
        <div className="relative animate-in fade-in-0 zoom-in-95 h-auto">
          <CommandList>
            <div className="absolute top-1.5 z-50 w-full">
              <CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md bg-white">
                {places.map((place, index) => (
                  <CommandItem
                    value={place.address}
                    onSelect={handleSelectPlace}
                    key={index}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {place.address}
                  </CommandItem>
                ))}
                <CommandEmpty>
                  {places.length === 0 && (
                    <div className="py-4 flex items-center justify-center">
                      {value === '' ? 'Ingresa la dirección' : 'Sin direcciones encontradas'}
                    </div>
                  )}
                </CommandEmpty>
              </CommandGroup>
            </div>
          </CommandList>
        </div>
      )}
    </Command>
  );
};
