import React, { Dispatch, FunctionComponent, SetStateAction, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import styled from 'styled-components';
import 'cropperjs/dist/cropper.css';
import { Button, ModalV2, Spinner } from '@/components';
import { useCallback } from 'react';
import { theme } from '@/components/App';
import { Image } from '@/containers/ActionUniqueEvent/utils';

type ImageGaleryCropperProps = {
  selectedImage: Image;
  onClose: () => void;
  setImages: Dispatch<SetStateAction<Image[]>>;
  images: Image[];
};

export const ImageGaleryCropper: FunctionComponent<ImageGaleryCropperProps> = ({
  selectedImage,
  onClose,
  setImages,
  images
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [imageCropped, setImageCropped] = useState<Image | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const onCrop = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const croppedImage = {
      ...selectedImage
    };

    croppedImage.url = cropper.getCroppedCanvas().toDataURL();

    cropper.getCroppedCanvas().toBlob((blob: File) => {
      const myBlob = blob ? Object.assign(blob) : null;
      if (myBlob) {
        myBlob.lastModified = new Date();
        myBlob.name = selectedImage.blob?.name || selectedImage.id;
        croppedImage.blob = myBlob;
      }
    });

    setImageCropped(croppedImage);
  }, [selectedImage]);

  const onSave = useCallback(() => {
    const changedImages = images.map((image) => {
      if (image.id === selectedImage.id) {
        return imageCropped;
      }
      return image;
    });

    setImages(changedImages as Image[]);
    onClose();
  }, [imageCropped, images, selectedImage.id, setImages, onClose]);

  return (
    <ModalV2 title="Ajustá tu imagen" show onClose={onClose}>
      <ModalContent>
        {loading && (
          <Absolute>
            <Spinner size={23} background={theme.colors.grey[400]} />
          </Absolute>
        )}
        <Cropper
          src={selectedImage?.url}
          style={{
            height: 300,
            width: 300,
            margin: 'auto',
            ...(loading && { visibility: 'hidden' })
          }}
          // Cropper.js options
          autoCropArea={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          ready={() => {
            setLoading(false);
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Button color={theme.colors.blue[500] + '80'} onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={onSave}>Guardar</Button>
      </ModalFooter>
    </ModalV2>
  );
};

const Absolute = styled.div`
  position: absolute;
  margin: auto auto;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ModalFooter = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  > button {
    height: 40px;
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
`;
