import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Row, Column } from '@/components';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { stepTwoMockup } from '@/static/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useOnboarding } from '../contexts';
import { useWindowResize } from '@/hooks';
import { GENERIC_URL_REGEX, INSTAGRAM_REGEX, MIN_USERNAME_LENGTH } from '../utils';
import { InputImage } from '@/components/Input/InputImage';
import { TypeImageInput } from '@/components/ImageManagerV2';
import { NextStepButton } from '../components/Navbar/Navbar';
import TextArea from '@/components/TextArea/TextArea';
import { faStar } from '@fortawesome/free-regular-svg-icons';

export const SiteData: FunctionComponent = () => {
  useMixpanelTrackPage('Onboarding', { step: 'SiteData' });

  const {
    user,
    avatarImage,
    handleAvatarImage,
    username,
    usernameIsAvailable: usernameIsAvailable,
    control,
    errors,
    togglePrivacyPolicies,
    privacyPolicies,
    setValue,
    validationChecks,
    hasValidationError
  } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  const ValidationCheckItem = ({ isValid, label }: { isValid: boolean; label: string }) => (
    <Row align="center" gap={8} className="text-xs">
      <FontAwesomeIcon
        icon={username ? (isValid ? faCheck : faXmark) : faMinus}
        color={username ? (isValid ? '#25D366' : '#FF4658') : '#868686'}
        fixedWidth
        size="sm"
      />
      <span
        className={classNames(
          'transition-colors duration-300',
          username ? (isValid ? 'text-[#25D366]' : 'text-[#FF4658]') : 'text-[#868686]'
        )}
      >
        {label}
      </span>
    </Row>
  );

  React.useEffect(() => {
    const handleOnPaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const pastedText = e.clipboardData?.getData('Text').trim();

      if (!pastedText) return;

      let processedUsername: string;

      const instagramMatch = pastedText.match(INSTAGRAM_REGEX);
      if (instagramMatch) {
        processedUsername = instagramMatch[1];
      } else {
        const genericUrlMatch = pastedText.match(GENERIC_URL_REGEX);
        if (genericUrlMatch) {
          processedUsername = genericUrlMatch[1];
          // Eliminar 'www.' del inicio del texto
          processedUsername = processedUsername.replace(/^www\./, '');
        } else {
          processedUsername = pastedText;
        }
      }

      // Limitar a 20 caracteres
      processedUsername = processedUsername.slice(0, 20);
      setValue('username', processedUsername);
    };

    const inputEl = document.getElementById('username-i');
    inputEl?.addEventListener('paste', handleOnPaste);

    return () => {
      inputEl?.removeEventListener('paste', handleOnPaste);
    };
  }, []);

  return (
    <Row className="w-full max-h-[calc(100vh-48px)]">
      <Column
        className={classNames('gap-3', {
          'w-3/5 p-8 h-[calc(100vh-48px)]': !isMobileSize,
          'w-full py-3': isMobileSize
        })}
      >
        <Column
          className={classNames({
            'max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
              !isMobileSize,
            'my-4': isMobileSize
          })}
        >
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Nombre de tu negocio</h3>
            <p className="text-sm sm:text-base text-[#626262]">Este será el encabezado de tu sitio.</p>
            <Controller
              control={control}
              name="storefront_name"
              render={({ field: { onChange, value } }) => (
                <div className="w-full sm:w-3/4 mt-2">
                  <Input
                    inputId="storefront_name"
                    onChange={onChange}
                    value={value || ''}
                    maxLength={60}
                    showCount
                    error={errors.storefront_name && (errors.storefront_name.message as string)}
                    placeholderInput="Ej: Instituto de Nutrición"
                  />
                </div>
              )}
            />
          </Column>
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Descripcion</h3>
            <p className="text-sm sm:text-base text-[#626262]">Frase o breve descripción de tu negocio.</p>
            <Controller
              control={control}
              name="profession"
              render={({ field: { onChange, value } }) => (
                <div className="w-full sm:w-3/4 mt-2">
                  <TextArea
                    placeholder="Ej: Nutricionista Deportivo"
                    value={value || ''}
                    onChange={onChange}
                    autoExpand={false}
                    maxLength={70}
                    showCount
                    error={errors.profession && (errors.profession.message as string)}
                  />
                </div>
              )}
            />
          </Column>
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Personalizá tu URL</h3>
            <p className="text-sm sm:text-base text-[#626262]">
              Este será el enlace a tu página para compartir con clientes
            </p>
            <Controller
              control={control}
              name="username"
              defaultValue={user?.vendor.username}
              render={({ field: { onChange, value } }) => (
                <Row align="center" gap={8} className="w-full sm:w-3/4 mt-2">
                  <p className="whitespace-nowrap sm:text-lg text-[#21221] h-[40px] mb-1 sm:mb-2">
                    www.agora.red /
                  </p>
                  <div className="w-full">
                    <Input
                      inputId="username"
                      onChange={onChange}
                      placeholder="usuario"
                      maxLength={20}
                      showCount
                      value={value || ''}
                      error={(errors.username || (username.length >= 1 && hasValidationError)) && ' '}
                      postFix={
                        !hasValidationError && username && username.length >= MIN_USERNAME_LENGTH ? (
                          <Row align="center" gap={5}>
                            <FontAwesomeIcon
                              icon={usernameIsAvailable ? faCheck : faXmark}
                              fontSizeAdjust={14}
                              color={usernameIsAvailable ? '#25D366' : '#FF4658'}
                              className="mt-1"
                            />
                            <p
                              className={classNames(
                                'text-sm',
                                { 'text-[#25D366]': usernameIsAvailable },
                                { 'text-[#FF4658]': !usernameIsAvailable }
                              )}
                            >
                              {!isMobileSize && (usernameIsAvailable ? 'Disponible' : 'No disponible')}
                              {isMobileSize && (usernameIsAvailable ? '' : 'En uso')}
                            </p>
                          </Row>
                        ) : (
                          ''
                        )
                      }
                    />
                  </div>
                </Row>
              )}
            />
            <Column gap={2}>
              <span className="text-sm text-[#21221] font-medium">
                <FontAwesomeIcon icon={faStar} size="sm" className="mr-1" />
                Recomendamos que sea similar a tu usuario en Instagram.
              </span>
              <ValidationCheckItem
                isValid={validationChecks.length}
                label={`Mínimo ${MIN_USERNAME_LENGTH} caracteres`}
              />
              <ValidationCheckItem isValid={validationChecks.noTildes} label="Sin tildes" />
              <ValidationCheckItem
                isValid={validationChecks.noSpecialChars}
                label="Solo letras, números, punto y guion bajo"
              />
              <ValidationCheckItem
                isValid={validationChecks.noConsecutiveDots}
                label="No puntos consecutivos"
              />
              <ValidationCheckItem
                isValid={validationChecks.noStartEndDots}
                label="No comenzar/terminar con punto"
              />
              <ValidationCheckItem isValid={validationChecks.notUrl} label="No puede ser una URL" />
            </Column>
          </Column>
          {!isMobileSize && (
            <Row align="center" className="my-6 gap-8">
              <Column className="h-full">
                <h3 className="font-medium text-lg sm:text-xl">Foto de perfil</h3>
                <Column className="gap-2">
                  <p className="text-sm sm:text-base text-[#626262] mt-4">
                    Agregá una foto de perfil o logo para tu negocio
                  </p>
                  <p className="text-xs text-[#626262]">
                    Max. 2MB. Formato jpg, png. Recomendada (500x500) px
                  </p>
                </Column>
              </Column>
              <InputImage
                type={TypeImageInput.AVATAR}
                width={'128px'}
                height={'128px'}
                img={avatarImage}
                handleImg={handleAvatarImage}
              />
            </Row>
          )}
        </Column>
        <Row className="form-control">
          <label className="label gap-2 p-0 cursor-pointer">
            <input
              type="checkbox"
              checked={privacyPolicies}
              onChange={togglePrivacyPolicies}
              className="checkbox w-5 h-5 rounded checkbox-primary"
            />
            <span className="text-sm sm:text-base [&>a]:text-[#0072FB] [&>a]:cursor-pointer">
              Acepto{' '}
              <a href="https://agora.red/terminos-y-condiciones-proveedores" target="_blank" rel="noreferrer">
                términos
              </a>{' '}
              y{' '}
              <a href="https://agora.red/politicas-de-privacidad" target="_blank" rel="noreferrer">
                política de privacidad
              </a>{' '}
              de Ágora
            </span>
          </label>
        </Row>
        <div className="w-full sm:w-3/4">
          <NextStepButton fullWidth />
        </div>
      </Column>
      {!isMobileSize && (
        <Column gap={20} className="w-2/5 h-[calc(100vh-48px)] bg-[#FBFBFB] p-8">
          <div
            style={{
              backgroundImage: `url(${stepTwoMockup})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '100%'
            }}
          />
        </Column>
      )}
    </Row>
  );
};
