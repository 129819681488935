import {
  PatternE,
  RoundedE,
  SubtitleTypeFontE,
  TitleTypeFontE,
  TypeButtonsE
} from '@/containers/StoreFrontBuilder/context/storefront';
import { SizeImageE } from '@/types/cyclone/models';

export const getTemplateConfig = (variant: string) => {
  switch (variant) {
    case 'template_1':
    case 'template_11':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#212121',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.RALEWAY,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_5,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
    case 'template_2':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#212121',
        button_color: '#212121',
        text_color: '#FFFFFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.RALEWAY,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_20,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_3':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#032646',
        button_color: '#FFFFFF',
        text_color: '#032646',
        shadow_color: '#032646',
        font_type: SubtitleTypeFontE.RALEWAY,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: PatternE.PATTERN_4,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
    case 'template_4':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#DE9BB4',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#FFFFFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.MERIENDA,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_5':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#0DC0DE',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_6':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_7,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_7':
    case 'template_15':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#3B9648',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.POPPINS,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: PatternE.PATTERN_1,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_8':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#318549',
        button_color: '#FFFFFF',
        text_color: '#FF4658',
        shadow_color: '#4E9A61',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.POPPINS,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_0,
        pattern_type: PatternE.PATTERN_3,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
    case 'template_9':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#212121',
        button_color: '#212121',
        text_color: '#212121',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.MERIENDA,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_6,
        is_banner_displayed: false,
        is_avatar_displayed: true
      };
    case 'template_10':
      return {
        has_cover: true,
        has_avatar: false,
        background_color: '#212121',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_2,
        is_banner_displayed: true,
        is_avatar_displayed: false,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_12':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#780700',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_13':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#375928',
        button_color: '#375928',
        text_color: '#FFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.POPPINS,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_0,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_14':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#780700',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_16':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#780700',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#FFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.MERIENDA,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_12,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    default:
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#212121',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.RALEWAY,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_5,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
  }
};
