import { LabelValue } from '@/types';
import { toast } from 'react-toastify';

export const LINKS = {
  APPLY: 'https://agorared.typeform.com/to/RvKH7X',
  PRIVACY: 'https://agora.red/politicas-de-privacidad',
  TERMS: 'https://agora.red/terminos-y-condiciones-proveedores',
  FAQ: 'https://agora.red/faq',
  CONTACT_EMAIL: 'info@agora.red',
  SUPPORT_WHATSAPP: 'https://wa.me/5491160204078',
  SUPPORT_AGORA: 'https://agora.red/encontratupasion/servicios/575',
  HELP_CENTER: 'https://agora-red.notion.site'
};

export const DAYS_OF_WEEK = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

export const clipboardToast = () => {
  toast.success('Copiado', {
    position: 'bottom-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
};

export const OPTIONS_SOCIAL_MEDIA: LabelValue[] = [
  { label: 'Instagram', value: 'instagram', icon: 'instagram' },
  { label: 'Facebook', value: 'facebook', icon: 'facebook' },
  { label: 'Linkedin', value: 'linkedin', icon: 'linked_in' },
  { label: 'Youtube', value: 'youtube', icon: 'youtube' },
  { label: 'Web', value: 'web', icon: 'web' }
];

export const serviceNamesDefault = [
  'Corte y perfilado de barba',
  'Corte de pelo y barba',
  'Perfilado de cejas',
  'Laminado de cejas',
  'Depilación | 1 zona a elección',
  'Depilación | 4 zonas a elección',
  'Maquillaje social',
  'Maquillaje profesional para eventos',
  'Corte de pelo - Hombre',
  'Corte de pelo - Mujer',
  'Extensión de pestañas - Clásicas',
  'Laminado de pestañas',
  'Consulta podológica básica',
  'Limpieza profunda',
  'Masaje relajante | Cuerpo completo',
  'Drenaje linfático',
  'Limpieza profunda de cutis',
  'Peeling de cutis',
  'Kapping de uñas',
  'Uñas esculpidas'
];
