import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Button } from '@/components/Button';
import { DateSelector } from './DateSelector';
import { ServiceI, EventI } from '@/types/cyclone/models';
import { DAYS_OF_WEEK } from '@/constants';
import { formatDay, getNewId } from '@/utils';
import { CombinationStatus, CombinationType, ScheduleStatus } from '@/containers/Agenda/types';
import { getInitialSchedules } from '@/containers/Agenda/utils';

type SelectServiceDateProps = {
  selectedService: ServiceI | undefined;
  navigateToEventCreated: () => void;
  combinations: CombinationType[];
  addCombination: () => void;
  serviceDuration: number;
  handleCombination: (combination: CombinationType[]) => void;
};

export const SelectServiceDate: FunctionComponent<SelectServiceDateProps> = ({
  selectedService,
  navigateToEventCreated,
  addCombination,
  combinations,
  serviceDuration,
  handleCombination
}) => {
  useEffect(() => {
    const reduceCombinations =
      selectedService?.events
        ?.filter((event) => event.is_recurrent === true)
        .reduce((accum: Record<string, EventI[]>, event) => {
          const dayString = event.days?.sort().toString();
          if (dayString) {
            accum[dayString] = accum[dayString] || [];
            accum[dayString].push(event);
          }
          return accum;
        }, {}) || {};

    const previousCombinations: CombinationType[] = Object.entries(reduceCombinations).map(([key, value]) => {
      const days = key.split(',');

      const selectedDays = days.map((day) => ({
        label: DAYS_OF_WEEK[formatDay(parseInt(day))],
        value: day
      }));

      const selectedSchedules = value.map((event) => {
        const startTimeUnits = event.start_time.split(':');
        const hours = parseInt(startTimeUnits[0]);
        const minutes = parseInt(startTimeUnits[1]);
        const date = dayjs().set('hours', hours).set('minutes', minutes);

        return {
          label: event.start_time.slice(0, -3),
          value: event.start_time,
          status: ScheduleStatus.SELECTED,
          date
        };
      });

      const schedules = getInitialSchedules(selectedService?.duration || 60, selectedSchedules);

      return {
        status: CombinationStatus.CONFIRMED,
        schedules,
        id: getNewId(),
        selectedDays,
        selectedSchedules
      };
    });

    handleCombination(previousCombinations);
  }, [selectedService, handleCombination]);

  const combinationIsConfirmed = (combination: CombinationType) => {
    return combination.status === CombinationStatus.CONFIRMED;
  };

  const validateCombinations = combinations.some(combinationIsConfirmed);

  const shouldAddNewCombinations = combinations.every(combinationIsConfirmed);

  return (
    <Container>
      <DatesSelector>
        {combinations.map((combination, index) => {
          return (
            <DateSelector
              key={index}
              combination={combination}
              allowDelete={true}
              combinationIndex={index}
              serviceDuration={serviceDuration}
              allCombinations={combinations}
              handleCombination={handleCombination}
            />
          );
        })}
      </DatesSelector>
      {shouldAddNewCombinations && (
        <AddCombinationContainer>
          <CustomButton onClick={addCombination} variant="outlined" size="sm">
            + Agregar nueva combinación
          </CustomButton>
        </AddCombinationContainer>
      )}
      <ButtonContainer noBottomPadding={combinations.length < 2}>
        <Button
          disabled={!validateCombinations && combinations.length !== 0}
          onClick={navigateToEventCreated}
          fullWidth
        >
          Guardar eventos recurrentes
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 485px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const ButtonContainer = styled.div<{ noBottomPadding: boolean }>`
  margin-top: auto;
  padding-bottom: ${({ noBottomPadding }) => (noBottomPadding ? 0 : '2rem')};
`;

const DatesSelector = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[100]};
  margin-bottom: 24px;
`;

const AddCombinationContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
`;

const CustomButton = styled(Button)`
  height: 48px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
