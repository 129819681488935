import { Button } from '@/components/Button/ButtonV2';
import { faLink, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import { Command } from 'cmdk';
import React, { useState, useEffect } from 'react';

interface LinkCommandPaletteProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  initialUrl?: string;
  onSubmit: (url: string) => void;
  children: React.ReactNode;
}

export default function LinkMenu({
  children,
  isOpen,
  onOpenChange,
  initialUrl = '',
  onSubmit
}: LinkCommandPaletteProps) {
  const [url, setUrl] = useState(initialUrl);

  useEffect(() => {
    if (isOpen) {
      setUrl(initialUrl);
    }
  }, [isOpen, initialUrl]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(url);
    onOpenChange(false);
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="bg-white rounded-lg shadow-lg border border-gray-200 w-[320px] p-2 pb-0 animate-in fade-in zoom-in-95"
          sideOffset={5}
        >
          <Command className="relative">
            <form onSubmit={handleSubmit}>
              <div className="flex items-center border-b border-gray-200 pb-2">
                <FontAwesomeIcon icon={faLink} className="w-4 h-4 text-gray-500 mr-2" />
                <input
                  value={url}
                  onChange={(e: any) => setUrl(e.target.value)}
                  placeholder="Ingresa la URL"
                  className="flex-1 outline-none bg-transparent text-sm"
                  autoFocus
                />
                <button
                  type="button"
                  onClick={() => onOpenChange(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
                </button>
              </div>
              <div className="flex justify-center">
                <Button variant="link" size="sm" type="submit">
                  Aplicar
                </Button>
              </div>
            </form>
          </Command>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
