import { Icon } from '@/components';
import { theme } from '@/components/App';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ItemInfoI } from '../../DraggableList';

interface DraggableItemPropsI {
  item: ItemInfoI;
  position: number;
  removeItem: (position: number) => void;
  editItem: (position: number) => void;
}

export const DraggableItem: FunctionComponent<DraggableItemPropsI> = ({
  item,
  position,
  removeItem,
  editItem
}) => {
  return (
    <Container>
      <p>{item.title}</p>
      <ContentButtons>
        <ActionButton onClick={() => editItem(position)}>
          <Icon name="edit" color={theme.colors.blue[500] as string} width="14" height="14" />
        </ActionButton>
        <DeleteButton onClick={() => removeItem(position)}>
          <Icon name="trash" color={theme.colors.blue[500] as string} width="14" height="14" />
        </DeleteButton>
        <ActionButton>
          <Icon name="draggable" color={theme.colors.blue[500] as string} width="14" height="14" />
        </ActionButton>
      </ContentButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  border-radius: 4px;
  height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  flex: 1;
  width: 100%;
  input {
    margin-left: 20px;
  }
  p {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
  }
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 0.5rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContentButtons = styled.div`
  display: flex;
`;
