import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const EmptyTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 36px;
  text-align: center;
`;

export const EmptyMessage = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 23px;
  text-align: center;
`;

export const IconButton = styled.button`
  line-height: 0;
`;

export const Image = styled.img`
  background-color: red;
  border-radius: 50%;
  height: 30px;
  margin-right: 16px;
  width: 30px;
  object-position: center;
  object-fit: cover;

  ${({ theme }) => theme.breakpoint('sm')} {
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }
`;

export const ErrorImage = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 35px;
`;

export const ProfileImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const ActivityUser = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.grey[700]};
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 11px;
  }
`;
