import { FunctionComponent, useEffect } from 'react';
import { Spinner } from '@/components';
import { useAuth } from '@/contexts';

export const Logout: FunctionComponent = () => {
  const { logOut } = useAuth();

  useEffect(() => {
    logOut();
  }, []);

  return <Spinner />;
};
