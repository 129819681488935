import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Column } from '@/components';
import { theme } from '@/components/App';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm, SubmitHandler } from 'react-hook-form';
import { DragDropContext, Draggable, DraggableProvided, DragUpdate, Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { LinkInput } from './../../components';
import { DraggableItem } from './components/DraggableItem';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { InputV2 } from '@/components/Input';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

interface DraggableListPropsI {
  items: ItemInfoI[];
  removeItem: (position: number) => void;
  addItem: (newLink: ItemInfoI) => void;
  handlerItems: (links: ItemInfoI[]) => void;
  textAddButton?: string;
  titleModal: string;
  subtitleModal: string;
  placeHolderTitleInput: string;
  placeHolderDescriptionInput: string;
  isRichText?: boolean;
}

export interface ItemInfoI {
  id?: number;
  title: string;
  description: string;
}

const schema = yup.object().shape({
  title: yup.string(),
  url: yup.string(),
  description: yup.string()
});

const reorder = (list: ItemInfoI[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DraggableList: FunctionComponent<DraggableListPropsI> = ({
  items,
  removeItem,
  addItem,
  handlerItems,
  textAddButton = 'Agregar',
  titleModal,
  placeHolderTitleInput,
  placeHolderDescriptionInput,
  isRichText
}) => {
  const [positionItem, setPositionItem] = useState<number | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    handleSubmit: handleSubmitForm,
    formState: { errors },
    control,
    reset
  } = useForm({ resolver: yupResolver(schema) });

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    if (positionItem !== undefined) {
      const itemsList = items.map((item, index) => {
        if (index === positionItem) {
          return { ...item, title: data.title, description: data.description };
        }
        return item;
      });

      handlerItems(itemsList);
    } else {
      const newItem = {
        title: data.title,
        description: data.description
      };
      addItem(newItem);
    }
    setPositionItem(undefined);
    setIsOpen(false);
    reset({
      title: '',
      url: '',
      description: ''
    });
  };

  const editItem = (position: number) => {
    const item = items[position];
    reset({
      title: item.title,
      description: item.description
    });
    setIsOpen(true);
    setPositionItem(position);
  };

  const onDragEnd = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) {
        return;
      }

      const itemsSort = reorder(items, result.source.index, result.destination.index);

      handlerItems(itemsSort);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [items, handlerItems]
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={() => {
          setPositionItem(undefined);
          setIsOpen(false);
        }}
      >
        <DialogContent>
          <DialogHeader className="pb-4 border-b border-[#DACCE0]">
            <DialogClose />
            <DialogTitle>{titleModal}</DialogTitle>
            <button
              onClick={handleSubmitForm(handleSubmit, () => {
                // Handle error
              })}
              className="text-sm font-medium text-[#0072FB]"
            >
              Guardar
            </button>
          </DialogHeader>
          <Column gap={16} className="py-4">
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputV2
                  id={'title-item'}
                  onChange={onChange}
                  error={errors.title?.message?.toString() || ''}
                  placeholder={placeHolderTitleInput}
                  value={value || ''}
                  floatLabel
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {isRichText ? (
                    <RichTextEditor
                      placeholder={placeHolderDescriptionInput}
                      id={'description-item'}
                      onChange={onChange}
                      limit={250}
                      content={value || ''}
                      error={!!errors.title?.message}
                    />
                  ) : (
                    <LinkInput
                      name="description"
                      id={'description-item'}
                      variant="textarea"
                      onChange={onChange}
                      error={errors.title?.message?.toString() || ''}
                      placeholder={placeHolderDescriptionInput}
                      value={value || ''}
                    />
                  )}
                </>
              )}
            />
          </Column>
          <DialogFooter>
            <Button
              fullWidth
              onClick={handleSubmitForm(handleSubmit, () => {
                // Handle error
              })}
            >
              Guardar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={index.toString()}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided: DraggableProvided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          display: 'flex',
                          flex: 1,
                          ...provided.draggableProps.style
                        }}
                      >
                        <DraggableItem
                          key={index}
                          item={item}
                          position={index}
                          removeItem={removeItem}
                          editItem={editItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <p
        className="text-base font-normal leading-9 text-white bg-blue cursor-pointer text-center rounded"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faPlus} width="10" height="10" color={theme.colors.white as string} />{' '}
        {textAddButton}
      </p>
    </>
  );
};
