import React, { useEffect, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollTopPropsI {
  children?: React.ReactNode;
}

export const ScrollToTop: FunctionComponent<ScrollTopPropsI> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};
