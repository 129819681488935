import { omit } from '@/utils';
import classNames from 'classnames';
import React from 'react';

interface Props extends React.ComponentProps<'div'> {
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  as?: 'div' | 'section';
  children: React.ReactNode;
  className?: string;
  flex?: number;
  gap?: number;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  id?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  wrap?: boolean;
}

export const Row = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const Tag: 'div' | 'section' = `${props.as || 'div'}`;

  return (
    <Tag
      {...omit(props, [
        'align',
        'as',
        'children',
        'className',
        'flex',
        'gap',
        'justify',
        'id',
        'style',
        'wrap'
      ])}
      className={classNames(props.className, {
        'flex flex-row': true,
        'flex-wrap': props.wrap,

        'items-start': props.align === 'flex-start',
        'items-end': props.align === 'flex-end',
        'items-center': props.align === 'center',
        'items-baseline': props.align === 'baseline',
        'items-stretch': props.align === 'stretch',

        'justify-start': props.justify === 'flex-start',
        'justify-end': props.justify === 'flex-end',
        'justify-center': props.justify === 'center',
        'justify-between': props.justify === 'space-between',
        'justify-around': props.justify === 'space-around',
        'justify-evenly': props.justify === 'space-evenly'
      })}
      id={props.id}
      key={props.id}
      ref={ref}
      style={{
        flex: props.flex ?? undefined,
        gap: props.gap ? `${props.gap}px` : undefined,
        ...(props.style || {})
      }}
    >
      {props.children}
    </Tag>
  );
});
