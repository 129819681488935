import React, { FunctionComponent } from 'react';

import styled from 'styled-components';
import { ScheduleStatusT, VirtualEventInstancesI } from '@/types/cyclone/models';
import { useState } from 'react';
import { ContactModal } from './ContactModal';
import { BookingModal } from '.';

interface EventActionsProps {
  status: ScheduleStatusT;
  mobile?: boolean;
  onSubmitContactAll: (message: string) => void;
  onSubmitBookUser: (email: string, modality: string) => void;
  eventInstance: VirtualEventInstancesI;
}

const statusName: Record<ScheduleStatusT, string> = {
  bookable: 'Activo',
  paused: 'Pausado',
  canceled: 'Cancelado',
  finished: 'Finalizado',
  full: 'Lleno'
};

export const EventActions: FunctionComponent<EventActionsProps> = ({
  status,
  mobile,
  onSubmitContactAll,
  onSubmitBookUser,
  eventInstance
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showBookingModal, setShowBookingModal] = useState<boolean>(false);

  return (
    <>
      {showModal && <ContactModal setShowModal={setShowModal} onSubmit={onSubmitContactAll} />}
      {showBookingModal && (
        <BookingModal
          setShowModal={setShowBookingModal}
          onSubmit={onSubmitBookUser}
          eventInstance={eventInstance}
        />
      )}
      <Container mobile={mobile}>
        <EventStatus status={status}>
          <p>{statusName[status]}</p>
        </EventStatus>
      </Container>
    </>
  );
};

const Container = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: row;
`;

const EventStatus = styled.p<{ status: ScheduleStatusT }>`
  background-color: ${({ theme, status }) => theme.bookingActions[status + 'Status']};
  color: ${({ theme, status }) => theme.bookingActions[status + 'StatusText']};
  border-radius: 5px;
  height: 28px;
  width: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 15px;
  line-height: 19px;
`;
