import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

type ColumnsCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props {
  as: 'div' | 'section';
  children: React.ReactNode;
  className?: string;
  columns?: ColumnsCount;
  xlColumns?: ColumnsCount;
  lgColumns?: ColumnsCount;
  mdColumns?: ColumnsCount;
  smColumns?: ColumnsCount;
  gap?: number;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const Grid: FunctionComponent<Props> = (props) => {
  const Element = `${props.as}`;

  return (
    // @ts-ignore
    <Element
      className={classNames({
        'grid auto-rows-min auto-cols-min': true,

        'grid-cols-1': props.columns === 1,
        'grid-cols-2': props.columns === 2,
        'grid-cols-3': props.columns === 3,
        'grid-cols-4': props.columns === 4,
        'grid-cols-5': props.columns === 5,
        'grid-cols-6': props.columns === 6,
        'grid-cols-7': props.columns === 7,
        'grid-cols-8': props.columns === 8,
        'grid-cols-9': props.columns === 9,
        'grid-cols-10': props.columns === 10,
        'grid-cols-11': props.columns === 11,
        'grid-cols-12': props.columns === 12,

        'xl:grid-cols-1': props.xlColumns === 1,
        'xl:grid-cols-2': props.xlColumns === 2,
        'xl:grid-cols-3': props.xlColumns === 3,
        'xl:grid-cols-4': props.xlColumns === 4,
        'xl:grid-cols-5': props.xlColumns === 5,
        'xl:grid-cols-6': props.xlColumns === 6,
        'xl:grid-cols-7': props.xlColumns === 7,
        'xl:grid-cols-8': props.xlColumns === 8,
        'xl:grid-cols-9': props.xlColumns === 9,
        'xl:grid-cols-10': props.xlColumns === 10,
        'xl:grid-cols-11': props.xlColumns === 11,
        'xl:grid-cols-12': props.xlColumns === 12,

        'lg:grid-cols-1': props.lgColumns === 1,
        'lg:grid-cols-2': props.lgColumns === 2,
        'lg:grid-cols-3': props.lgColumns === 3,
        'lg:grid-cols-4': props.lgColumns === 4,
        'lg:grid-cols-5': props.lgColumns === 5,
        'lg:grid-cols-6': props.lgColumns === 6,
        'lg:grid-cols-7': props.lgColumns === 7,
        'lg:grid-cols-8': props.lgColumns === 8,
        'lg:grid-cols-9': props.lgColumns === 9,
        'lg:grid-cols-10': props.lgColumns === 10,
        'lg:grid-cols-11': props.lgColumns === 11,
        'lg:grid-cols-12': props.lgColumns === 12,

        'md:grid-cols-1': props.mdColumns === 1,
        'md:grid-cols-2': props.mdColumns === 2,
        'md:grid-cols-3': props.mdColumns === 3,
        'md:grid-cols-4': props.mdColumns === 4,
        'md:grid-cols-5': props.mdColumns === 5,
        'md:grid-cols-6': props.mdColumns === 6,
        'md:grid-cols-7': props.mdColumns === 7,
        'md:grid-cols-8': props.mdColumns === 8,
        'md:grid-cols-9': props.mdColumns === 9,
        'md:grid-cols-10': props.mdColumns === 10,
        'md:grid-cols-11': props.mdColumns === 11,
        'md:grid-cols-12': props.mdColumns === 12,

        'sm:grid-cols-1': props.smColumns === 1,
        'sm:grid-cols-2': props.smColumns === 2,
        'sm:grid-cols-3': props.smColumns === 3,
        'sm:grid-cols-4': props.smColumns === 4,
        'sm:grid-cols-5': props.smColumns === 5,
        'sm:grid-cols-6': props.smColumns === 6,
        'sm:grid-cols-7': props.smColumns === 7,
        'sm:grid-cols-8': props.smColumns === 8,
        'sm:grid-cols-9': props.smColumns === 9,
        'sm:grid-cols-10': props.smColumns === 10,
        'sm:grid-cols-11': props.smColumns === 11,
        'sm:grid-cols-12': props.smColumns === 12,

        ...(props.className
          ? {
              [props.className]: true
            }
          : {})
      })}
      onClick={props.onClick}
      style={{
        gap: props.gap ? `${props.gap}px` : undefined,
        ...(props.style || {})
      }}
    >
      {props.children}
    </Element>
  );
};

Grid.defaultProps = {
  as: 'div'
};
