import { trackGenericEvent } from '@/analytics';
import { Column, ResponsiveContainer, Row } from '@/components';
import { useDownloadable } from '@/containers/ActionDownloadable/context';
import { TabE } from '@/containers/ActionServiceV2/utils';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

const tabs = [
  {
    label: 'Principal',
    value: TabE.MAIN
  },
  {
    label: 'Contenido',
    value: TabE.CONTENT
  },
  {
    label: 'Opciones avanzadas',
    value: TabE.OPTIONS
  }
];

export const Header: FunctionComponent = () => {
  const { activeTab, handleActiveTab } = useDownloadable();
  return (
    <Row className="bg-[#FFF] pt-8 w-full border-b border-[##DADBDD] shadow-md sticky left-0 right-0 top-[48px] z-10">
      <ResponsiveContainer>
        <Column className="w-full" gap={16}>
          <h1 className="text-2xl font-medium">Detalles del descargable</h1>
          <Row gap={25} className="w-full">
            {tabs.map((tab) => (
              <Column
                key={tab.value}
                gap={8}
                className="cursor-pointer"
                onClick={() => {
                  trackGenericEvent('Downloadables Tab Clicked', {
                    name: tab.label
                  });
                  handleActiveTab(tab.value);
                }}
              >
                <p
                  className={classNames({
                    'text-sm font-medium': true,
                    'text-[#0072FB] transition-all duration-300': activeTab === tab.value
                  })}
                >
                  {tab.label}
                </p>
                <div
                  className={classNames({
                    'h-0.5 bg-[#0072FB] transition-all duration-300': activeTab === tab.value
                  })}
                />
              </Column>
            ))}
          </Row>
        </Column>
      </ResponsiveContainer>
    </Row>
  );
};
