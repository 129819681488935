import { Column, Grid } from '@/components';
import { useWindowResize } from '@/hooks';
import React, { Dispatch, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useStoreFront } from '../../context';
import { HeaderTab } from '../EditorSiteBuilder/components/HeaderTab';
import { trackGenericEvent } from '@/analytics';

interface HeaderSiteBuilderPropsI {
  setSelectTab: Dispatch<React.SetStateAction<string>>;
  selectTab: string;
  setSelectTabFrame: React.Dispatch<React.SetStateAction<number>>;
  selectTabFrame: number;
}

export const HeaderSiteBuilder: FunctionComponent<HeaderSiteBuilderPropsI> = ({
  setSelectTab,
  selectTab,
  setSelectTabFrame,
  selectTabFrame
}) => {
  const { handleSubmit, isEnableSave } = useStoreFront();
  const { isMobileSize } = useWindowResize(1024);

  return (
    <Grid
      as="div"
      columns={1}
      className="h-[59px] w-full bg-[white] lg:bg-[#f5f5f5] shadow-md lg:shadow-none lg:border-b lg:border-[#dcdcdc] sticky top-[48px] z-10"
    >
      <Column>
        <Grid as="div" columns={1} className="lg:flex max-w-[1536px] w-full xl:w-[90%] my-0 mx-auto">
          <Column className="w-full lg:border-r-[#dcdcdc] lg:border-r">
            <HeaderTab
              selectTab={selectTab}
              setSelectTab={setSelectTab}
              handleSubmit={handleSubmit}
              isEnableSave={isEnableSave}
            />
          </Column>
          <Column className="lg:w-[500px] xl:w-[550px] h-full lg:min-w-[500px] xl:min-w-[550px]">
            {!isMobileSize && (
              <div className="h-[59px] flex justify-center">
                <div className="flex h-[59px]">
                  <Tab
                    selected={selectTabFrame == 0}
                    onClick={() => {
                      trackGenericEvent('Storefront Tab Clicked', {
                        name: 'Vista previa celular'
                      });
                      setSelectTabFrame(0);
                    }}
                  >
                    <p>Vista previa celular</p>
                  </Tab>
                  <Tab
                    selected={selectTabFrame == 1}
                    onClick={() => {
                      trackGenericEvent('Storefront Tab Clicked', {
                        name: 'Vista previa escritorio'
                      });
                      setSelectTabFrame(1);
                    }}
                  >
                    <p>Vista previa escritorio</p>
                  </Tab>
                </div>
              </div>
            )}
          </Column>
        </Grid>
      </Column>
    </Grid>
  );
};

const Tab = styled.div<{ selected?: boolean }>`
  height: 100%;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  width: 180px;
  ${({ selected, theme }) =>
    selected
      ? `border-bottom: 2px solid ${theme.colors.grey[700]};
`
      : ''}

  p {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey[700]};
    ${({ selected }) => (selected ? `` : `margin-bottom: 2px;`)}
  }
`;
