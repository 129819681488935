import { Column, InputDatepicker, Row } from '@/components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { InputTimePicker } from '@/components/Input';
import React, { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { BlockHoursFormState } from './BlockHoursModal';

type BlockHoursProps = {
  show: boolean;
  onClose: () => void;
  control: Control<BlockHoursFormState>;
  onSubmit: () => void;
  startAt: Date;
  dateChange: boolean;
  timeChange: boolean;
};

export const BlockHours: FunctionComponent<BlockHoursProps> = ({
  show,
  onClose,
  control,
  onSubmit,
  startAt,
  dateChange,
  timeChange
}) => {
  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <div className="grid grid-cols-5 place-items-center w-full">
            <DialogClose className="place-self-start" />
            <DialogTitle className="col-span-3 place-self-center">Bloquear días / horarios</DialogTitle>
            <button
              className="place-self-end self-center text-sm font-medium text-[#0072FB]"
              onClick={onSubmit}
            >
              Guardar
            </button>
          </div>
        </DialogHeader>
        <Column className="sm:min-h-[40vh] min-h-[70vh] pb-4 w-full">
          <span className="mt-3 pb-2 text-left text-sm">
            Indicá el rango durante el cual tus clientes no podrán ingresar reservas:
          </span>
          <div className="text-sm text-[#868686] divider">Desde</div>
          <Row gap={15} align="center" className="pt-2" justify="space-between">
            <Controller
              control={control}
              name="startAt"
              render={({ field: { onChange, value } }) => (
                <div className="w-1/2">
                  <InputDatepicker setDate={onChange} date={value} customInput={true} />
                </div>
              )}
            />
            <Controller
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <div className="w-1/2">
                  <InputTimePicker onTimeChange={onChange} initialValue={value} />
                </div>
              )}
            />
          </Row>
          <div className="text-sm text-[#868686] divider">Hasta</div>
          <Row gap={15} align="center" className="pt-2 pb-2" justify="space-between">
            <Controller
              control={control}
              name="endAt"
              render={({ field: { onChange, value } }) => (
                <div className="w-1/2 relative">
                  {dateChange && (
                    <span className="absolute text-blue font-medium right-9 top-2.5 sm:right-10 sm:top-3 transform z-40 transition-opacity duration-200 ease-in-out animation-fadeInUp">
                      +1
                    </span>
                  )}
                  <InputDatepicker
                    setDate={onChange}
                    datepickerProps={{ minDate: startAt }}
                    date={value}
                    customInput={true}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="endTime"
              render={({ field: { onChange, value } }) => (
                <div className="w-1/2 relative">
                  {timeChange && (
                    <span className="absolute text-blue font-medium right-10 top-2.5 sm:top-3 transform z-40 transition-opacity duration-200 ease-in-out animation-fadeInUp">
                      +30
                    </span>
                  )}
                  <InputTimePicker onTimeChange={onChange} initialValue={value} />
                </div>
              )}
            />
          </Row>
        </Column>
        <DialogFooter>
          <button
            className="rounded-[5px] h-12 bg-[#0072FB] text-white font-medium w-full"
            onClick={onSubmit}
          >
            Guardar
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
