import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import { PositionType } from '../Tooltip/Tooltip';

type CropTextProps = {
  text: string;
  maxLength: number;
  showPointer?: boolean;
  position?: PositionType;
  style?: React.CSSProperties;
};

export const CropText: FunctionComponent<CropTextProps> = ({
  text,
  maxLength,
  showPointer = true,
  position,
  style
}) => {
  const shouldCropText = text.length > maxLength;

  if (shouldCropText) {
    return (
      <Tooltip content={text} position={(position && position) || `childBottom`}>
        <CroppedLocation style={style} showPointer={showPointer}>{`${text.substring(
          0,
          maxLength
        )}...`}</CroppedLocation>
      </Tooltip>
    );
  } else {
    return <span style={style}>{text}</span>;
  }
};

const CroppedLocation = styled.span<{ showPointer: boolean }>`
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'default')};
`;
