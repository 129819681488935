import { DEFAULT_AGORA_IMAGE } from '@/containers/ActionServiceV2/utils';
import { useAuth } from '@/contexts';
import { useClient } from '@/hooks';
import useLog from '@/hooks/useLog';
import { CustomQuestionI, FAQItemI, Image } from '@/types';
import { DigitalContentI, LabelI, WalletTypeE } from '@/types/cyclone/models';
import { ErrorI, GetDigitalContentI, GetLabelsI, PostDigitalContentI } from '@/types/cyclone/requests';
import { blobToBase64, urlImageToFile } from '@/utils';
import React from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
  UseFormWatch,
  useForm
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

type DownloadableContextType = {
  editFormControl: Control<EditDownloadableFormStateI>;
  watch: UseFormWatch<EditDownloadableFormStateI>;
  editFormErrors: DeepMap<FieldValues, FieldError>;
  handleActiveTab: (tab: TabE) => void;
  activeTab: TabE;
  isFetchingDownloadable: boolean;
  isStripeConnected: boolean;
  fullSubmit: () => void;
  downloadableDescription: string;
  setDownloadableDescription: (value: string) => void;
  faqs: FAQItemI[];
  setFaqs: (value: FAQItemI[]) => void;
  imageGallery: Image[];
  setImageGallery: React.Dispatch<React.SetStateAction<Image[]>>;
  coverImage: string;
  setCoverImage: (value: string) => void;
  toggleIsUserPhoneRequired: boolean;
  setToggleIsUserPhoneRequired: (value: boolean) => void;
  toggleFee: boolean;
  setToggleFee: (value: boolean) => void;
  labelOptions: string[];
  handleSelectLabel: (label?: string) => void;
  label: string | undefined;
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const DownloadableContext = React.createContext<DownloadableContextType>({} as DownloadableContextType);

type Props = {
  children: React.ReactNode;
  downloadableId?: string;
};

interface EditDownloadableFormStateI {
  name: string;
  price: number;
  price_usd?: number;
  description?: string;
  content_url: string;
  questions: CustomQuestionI[];
}

enum TabE {
  MAIN = 'main',
  PRICES = 'prices',
  CONTENT = 'content',
  OPTIONS = 'options'
}

const initialErrors = {
  name: false,
  price: false,
  content_url: false
};

export const DownloadableProvider: React.FC<Props> = ({ children, downloadableId }) => {
  const { client } = useClient();
  const { logAndNotify } = useLog();
  const { session } = useAuth();
  const navigate = useNavigate();

  const isEdit = !!downloadableId;

  const [activeTab, setActiveTab] = React.useState(TabE.CONTENT);
  const handleActiveTab = (tab: TabE) => setActiveTab(tab);

  const [coverImage, setCoverImage] = React.useState<string>(DEFAULT_AGORA_IMAGE);
  const [downloadableDescription, setDownloadableDescription] = React.useState<string>('');
  const [faqs, setFaqs] = React.useState<FAQItemI[]>([]);
  const [editFormErrors, setErrors] = React.useState<Record<string, boolean>>(initialErrors);

  const [toggleIsUserPhoneRequired, setToggleIsUserPhoneRequired] = React.useState(true);
  const handleTogglePhoneRequired = (toggle: boolean) => setToggleIsUserPhoneRequired(toggle);

  const [assetLabels, setAssetLabels] = React.useState<LabelI[]>([]);

  const [label, setLabel] = React.useState<string | undefined>();

  const [toggleFee, setToggleFee] = React.useState(false);
  const handleToggleFee = (toggle: boolean) => setToggleFee(toggle);

  React.useEffect(() => {
    if (!coverImage) setCoverImage(DEFAULT_AGORA_IMAGE);
  }, [coverImage]);

  const getItems = (count: number): Image[] =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`
    }));
  const [imageGallery, setImageGallery] = React.useState<Image[]>(getItems(5));
  const handleImages = (images?: string[]) => {
    if (images) {
      const presetImages = imageGallery.map((position, index) => ({
        ...position,
        url: images[index]
      }));

      setImageGallery(presetImages);
    }
  };

  const isStripeConnected =
    session?.vendor?.payment_gateways?.some((pg) => pg === WalletTypeE.STRIPE) || false;

  const {
    control: editFormControl,
    reset: handleEditFormValues,
    handleSubmit,
    watch
  } = useForm<EditDownloadableFormStateI>({
    defaultValues: {
      questions: [{ question: '', isSave: false, is_required: false }]
    }
  });

  const hasErrors = (): boolean => {
    const errors = {
      name: !watch('name'),
      price: watch('price') === 0 ? false : !watch('price'),
      content_url: !watch('content_url')
    };
    setErrors(errors);

    return Object.values(errors).some(Boolean);
  };

  const { isFetching: isFetchingDownloadable } = useQuery(
    ['downloadable', downloadableId],
    async () =>
      await client<GetDigitalContentI>(`me/vendor/digital_contents/${downloadableId}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit,
      onSuccess: (data) => {
        const {
          name,
          price,
          price_usd,
          description,
          content_url,
          images_gallery,
          custom_questions,
          is_transfer_fee_available,
          cover_url,
          is_user_phone_required,
          faqs,
          assets_labels
        } = data;

        handleEditFormValues({
          name: name,
          price: price || 0,
          price_usd: price_usd || 0,
          content_url: content_url,
          questions:
            custom_questions?.map((item) => ({
              id: item.id,
              question: item.question,
              isSave: true,
              is_required: item.is_required
            })) || []
        });
        setCoverImage(cover_url);
        setDownloadableDescription(description);
        handleImages(images_gallery);
        handleTogglePhoneRequired(is_user_phone_required);
        handleToggleFee(is_transfer_fee_available);
        if (faqs) {
          setFaqs(faqs);
        }
        if (assets_labels) {
          const firstLabel = assets_labels[0].label;
          setLabel(firstLabel.title);
        }
      }
    }
  );

  useQuery<GetLabelsI>(
    ['labels'],
    async () =>
      await client<GetLabelsI>(`me/vendor/labels`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: LabelI[]) => {
        setAssetLabels(data);
      }
    }
  );

  const labelOptions = assetLabels.map((label) => label.title);

  const handleSelectLabel = (label?: string) => {
    if (label) {
      setLabel(label);
    } else {
      setLabel(undefined);
    }
  };

  const onSubmit: SubmitHandler<EditDownloadableFormStateI> = async (data) => {
    const { name, price, price_usd, content_url, questions } = data;

    const isBlob = coverImage?.includes('blob');
    const coverUrl = isBlob ? await urlImageToFile(coverImage) : coverImage;

    const imageGalleryBase64 = [];

    for (let i = 0; i < imageGallery.length; i++) {
      const imageBlob = imageGallery[i].blob;

      if (imageBlob) {
        const imageBase64 = await blobToBase64(imageBlob);
        const image = {
          ...imageGallery[i],
          blob: imageBase64
        };
        imageGalleryBase64.push(image);
      } else if (imageGallery[i].url) {
        imageGalleryBase64.push({
          ...imageGallery[i]
        });
      }
    }

    const submitData: PostDigitalContentI = {
      name,
      price: price.toString(),
      price_usd: price_usd?.toString() || '0',
      content_url: content_url,
      cover_url: coverUrl,
      image_gallery: imageGalleryBase64,
      ...(faqs && { faqs: JSON.stringify(faqs) }),
      description: downloadableDescription,
      is_user_phone_required: toggleIsUserPhoneRequired,
      is_transfer_fee_available: toggleFee,
      questions: JSON.stringify(
        questions.map((item: CustomQuestionI, index: number) => ({ ...item, order: index }))
      ),
      label
    };
    mutationEdit.mutate(submitData);
  };

  const fullSubmit = () => {
    if (hasErrors()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast.error('Encontramos un error. Por favor, verificar los datos ingresados');
    } else {
      handleSubmit(onSubmit, (e) => {
        if (e) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          const errors = Object.values(e).map((error) => error.message);
          toast.error(errors.join('\r\n'));
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          toast.error('Error al guardar el servicio');
        }
      })();
    }
  };

  const mutationEdit = useMutation<DigitalContentI, ErrorI, PostDigitalContentI>(
    (downloadable) =>
      client(`digital_contents/${downloadableId}`, 'PUT', {
        isAuthRequired: true,
        isMultipartForm: true,
        data: downloadable
      }),
    {
      onSuccess: () => {
        toast.success('Descargable guardado');
        navigate('/descargables');
      },
      onError: () => {
        logAndNotify('Error al editar un descargable');
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );
  return (
    <DownloadableContext.Provider
      value={{
        editFormControl,
        watch,
        editFormErrors,
        handleActiveTab,
        activeTab,
        isFetchingDownloadable,
        fullSubmit,
        isStripeConnected,
        faqs,
        setFaqs,
        imageGallery,
        setImageGallery,
        downloadableDescription,
        setDownloadableDescription,
        coverImage,
        setCoverImage,
        toggleIsUserPhoneRequired,
        setToggleIsUserPhoneRequired,
        toggleFee,
        setToggleFee,
        labelOptions,
        handleSelectLabel,
        label,
        setLabel
      }}
    >
      {children}
    </DownloadableContext.Provider>
  );
};

export const useDownloadable = (): DownloadableContextType => React.useContext(DownloadableContext);
