import {
  ChangeEventHandler,
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  AgoraLinkTypeE,
  AgoraThemeColorI,
  FeaturedVideoI,
  SizeImageE,
  VendorI
} from '@/types/cyclone/models';
import { REGEX_YOUTUBE_VALID_URL } from './../../../constants/regex';
import { useAuth } from '@/contexts';
import { mediaList } from '../components/EditorSiteBuilder/data';
import { useMutation } from 'react-query';
import { ErrorI, PutStorefrontI, SessionI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import { toast } from 'react-toastify';
import { ItemInfoI } from '../components/EditorSiteBuilder/components/EditTemplateTab/components/DraggableList/DraggableList';
import { getTemplateConfig } from '@/utils/themes';
import { getBase64FromUrl } from '@/utils';

// .
export enum TypeButtonsE {
  SOLID = 'solid',
  OUTLINE = 'outline',
  DARK_SHADOW = 'dark_shadow',
  SOFT_SHADOW = 'soft_shadow'
}

export enum TitleTypeFontE {
  PLAYFAIR = 'font-playfair',
  PLAYFAIR_SC = 'font-playfair-sc',
  ABHAYA_EXTRABOLD = 'font-abhaya-extrabold',
  CINZEL_DECORATIVE = 'font-cinzel-decorative',
  KAPAKANA = 'font-kapakana',
  MERIENDA = 'font-merienda',
  POPPINS = 'font-poppins',
  RALEWAY = 'font-raleway',
  QUATTROCENTO = 'font-quattrocento',
  MERRIWEATHER = 'font-merriweather'
}
export enum SubtitleTypeFontE {
  PLAYFAIR = 'font-playfair',
  POPPINS = 'font-poppins',
  RALEWAY = 'font-raleway',
  QUATTROCENTO = 'font-quattrocento',
  MERRIWEATHER = 'font-merriweather'
}

export enum RoundedE {
  ROUNDED_0 = 0,
  ROUNDED_10 = 10,
  ROUNDED_20 = 20,
  ROUNDED_30 = 30
}

export enum PatternE {
  PATTERN_1 = 'pattern_1',
  PATTERN_2 = 'pattern_2',
  PATTERN_3 = 'pattern_3',
  PATTERN_4 = 'pattern_4',
  PATTERN_5 = 'pattern_5',
  PATTERN_6 = 'pattern_6',
  PATTERN_7 = 'pattern_7',
  PATTERN_8 = 'pattern_8',
  PATTERN_9 = 'pattern_9',
  PATTERN_10 = 'pattern_10',
  PATTERN_11 = 'pattern_11',
  PATTERN_12 = 'pattern_12',
  PATTERN_13 = 'pattern_13',
  PATTERN_14 = 'pattern_14'
}

export enum BackgroundE {
  SOLID = 'solid',
  PATTERN = 'pattern',
  GRADIENT = 'gradient'
}

export interface GradientI {
  from: string;
  to: string;
  type: string;
}

export interface LinkI {
  type: AgoraLinkTypeE;
  title: string;
  url: string;
  description?: string;
  isHidden: boolean;
}
export interface MediaI {
  id: number;
  icon: string;
  name: string;
  color: string;
  original_color: boolean;
  placeholder?: string;
  prefix: string;
  regex: RegExp;
  value?: string;
  isEdit?: boolean;
}
const FEATURE_YOUTUBE = { title: 'video destacado', url: '' };

export interface ThemeButtonI {
  type: TypeButtonsE;
  rounded: RoundedE;
}

export interface StylesThemeI {
  colors: AgoraThemeColorI;
  font_type: SubtitleTypeFontE;
  title_font_type: TitleTypeFontE;
  button: ThemeButtonI;
  pattern_type?: PatternE | null;
}

type StoreFrontContextType = {
  description: string;
  handlerDescription: (description: string) => void;
  name: string;
  handlerName: (name: string) => void;
  checkBancar: boolean;
  handlerCheckBancar: (show: boolean) => void;
  checkVideo: boolean;
  handlerCheckVideo: (show: boolean) => void;
  checkBanner: boolean;
  handlerCheckBanner: (show: boolean) => void;
  linkVideo: string;
  handlerLinkVideo: (linkVideo: string) => void;
  linkBanner: string;
  handlerLinkBanner: (linkVideo: string) => void;
  selectMediaHandler: (media: MediaI) => void;
  changeValueMediaList: ChangeEventHandler<HTMLInputElement>;
  removeLink: (position: number) => void;
  addLink: (newLink: LinkI) => void;
  setShowModalLink: (show: boolean) => void;
  handlerLinks: (links: LinkI[]) => void;
  mediaSelected: MediaI[] | undefined;
  handlerMedias: (medias: MediaI[]) => void;
  currentMediaEditIndex: number | null;
  linksTemplate: LinkI[] | undefined;
  avatarImage: string | undefined;
  setAvatarImage: Dispatch<React.SetStateAction<string | undefined>>;
  handleSubmit: () => Promise<void>;
  phone: string;
  handlerPhone: (phone: string) => void;
  bio: string;
  handlerBio: (bio: string) => void;
  experiences: ItemInfoI[] | undefined;
  handlerExperiences: (experiences: ItemInfoI[]) => void;
  certifications: ItemInfoI[] | undefined;
  handlerCertifications: (certifications: ItemInfoI[]) => void;
  removeCertification: (position: number) => void;
  removeExperience: (position: number) => void;
  addCertification: (certification: ItemInfoI) => void;
  addExperience: (experience: ItemInfoI) => void;
  featuredVideo: FeaturedVideoI | undefined;
  handlerAddFeature: () => void;
  handlerEditLinkFeatured: (link: string) => void;
  handlerEditTitleFeatured: (title: string) => void;
  handlerDeleteFeature: () => void;
  editName: boolean;
  setEditName: Dispatch<React.SetStateAction<boolean>>;
  editDescription: boolean;
  setEditDescription: Dispatch<React.SetStateAction<boolean>>;
  iFrameKey: number;
  saveColors: () => void;
  stylesTheme: StylesThemeI;
  handlerStylesColors: (value: string, nameLabel: string) => void;
  handlerStylesButton: (buttonTheme: ThemeButtonI) => void;
  handlerStylesFont: (fontTheme: SubtitleTypeFontE) => void;
  handlerStylesTitleFont: (fontTheme: TitleTypeFontE) => void;
  handlerEditMedia: (index: number | null) => void;
  editPhone: boolean;
  setEditPhone: Dispatch<React.SetStateAction<boolean>>;
  isEnableSave: boolean;
  removePhone: () => void;
  isShowPhone: boolean;
  handlerAvatarImage: (image?: string) => void;
  bannerImage: string | undefined;
  handlerBannerImage: (image?: string) => void;
  setIsShowPhone: Dispatch<React.SetStateAction<boolean>>;
  handlerSelectTheme: (variant: string) => void;
  handlerPattern: (pattern: PatternE | null) => void;
  showModalLink: boolean;
  modalLinkType?: AgoraLinkTypeE;
  handleModalLink: (type: AgoraLinkTypeE, position: number) => void;
  showAvatarImage: boolean | undefined;
  showBannerImage: boolean | undefined;
  setShowAvatarImage: Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowBannerImage: Dispatch<React.SetStateAction<boolean | undefined>>;
  sizeAvatarImage: SizeImageE | undefined;
  sizeBannerImage: SizeImageE | undefined;
  setSizeAvatarImage: Dispatch<React.SetStateAction<SizeImageE | undefined>>;
  setSizeBannerImage: Dispatch<React.SetStateAction<SizeImageE | undefined>>;
  saveBio: () => void;
};

const StoreFrontContext = createContext<StoreFrontContextType>({} as StoreFrontContextType);

type Props = {
  children: React.ReactNode;
};

export const StoreFrontProvider: FunctionComponent<Props> = ({ children }) => {
  const { session, reloadSession } = useAuth();
  const { client } = useClient();

  const originalValuesStoreFront = useRef<PutStorefrontI | undefined>(undefined);

  const [isEnableSave, setIsEnableSave] = useState<boolean>(false);

  const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined);
  const handlerAvatarImage = (image?: string) => setAvatarImage(image || undefined);
  const [showAvatarImage, setShowAvatarImage] = useState<boolean | undefined>(undefined);
  const [sizeAvatarImage, setSizeAvatarImage] = useState<SizeImageE | undefined>(undefined);

  const [bannerImage, setBannerImage] = useState<string | undefined>(undefined);
  const handlerBannerImage = (image?: string) => setBannerImage(image || undefined);
  const [showBannerImage, setShowBannerImage] = useState<boolean | undefined>(undefined);
  const [sizeBannerImage, setSizeBannerImage] = useState<SizeImageE | undefined>(undefined);

  const [showModalLink, setShowModalLink] = useState<boolean>(false);
  const [modalLinkType, setModalLinkType] = useState<AgoraLinkTypeE | undefined>(undefined);

  const [name, setName] = useState<string>('');
  const handlerName = (name: string) => setName(name);

  const [phone, setPhone] = useState<string>('');
  const handlerPhone = (phone: string) => setPhone(phone);
  const [editPhone, setEditPhone] = useState<boolean>(false);
  const [isShowPhone, setIsShowPhone] = useState<boolean>(false);

  const [bio, setBio] = useState<string>('');
  const handlerBio = (bio: string) => setBio(bio);

  const [description, setDescription] = useState<string>('');
  const handlerDescription = (description: string) => setDescription(description);

  const [linkVideo, setLinkVideo] = useState<string>('');
  const handlerLinkVideo = (linkVideo: string) => setLinkVideo(linkVideo);

  const [linkBanner, setLinkBanner] = useState<string>('');
  const handlerLinkBanner = (linkBanner: string) => setLinkBanner(linkBanner);

  const [checkBancar, setCheckBancar] = useState<boolean>(false);
  const handlerCheckBancar = (show: boolean) => setCheckBancar(show);

  const [checkVideo, setCheckVideo] = useState<boolean>(false);
  const handlerCheckVideo = (show: boolean) => setCheckVideo(show);

  const [checkBanner, setCheckBanner] = useState<boolean>(false);
  const handlerCheckBanner = (show: boolean) => setCheckBanner(show);

  const [mediaSelected, setMediaSelected] = useState<MediaI[] | undefined>(undefined);
  const handlerMedias = (medias: MediaI[]) => setMediaSelected(medias);

  const [linksTemplate, setLinksTemplate] = useState<LinkI[] | undefined>(undefined);
  const handlerLinks = (links: LinkI[]) => setLinksTemplate(links);

  const [experiences, setExperiences] = useState<ItemInfoI[] | undefined>(undefined);
  const handlerExperiences = (experiences: ItemInfoI[]) => setExperiences(experiences);

  const [certifications, setCertifications] = useState<ItemInfoI[] | undefined>(undefined);
  const handlerCertifications = (certifications: ItemInfoI[]) => setCertifications(certifications);

  const [featuredVideo, setFeaturedVideo] = useState<FeaturedVideoI | undefined>(undefined);
  const [iFrameKey, setIFrameKey] = useState<number>(1);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [stylesTheme, setStylesTheme] = useState<StylesThemeI>({
    colors: {
      background: undefined,
      title: undefined,
      button: undefined,
      text: undefined,
      shadow: undefined
    },
    font_type: SubtitleTypeFontE.RALEWAY,
    title_font_type: TitleTypeFontE.RALEWAY,
    button: {
      type: TypeButtonsE.SOLID,
      rounded: RoundedE.ROUNDED_0
    },
    pattern_type: undefined
  });

  const [editName, setEditName] = useState<boolean>(false);

  const [editDescription, setEditDescription] = useState<boolean>(false);

  const handlerAddFeature = () => {
    if (!featuredVideo) {
      setFeaturedVideo(FEATURE_YOUTUBE);
    }
  };

  const handlerDeleteFeature = () => {
    setFeaturedVideo(undefined);
  };

  const handlerEditLinkFeatured = (url: string) => {
    if (featuredVideo) setFeaturedVideo({ ...featuredVideo, url });
  };

  const handlerEditTitleFeatured = (title: string) => {
    if (featuredVideo) setFeaturedVideo({ ...featuredVideo, title });
  };
  const handlerStylesColors = (value: string, nameLabel: string) => {
    // check if value is linear gradient or color, if it's linear, then put it in the background
    const isGradient = value.includes('gradient');

    if (value !== '') {
      let colors;

      if (isGradient) {
        colors = {
          ...stylesTheme.colors,
          background: value
        };
      } else {
        colors = {
          ...stylesTheme.colors,
          [nameLabel]: `#${value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')}`
        };
      }

      setStylesTheme({
        ...stylesTheme,
        colors
      });
    }
  };

  const handlerStylesButton = (buttonTheme: ThemeButtonI) =>
    setStylesTheme({ ...stylesTheme, button: buttonTheme });

  const handlerStylesFont = (fontTheme: SubtitleTypeFontE) =>
    setStylesTheme({ ...stylesTheme, font_type: fontTheme });
  const handlerStylesTitleFont = (fontTheme: TitleTypeFontE) =>
    setStylesTheme({ ...stylesTheme, title_font_type: fontTheme });

  const mutation = useMutation<VendorI, ErrorI, PutStorefrontI>(
    (profile) =>
      client('me/vendor/storefront', 'PUT', {
        isAuthRequired: true,
        data: profile
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          setIFrameKey(iFrameKey + 1);
        }, 2000);
        reloadSession();
      }
    }
  );

  useEffect(() => {
    if (isDataLoaded) {
      setIsEnableSave(true);

      const body = {
        ...(name && { storefront_name: name }),
        storefront: {
          ...(stylesTheme.button.type && { button_type: stylesTheme.button.type }),
          border_radius_px: stylesTheme.button.rounded,
          ...(stylesTheme.colors.background && {
            background_color: stylesTheme.colors.background,
            pattern_type: null
          }),
          ...(stylesTheme.colors.title && { title_color: stylesTheme.colors.title }),
          ...(stylesTheme.colors.button && { button_color: stylesTheme.colors.button }),
          ...(stylesTheme.colors.text && { text_color: stylesTheme.colors.text }),
          ...(stylesTheme.colors.shadow && { shadow_color: stylesTheme.colors.shadow }),
          font_type: stylesTheme.font_type,
          title_font_type: stylesTheme.title_font_type,
          ...(stylesTheme.pattern_type && {
            pattern_type: stylesTheme.pattern_type,
            background_color: '#ffffff'
          })
        }
      };

      mutation.mutate(body);
    }
  }, [stylesTheme]);

  useEffect(() => {
    if (isDataLoaded) {
      const orderExperiences =
        experiences && experiences.map((experiences, index) => ({ ...experiences, order: index }));

      const body = {
        experiences: orderExperiences
      };
      originalValuesStoreFront.current = {
        ...originalValuesStoreFront.current,
        experiences: orderExperiences
      };
      mutation.mutate(body);
    }
  }, [experiences]);

  useEffect(() => {
    if (isDataLoaded) {
      const body = {
        storefront: {
          is_avatar_displayed: showAvatarImage
        }
      };
      mutation.mutate(body);
    }
  }, [showAvatarImage]);

  useEffect(() => {
    if (isDataLoaded) {
      const body = {
        storefront: {
          is_banner_displayed: showBannerImage
        }
      };
      mutation.mutate(body);
    }
  }, [showBannerImage]);

  useEffect(() => {
    if (isDataLoaded) {
      const body = {
        storefront: {
          size_avatar: sizeAvatarImage
        }
      };
      mutation.mutate(body);
    }
  }, [sizeAvatarImage]);

  useEffect(() => {
    if (isDataLoaded) {
      const body = {
        storefront: {
          size_banner: sizeBannerImage
        }
      };
      mutation.mutate(body);
    }
  }, [sizeBannerImage]);

  useEffect(() => {
    if (isDataLoaded) {
      const orderCertifications =
        certifications &&
        certifications.map((certifications, index) => ({
          ...certifications,
          order: index
        }));
      const body = {
        certifications: orderCertifications
      };
      originalValuesStoreFront.current = {
        ...originalValuesStoreFront.current,
        certifications: orderCertifications
      };
      mutation.mutate(body);
    }
  }, [certifications]);

  useEffect(() => {
    if (isDataLoaded) {
      const links =
        linksTemplate &&
        linksTemplate.map((link, index) => {
          const isAgoraLink = link.type !== AgoraLinkTypeE.CUSTOM && link.type !== AgoraLinkTypeE.YOUTUBE;
          return {
            order: index,
            name: link.title,
            is_agora_link: isAgoraLink,
            agora_link_type: link.type,
            url: !isAgoraLink ? link.url : undefined,
            is_hidden: link.isHidden
          };
        });
      const body = {
        ...(links && { links })
      };
      mutation.mutate(body);
    }
  }, [linksTemplate]);

  useEffect(() => {
    if (isDataLoaded) {
      if (!editName)
        mutation.mutate({
          ...(name && { storefront_name: name })
        });
    }
  }, [editName]);

  useEffect(() => {
    if (isDataLoaded) {
      if (!editPhone)
        mutation.mutate({
          phone
        });
    }
  }, [editPhone]);

  useEffect(() => {
    if (isDataLoaded) {
      if (!editDescription)
        mutation.mutate({
          ...(description && { professional_title: description })
        });
    }
  }, [editDescription]);

  useEffect(() => {
    if (isDataLoaded) {
      if (currentMediaEditIndex !== null) {
        return;
      }
      const socialApps =
        mediaSelected &&
        mediaSelected.map((media, index) => {
          return {
            social_app_id: media.id,
            order: index,
            link: `${media.value}`
          };
        });

      mutation.mutate({
        social_apps: socialApps
      });
    }
  }, [mediaSelected]);

  // useEffect(() => {
  //   return () => {
  //     restoreValues();
  //   };
  // }, []);

  useEffect(() => {
    if (isDataLoaded) saveImageBanner();
  }, [bannerImage]);

  useEffect(() => {
    if (isDataLoaded) saveImageProfile();
  }, [avatarImage]);

  useEffect(() => {
    if (isDataLoaded) return;
    if (session?.vendor?.vendor_storefront) {
      setBio(session.vendor.professional_bio);
      setPhone(session?.phone || '');
      if (session?.phone) {
        setIsShowPhone(true);
      }
      handlerName(session.vendor.vendor_storefront.name);
      handlerDescription(session.vendor.professional_title);

      handlerAvatarImage(session?.avatar_url);
      setShowAvatarImage(session.vendor.vendor_storefront.is_avatar_displayed);
      setSizeAvatarImage(session.vendor.vendor_storefront.size_avatar);

      handlerBannerImage(session?.vendor?.vendor_storefront?.cover_url || '');
      setShowBannerImage(session.vendor.vendor_storefront.is_banner_displayed);
      setSizeBannerImage(session.vendor.vendor_storefront.size_banner);
      const colors: AgoraThemeColorI = {
        background: session.vendor.vendor_storefront.background_color,
        title: session.vendor.vendor_storefront.title_color,
        button: session.vendor.vendor_storefront.button_color,
        text: session.vendor.vendor_storefront.text_color,
        shadow: session.vendor.vendor_storefront.shadow_color || '#000000'
      };

      setStylesTheme({
        ...stylesTheme,
        colors,
        font_type: session?.vendor?.vendor_storefront?.font_type || SubtitleTypeFontE.RALEWAY,
        title_font_type: session?.vendor?.vendor_storefront?.title_font_type || TitleTypeFontE.RALEWAY,
        button: {
          type: session?.vendor?.vendor_storefront?.button_type || TypeButtonsE.SOLID,
          rounded: session?.vendor?.vendor_storefront?.border_radius_px || RoundedE.ROUNDED_0
        },
        pattern_type: session?.vendor?.vendor_storefront?.pattern_type
      });

      if (
        session.vendor.vendor_storefront.featured_video_url ||
        session.vendor.vendor_storefront.featured_video_title
      )
        setFeaturedVideo({
          url: session.vendor.vendor_storefront.featured_video_url,
          title: session.vendor.vendor_storefront.featured_video_title
        });
    }

    if (session?.vendor?.vendor_links) {
      const links: LinkI[] = session?.vendor?.vendor_links
        .sort((a, b) => {
          return a.order - b.order;
        })
        .map((link) => {
          return {
            type: link.agora_link_type ? link.agora_link_type : AgoraLinkTypeE.CUSTOM,
            title: link.name,
            url: link.url || '',
            isHidden: link.is_hidden
          };
        });
      handlerLinks(links);
    }

    if (session?.vendor?.vendor_social_apps) {
      const medias: MediaI[] = [];
      session.vendor.vendor_social_apps
        .sort((a, b) => a.order - b.order)
        .forEach((socialApp) => {
          const media = mediaList.find((item) => item.id === socialApp.social_app_id);

          if (media) {
            medias.push({
              ...media,
              id: socialApp.social_app_id,
              value: socialApp.link,
              isEdit: false
            });
          }
        });
      handlerMedias(medias);
    }

    if (session?.vendor?.certifications) {
      const certificationsSort = session?.vendor?.certifications.sort((a, b) => a.order - b.order);
      setCertifications((certificationsSort as ItemInfoI[]) || []);
    }
    if (session?.vendor?.experiences) {
      const experiencesSort = session?.vendor?.experiences.sort((a, b) => a.order - b.order);
      setExperiences((experiencesSort as ItemInfoI[]) || []);
    }

    if (session) loadInitialsValues(session);
    setTimeout(() => setIsDataLoaded(true), 2000);
  }, [session]);

  const saveImageBanner = async () => {
    const coverUrlImageBase64 = bannerImage ? ((await getBase64FromUrl(bannerImage)) as string) : '';
    mutation.mutate({
      cover_url: coverUrlImageBase64
    });
  };

  const saveImageProfile = async () => {
    const avatarImageBase64 = avatarImage ? ((await getBase64FromUrl(avatarImage)) as string) : '';
    mutation.mutate({
      storefront: {
        size_avatar: sizeAvatarImage
      },
      avatar_url: avatarImageBase64
    });
  };

  const loadInitialsValues = async (session: SessionI) => {
    originalValuesStoreFront.current = {
      ...originalValuesStoreFront.current,
      template_name: session!.vendor!.vendor_storefront!.template_name,
      storefront: {
        background_color: session!.vendor!.vendor_storefront!.background_color,
        title_color: session!.vendor!.vendor_storefront!.title_color,
        button_color: session!.vendor!.vendor_storefront!.button_color,
        text_color: session!.vendor!.vendor_storefront!.text_color,
        button_type: session?.vendor?.vendor_storefront?.button_type || TypeButtonsE.SOLID,
        border_radius_px: session?.vendor?.vendor_storefront?.border_radius_px || RoundedE.ROUNDED_0,
        font_type: session?.vendor?.vendor_storefront?.font_type || SubtitleTypeFontE.RALEWAY,
        title_font_type: session?.vendor?.vendor_storefront?.title_font_type || TitleTypeFontE.RALEWAY,
        shadow_color: session!.vendor!.vendor_storefront!.shadow_color || '#000000',
        pattern_type: session?.vendor?.vendor_storefront?.pattern_type
      },
      featured_video_title: session!.vendor!.vendor_storefront!.featured_video_title,
      featured_video_url: session!.vendor!.vendor_storefront!.featured_video_url,
      experiences: session!.vendor!.experiences || [],
      certifications: session!.vendor!.certifications || [],
      professional_bio: session!.vendor!.professional_bio,
      phone: session.phone || ''
    };
  };

  const saveColors = () => {
    const storefront = {
      ...(stylesTheme.colors.background && { background_color: stylesTheme.colors.background }),
      ...(stylesTheme.colors.title && { title_color: stylesTheme.colors.title }),
      ...(stylesTheme.colors.button && { button_color: stylesTheme.colors.button }),
      ...(stylesTheme.colors.text && { text_color: stylesTheme.colors.text })
    };

    mutation.mutate({ storefront });
  };

  const handleSubmit = async () => {
    if (validateYoutube()) {
      toast.error('Encontramos un error. Por favor, verificar los datos ingresados');
      return document?.getElementById('featured-videos')?.scrollIntoView();
    }

    const socialApps =
      mediaSelected &&
      mediaSelected.map((media, index) => {
        return {
          social_app_id: media.id,
          order: index,
          link: `${media.value}`
        };
      });

    const orderExperiences =
      experiences && experiences.map((experiences, index) => ({ ...experiences, order: index }));

    const orderCertifications =
      certifications &&
      certifications.map((certifications, index) => ({
        ...certifications,
        order: index
      }));

    setIsEnableSave(false);

    const data: PutStorefrontI = {
      storefront_name: name,
      professional_title: description,
      social_apps: socialApps,
      experiences: orderExperiences,
      certifications: orderCertifications,
      professional_bio: bio,
      phone,
      featured_video_title: featuredVideo?.title || null,
      featured_video_url: featuredVideo?.url || null,
      storefront: {
        background_color: stylesTheme.colors.background,
        title_color: stylesTheme.colors.title,
        button_color: stylesTheme.colors.button,
        text_color: stylesTheme.colors.text,
        button_type: stylesTheme.button.type,
        border_radius_px: stylesTheme.button.rounded,
        font_type: stylesTheme.font_type,
        title_font_type: stylesTheme.title_font_type,
        shadow_color: stylesTheme.colors.shadow || '#000000',
        pattern_type: stylesTheme.pattern_type
      }
    };

    originalValuesStoreFront.current = data;
  };

  // const restoreValues = () => {
  //   if (originalValuesStoreFront.current) {
  //     mutation.mutate(originalValuesStoreFront.current);
  //   }
  // };

  const validateYoutube = () => {
    if (featuredVideo) {
      setFeaturedVideo({
        ...featuredVideo,
        error: REGEX_YOUTUBE_VALID_URL.test(featuredVideo.url) ? undefined : 'Error: es una url inválida'
      });
      return !REGEX_YOUTUBE_VALID_URL.test(featuredVideo.url);
    }
    return false;
  };

  const selectMediaHandler = (media: MediaI) => {
    if (mediaSelected) {
      const search = mediaSelected.find((item) => item.id === media.id);

      if (search) {
        return setMediaSelected(mediaSelected.filter((item) => item.id !== media.id));
      } else {
        setCurrentMediaEditIndex(media.id);
        setMediaSelected([...mediaSelected, { ...media, value: '', isEdit: true }]);
      }
    }
  };
  const [currentMediaEditIndex, setCurrentMediaEditIndex] = useState<number | null>(null);

  const handlerEditMedia = (index: number | null) => {
    if (mediaSelected) {
      if (currentMediaEditIndex === index) {
        const currentMedia: MediaI | undefined = mediaSelected.find((_m: MediaI) => _m.id === index);

        if (!currentMedia?.value || currentMedia?.value.trim() === '') {
          toast.error('Por favor, verificar los datos ingresados');
          return;
        } else {
          let link = currentMedia?.value;

          link = link.replace(/^(htp:\/\/|htps:\/\/|ttp:\/\/|ttps:\/\/)/, 'http://');
          link = link.replace(/^(www\.)/, '');

          if (!link.match(currentMedia?.regex)) {
            if (currentMedia.name === 'tiktok') {
              if (link.startsWith('@')) {
                link = link.replace(/^@+/, '');
              }
            }
            link = currentMedia?.prefix + link;
          }

          const updatedMedia = mediaSelected.map((media) => {
            if (media.id === index) {
              return { ...media, value: link, isEdit: false };
            }
            return media;
          });

          setCurrentMediaEditIndex(null);
          return setMediaSelected(updatedMedia);
        }
      } else {
        setCurrentMediaEditIndex(index);
      }
    }
  };

  const changeValueMediaList: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (mediaSelected) {
      setMediaSelected(
        mediaSelected.map((media) => {
          if (media.icon === event.target.name) {
            return { ...media, value: event.target.value };
          }
          return media;
        })
      );
    }
  };

  const removePhone = () => {
    setPhone('');
    setIsShowPhone(false);
    mutation.mutate({
      phone: ''
    });
  };
  const removeLink = (position: number) => {
    if (linksTemplate) setLinksTemplate(linksTemplate.filter((link, index) => index !== position));
  };

  const showLink = (position: number) => {
    if (linksTemplate) {
      setLinksTemplate(
        linksTemplate.map((link, index) => {
          if (index === position) return { ...link, isHidden: !link.isHidden };
          return link;
        })
      );
    }
  };

  const handleModalLink = (type: AgoraLinkTypeE, position: number) => {
    if (type === AgoraLinkTypeE.SERVICE) {
      if (!session?.has_services) {
        setShowModalLink(true);
        setModalLinkType(type);
        return;
      } else return showLink(position);
    }
    if (type === AgoraLinkTypeE.EVENT) {
      if (!session?.has_events) {
        setShowModalLink(true);
        setModalLinkType(type);
        return;
      } else return showLink(position);
    }
    if (type === AgoraLinkTypeE.SUBSCRIPTION) {
      if (!session?.has_subscriptions) {
        setShowModalLink(true);
        setModalLinkType(type);
        return;
      } else return showLink(position);
    } else return showLink(position);
  };

  const addLink = (newLink: LinkI) => {
    setLinksTemplate([...(linksTemplate || []), newLink]);
  };

  const removeCertification = (position: number) => {
    if (certifications) setCertifications(certifications.filter((link, index) => index !== position));
  };

  const addCertification = (certification: ItemInfoI) => {
    setCertifications([...(certifications || []), certification]);
  };

  const removeExperience = (position: number) => {
    if (experiences) setExperiences(experiences.filter((link, index) => index !== position));
  };

  const addExperience = (experience: ItemInfoI) => {
    setExperiences([...(experiences || []), experience]);
  };

  const handlerSelectTheme = (variant: string) => {
    const styles = getTemplateConfig(variant);
    setStylesTheme({
      ...stylesTheme,
      colors: {
        background: styles.background_color,
        title: styles.title_color,
        button: styles.button_color,
        text: styles.text_color,
        shadow: styles.shadow_color
      },
      font_type: styles.font_type,
      button: {
        type: styles.button_type,
        rounded: styles.border_radius_px
      },
      pattern_type: styles.pattern_type
    });

    if (session?.vendor?.vendor_storefront?.is_avatar_displayed) {
      setShowAvatarImage(styles.has_avatar);
      setSizeAvatarImage(styles.size_avatar);
    }

    if (session?.vendor?.vendor_storefront?.is_banner_displayed) {
      setShowBannerImage(styles.has_cover);
      setSizeBannerImage(styles.size_banner);
    }
  };

  const handlerPattern = (pattern: PatternE | null) => {
    setStylesTheme({
      ...stylesTheme,
      pattern_type: pattern
    });
  };

  const saveBio = () => {
    mutation.mutate({
      professional_bio: bio
    });
    originalValuesStoreFront.current = {
      ...originalValuesStoreFront.current,
      professional_bio: bio
    };
    toast.success('Cambios guardados exitosamente');
  };

  return (
    <StoreFrontContext.Provider
      value={{
        description,
        handlerDescription,
        name,
        handlerName,
        checkBancar,
        handlerCheckBancar,
        checkVideo,
        handlerCheckVideo,
        checkBanner,
        handlerCheckBanner,
        linkVideo,
        handlerLinkVideo,
        linkBanner,
        handlerLinkBanner,
        selectMediaHandler,
        changeValueMediaList,
        removeLink,
        addLink,
        setShowModalLink,
        handlerLinks,
        mediaSelected,
        linksTemplate,
        handlerMedias,
        currentMediaEditIndex,
        avatarImage,
        setAvatarImage,
        bannerImage,
        handlerBannerImage,
        handlerAvatarImage,
        phone,
        handlerPhone,
        bio,
        handlerBio,
        experiences,
        handlerExperiences,
        certifications,
        handlerCertifications,
        handleSubmit,
        removeCertification,
        addCertification,
        removeExperience,
        addExperience,
        featuredVideo,
        handlerAddFeature,
        handlerEditLinkFeatured,
        handlerEditTitleFeatured,
        handlerDeleteFeature,
        editName,
        setEditName,
        editPhone,
        setEditPhone,
        editDescription,
        setEditDescription,
        iFrameKey,
        saveColors,
        stylesTheme,
        handlerStylesColors,
        handlerStylesButton,
        handlerStylesFont,
        handlerStylesTitleFont,
        handlerEditMedia,
        isEnableSave,
        removePhone,
        isShowPhone,
        setIsShowPhone,
        handlerSelectTheme,
        handlerPattern,
        showModalLink,
        modalLinkType,
        handleModalLink,
        showAvatarImage,
        setShowAvatarImage,
        sizeAvatarImage,
        setSizeAvatarImage,
        showBannerImage,
        setShowBannerImage,
        sizeBannerImage,
        setSizeBannerImage,
        saveBio
      }}
    >
      {children}
    </StoreFrontContext.Provider>
  );
};

export const useStoreFront = (): StoreFrontContextType => useContext(StoreFrontContext);
