import { Row } from '@/components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

type MobileCardProps = {
  items: Array<string | null | number | JSX.Element>;
};

export const UserCard: FunctionComponent<MobileCardProps> = ({ items }) => {
  return (
    <ContainerCard>
      <DivContainer>
        <>{items[0]}</>
        <Row align="center" gap={8}>
          <div> {items[1]}</div>
          <div>¿Pagado?</div> <div>{items[2]}</div>
        </Row>
        <Row align="center" gap={8}>
          <div>¿Asistió?</div> {items[3]}
        </Row>
      </DivContainer>
      <div>{items[4]}</div>
    </ContainerCard>
  );
};

const ContainerCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
`;

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
