import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SubscriptionI } from '@/types/cyclone/models';
import { Card } from '.';
import { clipboardGray } from '@/static/images';
import { useNavigate } from 'react-router-dom';
import { trackGenericEvent } from '@/analytics';

type SubscriptionsProps = {
  subscriptions: SubscriptionI[];
  refetch: () => void;
};

export const Subscriptions: FunctionComponent<SubscriptionsProps> = ({ subscriptions, refetch }) => {
  const navigate = useNavigate();

  if (!subscriptions.length)
    return (
      <EmptyContainer>
        <ImageIcon src={clipboardGray} />
        <EmptyText>No tenés planes mensuales añadidos</EmptyText>
        <Link
          onClick={() => {
            trackGenericEvent('Button Create New Asset Clicked', { type: 'Subscription' }),
              navigate('/planesmensuales/crear');
          }}
        >
          Agregar nuevo
        </Link>
      </EmptyContainer>
    );

  return (
    <Container>
      {subscriptions.map((subscription, i) => (
        <Card subscription={subscription} key={i} refetch={refetch} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
`;

const EmptyContainer = styled.div`
  padding: 70px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.breakpoint('md')} {
    padding: 50px;
  }
`;

const EmptyText = styled.span`
  font-size: 1.125rem;
  padding-top: 35px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue[500]};
  padding-top: 20px;
  font-size: 1.125rem;
  cursor: pointer;
`;

const ImageIcon = styled.img`
  width: 100px;
  height: 110px;
`;
