import {
  REGEX_FACEBOOK_VALID_URL,
  REGEX_INSTAGRAM_VALID_URL,
  REGEX_LINKED_IN_VALID_URL,
  REGEX_MEDIUM_VALID_URL,
  REGEX_PINTEREST_VALID_URL,
  REGEX_REDDIT_VALID_URL,
  REGEX_SNAPCHAT_VALID_URL,
  REGEX_SPOTIFY_VALID_URL,
  REGEX_TIKTOK_VALID_URL,
  REGEX_TWITCH_VALID_URL,
  REGEX_TWITTER_VALID_URL,
  REGEX_YOUTUBE_VALID_URL
} from '@/constants/regex';
import { MediaI } from '../../context/storefront';

export const mediaList: MediaI[] = [
  {
    id: 1,
    icon: 'facebook',
    name: 'facebook',
    color: '#1877F2',
    original_color: false,
    prefix: 'https://facebook.com/',
    regex: REGEX_FACEBOOK_VALID_URL,
    placeholder: 'https://facebook.com/perfil'
  },
  {
    id: 2,
    icon: 'instagram_circle',
    name: 'instagram',
    color: '',
    original_color: true,
    prefix: 'https://instagram.com/',
    regex: REGEX_INSTAGRAM_VALID_URL,
    placeholder: 'https://instagram.com/usuario'
  },
  {
    id: 13,
    icon: 'medium_circle',
    name: 'medium',
    color: '#000000',
    original_color: false,
    prefix: 'https://medium.com/',
    regex: REGEX_MEDIUM_VALID_URL,
    placeholder: 'https://medium.com/@usuario'
  },
  {
    id: 4,
    icon: 'youtube_circle',
    name: 'youtube',
    color: '#FF0000',
    original_color: false,
    prefix: 'https://youtube.com/channel/',
    regex: REGEX_YOUTUBE_VALID_URL,
    placeholder: 'https://youtube.com/channel/canal'
  },
  {
    id: 5,
    icon: 'twitter_circle',
    name: 'twitter',
    color: '#00ACEE',
    original_color: false,
    prefix: 'https://twitter.com/',
    regex: REGEX_TWITTER_VALID_URL,
    placeholder: 'https://twitter.com/usuario'
  },
  {
    id: 6,
    icon: 'linked_in_circle',
    name: 'linkedin',
    color: '#0A66C2',
    original_color: false,
    prefix: 'https://linkedin.com/in/',
    regex: REGEX_LINKED_IN_VALID_URL,
    placeholder: 'https://linkedin.com/in/usuario'
  },
  {
    id: 7,
    icon: 'twitch_circle',
    name: 'twitch',
    color: '#6441A4',
    original_color: false,
    prefix: 'https://twitch.tv/',
    regex: REGEX_TWITCH_VALID_URL,
    placeholder: 'https://twitch.tv/usuario'
  },
  {
    id: 8,
    icon: 'spotify_circle',
    name: 'spotify',
    color: '#1CCC5B',
    original_color: false,
    placeholder: 'https://open.spotify.com/playlist',
    regex: REGEX_SPOTIFY_VALID_URL,
    prefix: 'https://open.spotify.com/'
  },
  {
    id: 9,
    icon: 'tiktok_circle',
    name: 'tiktok',
    color: '',
    original_color: true,
    prefix: 'https://tiktok.com/@',
    regex: REGEX_TIKTOK_VALID_URL,
    placeholder: 'https://tiktok.com/@usuario'
  },
  {
    id: 10,
    icon: 'snapchat_circle',
    name: 'snapchat',
    color: '#FFFC00',
    original_color: false,
    prefix: 'https://snapchat.com/add/',
    regex: REGEX_SNAPCHAT_VALID_URL,
    placeholder: 'https://snapchat.com/add/usuario'
  },
  {
    id: 11,
    icon: 'reddit_circle',
    name: 'reddit',
    color: '#FF5700',
    original_color: false,
    prefix: 'https://reddit.com/r/',
    regex: REGEX_REDDIT_VALID_URL,
    placeholder: 'https://reddit.com/r/usuario'
  },
  {
    id: 12,
    icon: 'pinterest_circle',
    name: 'pinterest',
    color: '#b3081b',
    original_color: false,
    prefix: 'https://pinterest.com/',
    regex: REGEX_PINTEREST_VALID_URL,
    placeholder: 'https://pinterest.com/usuario'
  }
];
