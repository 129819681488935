import React, { useState } from 'react';

interface ImageComponentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  blurSrc?: string;
  dimensions?: ImageDimensions;
}

export enum ImageDimensions {
  LARGE = 1000,
  MEDIUM = 500,
  SMALL = 250
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  dimensions = ImageDimensions.LARGE,
  src,
  blurSrc,
  alt,
  width,
  height,
  loading,
  className,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  const blurURL = `${src}_blur.webp`;
  const srcURL = dimensions ? `${src}_${dimensions}.webp` : `${src}.webp`;

  return (
    <div className={`relative w-full h-full overflow-hidden ${className}`} style={{ width, height }}>
      <img
        src={srcURL}
        alt={alt}
        width={width}
        height={height}
        loading={loading as 'lazy' | 'eager'}
        onLoad={handleLoad}
        className={`w-full h-full object-cover object-center transition-all duration-300 ${
          isLoading ? 'blur-xl' : 'blur-none'
        }`}
        {...rest}
      />
      {isLoading && blurSrc && (
        <img
          src={blurURL}
          alt={alt}
          width={width}
          height={height}
          loading={loading as 'lazy' | 'eager'}
          className="absolute inset-0 w-full h-full object-cover object-center blur-xl"
          {...rest}
        />
      )}
    </div>
  );
};

export default ImageComponent;
