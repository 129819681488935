import React, { FunctionComponent } from 'react';
import { MultiSelectOptionProps } from '@/components/MultiSelect';
import { ScheduleTypeEnum } from '../../types';
import { ScheduleType } from '@/containers/Agenda/types';
import { Schedule } from './Schedule';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

type SelectScheduleProps = {
  scheduleType: ScheduleTypeEnum;
  daysOptions: MultiSelectOptionProps[];
  recurringDays: number[];
  schedules: ScheduleType[];
  handleSchedule: (schedule: ScheduleType) => void;
  handleSaveSchedules: (schedules: ScheduleType[]) => void;
  show: boolean;
  closeModal: () => void;
  handleInitialSchedules: (schedule: ScheduleType[]) => void;
};

export const SelectSchedule: FunctionComponent<SelectScheduleProps> = ({
  scheduleType,
  daysOptions,
  recurringDays,
  schedules,
  handleSchedule,
  handleSaveSchedules,
  closeModal,
  show,
  handleInitialSchedules
}) => {
  const [step, setStep] = React.useState(0);
  return (
    <Dialog open={show} onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <div className="grid grid-cols-4 w-full">
            <button
              className={classNames(
                'flex items-center gap-1 text-sm font-medium text-[#CECECE] hover:text-[#0072FB] transition-colors',
                { invisible: step === 0 }
              )}
              onClick={() => setStep(step - 1)}
            >
              <FontAwesomeIcon icon={faCircleChevronLeft} className="cursor-pointer" size="xl" />
              Atras
            </button>
            <DialogTitle className="col-span-2">Servicios Grupales</DialogTitle>
            <DialogClose className="place-self-end" />
          </div>
        </DialogHeader>
        <DialogDescription className="pb-4 mb-4">
          {step === 0
            ? 'Seleccioná los días en los que sucederán estos eventos semanales. Por ejemplo: todos los lunes, miércoles y viernes.'
            : step === 1
            ? 'Seleccioná los horarios en los que sucederán estos eventos, en los días que previamente seleccionaste. Por ejemplo: todos los lunes, miércoles y viernes a las 9:00 hs. y 11:00 hs.'
            : undefined}
        </DialogDescription>
        {scheduleType === ScheduleTypeEnum.DAY ? (
          <>
            <div className="pb-4 grid grid-cols-7 gap-2">
              {daysOptions.map((day, index) => {
                return (
                  <div
                    key={index}
                    onClick={day.onClick}
                    className={classNames(
                      'w-auto cursor-pointer hover:border-[#0072FB] transition-colors duration-200 aspect-square flex items-center justify-center rounded-full border',
                      {
                        'bg-[#0072FB] text-white border-[#0072FB]': day.isSelected,
                        'bg-white text-[#0072FB] border-[#CECECE]': !day.isSelected
                      }
                    )}
                  >
                    <p className="text-base font-medium">
                      {day.item.label.split(' ')[0].charAt(0).toUpperCase() +
                        day.item.label.split(' ')[0].charAt(1)}
                    </p>
                  </div>
                );
              })}
            </div>
            <DialogFooter>
              <button
                className="rounded-[5px] h-12 bg-[#0072FB] text-white font-medium w-full"
                onClick={closeModal}
              >
                Guardar y configurar horarios
              </button>
            </DialogFooter>
          </>
        ) : (
          <Schedule
            recurringDays={recurringDays}
            schedules={schedules}
            handleSchedule={handleSchedule}
            handleSaveSchedules={handleSaveSchedules}
            closeModal={closeModal}
            handleInitialSchedules={handleInitialSchedules}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
