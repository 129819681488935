import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '..';
import { theme } from '../App';

type ButtonProps = {
  variant?: 'filled' | 'outlined' | 'link' | 'leftlink' | 'facebook' | 'google';
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  fullHeight?: boolean;
  loading?: boolean;
  disabled?: boolean;
  underline?: boolean;
  gradient?: boolean;
  [key: string]: any;
  hoverColor?: string;
  paddingHorizontal?: number;
  children?: React.ReactNode;
  rounded?: boolean;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* Fix any */
export const Button: FunctionComponent<ButtonProps> = ({
  variant,
  color,
  size = 'md',
  fullWidth = false,
  fullHeight = false,
  loading = false,
  disabled = false,
  children,
  underline = false,
  gradient = false,
  hoverColor = theme.colors.blue[700],
  paddingHorizontal = 42,
  rounded = false,
  ...restProps
}) => (
  <Container
    variant={variant}
    color={color}
    fullWidth={fullWidth}
    fullHeight={fullHeight}
    disabled={disabled || loading}
    underline={underline}
    gradient={gradient}
    size={size}
    hoverColor={hoverColor}
    paddingHorizontal={paddingHorizontal}
    rounded={rounded}
    {...restProps}
  >
    {loading ? <Spinner size={23} background={theme.colors.grey[400]} /> : children}
  </Container>
);

const getHeight = (size: string): string => {
  switch (size) {
    case 'sm':
      return '44px';
    case 'lg':
      return '50px';
    default:
      return '48px';
  }
};

const Container = styled.button<ButtonProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: ${theme.font.weight.medium};
  ${({
    variant = 'filled',
    theme,
    color = theme.colors.blue[500],
    fullHeight,
    underline = true,
    gradient,
    size,
    hoverColor = theme.colors.blue[700],
    paddingHorizontal = 42,
    rounded
  }) => {
    if (variant === 'filled')
      return css`
        height: ${fullHeight ? '100%' : getHeight(size || 'md')};
        padding: ${`0 ${paddingHorizontal}px`};
        border-radius: ${rounded ? '999px' : '4px'};
        color: ${color === 'white' ? theme.colors.blue[500] : theme.colors.white};
        //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: background-color 0.2s;
        ${color === 'blue'
          ? gradient
            ? css`
                background: linear-gradient(
                  91.26deg,
                  rgba(0, 114, 255, 0.5) 0%,
                  ${theme.colors.blue[500]} 100%
                );
              `
            : css`
                background-color: ${theme.colors.blue[500]};
              `
          : css`
              background-color: ${color};
            `}
        &:hover:not([disabled]) {
          background-color: ${color === 'red' ? theme.colors.red[300] : hoverColor};
          color: ${theme.colors.white} !important;
        }
      `;
    if (variant === 'facebook')
      return css`
        height: ${fullHeight ? '100%' : '45px'};
        padding: 0 42px;
        border-radius: 4px;
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.blue[600]};
        background-color: ${theme.colors.blue[600]};
        transition: background-color 0.2s;
        &:hover:not([disabled]) {
          background-color: ${color === 'red' ? theme.colors.red[300] : theme.colors.blue[700]};
          border: 1px solid ${color === 'red' ? theme.colors.red[300] : theme.colors.blue[700]};
        }
      `;
    if (variant === 'google')
      return css`
        height: ${fullHeight ? '100%' : '45px'};
        padding: 0 42px;
        border-radius: 4px;
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.black};
        background-color: ${theme.colors.white};
        transition: background-color 0.2s;
      `;
    if (variant === 'outlined')
      return css`
        height: ${fullHeight ? '100%' : getHeight(size || 'md')};
        padding: 0 42px;
        border-radius: 4px;
        color: ${color};
        border: 1px solid ${color};
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        transition: border 0.2s, color 0.2s, background-color 0.2s;
        background: #fff;
        &:hover:enabled {
          border: 1px solid transparent;
          color: ${theme.colors.white};
          background-color: ${color};
        }
      `;
    if (variant === 'link')
      return css`
        color: ${color};
        text-decoration: ${underline && 'underline'};
      `;
    if (variant === 'leftlink')
      return css`
        color: ${color};
        text-decoration: ${underline && 'underline'};
        justify-content: flex-start;
        align-items: flex-start;
        ${({ theme }) => theme.breakpoint('xs')} {
          justify-content: center;
          align-items: center;
        }
      `;
  }}
  &:disabled {
    opacity: 0.5;
  }
  ${({ theme }) => theme.breakpoint('xs')} {
    font-size: 13px;
    padding: 9px 14px;
  }
`;
