import { Column, Row } from '@/components';
import { Button } from '@/components/Button/ButtonV2';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { InputDatepicker, InputTimePicker } from '@/components/Input';
import { AgoraPlanNameE, VendorI } from '@/types/cyclone/models';
import { getGMTOffset } from '@/utils/schedule';
import { faArrowLeft, faClock, faLayerGroup, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { useForm, useFieldArray, Controller, SubmitHandler } from 'react-hook-form';
import { RepeatDate, Repeated } from './Repeated';
import { toast } from 'react-toastify';
import { EmptyObject, ErrorI, RescheduleUniqueEventI } from '@/types/cyclone/requests';
import { useMutation } from 'react-query';
import { useClient } from '@/hooks';
import useLog from '@/hooks/useLog';
import { useAuth } from '@/contexts';

type Props = {
  vendor: VendorI;
  id: number;
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  onClose: () => void;
};

export type FormValues = {
  dates: { date: Date; start: string; end: string }[];
};

export const ModalDuplicate: FunctionComponent<Props> = ({
  vendor,
  id,
  modalOpen,
  setModalOpen,
  onClose
}) => {
  const { client } = useClient();
  const { session } = useAuth();
  const { logAndNotify } = useLog();
  const [isRepeatMode, setRepeatMode] = React.useState(false);
  const [repeatedDates, setRepeatedDates] = React.useState<RepeatDate[]>([]);
  const [disabled, setDisabled] = React.useState(false);

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;

  const eventDate = new Date();
  const eventHour = { start: '12:00', end: '13:00' };

  const { control, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues: {
      dates: [{ date: eventDate, start: eventHour.start, end: eventHour.end }]
    },
    shouldUnregister: false
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates'
  });

  const mutationDuplicate = useMutation<EmptyObject, ErrorI, RescheduleUniqueEventI>(
    (data) =>
      client(`unique_events/${id}/clone`, 'POST', {
        data,
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        toast.success(`${isEventPlan ? 'Evento' : 'Capacitación'} duplicado exitosamente`);
        setModalOpen(false);
        onClose();
      },
      onError: (e) => {
        logAndNotify(`Error al duplicar un evento - ${JSON.stringify(e)}`);
      }
    }
  );

  const formatWithTimezone = (date: any, time: any) => {
    return dayjs.tz(`${dayjs(date).format('YYYY-MM-DD')} ${time}`, vendor.timezone).format();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { dates } = data;

    const mappedDates = dates.map((item) => ({
      start_at: formatWithTimezone(item.date, item.start),
      end_at: formatWithTimezone(item.start > item.end ? dayjs(item.date).add(1, 'day') : item.date, item.end)
    }));

    return mutationDuplicate.mutate({ dates: mappedDates });
  };

  const fullSubmit = () => {
    handleSubmit(onSubmit, (e) => {
      if (e) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const errors = Object.values(e).map((error) => error.message);
        toast.error(errors.join('\r\n'));
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        toast.error(`Error al guardar el ${isEventPlan ? 'evento' : 'capacitación'}`);
      }
    })();
  };

  const onSaveRepeated = () => {
    const existingDates = getValues('dates');

    const newDates = repeatedDates.filter(
      (newDate) =>
        !existingDates.some(
          (existingDate) =>
            existingDate.date === newDate.date &&
            existingDate.start === newDate.start &&
            existingDate.end === newDate.end
        )
    );

    if (newDates.length > 0) {
      append(newDates);
    }

    setRepeatedDates([]);
    setRepeatMode(false);
  };

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={() => {
        setModalOpen(false);
      }}
    >
      <DialogContent>
        <DialogHeader className="mb-4">
          <div className="grid grid-cols-4 w-full">
            <div />
            <DialogTitle className="col-span-2">
              Duplicar {isEventPlan ? 'evento' : 'capacitación'}
            </DialogTitle>
            <DialogClose className="place-self-end" />
          </div>
        </DialogHeader>
        <DialogDescription className="text-[#868686] pb-4 mb-4 border-b">
          Se copiará todo excepto la lista de invitados y los anuncios del{' '}
          {isEventPlan ? 'evento' : 'capacitación'}.
        </DialogDescription>
        <Column justify="space-between" className="min-h-[50vh] sm:max-h-[50vh] overflow-y-auto mb-4">
          {isRepeatMode ? (
            <Repeated
              setDates={(data) => setRepeatedDates(data)}
              dates={getValues}
              setDisabled={(value) => setDisabled(value)}
            />
          ) : (
            <Column className="w-full">
              <span className="text-[#868686] text-sm">
                {fields.length > 1 ? `${fields.length} nuevas fechas` : 'Nueva fecha'}
              </span>
              <Column gap={16} className="py-2">
                {fields.map((item, index) => (
                  <Row align="center" gap={8} justify="space-between" key={item.id}>
                    <div className="w-[30%] flex items-center">
                      <Controller
                        control={control}
                        name={`dates.${index}.date`}
                        render={({ field }) => (
                          <InputDatepicker setDate={field.onChange} date={field.value} customInput={true} />
                        )}
                      />
                    </div>
                    <div className="w-[70%] flex items-center">
                      <span className="mr-2 text-[#868686] text-sm">de</span>
                      <Controller
                        control={control}
                        name={`dates.${index}.start`}
                        render={({ field }) => (
                          <InputTimePicker initialValue={field.value} onTimeChange={field.onChange} />
                        )}
                      />
                      <span className="mx-2 text-[#868686] text-sm">a</span>
                      <Controller
                        control={control}
                        name={`dates.${index}.end`}
                        render={({ field }) => (
                          <InputTimePicker initialValue={field.value} onTimeChange={field.onChange} />
                        )}
                      />
                      <Button
                        className={classNames('ml-2 !rounded-full', { invisible: fields.length === 1 })}
                        variant="muted"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon icon={faXmark} color="#868686" />
                      </Button>
                    </div>
                  </Row>
                ))}
                <Row align="center" gap={16}>
                  <Button
                    variant="muted"
                    size="sm"
                    onClick={() =>
                      append({
                        date: dayjs(eventDate).add(1, 'day').toDate(),
                        start: eventHour.start,
                        end: eventHour.end
                      })
                    }
                    type="button"
                    className="flex items-center gap-1"
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" fixedWidth className="mt-0.5" />
                    <span>Agregar fecha</span>
                  </Button>
                  <Button
                    variant="muted"
                    size="sm"
                    onClick={() => setRepeatMode(true)}
                    type="button"
                    className="flex items-center gap-1"
                  >
                    <FontAwesomeIcon icon={faLayerGroup} size="sm" fixedWidth className="mt-0.5 scale-x-90" />
                    <span>Repetición</span>
                  </Button>
                </Row>
              </Column>
              <Row align="center" gap={4} className="text-[#868686] text-sm border-t border-[#F4F4F4] py-2">
                <FontAwesomeIcon size="sm" fixedWidth icon={faClock} className="mt-0.5" />
                <p>
                  {getGMTOffset(vendor.timezone)} {vendor.country}
                </p>
              </Row>
            </Column>
          )}
        </Column>
        <DialogFooter>
          {isRepeatMode ? (
            <Row gap={16} align="center">
              <Button
                variant="muted"
                onClick={() => setRepeatMode(false)}
                type="button"
                className="flex items-center gap-1 max-w-fit"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" fixedWidth className="mt-0.5 scale-x-90" />
                <span>Volver</span>
              </Button>
              <Button onClick={onSaveRepeated} disabled={disabled}>
                {`Agregar ${
                  getValues('dates').some((date) => date === repeatedDates[0])
                    ? repeatedDates.length - 1
                    : repeatedDates.length
                } fecha${
                  getValues('dates').some((date) => date === repeatedDates[0])
                    ? repeatedDates.length - 1 > 1
                      ? 's'
                      : ''
                    : repeatedDates.length > 1
                    ? 's'
                    : ''
                }`}
              </Button>
            </Row>
          ) : (
            <Button onClick={fullSubmit} type="submit">
              Duplicar {isEventPlan ? 'evento' : 'capacitación'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
