import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon, IconName } from '@/components';
import { theme } from '@/components/App';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IconLabel = {
  icon: IconName | IconDefinition;
  label: string | number;
  color?: string;
  gap?: string;
  iconSize?: string;
  iconColor?: string;
  small?: boolean;
  className?: string;
};

export const IconLabel: FunctionComponent<IconLabel> = ({
  icon,
  label,
  color = theme.colors.grey[800],
  gap = '8px',
  iconSize = typeof icon === 'string' ? '16px' : '1x',
  iconColor = theme.colors.grey[800],
  small = false,
  className
}) => (
  <Container color={color} small={small} className={className}>
    {typeof icon === 'string' ? (
      <Icon name={icon} color={iconColor} marginRight={gap} width={iconSize} height={iconSize} />
    ) : (
      <FontAwesomeIcon
        icon={icon}
        color={iconColor}
        size={iconSize as SizeProp}
        width={iconSize}
        height={iconSize}
        style={{ marginRight: gap }}
      />
    )}
    <span>{label}</span>
  </Container>
);

const Container = styled.div<{ color: string; small: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  font-size: ${({ small }) => (small ? '0.8rem' : '1rem')};
`;
