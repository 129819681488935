import { Button, ModalV2 } from '@/components';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface ModalActivateServiceI {
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  clickButton: () => void;
}

export const ModalActivateService: FunctionComponent<ModalActivateServiceI> = ({
  modalOpen,
  setModalOpen,
  clickButton
}) => {
  return (
    <ModalV2 show={modalOpen} onClose={() => setModalOpen(false)}>
      <ModalContainer>
        <h3>Este plan no incluye venta de servicios o planes mensuales</h3>
        <p>Para publicar tu servicio es plan mensual es necesario mejorar tu plan.</p>
        <p>
          No tiene ningún costo mensual hasta que superes los primeros 30 dias y no te pedimos que ingreses tu
          tarjeta.
        </p>
        <Button onClick={clickButton} fullWidth>
          Mejorar plan
        </Button>
      </ModalContainer>
    </ModalV2>
  );
};

const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin: 0px 0px 31px 24px;
    font-size: 17px;
  }
  p {
    color: ${({ theme }) => theme.colors.grey[700]};
    font-size: 14px;
  }
  button {
    :last-child {
      margin-top: 40px;
    }
  }
`;
