import React, { FunctionComponent } from 'react';
import { Column } from '@/components';
import { Navbar } from '../components';

interface OnBoardingLayoutPropsI {
  children?: React.ReactNode;
}

export const OnboardingLayout: FunctionComponent<OnBoardingLayoutPropsI> = ({ children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <main className="notranslate">
        <Column className="w-[90%] relative mx-auto my-0 max-w-[1536px]">{children}</Column>
      </main>
    </React.Fragment>
  );
};
