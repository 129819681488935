import { deleteCookie } from '@/utils/cookies';
import { useAuth0 } from '@auth0/auth0-react';
import { QueryCache } from 'react-query';
import { useLocalStorage } from './useLocalStorage';
import { toast } from 'react-toastify';

const queryCache = new QueryCache();
const FLASH_URL = import.meta.env.VITE_FLASH_URL;

interface useClientI {
  client: ClientI;
}

interface FetchConfigI {
  method: string;
  body: any;
  headers: any;
}

type EndpointRequest = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface ClientI {
  <T>(
    endpoint: string,
    type?: EndpointRequest,
    options?: { isAuthRequired?: boolean; data?: any; isMultipartForm?: boolean }
  ): Promise<T>;
}

export const useClient = (): useClientI => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const vendorUsernameGodMode = useLocalStorage<string | null>('vendorUsernameGodMode', null);

  const API_URL = import.meta.env.VITE_CYCLONE_API_URL || 'INSERT_CYCLONE_API_URL';

  const logOutUser = () => {
    logout({ returnTo: FLASH_URL });
    deleteCookie('authInfo');
  };

  const getProcessedData = (data: any, isMultipartForm: boolean): FormData | string => {
    if (isMultipartForm) {
      const formData = new FormData();

      for (const name in data) {
        if (data[name] instanceof File) {
          formData.append('files', data[name]);
        } else if (name === 'image_gallery') {
          formData.append(
            name,
            new Blob([JSON.stringify(data[name])], {
              type: 'application/json'
            })
          );
        } else {
          formData.append(name, data[name]);
        }
      }

      return formData;
    }
    return JSON.stringify(data);
  };

  const handleNetworkError = (error: Error): never => {
    // Check if the error is due to no internet connection
    if (!navigator.onLine) {
      toast.error('No hay conexión a internet. Por favor, revisa tu conexión y vuelve a intentarlo.');
      throw error;
    }

    // Handle failed fetch due to network issues
    if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
      toast.error('Error de red. Por favor, revisa tu conexión y vuelve a intentarlo.');
      throw error;
    }

    throw error;
  };

  const client: ClientI = async (endpoint, type = 'GET', { data, isAuthRequired, isMultipartForm } = {}) => {
    let authHeader = {};

    if (isAuthRequired) {
      const token = await getAccessTokenSilently();
      authHeader = { Authorization: `Bearer ${token}` };
    }

    const config: FetchConfigI = {
      method: type,
      body: data ? getProcessedData(data, !!isMultipartForm) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Frontend-Page-URL': window.location.href,
        ...(vendorUsernameGodMode.storedValue && { 'X-God-Mode': vendorUsernameGodMode.storedValue }),
        ...authHeader
      }
    };

    if (isMultipartForm) delete config.headers['Content-Type'];

    try {
      const response = await fetch(`${API_URL}/${endpoint}`, config).catch(handleNetworkError);

      // status which does not break the app
      if (response.status === 401) {
        queryCache.clear();
        logOutUser();
        toast.error('Please re-authenticate.');
        return Promise.reject({ message: 'Please re-authenticate.' });
      }
      if (response.status === 404) {
        toast.error('Resource not found.');
        return Promise.reject({ message: 'Not found.' });
      }
      if (response.status >= 500) {
        return Promise.reject({ message: 'Server unavailable.' });
      }

      const data = await response.json().catch(() => {
        return Promise.reject({ message: 'Invalid response' });
      });

      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    } catch (error: any) {
      // If it wasn't already handled by handleNetworkError
      throw error;
    }
  };

  return { client };
};
