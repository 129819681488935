import { Icon } from '@/components';
import { theme } from '@/components/App';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface BookingItemProps {
  name: string;
  serviceName?: string;
  avatar?: string;
  date?: string;
  time?: string;
  last?: boolean;
}

export const BookingItem: FunctionComponent<BookingItemProps> = ({
  avatar,
  name,
  date,
  serviceName,
  time,
  last
}) => (
  <BookingItemContainer last={last}>
    <AvatarWithName>
      {avatar ? (
        <Avatar src={avatar} alt={`${name} avatar`} />
      ) : (
        <Icon
          name="user_circle"
          width="30px"
          height="30px"
          marginRight="15px"
          color={theme.colors.grey[700]}
        />
      )}
      {name}
    </AvatarWithName>
    <RowOnMd>
      <Column flex={'2'}>{serviceName}</Column>
      <Column>{dayjs(date).format('DD/MM/YYYY')}</Column>
      <Column justifyOnMd={'flex-end'}>{time}</Column>
    </RowOnMd>
  </BookingItemContainer>
);

const RowOnMd = styled.div`
  display: flex;
  flex: 4;
  flex-direction: row;
  ${({ theme }) => theme.breakpoint('md')} {
    padding-top: 16px;
    width: 100%;
  }
`;

const AvatarWithName = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  font-size: 15px;
  line-height: 19px;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Column = styled.p<{ flex?: string; justifyOnMd?: string }>`
  display: flex;
  align-items: center;
  flex: ${({ flex }) => flex || 1};
  font-size: 15px;
  line-height: 19px;
  padding: 0px 10px;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.grey[700]};
    justify-content: ${({ justifyOnMd }) => justifyOnMd || 'flex-start'};
  }
`;

const BookingItemContainer = styled.div<{ last?: boolean }>`
  padding: 18px;
  display: flex;
  ${({ theme, last }) => !last && `border-bottom: 1px solid ${theme.colors.grey[500]}`};
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 24px 22px;
  }
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  object-position: center;
  object-fit: cover;
`;
