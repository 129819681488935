import React, { FunctionComponent } from 'react';
import { DAYS_OF_WEEK } from '@/constants';
import { formatDay } from '@/utils';
import { Row } from '../Row';
import classNames from 'classnames';

type WeekDaysProps = {
  daysIn: number[];
  raw?: boolean;
};

export const WeekDays: FunctionComponent<WeekDaysProps> = ({ daysIn, raw }) => {
  const formattedDaysIn = daysIn.map((day) => formatDay(day));

  if (raw)
    return DAYS_OF_WEEK.map((day, i) => (
      <span
        key={i}
        className={classNames('flex items-center gap-1leading-none font-medium', {
          'text-[#212121]': formattedDaysIn.includes(i),
          'text-[#868686]/70': !formattedDaysIn.includes(i)
        })}
      >
        {day.charAt(0)}
      </span>
    ));

  return (
    <Row
      align="center"
      justify="center"
      className="sm:py-1 sm:h-[28px] gap-1 sm:gap-[6px] sm:px-2 sm:rounded-[3px] text-base sm:text-sm sm:bg-[#CECECE]/50 w-fit"
    >
      {DAYS_OF_WEEK.map((day, i) => (
        <span
          key={i}
          className={classNames('leading-none font-medium', {
            'sm:text-blue text-[#212121]': formattedDaysIn.includes(i),
            'sm:text-[#212121]/50 text-[#868686]/70': !formattedDaysIn.includes(i)
          })}
        >
          {day.charAt(0)}
        </span>
      ))}
    </Row>
  );
};
