import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../Dialog';
import { ImageCropper } from './components/ImageCropperV2';
import { toast } from 'react-toastify';
import { Column } from '../Column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button';
import { theme } from '../App';
import { DEFAULT_AGORA_IMAGE } from '@/containers/ActionServiceV2/utils';
import classNames from 'classnames';
import useLog from '@/hooks/useLog';
import { Spinner } from '../Spinner';

type ImageManagerProps = {
  img?: string;
  handleImg: (img: string) => void;
  children?: React.ReactNode;
  type?: TypeImageInput;
};

const VALID_EXT = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];

const MAX_FILE_SIZE = 10485760; // Max 10 MB

export enum TypeImageInput {
  BANNER = 'banner',
  AVATAR = 'avatar',
  EVENT = 'event',
  SERVICE = 'service',
  DIGITAL_CONTENT = 'digitalContent',
  SUBSCRIPTION = 'subscription'
}

export const ImageManagerV2: FunctionComponent<ImageManagerProps> = ({ children, img, handleImg, type }) => {
  const { logAndNotify } = useLog();
  const [imgFile, setImgFile] = useState<File | undefined>(undefined);
  const [cropper, setCropper] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    try {
      if (file.size > MAX_FILE_SIZE) {
        throw new Error('La imagen no debe pesar más de 10 MB');
      }

      const type = file.name.split('.').pop();
      const isValidFile = type && VALID_EXT.includes(type);

      if (!isValidFile) {
        throw new Error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
      }

      setIsUploading(true);
      setImgFile(file);
      setCropper(true);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Ocurrió un error al procesar el archivo');
      }
    } finally {
      setIsUploading(false);
      // Had to this because you couldn't select the same image twice
      // SO link to solution: https://stackoverflow.com/a/56258902
      e.target.value = '';
      e.stopPropagation();
    }
  };

  const getAspectRadio = () => {
    switch (type) {
      case TypeImageInput.AVATAR:
      case TypeImageInput.DIGITAL_CONTENT:
      case TypeImageInput.EVENT:
      case TypeImageInput.SERVICE:
      case TypeImageInput.SUBSCRIPTION:
        return 1 / 1;

      default:
        return 3 / 1;
    }
  };

  useEffect(() => {
    if (!cropper && imgFile) {
      handleImg(URL.createObjectURL(imgFile));
    }
  }, [cropper]);

  const openCrop = async () => {
    setCropper(true);
    if (img) {
      try {
        const response = await fetch(img);
        if (!response.ok) {
          throw new Error(`Error al obtener la imagen: ${response.statusText}`);
        }

        const blob = await response.blob();
        const file = new File([blob], 'test.jpg', { type: 'image/jpeg' });

        // Utilizar la URL de objeto para la imagen
        setImgFile(file);
      } catch (error) {
        console.error('Error:', error);
        logAndNotify(`Error al procesar la imagen - ${error}`);
        toast.error(`Error al procesar la imagen`);
      }
    }
  };

  return (
    <Dialog open={showModal} onOpenChange={setShowModal}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{!imgFile ? 'Subir imagen' : 'Vista previa'}</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <div className="pt-4 min-h-[400px]">
          {imgFile ? (
            cropper ? (
              <ImageCropper
                type={type}
                image={imgFile}
                prevImage={URL.createObjectURL(imgFile) || img}
                onClose={() => {
                  setCropper(false);
                  setShowModal(false);
                }}
                setImage={(image: File | undefined) => {
                  setImgFile(image);
                }}
                aspectRatio={getAspectRadio()}
              />
            ) : (
              <Column align="center" gap={16} justify="space-between" className="w-full w-hull">
                <div className="w-full h-[60vh] bg-gray-300 flex items-center justify-center">
                  <img
                    src={img}
                    alt="Imagen"
                    className={classNames({
                      'rounded-full aspect-auto object-contain w-[70%]': type === TypeImageInput.AVATAR,
                      'w-full h-full object-contain': type !== TypeImageInput.AVATAR
                    })}
                  />
                </div>
                <DialogFooter className="w-full gap-4">
                  <Button fullWidth size="md" variant="outlined" onClick={openCrop}>
                    Editar
                  </Button>
                  <Button
                    fullWidth
                    hoverColor={theme.colors.red[200]}
                    size="md"
                    onClick={() => {
                      handleImg(DEFAULT_AGORA_IMAGE);
                      setImgFile(undefined);
                    }}
                  >
                    Borrar
                  </Button>
                </DialogFooter>
              </Column>
            )
          ) : (
            <>
              <input className="hidden" id="input_img" type="file" onChange={onChange} />
              <label htmlFor="input_img" className="relative w-full h-[400px] cursor-pointer">
                <Column
                  justify="center"
                  align="center"
                  gap={16}
                  className="w-full h-[400px] border border-dashed border-gray-700"
                >
                  {isUploading ? (
                    <div className="flex w-full h-[40vh] m-auto items-center justify-center">
                      <Spinner size={23} background={theme.colors.grey[400]} />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faFileUpload} size="xl" />
                      Seleccioná la imagen a subir
                    </>
                  )}
                </Column>
              </label>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
