import React, { FunctionComponent } from 'react';
import { theme } from '@/components/App';
import { faCamera, faCropSimple, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

type ImageActionButton = {
  handleDelete?: () => void;
  handleCrop: () => void;
  avatar?: boolean;
};

export const ImageActionButton: FunctionComponent<ImageActionButton> = ({
  handleDelete,
  handleCrop,
  avatar
}) => {
  return (
    <ActionButtonContainer avatar={avatar}>
      <IconButton onClick={handleCrop} active={avatar}>
        <FontAwesomeIcon icon={faCamera} color={theme.colors.white as string} />
      </IconButton>
      <IconButton onClick={handleCrop} active={avatar}>
        <FontAwesomeIcon icon={faCropSimple} color={theme.colors.white as string} />
      </IconButton>
      {handleDelete && (
        <IconButton onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} color={theme.colors.white as string} />
        </IconButton>
      )}
    </ActionButtonContainer>
  );
};

export const IconButton = styled.button<{ active?: boolean }>`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${({ theme, active }) => (active ? theme.colors.blue[500] : theme.colors.grey[700])};
  margin-left: 4px;
  margin-right: 4px;
  :hover {
    background: ${({ theme }) => theme.colors.blue[500]};
  }
`;

const ActionButtonContainer = styled.div<{ avatar?: boolean }>`
  position: absolute;
  ${({ avatar }) =>
    avatar
      ? css`
          top: 0px;
          right: 0px;
        `
      : css`
          top: 15px;
          right: 15px;
        `}
`;
