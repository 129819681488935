const Skeleton = () => {
  return (
    <div className="sm:bg-[#FAFAFA] h-full">
      <div className="container mx-auto">
        <div className="grid grid-cols-6 pt-5 pb-20 gap-12">
          {/* Left Column - Personal Info */}
          <div className="col-span-6 md:col-span-2 bg-white md:border md:border-[#F0F0F0] md:rounded-xl md:px-8 py-4 md:py-9 md:gap-5">
            {/* Header */}
            <div className="flex justify-between items-center w-full mb-4">
              <div className="h-8 bg-gray-200 rounded w-2/3 animate-pulse"></div>
              <div className="h-6 w-6 bg-gray-200 rounded animate-pulse"></div>
            </div>

            {/* Section Title */}
            <div className="h-6 bg-gray-200 rounded w-1/2 mb-6 animate-pulse"></div>

            {/* Info Grid */}
            <div className="grid grid-cols-3 gap-4 w-full">
              {/* Email */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-4 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Phone */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-4 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Birth Date */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-4 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Address */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-4 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Client Since */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-4 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Other Data */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 h-16 bg-gray-200 rounded w-full animate-pulse"></div>

              {/* Professionals */}
              <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
              <div className="col-span-2 flex gap-2">
                <div className="h-6 bg-gray-200 rounded-full w-20 animate-pulse"></div>
                <div className="h-6 bg-gray-200 rounded-full w-20 animate-pulse"></div>
              </div>
            </div>
          </div>

          {/* Right Column - Activity */}
          <div className="col-span-6 md:col-span-4 bg-white md:border md:border-[#F0F0F0] md:rounded-xl md:px-8 md:py-9 md:gap-5">
            <div className="flex justify-between items-center mb-6">
              <div className="h-8 bg-gray-200 rounded w-1/3 animate-pulse"></div>
              <div className="h-10 bg-gray-200 rounded-3xl w-32 animate-pulse"></div>
            </div>

            {/* Timeline Skeleton */}
            <div className="space-y-6">
              {[1, 2, 3].map((item) => (
                <div key={item} className="flex gap-4">
                  <div className="h-12 w-12 bg-gray-200 rounded-full animate-pulse"></div>
                  <div className="flex-1">
                    <div className="h-4 bg-gray-200 rounded w-1/4 mb-2 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
