import { EventApi } from '@fullcalendar/react';
import {
  ScheduleStatusE,
  UniqueEventI,
  VendorBlockedHoursI,
  VirtualEventInstancesI
} from '@/types/cyclone/models';
import { Dayjs } from 'dayjs';
import { LabelValue } from '@/types';

export type SlotStatus = ScheduleStatusE | 'individual' | 'workingHour';

export type DropdownType = {
  x: number;
  y: number;
  event?: EventApi;
  date?: Date;
};

export type EmptyObject = {
  [K in string]: never;
};

export type WorkingHour = {
  startAt: string;
  endAt: string;
};

export type WorkingHourWithDate = {
  date: string;
  startAt: string;
  endAt: string;
};

export type CalendarEvent = {
  id: string;
  start: Date;
  end: Date;
  title: string;
  slots: number;
  participants: number;
  status: SlotStatus | string;
  timeDuration: string;
  className: string;
  extendedProps:
    | VirtualEventInstancesI
    | UniqueEventI
    | EmptyObject
    | VendorBlockedHoursI
    | {
        id: number;
      };
  serviceIds?: number[];
};

export enum ScheduleStatus {
  SELECTED = 'selected',
  TAKEN = 'taken',
  DOUBLE_TAKEN = 'double_taken',
  FREE = 'free',
  OTHER = 'other'
}

export type ScheduleType = {
  label: string;
  value: string;
  date: Dayjs;
  status: ScheduleStatus;
};

export enum CombinationStatus {
  PENDING = 'pending',
  CONFIRMED = 'confirmed'
}

export type CombinationType = {
  selectedDays: LabelValue[] | undefined;
  schedules: ScheduleType[];
  selectedSchedules: ScheduleType[];
  id: number;
  status: CombinationStatus;
};
