import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  withMaxWidth?: boolean;
}

export const ResponsiveContainer: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={classNames({
        'px-4 sm:px-6 lg:px-8 relative': true,
        ...(props.className
          ? {
              [props.className]: true
            }
          : {})
      })}
      style={props.style}
    >
      <div className="m-auto max-w-screen-2xl">{props.children}</div>
    </div>
  );
};
