import React from 'react';

const GOOGLE_MAPS_EMBED_API_KEY = 'AIzaSyB3VlLmWVI9raKV5QoDfqJrxsp8vPr41LY';

type MapProps = {
  height?: string;
  address: string;
  zoom?: number;
};

export const Map: React.FC<MapProps> = ({ height = '650px', address, zoom = 5 }) => {
  const iframeSrc = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_EMBED_API_KEY}&q=${encodeURIComponent(
    address
  )}&zoom=${zoom}`;

  return (
    <div className="relative w-full rounded-xl border" style={{ height: height }}>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 0 }}
        loading="lazy"
        className="rounded-xl border"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={iframeSrc}
      />
    </div>
  );
};
