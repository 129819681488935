import React from 'react';
import { ServiceModalityE } from '@/types/cyclone/models';
import { StepOptionI } from './components';
import { serviceGroup, serviceLocal, serviceOnDemand, serviceVirtual } from '../../utils';

export const stepOne: StepOptionI[] = [
  {
    label: 'Presencial',
    value: `${ServiceModalityE.LOCAL}`,
    image: serviceLocal,
    description: (
      <React.Fragment>
        Asigná una <strong>direccion</strong> o <strong>zona de cobertura</strong> a tu servicio. <br />
        <br />
        Podrás cobrar mediante <strong>Mercado Pago</strong> (por anticipado en Ágora) o en
        <strong> efectivo / transferencia bancaria </strong>
        (a coordinar con cada cliente)
      </React.Fragment>
    ),
    shortDescription: 'Asigná una direccion o zona de cobertura a tu servicio.'
  },
  {
    label: 'Online',
    value: `${ServiceModalityE.VIRTUAL}`,
    image: serviceVirtual,
    description: (
      <React.Fragment>
        Brindá tu servicio mediante:
        <br />
        <br />
        <ul className="list-disc list-inside">
          <div className="ml-4">
            <li className="list-item">
              <strong>Zoom:</strong> cuentas gratuitas permiten videollamadas con un límite de 40’
            </li>
          </div>
          <div className="ml-4">
            <li className="list-item">
              <strong>Google Meet:</strong> límite de 60’ para videollamadas grupales y de 24hs para
              videollamadas 1-1
            </li>
          </div>
        </ul>
        <br />
        Podrás cobrar con <strong>Mercado Pago</strong> (por anticipado en Ágora) o
        <strong> transferencia bancaria </strong>
        (reservas sin pago anticipado)
      </React.Fragment>
    ),
    shortDescription: 'Brindá tu servicio mediante Zoom o Google Meet.'
  }
];

export const stepTwo: StepOptionI[] = [
  {
    label: 'Personalizado',
    value: 'on-demand',
    image: serviceOnDemand,
    description: 'La disponibilidad de este servicio está definida según tus horarios laborales.'
  },
  {
    label: 'Grupal',
    value: 'group',
    image: serviceGroup,
    description: (
      <React.Fragment>
        Cuenta con su propia disponibilidad semanal de días y horarios. <br />
        <br />
        Podrás indicar la cantidad máxima de participantes por encuentro.
      </React.Fragment>
    )
  }
];
