import { Column, Grid } from '@/components';
import { SubtitleTypeFontE, TitleTypeFontE } from '@/containers/StoreFrontBuilder/context/storefront';
import useScreenSize from '@/hooks/useScreenSize';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

const subtitleFontsTheme = [
  { type: SubtitleTypeFontE.PLAYFAIR, name: 'Playfair Display' },
  { type: SubtitleTypeFontE.POPPINS, name: 'Poppins' },
  { type: SubtitleTypeFontE.RALEWAY, name: 'Raleway' },
  { type: SubtitleTypeFontE.QUATTROCENTO, name: 'Quattrocento' },
  { type: SubtitleTypeFontE.MERRIWEATHER, name: 'Merriweather' }
];
const titleFontsTheme = [
  { type: TitleTypeFontE.PLAYFAIR, name: 'Playfair' },
  { type: TitleTypeFontE.PLAYFAIR_SC, name: 'Playfair SC' },
  { type: TitleTypeFontE.ABHAYA_EXTRABOLD, name: 'Abhaya Bold' },
  { type: TitleTypeFontE.CINZEL_DECORATIVE, name: 'Cinzel' },
  { type: TitleTypeFontE.KAPAKANA, name: 'Kapakana' },
  { type: TitleTypeFontE.MERIENDA, name: 'Merienda One' },
  { type: TitleTypeFontE.POPPINS, name: 'Poppins' },
  { type: TitleTypeFontE.RALEWAY, name: 'Raleway' },
  { type: TitleTypeFontE.QUATTROCENTO, name: 'Quattrocento' },
  { type: TitleTypeFontE.MERRIWEATHER, name: 'Merriweather' }
];

interface FontThemePropsI {
  handlerFontTheme: (fontTheme: SubtitleTypeFontE) => void;
  handlerFontThemeTitle: (fontTheme: TitleTypeFontE) => void;
  fontTheme: SubtitleTypeFontE | TitleTypeFontE | undefined;
  fontThemeTitle: SubtitleTypeFontE | TitleTypeFontE | undefined;
}

export const FontTheme: FunctionComponent<FontThemePropsI> = ({
  handlerFontTheme,
  handlerFontThemeTitle,
  fontTheme,
  fontThemeTitle
}) => {
  const { isSm } = useScreenSize();
  return (
    <div className="mb-[30px]">
      <p className="text-[#212121] mb-5 text-base font-medium">Tipografía Título</p>

      <Grid as="div" columns={isSm ? 3 : 2} className="mt-3 mb-7">
        {titleFontsTheme.map((font) => (
          <div key={`font-${font.type}`}>
            <Column
              className={classNames('p-2 border rounded', {
                ' border-blue': fontThemeTitle === font.type,
                'border-transparent': fontThemeTitle !== font.type
              })}
            >
              <button
                className={`border border-solid border-[#212121] h-10 w-full ${font.type}`}
                onClick={() => handlerFontThemeTitle(font.type)}
              >
                {font.name}
              </button>
            </Column>
          </div>
        ))}
      </Grid>

      <p className="text-[#212121] mb-5 text-base font-medium">Tipografía Subtítulo y Botones</p>
      <Grid as="div" columns={isSm ? 3 : 2} className="mt-3 mb-7">
        {subtitleFontsTheme.map((font) => (
          <div key={font.type}>
            <Column
              className={classNames('p-2 border rounded', {
                ' border-blue': fontTheme === font.type,
                'border-transparent': fontTheme !== font.type
              })}
            >
              <button
                className={`border border-solid border-[#212121] h-10 w-full ${font.type}`}
                onClick={() => handlerFontTheme(font.type)}
              >
                {font.name}
              </button>
            </Column>
          </div>
        ))}
      </Grid>
    </div>
  );
};
