import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import googleLogo from '@/static/images/google-logo.svg';
import facebookLogo from '@/static/images/facebook-logo.svg';
import { Row } from '../Row';

type SocialButtonProps = {
  text: string;
  social: 'facebook' | 'google';
  onClick: () => void;
};

export const SocialButton: FunctionComponent<SocialButtonProps> = ({ text, social, onClick }) => {
  if (social === 'facebook') {
    return (
      <Button variant="facebook" onClick={onClick}>
        <Row gap={10}>
          <FacebookLogo src={facebookLogo} />
          <FacebookText>{text}</FacebookText>
        </Row>
      </Button>
    );
  } else {
    return (
      <Button variant="google" onClick={onClick}>
        <Row gap={10}>
          <GoogleLogo src={googleLogo} />
          <GoogleText>Ingresar con Google</GoogleText>
        </Row>
      </Button>
    );
  }
};

const Button = styled.div<{ variant: 'facebook' | 'google' }>`
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 12px 42px;
  border-radius: 20px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  ${({ variant = 'filled', theme, color = 'blue' }) => {
    if (variant === 'facebook')
      return css`
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.blue[600]};
        background-color: ${theme.colors.blue[600]};
        transition: background-color 0.2s;
        &:hover:not([disabled]) {
          background-color: ${color === 'red' ? theme.colors.red[300] : theme.colors.blue[700]};
          border: 1px solid ${color === 'red' ? theme.colors.red[300] : theme.colors.blue[700]};
        }
      `;
    if (variant === 'google')
      return css`
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.grey[800]};
        background-color: ${theme.colors.white};
        transition: background-color 0.2s;
        &:hover:not([disabled]) {
          background-color: ${theme.colors.grey[200]};
          border: 1px solid ${theme.colors.grey[700]};
        }
      `;
  }}
`;

const GoogleLogo = styled.img`
  height: 20px;
  width: 20px;
`;

const FacebookLogo = styled.img`
  height: 20px;
  width: 20px;
`;

const FacebookText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const GoogleText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;
