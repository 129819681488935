import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Button, Input, ModalV2, ModalContent, ModalFooter, ModalHeader, Select } from '@/components';
import { buildSelectOptions } from '@/components/Select';
import { LabelValue } from '@/types';
import { ServiceModalityE, VirtualEventInstancesI } from '@/types/cyclone/models';
import { useMutation } from 'react-query';
import { EmptyObject, ErrorI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';

type BookingModalProps = {
  setShowModal: (show: boolean) => void;
  onSubmit: (email: string, modality: string) => void;
  eventInstance: VirtualEventInstancesI;
};

export const OPTIONS_MODALITIES: LabelValue[] = [
  { label: 'Presencial', value: 'local' },
  { label: 'Online', value: 'virtual' }
];

export const BookingModal: FunctionComponent<BookingModalProps> = ({
  setShowModal,
  onSubmit,
  eventInstance
}) => {
  const { client } = useClient();

  const [email, setEmail] = useState('');
  const [modality, setModality] = useState<LabelValue | undefined>(undefined);
  const [isEmailExist, setIsEmailExist] = useState<boolean>(true);

  const selectOptionsModality = buildSelectOptions(OPTIONS_MODALITIES, modality, setModality);

  const handleSubmit = () => {
    mutationCheckEmail.mutate({ email });
  };

  const mutationCheckEmail = useMutation<
    EmptyObject,
    ErrorI,
    {
      email: string;
    }
  >(
    (data) =>
      client<EmptyObject>(`users/verify`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: (data) => {
        setIsEmailExist(data.exist);

        if (data.exist) {
          const modalitySelected = isModalityOpen ? modality!.value : eventInstance.modality;
          onSubmit(email, modalitySelected);
          setShowModal(false);
        }
      }
    }
  );

  const isModalityOpen = eventInstance.modality === ServiceModalityE.OPEN;

  const shouldSubmitBeDisabled = isModalityOpen
    ? modality === undefined || email.length === 0
    : email.length === 0;

  return (
    <ModalV2
      show
      onClose={() => {
        setShowModal(false);
      }}
    >
      <ModalHeader>Reservar</ModalHeader>
      <ModalContent>
        <HelpText>Crea una reserva para usarios que conozcas y quieras que participen del evento</HelpText>
        <FieldContainer>
          <Input
            inputId="email"
            placeholder="Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
          />
        </FieldContainer>
        {isModalityOpen && (
          <FieldContainer>
            <Select placeholder="Modalidad" value={modality?.label} options={selectOptionsModality} />
          </FieldContainer>
        )}
        {!isEmailExist && (
          <WarningText>
            Este usuario no existe, por favor{' '}
            <Link href="/referidos">invitelo a utilizar la plataforma.</Link>
          </WarningText>
        )}
      </ModalContent>
      <ModalFooter>
        <Button fullWidth onClick={handleSubmit} disabled={shouldSubmitBeDisabled}>
          Agregar reservas
        </Button>
      </ModalFooter>
    </ModalV2>
  );
};

const HelpText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

const WarningText = styled.span`
  display: block;
  font-size: 0.75rem;
  margin-top: 1rem;
`;

const FieldContainer = styled.div`
  padding-top: 15px;
`;

const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.colors.blue[700]};
    text-decoration: underline;
  }
`;
