import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Column, Input, Row, SocialButton } from '@/components';
import { Button } from '@/components/Button';
import { authorize, logIn } from '@/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackGenericEvent } from '@/analytics';
import { isValidEmailDomain } from '@/utils';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Mail inválido')
    .test('is-valid-suffix', 'El correo electrónico tiene un sufijo inválido', function (value) {
      return isValidEmailDomain(value);
    })
    .required('Campo requerido'),
  password: yup.string().min(8, 'Al menos 8 caracteres').required('Campo requerido')
});

type SignInProps = {
  navigateToForgotPassword: () => void;
};

export const SignIn: FunctionComponent<SignInProps> = ({ navigateToForgotPassword }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ resolver: yupResolver(schema), mode: 'all' });
  const [loading, setLoading] = useState(false);

  const errorHandler = (err: auth0.Auth0Error | null) => {
    setLoading(false);
    if (err) {
      toast.error('El email o la contraseña que ingresaste son incorrectos, por favor intentalo nuevamente.');
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    trackGenericEvent('Button Sign In Clicked');
    logIn(data.email, data.password, errorHandler);
    setLoading(true);
  };

  const forgotPasswordTextJSX = (
    <LinkForgotPassword onClick={navigateToForgotPassword}>¿Olvidaste tu contraseña?</LinkForgotPassword>
  );

  return (
    <form>
      <Row
        className="text-xl md:text-2xl pt-12 pb-6 border-b border-gray-300 h-full"
        align="center"
        justify="center"
      >
        Iniciá sesión en Ágora
      </Row>
      <Column className="px-10 lg:px-16 py-6 xl:px-[100px]">
        <SocialButton social="google" text="Ingresar con Google" onClick={() => authorize('google-oauth2')} />
      </Column>
      <Row>
        <div className="flex items-center justify-center w-full">
          <div className="flex-grow border-t border-gray-300" />
          <span className="mx-4 text-[#212121]">O completá tus datos</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
      </Row>
      <Column className="pt-8 md:pt-12 px-10 lg:px-16 xl:px-[100px]">
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...field } }) => (
            <Input
              inputId="email"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const formattedValue = e.target.value.trim().toLowerCase();
                onChange(formattedValue);
              }}
              placeholder="Email"
              error={errors?.email?.message as string}
              {...field}
            />
          )}
        />
        <Spacer spacing="12" />
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Input
              onChange={onChange}
              inputId="password"
              placeholder="Contraseña"
              helpText={forgotPasswordTextJSX}
              variant="password"
              error={errors?.password?.message as string}
            />
          )}
        />
        <Column className="pt-32 md:pt-[80px]">
          <SubmitButton loading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            <PrimaryButtonContent>Ingresar</PrimaryButtonContent>
          </SubmitButton>
          <Row align="center" justify="center" gap={10} className="pt-5">
            <FontAwesomeIcon icon={faUser} color="#868686" />
            <span className="text-[#868686]">
              ¿No tenés cuenta?{' '}
              <Link to="/registro" className="text-[#007aff] cursor-pointer underline">
                Registrate
              </Link>
            </span>
          </Row>
        </Column>
      </Column>
    </form>
  );
};

const Spacer = styled.div<{ spacing: string }>`
  margin-top: ${({ spacing }) => `${spacing}px`};
`;

const PrimaryButtonContent = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.white};
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const LinkForgotPassword = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-size: 14px;
`;
