import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Input,
  Row,
  Column,
  SelectV2,
  SelectProps,
  InputDatepicker,
  ResponsiveContainer,
  Button
} from '@/components';
import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import { ErrorI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import {
  AgoraPlanNameE,
  CouponApplyToE,
  CouponI,
  SupportedCountriesE,
  VendorI
} from '@/types/cyclone/models';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import { useAuth } from '@/contexts';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import useScreenSize from '@/hooks/useScreenSize';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { Switch } from '@headlessui/react';
import { InputTimePicker } from '@/components/Input';
import classNames from 'classnames';
import { getCurrencySymbolFromCountry } from '@/utils/money';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { trackGenericEvent } from '@/analytics';

const discountOptions: SelectProps[] = [
  {
    label: 'Monto fijo',
    value: 'amount'
  },
  {
    label: 'Porcentaje',
    value: 'percentage'
  }
];

interface CreateCouponI {
  apply_to?: string;
  name: string;
  code: string;
  amount_discount?: number;
  amount_usd_discount?: number;
  discount?: number;
  end_at?: Date;
  subscription_id?: number;
  unique_event_ticket_id?: number;
  digital_content_id?: number;
  service_id?: number;
  quantity?: number;
}

export const Coupon: FunctionComponent = () => {
  const { id } = useParams();
  useMixpanelTrackPage(id ? 'Edit Discount Code' : 'Create Discount Code');

  const navigate = useNavigate();
  const { client } = useClient();
  const { isMobile } = useScreenSize();
  const { session } = useAuth();

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;
  const currency = getCurrencySymbolFromCountry(country);

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;

  const [discountType, setDiscountType] = useState<SelectProps | null>(discountOptions[0]);
  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [discountAmount, setDiscountAmount] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [amountUSD, setAmountUSD] = useState<string>('');
  const [applyTo, setApplyTo] = useState<SelectProps>({
    label: 'Todo tu negocio',
    value: CouponApplyToE.ALL
  });
  const [endDate, setEndDate] = useState<Date>();
  const [endTime, setEndTime] = useState<string>();
  const [totalQuantity, setTotalQuantity] = useState<string>('');

  const [endDateEnabled, setEndDateEnabled] = useState<boolean>(false);
  const [quantityEnabled, setQuantityEnabled] = useState<boolean>(false);

  // const haveUSDEnabled = session?.vendor?.payment_gateways?.includes(WalletTypeE.STRIPE);
  const haveUSDEnabled = false;

  const isEdit = !!id;

  const { data: coupons } = useQuery(
    ['coupons'],
    async () =>
      await client<CouponI[]>(`me/vendor/coupons`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const { data: coupon } = useQuery(
    ['coupon', id],
    async () =>
      await client<CouponI>(`me/vendor/coupons/${id}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
      onSuccess: (data) => {
        setName(data.name);
        setCode(data.code);
        setDiscountType(
          discountOptions.find((option) =>
            data.discount ? option.value === 'percentage' : option.value === 'amount'
          )!
        );
        setDiscountAmount(data.discount ? (data.discount * 100).toString() : '');
        setAmount(data.amount_discount ? data.amount_discount.toString() : '');
        setAmountUSD(data.amount_usd_discount ? data.amount_usd_discount.toString() : '');

        if (data.apply_to) {
          setApplyTo(applyToOptions.find((option) => option.value === data.apply_to)!);
        } else {
          if (data.service_id) {
            setApplyTo(applyToOptions.find((option) => option.value === `service-${data.service_id}`)!);
          }
          if (data.subscription_id) {
            setApplyTo(
              applyToOptions.find((option) => option.value === `subscription-${data.subscription_id}`)!
            );
          }
          if (data.digital_content_id) {
            setApplyTo(
              applyToOptions.find((option) => option.value === `digitalContent-${data.digital_content_id}`)!
            );
          }
          if (data.unique_event_ticket_id) {
            setApplyTo(
              applyToOptions.find(
                (option) => option.value === `uniqueEventTicket-${data.unique_event_ticket_id}`
              )!
            );
          }
        }

        if (data.end_at) {
          setEndDateEnabled(true);
          setEndDate(dayjs(data.end_at).toDate());
          setEndTime(dayjs(data.end_at).format('HH:mm'));
        }

        if (data.quantity) {
          setQuantityEnabled(true);
          setTotalQuantity(data.quantity.toString());
        }
      }
    }
  );

  const { data: vendor } = useQuery(
    ['vendor', 'assets'],
    async () =>
      await client<VendorI>(`me/vendor/assets`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const activeServices = vendor?.services?.filter((service) => service.status === 'active') || [];
  const activeDigitalContents = vendor?.digital_contents?.filter((dc) => dc.status === 'active') || [];
  const activeSubscriptions = vendor?.subscriptions?.filter((sub) => sub.status === 'active') || [];
  const activeUniqueEvents = vendor?.unique_events?.filter((ue) => ue.status === 'active') || [];

  // Create an array of objects with ticket.name, ue.name, and ticket.id
  const mappedEvents = activeUniqueEvents.flatMap((ue) => {
    return ue.unique_events_tickets.map((ticket) => ({
      ticket_name: ticket.name,
      event_name: ue.name,
      ticket_id: ticket.id
    }));
  });

  const assetsOptions = [
    ...activeServices.map((service) => ({
      label: service.name,
      value: `service-${service.id}`
    })),
    ...activeDigitalContents.map((dc) => ({
      label: dc.name,
      value: `digitalContent-${dc.id}`
    })),
    ...activeSubscriptions.map((sub) => ({
      label: sub.name,
      value: `subscription-${sub.id}`
    })),
    ...mappedEvents.map((event) => ({
      label: `${event.event_name} - ${event.ticket_name}`,
      value: `uniqueEventTicket-${event.ticket_id}`
    }))
  ];

  const applyToOptions: SelectProps[] = [
    {
      label: 'Todo tu negocio',
      value: CouponApplyToE.ALL
    },
    {
      label: 'Todos tus servicios',
      value: CouponApplyToE.SERVICES
    },
    {
      label: 'Todos tus descargables',
      value: CouponApplyToE.DIGITAL_CONTENTS
    },
    {
      label: 'Todos tus planes mensuales',
      value: CouponApplyToE.SUBSCRIPTIONS
    },
    {
      label: isEventPlan ? 'Todos tus eventos únicos' : 'Todas tus capacitaciones',
      value: CouponApplyToE.UNIQUE_EVENTS
    },
    ...assetsOptions.map((asset) => ({
      label: asset.label,
      value: asset.value
    }))
  ];

  const mutationCreate = useMutation<any, ErrorI, CreateCouponI>(
    (data) =>
      client(`coupons`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Código de descuento creado exitosamente.');
        navigate(`/descuentos`);
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const mutationEdit = useMutation<any, ErrorI, CreateCouponI>(
    (data) =>
      client(`coupons/${id}`, 'PUT', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Código de descuento editado exitosamente.');
        navigate(`/descuentos`);
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const handleSave = () => {
    // validations
    const errors = [];
    if (!name || name.length === 0) errors.push('El nombre es requerido');
    if (!isEdit && coupons?.map((coupon) => coupon.name).includes(name))
      errors.push('Ya tenes un cupon con ese nombre');
    if (name && name.length > 20) errors.push('El nombre no puede tener más de 20 caracteres');
    if (!code || code.length === 0) errors.push('El código es requerido');
    if (!isEdit && coupons?.map((coupon) => coupon.code).includes(code))
      errors.push('Ya tenes un cupon con ese código');
    if (code && code.length !== 10) errors.push('El código debe tener 10 caracteres');

    // only numbers or letters on code
    const codeRegex = /^[a-zA-Z0-9]+$/;
    if (!codeRegex.test(code)) errors.push('El código sólo puede tener letras y/o números');

    if (discountType?.value === 'amount') {
      // check if at least exist amount or amountUSD
      if (!amount && !amountUSD) errors.push('El monto es requerido');
    }
    if (discountType?.value === 'percentage') {
      if (!discountAmount || discountAmount.length === 0) errors.push('El porcentaje es requerido');
    }
    if (!applyTo || applyTo.value.length === 0) errors.push('El tipo de descuento es requerido');
    if (endDateEnabled && !endDate) errors.push('La fecha de vencimiento es requerida');
    if (endDateEnabled && !endTime) errors.push('La hora de vencimiento es requerida');
    if (quantityEnabled && (!totalQuantity || totalQuantity.length === 0))
      errors.push('La cantidad es requerida');

    if (errors.length > 0) {
      if (errors.length === 1) return toast.error(errors[0]);
      else {
        // format errors
        const formatErrors = errors.join('\n');
        return toast.error(formatErrors);
      }
    }

    const endAt =
      endDate && endTime
        ? dayjs(endDate)
            .set('hour', +endTime.split(':')[0])
            .set('minute', +endTime.split(':')[1])
            .toDate()
        : undefined;

    const applyToValue = applyTo?.value as string;

    const data: CreateCouponI = {
      name,
      code,
      apply_to: applyToValue,
      end_at: endAt,
      amount_discount: discountType?.value === 'amount' ? parseInt(amount) : undefined,
      amount_usd_discount: discountType?.value === 'amount' ? parseInt(amountUSD) : undefined,
      discount: discountType?.value === 'percentage' ? parseInt(discountAmount) / 100 : undefined,
      quantity: quantityEnabled ? parseInt(totalQuantity) : undefined,
      unique_event_ticket_id: applyTo?.value.includes('uniqueEventTicket')
        ? parseInt(applyToValue.split('-')[1])
        : undefined,
      digital_content_id: applyTo?.value.includes('digitalContent')
        ? parseInt(applyToValue.split('-')[1])
        : undefined,
      service_id: applyTo?.value.includes('service') ? parseInt(applyToValue.split('-')[1]) : undefined,
      subscription_id: applyTo?.value.includes('subscription')
        ? parseInt(applyToValue.split('-')[1])
        : undefined
    };

    if (isEdit) mutationEdit.mutate(data);
    else mutationCreate.mutate(data);
  };

  const remainingQuantity = coupon && coupon.total_quantity ? coupon.total_quantity - coupon.quantity : 0;

  return (
    <div className="my-2">
      <ResponsiveContainer>
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <Row align="baseline" gap={10}>
              <Link to={'/descuentos'} className="text-xl sm:text-3xl">
                Códigos de descuento
              </Link>
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
              <p className="text-xl sm:text-3xl">
                {isEdit ? 'Editar código' : `Crear ${!isMobile ? 'un código' : ''}`}
              </p>
            </Row>
            <p className="text-sm text-[#828282]">
              Creá o gestioná tus códigos de descuento.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/8554422-codigos-de-descuento"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
          <Row align="center" justify="center" gap={10}>
            <Button onClick={handleSave} rounded size="sm">
              Guardar
            </Button>
          </Row>
        </Row>
        <Row justify="center" className="w-full">
          <Column
            className="py-10 bg-[#FBFBFB] w-full max-w-full md:max-w-[80%] px-2 md:px-6"
            style={{
              boxShadow: '0px 0px 10px 0px rgba(33, 33, 33, 0.10)'
            }}
          >
            <Column gap={4} className="border-l-2 border-[#212121] pl-6">
              <p className="md:text-lg font-medium">Nombre de referencia</p>
              <p className="text-sm md:text-base">Sólo para identificar este código fácilmente.</p>
              <div className="pt-4 w-full sm:w-[600px]">
                <Input
                  inputId="name"
                  placeholder="Nombre de referencia"
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  maxLength={20}
                  showCount
                />
              </div>
            </Column>
            <Column gap={4} className="border-l-2 border-[#212121] pl-6 mt-10">
              <p className="md:text-lg font-medium">Código</p>
              <p className="text-sm md:text-base">
                Tus clientes ingresarán este código antes de pagar. Sólo letras y/o números.
              </p>
              <div className="pt-4 w-full sm:w-[600px]">
                <Input
                  inputId="code"
                  placeholder="Código de 10 caracteres"
                  value={code}
                  maxLength={10}
                  showCount
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // use capital letters
                    const value = e.target.value.toUpperCase();
                    setCode(value);
                  }}
                />
              </div>
            </Column>
            <Column gap={4} className="border-l-2 border-[#212121] pl-6 mt-10">
              <p className="md:text-lg font-medium">Descuento</p>
              <p className="text-sm md:text-base">
                Indicá si aplicará un % sobre el total o un monto fijo de descuento.
              </p>
              <div className="flex flex-col md:flex-row gap-3 pt-4 w-full sm:w-[600px]">
                <div className="w-full sm:w-1/2">
                  <SelectV2
                    selected={discountType}
                    setSelected={(value) => setDiscountType(value)}
                    options={discountOptions}
                  />
                </div>
                {discountType?.value === 'amount' && (
                  <Row className="w-full sm:w-1/2 gap-2">
                    <div className={classNames({ 'w-1/2': !haveUSDEnabled, 'w-full': haveUSDEnabled })}>
                      <Input
                        inputId="amount"
                        value={amount}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                        postFix={currency}
                        type="number"
                      />
                    </div>
                    {!haveUSDEnabled && (
                      <div className="w-1/2">
                        <Input
                          inputId="amountUSD"
                          value={amountUSD}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setAmountUSD(e.target.value)}
                          postFix={'US$'}
                          type="number"
                        />
                      </div>
                    )}
                  </Row>
                )}
                {discountType?.value === 'percentage' && (
                  <>
                    <div className="w-1/2">
                      <Input
                        inputId="discountAmount"
                        value={discountAmount}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setDiscountAmount(e.target.value)}
                        postFix={'%'}
                        type="number"
                      />
                    </div>
                  </>
                )}
              </div>
            </Column>
            <Column gap={4} className="border-l-2 border-[#212121] pl-6 mt-10">
              <p className="md:text-lg font-medium">Aplicable a</p>
              <Row className="w-full pt-4" gap={12}>
                <div className="w-full sm:w-[600px]">
                  <Select
                    defaultValue={(applyTo?.value as string) || ''}
                    onValueChange={(value) => {
                      const selectedOption = applyToOptions.find((option) => option.value === value);
                      if (selectedOption) {
                        setApplyTo(selectedOption);
                      }
                    }}
                  >
                    <SelectTrigger className="w-full rounded bg-white !h-12 px-4 text-left shadow-sm focus:outline-none sm:text-sm border border-solid border-[#0000005c]">
                      <SelectValue placeholder="Seleccioná una opción">{applyTo?.label}</SelectValue>
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] h-full">
                      {applyToOptions.map((option, i) => (
                        <SelectItem key={i} value={option.value as string} onClick={() => setApplyTo(option)}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </Row>
            </Column>
            <Column gap={4} className="border-l-2 border-[#212121] pl-6 mt-10">
              <Row align="center" gap={30}>
                <p className="md:text-lg font-medium">Vencimiento</p>
                <Switch
                  checked={endDateEnabled}
                  onChange={setEndDateEnabled}
                  className={`${
                    endDateEnabled ? 'bg-[#7FB8FF]' : 'bg-[#CACACA]'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      endDateEnabled ? 'translate-x-6 bg-[#0072FB]' : 'translate-x-1 bg-white'
                    } inline-block h-4 w-4 transform rounded-full transition`}
                  />
                </Switch>
              </Row>
              {endDateEnabled && (
                <Row className="w-full sm:w-[600px] pt-4" gap={10} align="center">
                  <span className="w-[15%]">Hasta</span>
                  <div className="w-[50%]">
                    <InputDatepicker setDate={(date) => setEndDate(date)} date={endDate} customInput={true} />
                  </div>
                  <div className="w-[35%]">
                    <InputTimePicker initialValue={endTime} onTimeChange={setEndTime} />
                  </div>
                </Row>
              )}
            </Column>
            <Column gap={4} className="border-l-2 border-[#212121] pl-6 mt-10">
              <Row align="center" gap={30}>
                <p className="md:text-lg font-medium">Limitar cantidad máxima de usos</p>
                <Switch
                  checked={quantityEnabled}
                  onChange={setQuantityEnabled}
                  className={`${
                    quantityEnabled ? 'bg-[#7FB8FF]' : 'bg-[#CACACA]'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      quantityEnabled ? 'translate-x-6 bg-[#0072FB]' : 'translate-x-1 bg-white'
                    } inline-block h-4 w-4 transform rounded-full transition`}
                  />
                </Switch>
              </Row>
              {quantityEnabled && (
                <Row className="w-full pt-4" gap={10} align="center">
                  Hasta
                  <div className="w-24">
                    <Input
                      inputId="totalQuantity"
                      value={totalQuantity}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setTotalQuantity(e.target.value)}
                      type="number"
                    />
                  </div>
                  {isEdit && (
                    <div>
                      <span>
                        (Se usó <span className="text-blue">{remainingQuantity}</span> veces)
                      </span>
                    </div>
                  )}
                </Row>
              )}
            </Column>
          </Column>
        </Row>
      </ResponsiveContainer>
    </div>
  );
};
