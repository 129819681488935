import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, ModalContent, Icon, Checkbox, ModalFooter, ModalHeader, Tooltip } from '@/components';
import { PutCrowdfundingPlanI } from '@/types/cyclone/requests';
import { CrowdfundingPlanI } from '@/types/cyclone/models';
import { theme } from '@/components/App';
import * as yup from 'yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

type EditModalProps = {
  onSubmit: (data: PutCrowdfundingPlanI) => void;
  plan: CrowdfundingPlanI;
};

const schema = yup.object().shape({
  title: yup.string().required('Campo requerido'),
  amountToCollect: yup
    .number()
    .positive()
    .integer()
    .required('Campo requerido')
    .typeError('Debe ser un numero positivo')
});

export const EditModal: FunctionComponent<EditModalProps> = ({ plan, onSubmit }) => {
  const [description, setDescription] = useState('');
  const [showAmountToCollect, setShowAmountToCollect] = useState<boolean>(false);

  const {
    handleSubmit: handleSubmitForm,
    formState: { errors },
    reset,
    control
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setDescription(plan.description);
    setShowAmountToCollect(plan.show_amount_to_collect);
    reset({ title: plan.title, amountToCollect: plan.amount_to_collect.toString() });
  }, [plan]);

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    const request: PutCrowdfundingPlanI = {
      id: plan.id.toString(),
      title: data.title,
      description,
      amount_to_collect: data.amountToCollect,
      show_amount_to_collect: showAmountToCollect
    };

    onSubmit(request);
  };

  const toggleCheckbox = () => setShowAmountToCollect(!showAmountToCollect);

  return (
    <>
      <ModalHeader>Editar un Objetivo</ModalHeader>
      <ModalContent>
        <HelpText>
          Al definir un Objetivo, tus seguidores podrán realizar aportes y ayudarte a alcanzarlo.
        </HelpText>
        <InputContainer>
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={onChange}
                capitalize={false}
                inputId="title"
                placeholder="Título del Objetivo"
                value={value || ''}
                error={(errors?.title?.message as string) || ''}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <RichTextEditor
            placeholder="Contale a tus clientes de qué trata tu servicio"
            onChange={(description: string) => setDescription(description)}
            content={description || ''}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            name="amountToCollect"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={onChange}
                capitalize={false}
                inputId="amountToCollect"
                placeholder="Monto a alcanzar"
                helpText="Indicá el monto para cumplir tu Objetivo"
                pattern="\d*"
                value={value || ''}
                error={errors?.amountToCollect?.message as string}
              />
            )}
          />
        </InputContainer>
        <CheckboxContainer>
          <Checkbox enable={!showAmountToCollect} onClick={toggleCheckbox} />
          <DescriptionWrapper>
            <Description>Ocultar monto a alcanzar</Description>
            <TooltipWrapper>
              <Tooltip content="Mostraremos el total recaudado pero no tu meta a alcanzar" position="top">
                <Icon name="question_circle" width="15px" height="15px" color={theme.colors.grey[800]} />
              </Tooltip>
            </TooltipWrapper>
          </DescriptionWrapper>
        </CheckboxContainer>
      </ModalContent>
      <ModalFooter>
        <Button
          fullWidth
          onClick={handleSubmitForm(handleSubmit, () => {
            // Handle error
          })}
        >
          Editar Objetivo
        </Button>
      </ModalFooter>
    </>
  );
};

const HelpText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  padding-bottom: 15px;
`;

const CheckboxContainer = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  width: 100%;
`;

const Description = styled.span`
  flex: 1;
  color: ${theme.colors.grey[700]};
  font-size: ${theme.font.size.lg};
  max-width: fit-content;
`;

const TooltipWrapper = styled.div`
  flex: 2;
  margin-left: 0.5rem;
  max-width: 20%;
`;
