import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';

type TableProps = {
  body: Array<string | number | null | JSX.Element>[];
  headers: Array<string | JSX.Element>;
  classNameContainer?: string;
  maxHeight?: number;
  paginationEnabled?: boolean;
  onEnd?: () => void;
  isLoading?: boolean;
};

export const Table: FunctionComponent<TableProps> = ({
  body,
  headers,
  classNameContainer,
  maxHeight,
  paginationEnabled = false,
  onEnd,
  isLoading = false
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        // We've reached the bottom
        if (onEnd) onEnd();
      }
    }
  };

  useEffect(() => {
    if (paginationEnabled && wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [paginationEnabled]);

  const renderBody = () =>
    body.map((items, index) => (
      <TableLine key={index}>
        {items.map((item, index) => (
          <TableCell key={index}>{item}</TableCell>
        ))}
      </TableLine>
    ));

  const renderHeaders = () =>
    headers.map((header, index) => <TableCellHeader key={index}>{header}</TableCellHeader>);

  const renderSkeletonLoader = () => {
    return (
      <SkeletonLoaderRow>
        {headers.map((_, index) => (
          <SkeletonLoaderCell key={index}>
            <SkeletonLoaderDiv />
          </SkeletonLoaderCell>
        ))}
      </SkeletonLoaderRow>
    );
  };

  return (
    <Wrapper ref={wrapperRef} className={`${classNameContainer}`} style={{ maxHeight: `${maxHeight}px` }}>
      <TableContainer>
        <TableHeader>
          <TableLine>{renderHeaders()}</TableLine>
        </TableHeader>
        <tbody>
          {renderBody()}
          {isLoading && renderSkeletonLoader()}
        </tbody>
      </TableContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: auto;
  width: auto;
  position: relative;
  min-height: 200px;
`;

const TableContainer = styled.table`
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  position: relative;
`;

const TableHeader = styled.thead`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #fbfbfb;
`;

const TableLine = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
`;

const TableCell = styled.td`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 18px;
  padding: 9px 0px;
  text-align: left;
`;

const TableCellHeader = styled.th`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 15px;
  font-style: normal;
  line-height: 19px;
  padding-bottom: 16px;
  text-align: left;
  font-weight: normal;
`;

const SkeletonLoaderRow = styled.tr`
  width: 100%;
`;

const SkeletonLoaderCell = styled.td`
  padding: 20px;
  width: auto; // Adjust as needed
`;

const SkeletonLoaderDiv = styled.div`
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
  width: 100%;
`;
