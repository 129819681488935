import { Column } from '@/components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { InputV2, Input, InputPhone } from '@/components/Input';
import { VendorClientsBaseI } from '@/types/cyclone/models';
import { PutClientI } from '@/types/cyclone/requests';
import { FC, useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface Props {
  clientBase: VendorClientsBaseI;
  show: boolean;
  setShow: (show: boolean) => void;
  onSubmit: (data: PutClientI) => void;
}

export const ClientModal: FC<Props> = ({ clientBase, show, setShow, onSubmit }) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [otherData, setOtherData] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (clientBase.user) {
      const dob = clientBase.user.dob ? new Date(clientBase.user.dob).toISOString().split('T')[0] : '';
      setPhone(clientBase.user.phone || '');
      setEmail(clientBase.user.email || '');
      setDob(dob);
      setAddress(clientBase.address || '');
      setOtherData(clientBase.notes || '');
    }
  }, [clientBase]);

  const validatePhone = (value: string): boolean => {
    if (!value) return false;
    const phoneNumber = parsePhoneNumberFromString('+' + value);
    if (phoneNumber?.country === 'UY' && phoneNumber?.nationalNumber.length <= 7) {
      return false;
    }
    return phoneNumber?.isValid() || false;
  };

  const handlePhoneChange = (value: string) => {
    setPhone(value);

    if (value) {
      if (!validatePhone(value)) {
        setErrors((prev) => ({ ...prev, phone: 'Número inválido' }));
      } else {
        setErrors((prev) => ({ ...prev, phone: '' }));
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Validate email
    if (value) {
      if (!validateEmail(value)) {
        setErrors((prev) => ({ ...prev, email: 'Email inválido' }));
      } else {
        setEmail(value);
        setErrors((prev) => ({ ...prev, email: '' }));
      }
    }
  };

  const validateEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleDobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDob(event.target.value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleOtherDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherData(event.target.value);
  };

  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};

    if (phone) {
      if (!validatePhone(phone)) {
        newErrors.phone = 'Nmero inválido';
      }
    }

    if (email) {
      if (!validateEmail(email)) {
        newErrors.email = 'Email inválido';
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log('submit', {
        phone: phone === '' ? null : phone,
        dob,
        address: address === '' ? null : address,
        email: email === '' ? null : email,
        notes: otherData
      });
      onSubmit({
        phone: phone === '' ? null : phone,
        dob,
        address: address === '' ? null : address,
        email: email === '' ? null : email,
        notes: otherData
      });
      setShow(false);
    }
  };

  return (
    <Dialog open={show} onOpenChange={setShow}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <DialogClose />
          <DialogTitle>Editar datos personales</DialogTitle>
          <button className="text-sm font-medium text-[#0072FB]" onClick={handleSubmit}>
            Guardar
          </button>
        </DialogHeader>
        <Column gap={12} className="py-4">
          <Column>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <InputV2
              id="email"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(event)}
            />
            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
          </Column>

          <Column>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Teléfono
            </label>
            <InputPhone
              id="phone"
              inputPhoneProps={{
                value: phone,
                onChange: (value: string) => handlePhoneChange(value),
                countryCodeEditable: false
              }}
            />
            {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
          </Column>

          <Column>
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
              Fecha de Nacimiento
            </label>
            <InputV2 id="dob" value={dob} onChange={handleDobChange} type="date" />
            {errors.dob && <span className="text-red-500 text-sm">{errors.dob}</span>}
          </Column>

          <Column>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
              Dirección
            </label>
            <InputV2 id="address" value={address} onChange={handleAddressChange} />
            {errors.address && <span className="text-red-500 text-sm">{errors.address}</span>}
          </Column>

          <Column>
            <label htmlFor="otherData" className="block text-sm font-medium text-gray-700">
              Otros Datos
            </label>
            <Input
              inputId="otherData"
              value={otherData}
              onChange={handleOtherDataChange}
              variant="textarea"
              rows={120}
            />
          </Column>
        </Column>
        <DialogFooter>
          <button
            className="rounded-[5px] h-12 bg-[#0072FB] text-white font-medium w-full"
            onClick={handleSubmit}
          >
            Guardar
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
