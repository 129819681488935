import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Description, Selects, List } from '.';
import { LabelValue } from '@/types';
import { ServiceKey, ServiceType } from '../../types';
import { getNewId } from '@/utils';

type ServiceProps = {
  services: ServiceType[];
  handleServices: (service: ServiceType[]) => void;
  isEmptyServices: boolean;
};

export const Service: FunctionComponent<ServiceProps> = ({ services, handleServices, isEmptyServices }) => {
  const [isAddingService, setIsAddingService] = useState<boolean>(false);
  const [name, setName] = useState<LabelValue | undefined>(undefined);
  const [limit, setLimit] = useState<string | undefined>(undefined);
  const [isLimited, setIsLimited] = useState<boolean>(true);

  const addService = () => {
    const newService: ServiceType = {
      id: getNewId(),
      name: name?.label || '',
      limit: limit ? parseInt(limit) : 0,
      serviceId: parseInt(name?.value || ''),
      key: name?.key as ServiceKey,
      is_limited: isLimited
    };

    handleServices(services ? [...services, newService] : [newService]);
    setIsAddingService(false);
    resetSelect();
  };

  const resetSelect = () => {
    setName(undefined);
    setLimit(undefined);
    setIsLimited(true);
  };

  const deleteService = (id: number) => {
    const newServices = services?.filter((service) => service.id !== id);
    handleServices(newServices);
  };

  useEffect(() => {
    if (name?.key === 'service_exception') {
      setLimit(undefined);
      addService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Container>
      <Description isEmptyServices={isEmptyServices} />
      <InnerContainer>
        <List services={services} deleteService={deleteService} />
        <Selects
          prevSelectedServices={services}
          name={name}
          handleName={setName}
          limit={limit}
          handleLimit={setLimit}
          isAddingService={isAddingService}
          addService={addService}
          handleIsLimited={setIsLimited}
          isLimited={isLimited}
        />
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  ${({ theme }) => theme.breakpoint('xl')} {
    flex-direction: column;
  }
`;

const InnerContainer = styled.div`
  width: 60%;
  ${({ theme }) => theme.breakpoint('xl')} {
    width: 100%;
  }
`;
