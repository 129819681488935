import { useCallback, useEffect, useState } from 'react';
import { MultiSelectOptionProps } from '@/components/MultiSelect';
import { DAYS_OF_WEEK } from '@/constants';
import { CombinationStatus, CombinationType, ScheduleStatus, ScheduleType } from '../types';

const daysMap = DAYS_OF_WEEK.map((day, i) => ({ label: day, value: (i === 6 ? 0 : i + 1).toString() }));

type UseFieldsParams = {
  combinationIndex: number;
  serviceDuration: number;
  currentCombination: CombinationType;
  allCombinations: CombinationType[];
  handleCombination: (combination: CombinationType[]) => void;
  closeModal: () => void;
};

type UseFieldsReturnType = {
  handleSchedule: (selectedSchedule: ScheduleType) => void;
  handleSaveSchedules: (schedules: ScheduleType[]) => void;
  handleInitialSchedules: (schedules: ScheduleType[]) => void;
  deleteCombination: () => void;
  handleClose: () => void;
  daysOptions: MultiSelectOptionProps[];
  hasSelectedSchedules: boolean;
  schedulesLength: number;
  recurringDays: number[];
};

export const useFields = ({
  combinationIndex,
  serviceDuration,
  currentCombination,
  handleCombination,
  allCombinations,
  closeModal
}: UseFieldsParams): UseFieldsReturnType => {
  const [intialSchedules, setInitialSchedules] = useState<ScheduleType[] | undefined>(undefined);

  useEffect(() => {
    const newCombination = allCombinations.map((combination, i) => ({
      ...combination,
      ...(combinationIndex === i && { selectedDays: undefined })
    }));

    if (currentCombination.selectedDays?.length === 0) handleCombination(newCombination);
  }, [allCombinations, currentCombination.selectedDays?.length, handleCombination, combinationIndex]);

  useEffect(() => {
    const currentDays = currentCombination.selectedDays?.map((day) => day.value);

    const relatedCombinations = allCombinations.filter((combination) =>
      combination.selectedDays?.some(
        (day) => currentDays?.includes(day.value) && combination.id !== currentCombination.id
      )
    );

    const relatedSchedules = relatedCombinations.map((combination) => combination.schedules);

    const concatSchedules: ScheduleType[] = Array.prototype.concat.apply([], relatedSchedules);

    const filterSchedules = concatSchedules.filter((schedule) => schedule.status !== ScheduleStatus.FREE);

    const takenSchedules = filterSchedules.filter(
      (schedule, index, a) => a.findIndex((item) => item.value === schedule.value) === index
    );

    const isTakenDay = allCombinations
      .map(
        (combination) =>
          combination.selectedDays?.some(
            (day) => currentDays?.includes(day.value) && combination.id !== currentCombination.id
          ) || false
      )
      .includes(true);

    const newSchedules = currentCombination.schedules.map((schedule) => {
      const isTakenSchedule = takenSchedules.some((takenSchedule) => takenSchedule.value === schedule.value);
      return {
        ...schedule,
        status: isTakenSchedule && isTakenDay ? ScheduleStatus.OTHER : schedule.status
      };
    });

    const newCombination = allCombinations.map((combination, i) => ({
      ...combination,
      ...(combinationIndex === i && { schedules: newSchedules })
    }));

    handleCombination(newCombination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinationIndex, currentCombination.id, currentCombination.selectedDays, handleCombination]);

  useEffect(() => {
    if (currentCombination.selectedDays) return;

    const newCombination = allCombinations.map((combination, i) => ({
      ...combination,
      ...(combinationIndex === i && { selectedSchedules: [] })
    }));

    handleCombination(newCombination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinationIndex, currentCombination.selectedDays, handleCombination]);

  const handleSchedule = (selectedSchedule: ScheduleType) => {
    if (!currentCombination.schedules) return;

    const isSelecting = selectedSchedule.status === ScheduleStatus.FREE;

    const selectedIndex = currentCombination.schedules.findIndex(
      ({ value }) => value === selectedSchedule.value
    );

    const editedSelectedSchedule = {
      ...selectedSchedule,
      status: ScheduleStatus[isSelecting ? 'SELECTED' : 'FREE']
    };

    const prevSchedule = selectedSchedule.date.subtract(serviceDuration, 'minutes');
    const endSchedule = selectedSchedule.date.add(serviceDuration, 'minutes');

    const newSchedules = currentCombination.schedules.map((schedule, i) => {
      if (selectedIndex === i) return editedSelectedSchedule;
      else {
        const isTakenAfter =
          schedule.date.isBefore(endSchedule) && schedule.date.isAfter(selectedSchedule.date);

        const isTakenBefore =
          schedule.date.isAfter(prevSchedule) && schedule.date.isBefore(selectedSchedule.date);

        if (isTakenAfter || isTakenBefore) {
          return {
            ...schedule,
            status:
              ScheduleStatus[
                isSelecting
                  ? schedule.status === ScheduleStatus.OTHER
                    ? 'OTHER'
                    : schedule.status === ScheduleStatus.TAKEN
                    ? 'DOUBLE_TAKEN'
                    : 'TAKEN'
                  : schedule.status === ScheduleStatus.OTHER
                  ? 'OTHER'
                  : schedule.status === ScheduleStatus.DOUBLE_TAKEN
                  ? 'TAKEN'
                  : 'FREE'
              ]
          };
        } else {
          return schedule;
        }
      }
    });

    const newCombination = allCombinations.map((combination, i) => ({
      ...combination,
      ...(combinationIndex === i && { schedules: newSchedules })
    }));

    handleCombination(newCombination);
  };

  const handleSaveSchedules = (schedules: ScheduleType[]) => {
    const newCombination = allCombinations.map((combination, i) => {
      return {
        ...combination,
        ...(combinationIndex === i && { selectedSchedules: schedules, status: CombinationStatus.CONFIRMED })
      };
    });
    handleCombination(newCombination);
  };

  const deleteCombination = () => {
    const newCombinations = allCombinations.filter((_, i) => i !== combinationIndex);
    handleCombination(newCombinations);
  };

  const handleInitialSchedules = useCallback(
    (schedules: ScheduleType[]) => setInitialSchedules(schedules),
    []
  );

  const handleClose = () => {
    const newCombination = allCombinations.map((combination, i) => ({
      ...combination,
      ...(combinationIndex === i && { schedules: intialSchedules })
    }));
    handleCombination(newCombination);

    closeModal();
  };

  const daysOptions: MultiSelectOptionProps[] = daysMap.map((day) => {
    const isSelected = currentCombination?.selectedDays?.some(({ value }) => value === day.value) || false;

    const newCombination = allCombinations.map((combination, i) => {
      if (combinationIndex === i) {
        return {
          ...combination,
          selectedDays: isSelected
            ? combination?.selectedDays?.filter(({ value }) => value !== day.value)
            : combination?.selectedDays
            ? [...combination.selectedDays, day]
            : [day]
        };
      } else {
        return combination;
      }
    });

    const onClick = () => handleCombination(newCombination);

    return { item: day, onClick, isSelected };
  });

  const recurringDays = currentCombination.selectedDays?.map(({ value }) => parseInt(value)) || [];

  const schedulesLength = currentCombination.selectedSchedules.length;
  const hasSelectedSchedules = schedulesLength !== 0;

  return {
    handleSchedule,
    handleSaveSchedules,
    deleteCombination,
    handleInitialSchedules,
    handleClose,
    daysOptions,
    hasSelectedSchedules,
    schedulesLength,
    recurringDays
  };
};
