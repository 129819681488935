import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Row } from '../Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { es } from 'date-fns/locale/es';

type inputDatepickerProps = {
  date?: Date | string;
  setDate: (date: Date) => void;
  datepickerProps?: Partial<ReactDatePickerProps>;
  customInput?: boolean;
  className?: string;
};

const TODAY_DATE = new Date();

export const InputDatepicker: React.FC<inputDatepickerProps> = ({
  date,
  setDate,
  datepickerProps,
  customInput = true,
  className
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);

  React.useEffect(() => {
    if (date) {
      setSelectedDate(dayjs(date).toDate());
    } else {
      setSelectedDate(undefined);
      setDate(TODAY_DATE); //eslint-disable-line
    }
  }, [date]);

  const CustomInput = React.forwardRef<HTMLDivElement, { value: string; onClick: () => void }>(
    ({ value, onClick }, ref) => (
      <Row
        align="center"
        justify="space-between"
        className={classNames(
          'bg-white cursor-pointer text-sm transition-colors rounded p-4 h-10 w-full border border-[#212121]/40 hover:border-[#0072FB]',
          { 'text-[#212121]': selectedDate },
          { 'text-gray-500': !selectedDate },
          {
            'text-gray-400 bg-transparent border-gray-400 cursor-default hover:border-gray-400':
              datepickerProps?.disabled
          }
        )}
        ref={ref}
        onClick={onClick}
      >
        {value}
        <FontAwesomeIcon size="sm" fixedWidth icon={faCalendarAlt} />
      </Row>
    )
  );

  const handleDateChange = (date: Date) => {
    setDate(date);
    setSelectedDate(date);
  };

  return (
    <DatePicker
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      minDate={TODAY_DATE}
      locale={es}
      {...(selectedDate ? { selected: selectedDate } : { value: 'Selecciona' })}
      {...(customInput && { customInput: <CustomInput value="" onClick={() => {}} /> })} //eslint-disable-line
      {...datepickerProps}
      className={classNames(className)}
    />
  );
};
