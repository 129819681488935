import React, { FunctionComponent, useEffect, useState } from 'react';
import { GoogleMap, DrawingManager, Polygon, Circle } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Button, Column, Row } from '@/components';
import { defaultZone } from './utils';
import { ZoneType, PolygonType, CircleType } from './types';
import { SetDrawingTool, Zone } from './components';
import classNames from 'classnames';

const mapStyles = {
  width: '100%',
  height: '423px'
};

const center = {
  lat: -34.59804893650334,
  lng: -58.43065087994133
};

type CoverageAreaProps = {
  zones: ZoneType[];
  setZones: (zones: ZoneType[]) => void;
  error: boolean;
};

// ToDo: Use savedZones to load already saved zones
// ToDo: Save zones in the action service object in order to save them in the database
export const CoverageArea: FunctionComponent<CoverageAreaProps> = ({ zones, setZones, error }) => {
  const [drawingMode, setDrawingMode] = useState<google.maps.drawing.OverlayType | null>(
    google.maps.drawing.OverlayType.POLYGON
  );
  const coverageAreaError = error && !zones.some((zone) => zone.isSaved);

  useEffect(() => {
    if (zones.length === 0) {
      setDrawingMode(null);
    } else if (zones.length === 1 && drawingMode === null) {
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    }
  }, [zones]);

  const selectedZone = zones.find((zone) => !zone.isSaved);

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const polygonData: google.maps.LatLngLiteral[] = [];

    polygon.getPath().forEach((polygonCoords) => {
      polygonData.push({
        lat: polygonCoords.lat(),
        lng: polygonCoords.lng()
      });
    });

    setZones(
      zones.map((zone: ZoneType) => {
        if (selectedZone && zone.id === selectedZone.id) {
          return {
            ...zone,
            type: 'polygon',
            data: polygonData
          };
        } else {
          return zone;
        }
      })
    );

    polygon.setVisible(false);
  };

  const onCircleComplete = (circle: google.maps.Circle) => {
    setZones(
      zones.map((zone: ZoneType) => {
        if (selectedZone && zone.id === selectedZone.id) {
          return {
            ...zone,
            type: 'circle',
            data: {
              center: {
                lat: circle.getCenter()?.lat() || 0,
                lng: circle.getCenter()?.lng() || 0
              },
              radius: circle.getRadius()
            }
          };
        } else {
          return zone;
        }
      })
    );

    circle.setVisible(false);
  };

  return (
    <Column className="w-full md:w-1/2" gap={20}>
      <Column
        gap={20}
        className={classNames({
          'w-full': true,
          'border border-solid border-red-500': coverageAreaError
        })}
      >
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            styles: [
              {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              }
            ]
          }}
          mapContainerStyle={mapStyles}
          center={center}
          zoom={12}
        >
          <DrawingManager
            options={{
              drawingControlOptions: {
                drawingModes: []
              },
              drawingControl: false,
              circleOptions: {
                strokeColor: selectedZone?.color,
                fillColor: selectedZone?.color
              },
              polygonOptions: {
                strokeColor: selectedZone?.color
              }
            }}
            onPolygonComplete={onPolygonComplete}
            onCircleComplete={onCircleComplete}
            drawingMode={drawingMode}
          />
          {zones.length > 0 &&
            zones.map((zone, index) => {
              const circleData = zone.data as CircleType;

              if (zone.data !== undefined && zone.type === 'polygon') {
                return (
                  <Polygon
                    key={index}
                    paths={zone.data as PolygonType}
                    options={{
                      strokeColor: zone?.color,
                      fillColor: zone?.color
                    }}
                  />
                );
              } else if (zone.data !== undefined && zone.type === 'circle' && circleData.center !== null) {
                return (
                  <Circle
                    key={index}
                    radius={circleData.radius}
                    center={circleData.center}
                    options={{
                      strokeColor: zone?.color,
                      fillColor: zone?.color
                    }}
                  />
                );
              }
            })}
        </GoogleMap>
      </Column>
      <Column
        gap={20}
        className={classNames({
          'w-full': true,
          'border border-solid border-red-500': coverageAreaError
        })}
      >
        <Row align="center" justify="space-between" className="w-full md:w-1/2">
          <SetDrawingTool
            onClick={() => setDrawingMode(null)}
            height={30}
            icon="hand"
            label="Desplazar"
            isSelected={zones.length === 0 || drawingMode === null}
          />
          <SetDrawingTool
            onClick={() => setDrawingMode(google.maps.drawing.OverlayType.POLYGON)}
            height={28}
            icon="polygon"
            label="Dibujar"
            isSelected={zones.length !== 0 && drawingMode === google.maps.drawing.OverlayType.POLYGON}
            disabled={zones.length === 0}
          />
          <SetDrawingTool
            onClick={() => setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)}
            height={24}
            icon="circle"
            label="Círculo"
            isSelected={zones.length !== 0 && drawingMode === google.maps.drawing.OverlayType.CIRCLE}
            disabled={zones.length === 0}
          />
        </Row>
        <IncludedZones>
          {zones.length !== 0 && <SubTitle>Zonas Incluidas</SubTitle>}
          {zones.map((zone, index) => (
            <Zone zone={zone} zones={zones} setZones={setZones} index={index} key={zone.id} />
          ))}
          {(zones.length === 0 || zones[zones.length - 1].isSaved) && (
            <AddZone>
              <Button
                fullWidth
                variant="link"
                onClick={() => {
                  setZones([...zones, { ...defaultZone, id: uuidv4() }]);
                }}
              >
                Agregar zona
              </Button>
            </AddZone>
          )}
        </IncludedZones>
        {coverageAreaError && (
          <Error pushToBottom={zones.some((zone) => zone.isSaved)}>
            No olvides definir la zona de cobertura para tu servicio.
          </Error>
        )}
      </Column>
    </Column>
  );
};

const IncludedZones = styled.div``;

const SubTitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;

const AddZone = styled.div`
  margin-top: 16px;
`;

const Error = styled.div<{ pushToBottom: boolean }>`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.red[200]};
  margin-top: ${({ pushToBottom }) => (pushToBottom ? '16px' : 'auto')};
  text-align: center;
`;
