import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Button, Input, ModalV2 } from '@/components';

type ContactModalProps = {
  setShowModal: (show: boolean) => void;
  onSubmit: (message: string) => void;
  contactTo?: string;
};

export const ContactModal: FunctionComponent<ContactModalProps> = ({
  setShowModal,
  onSubmit,
  contactTo = 'todos'
}) => {
  const [message, setMessage] = useState('');

  const handleOnSubmit = () => {
    onSubmit(message);
    setShowModal(false);
  };

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.keyCode === 13) event.preventDefault();
    };
    window.addEventListener('keydown', handleEnter);

    return () => window.removeEventListener('keydown', handleEnter);
  });

  return (
    <ModalV2
      show
      onClose={() => {
        setShowModal(false);
      }}
    >
      <h3 className="text-lg font-medium">Contactar a {contactTo}</h3>
      <p className="text-sm text-gray-500 mt-4">
        Los destinatarios recibirán tu mensaje a través de su email.
      </p>
      <div className="my-5">
        <Input
          capitalize={false}
          inputId="contact-message"
          value={message}
          variant="textarea"
          placeholder="Escribí tu mensaje"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setMessage(value)}
        />
      </div>
      <Button onClick={() => handleOnSubmit()}>Enviar mensaje</Button>
    </ModalV2>
  );
};
