import React, { FunctionComponent } from 'react';
import { Icon, Table, IconLabel, ModalV2 } from '@/components';
import { MobileCard } from './';
import { target } from '@/static/images';
import dayjs from 'dayjs';
import { useModal, useWindowResize } from '@/hooks';
import { useAuth, useConfirmation } from '@/contexts';
import { CrowdfundingPlanI, CrowdfundingPlanStatusE, SupportedCountriesE } from '@/types/cyclone/models';
import { EditModal } from '../EditModal';
import { PutCrowdfundingPlanI } from '@/types/cyclone/requests';
import { formatCurrency } from '@/utils/money';
import { theme } from '@/components/App';

const headers = ['Título', 'Creado', 'Aportes', 'Objetivo', 'Estado', ''];

type CrowdfundingPlanStatusInfo = {
  name: string;
  textColor: string;
  backgroundColor: string;
};

const planStatus: Record<string, CrowdfundingPlanStatusInfo> = {
  active: {
    name: 'Activo',
    textColor: theme.suscription.active,
    backgroundColor: theme.colors.blue[200]
  },
  completed: {
    name: '¡Alcanzado!',
    textColor: `rgba(0, 0, 0, 0.5)`,
    backgroundColor: theme.suscription.pending
  },
  finished: {
    name: 'Finalizado',
    textColor: theme.colors.red[200],
    backgroundColor: theme.suscription.expired
  }
};

type TableCrowdfundingPlansProps = {
  plans: CrowdfundingPlanI[];
  onDelete: (id: number) => void;
  onEdit: (plan: PutCrowdfundingPlanI) => void;
  onCreate: () => void;
};

export const TableCrowdfundingPlans: FunctionComponent<TableCrowdfundingPlansProps> = ({
  plans,
  onDelete,
  onEdit,
  onCreate
}) => {
  const { isMobileSize } = useWindowResize();
  const { confirm } = useConfirmation();
  const { session } = useAuth();
  const { show, onOpen, onClose } = useModal();
  const [selectedPlan, setSelectedPlan] = React.useState<CrowdfundingPlanI | null>(null);

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;

  const handleDelete = (id: number) =>
    confirm({
      status: 'danger',
      content: '¿Querés eliminar este objetivo?',
      title: 'Eliminar objetivo',
      confirmText: 'Si, eliminar',
      onConfirm: () => onDelete(id)
    });

  const openEditModal = (plan: CrowdfundingPlanI) => {
    setSelectedPlan(plan);
    onOpen();
  };

  const handleEdit = (plan: PutCrowdfundingPlanI) => {
    onEdit(plan);
    onClose();
  };

  const body =
    plans.map((plan, i) => {
      const { id, title, participants, amount_recollected, amount_to_collect, status, created_at } = plan;

      return [
        title,
        dayjs(created_at).format('DD/MM/YYYY'),
        participants,
        `${formatCurrency(amount_recollected, country)} de ${formatCurrency(amount_to_collect, country)}`,
        <div
          className={`text-[${planStatus[status].textColor}] bg-[${planStatus[status].backgroundColor}]`}
          key={i + 'plan'}
        >
          <p>{planStatus[status].name}</p>
        </div>,
        status === CrowdfundingPlanStatusE.ACTIVE ? (
          <div className="flex items-center" key={`actions-${id}`}>
            <button className="px-1" onClick={() => openEditModal(plan)}>
              <IconLabel
                icon="edit"
                label="Editar"
                color={theme.colors.blue[500]}
                iconColor={theme.colors.blue[500]}
              />
            </button>
            <button className="ml-6 px-1" onClick={() => handleDelete(id)}>
              <Icon name="trash" width="14px" height="14px" color={theme.colors.grey[700]} />
            </button>
          </div>
        ) : null
      ];
    }) || [];

  if (!plans.length)
    return (
      <div className="text-center py-8">
        <img src={target} alt="No hay objetivos" className="mx-auto" />
        <p className="mt-6 text-gray-700 max-w-md mx-auto text-sm sm:text-base p-2 rounded-lg">
          No tenés objetivos creados. Igualmente tus clientes podrán hacer donaciones, aunque es recomendable
          que les indiques un objetivo a alcanzar.
        </p>
        <a onClick={onCreate} className="text-lg cursor-pointer text-[#0072ff] pt-9">
          Agregar objetivo
        </a>
      </div>
    );

  return (
    <>
      {isMobileSize ? (
        <>{body?.map((item, i) => (item.length ? <MobileCard items={item} key={i} /> : null))}</>
      ) : (
        <div className="pt-4">
          <Table headers={headers} body={body} />
        </div>
      )}
      <ModalV2 show={show} onClose={onClose}>
        {selectedPlan && <EditModal plan={selectedPlan} onSubmit={handleEdit} />}
      </ModalV2>
    </>
  );
};
