import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { scrollCSS } from '@/components/App';
import { Spinner } from '@/components';
import { PlaceType } from '../../utils';

type LocationDropdownProps = {
  places: PlaceType[];
  isLoading: boolean;
  handleSelectPlace: (place: PlaceType) => void;
  closeDropdown: () => void;
};

export const LocationDropdown: FunctionComponent<LocationDropdownProps> = ({
  places,
  isLoading,
  handleSelectPlace,
  closeDropdown
}) => (
  <Container>
    <InnerContainer>
      {isLoading ? (
        <Spinner size={22} />
      ) : !places.length ? (
        <Empty>No encontramos una ubicación para esta búsqueda</Empty>
      ) : (
        places.map((place, i) => (
          <Item
            key={i}
            onClick={() => {
              handleSelectPlace(place);
              closeDropdown();
            }}
          >
            {place.address}
          </Item>
        ))
      )}
    </InnerContainer>
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 80%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  width: 100%;
  padding: 1.3rem 0.9rem 1.3rem 1.3rem;
  z-index: 300;
  color: ${({ theme }) => theme.colors.black};
`;

const InnerContainer = styled.div`
  ${scrollCSS};
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  padding-right: 0.8rem;
  > div {
    height: 100%;
  }
`;

const Item = styled.button`
  display: block;
  margin-bottom: 0.7rem;
  padding-left: 0.8rem;
  transition: color 0.2s;
  text-align: left;
  font-size: 0.95rem;
  &:hover {
    color: ${({ theme }) => theme.colors.red[200]};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Empty = styled.span`
  display: block;
  font-style: italic;
  margin-left: 0.8rem;
  font-size: 0.8rem;
`;
