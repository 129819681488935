import React, { FunctionComponent, useState } from 'react';
import { Row } from '@/components';
import { AgoraPlanNameE, ServiceStatusE, SubscriptionStatusE } from '@/types/cyclone/models';
import { useAuth } from '@/contexts';
import { useNavigate } from 'react-router-dom';
import { faCopy, faEdit, faPauseCircle, faPlayCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalActivateService } from '@/containers/Services/components';
import { toast } from 'react-toastify';

type MenuProps = {
  children: React.ReactNode;
  onEdit: () => void;
  onStatusChange: () => void;
  onDelete?: () => void;
  onCopy?: () => void;
  disableCopy?: boolean;
  status: ServiceStatusE | SubscriptionStatusE;
};

export const Menu: FunctionComponent<MenuProps> = ({
  children,
  onEdit,
  onDelete,
  onCopy,
  onStatusChange,
  status,
  disableCopy = false
}) => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const isPlanAvailable = session?.active_plan.is_plan_available || false;

  const handlerStatusChange = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!isPlanAvailable)
      return toast.error('No es posible activar el servicio, por favor actualiza tu plan.');

    if (session?.vendor?.plan_name !== AgoraPlanNameE.PROFESSIONAL) {
      return setModalOpen(true);
    }
    onStatusChange();
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete?.();
  };

  const handleDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCopy?.();
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={handleEdit}>
            <Row gap={10} align="center">
              <FontAwesomeIcon icon={faEdit} />
              Editar
            </Row>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handlerStatusChange}>
            <Row gap={10} align="center">
              <FontAwesomeIcon icon={status === ServiceStatusE.ACTIVE ? faPauseCircle : faPlayCircle} />
              {status === ServiceStatusE.ACTIVE ? 'Archivar' : 'Activar'}
            </Row>
          </DropdownMenuItem>
          {!disableCopy && (
            <DropdownMenuItem onClick={handleDuplicate}>
              <Row gap={10} align="center">
                <FontAwesomeIcon icon={faCopy} />
                Duplicar
              </Row>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={handleDelete}>
            <Row gap={10} align="center">
              <FontAwesomeIcon icon={faTrash} />
              Eliminar
            </Row>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ModalActivateService
        clickButton={() => navigate('/planes')}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};
