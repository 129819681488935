import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon } from '@/components';
import { theme } from '@/components/App';

type CheckboxProps = {
  enable: boolean;
  onClick?: () => void;
  size?: number;
  margin?: 'right' | 'left';
  color?: string;
  rounded?: boolean;
  disabled?: boolean;
};

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  enable,
  onClick,
  size = 19,
  margin,
  color = theme.colors.blue[500],
  rounded = false,
  disabled = false
}) => {
  const marginProp =
    margin === 'right' ? { marginRight: '8px' } : margin === 'left' ? { marginLeft: '8px' } : {};

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Container onClick={handleClick} disabled={disabled}>
      <Icon
        name={
          enable ? `check_${rounded ? 'rounded' : 'square'}` : `uncheck_${rounded ? 'rounded' : 'square'}`
        }
        color={disabled ? 'gray' : color}
        width={`${size}px`}
        height={`${size}px`}
        {...marginProp}
      />
    </Container>
  );
};

const Container = styled.div<{ disabled: boolean }>`
  line-height: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
