import { createGlobalStyle } from 'styled-components';
import { ThemeType } from './theme';

export const GlobalStyles = createGlobalStyle<{ theme?: ThemeType }>`
    
    @font-face {
        font-family: 'Playfair Display';
        src: local('Playfair Display'),
        url('https://d2nw8b1k8diib7.cloudfront.net/general/fonts/playfair_display.woff2') format('woff2');
        font-display:auto;
        font-style:normal;
        font-stretch:normal;
    }

    .soho-font {
        font-family: 'Playfair Display';
    }

    *,
    *:after,
    *:before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html {
        scroll-behavior: smooth;
    }

    .gu-mirror {
        display: none !important;
    }

    ${({ theme }) => theme.breakpoint('sm')}{
        html{
            font-size: 90%;
        }
    }

    body {
        /* overflow-x: hidden; */
    }

    #root {
				min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    body {
        font-family: ${(props) => props.theme.fontFamily};
        font-size: 16px;
    }

    img {
        max-width: 100%;
    }

    a {
        text-decoration: none;
    }

    button {
        font-family: ${(props) => props.theme.fontFamily};
        font-size: 16px;
        background: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        &:disabled {
            cursor: not-allowed;
        }
    }

    input, textarea {
        font-family: ${(props) => props.theme.fontFamily};
        font-size: 16px;
        outline: none;
        background-color: transparent;
    }

    textarea {
        resize: none;
    }
`;
