import React, { FunctionComponent } from 'react';
import { theme } from '@/components/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type IconLabelV2 = {
  icon: IconDefinition;
  label: string | number | JSX.Element;
  color?: string;
  gap?: string;
  iconSize?: string;
  iconColor?: string;
  small?: boolean;
  className?: string;
  margin?: string;
  style?: React.CSSProperties;
};

export const IconLabelV2: FunctionComponent<IconLabelV2> = ({
  icon,
  label,
  color = theme.colors.grey[700],
  gap = '4px',
  iconSize = '16px',
  iconColor = theme.colors.grey[700],
  small = false,
  className,
  margin
}) => (
  <div className={`${className} flex items-center ${margin ? `m-[${margin}]` : 'm-2'}`}>
    <FontAwesomeIcon
      icon={icon}
      color={iconColor}
      style={{ marginRight: gap }}
      width={iconSize}
      height={iconSize}
    />
    <span
      className={`${small ? `text-[0.875rem]` : 'text-[1rem]'} ${
        small ? `leading-[0.875rem]` : 'leading-[1rem]'
      }`}
      style={{ color }}
    >
      {label}
    </span>
  </div>
);
