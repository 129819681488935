import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';
import { toast } from 'react-toastify';
import { ImageCropper } from '../ImageCropper';
import classNames from 'classnames';
import { theme } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faCropSimple, faImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Row } from '../Row';

enum TypeImageInput {
  BANNER = 'banner',
  AVATAR = 'avatar',
  EVENT = 'event',
  OTHER = 'other'
}

const MAX_FILE_SIZE = 10485760; // Max 10 MB

type InputFileProps = {
  reference: string;
  handleFile: (avatar: string) => void;
  styles?: CSSProp;
  validExts?: string[];
  helpText?: string;
  error?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  img?: string;
  width: string;
  height: string;
  type?: TypeImageInput;
  onClick?: () => void;
  showIcons?: boolean;
};

export const InputFileV2: FunctionComponent<InputFileProps> = ({
  reference,
  handleFile,
  styles,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  helpText,
  error,
  disabled,
  img,
  width,
  height,
  type = TypeImageInput.OTHER,
  showIcons = true
}) => {
  const [imgFile, setImgFile] = useState<File | undefined>(undefined);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) return toast.error('La imagen no debe pesar mas de 10 MB');
    const type = file.name.split('.').pop();
    const isValidFile = type && validExts.includes(type);
    if (isValidFile) {
      setImgFile(file);
      setIsOpenModal(true);
    } else toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
    // Had to this because you couldn't select the same image twice
    // SO link to solution: https://stackoverflow.com/a/56258902
    e.target.value = '';
    e.stopPropagation();
  };

  const getAspectRadio = () => {
    switch (type) {
      case TypeImageInput.AVATAR:
      case TypeImageInput.OTHER:
        return 1;

      default:
        return 3 / 1;
    }
  };

  const emptyImage = () => {
    switch (type) {
      case TypeImageInput.AVATAR:
        return (
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faCircleUser}
            color="#DADCE0"
            style={{ width, height }}
          />
        );
      case TypeImageInput.BANNER:
        return <div className={classNames('bg-[#DADCE0]')} style={{ width, height }} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isOpenModal && imgFile) {
      handleFile(URL.createObjectURL(imgFile));
      setImgFile(undefined);
    }
  }, [isOpenModal]);

  const openCrop = async () => {
    setIsOpenModal(true);
    if (img) {
      setIsLoading(true); // Iniciar carga
      try {
        const response = await fetch(img);
        const data = await response.blob();
        const metadata = {
          type: 'image/jpeg'
        };
        const file = new File([data], 'test.jpg', metadata);
        setImgFile(file);
      } catch (error) {
        toast.error(`Error al procesar la imagen`);
      } finally {
        setIsLoading(false); // Finalizar carga
      }
    }
  };

  const ImageSkeleton: React.FC = () => (
    <div className="animate-pulse bg-gray-300" style={{ width, height }}></div>
  );

  return (
    <div className="w-full">
      {isOpenModal && imgFile && (
        <ImageCropper
          image={imgFile}
          prevImage={URL.createObjectURL(imgFile) || img}
          onClose={() => {
            setIsOpenModal(false);
          }}
          setImage={(image: File | undefined) => {
            setImgFile(image);
          }}
          aspectRatio={getAspectRadio()}
        />
      )}
      {isLoading ? (
        <ImageSkeleton />
      ) : (
        <>
          <input className="hidden" id={reference} type="file" onChange={onChange} disabled={disabled} />

          <label htmlFor={reference} className="relative w-full">
            <Button styles={styles} disabled={disabled}>
              {img ? (
                <div
                  className={classNames('bg-cover bg-no-repeat bg-center bg-[#FFF] cursor-pointer', {
                    'rounded-[50%]': type === TypeImageInput.AVATAR,
                    'rounded-[6px]': type === TypeImageInput.BANNER,
                    'rounded-[10px] ': type === TypeImageInput.OTHER
                  })}
                  style={{ backgroundImage: `url(${img})`, width, height }}
                />
              ) : (
                <>{emptyImage()}</>
              )}
            </Button>
            {showIcons && (
              <div className="absolute bottom-[20px] right-[10px] cursor-pointer">
                <Row justify="space-between">
                  {type === TypeImageInput.AVATAR && (
                    <>
                      <label htmlFor={reference}>
                        <Row
                          justify="center"
                          align="center"
                          className="rounded-[50%] w-[40px] h-[40px] bg-[#999999] mx-[2px] cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faImage} color={theme.colors.white as string} />
                        </Row>
                      </label>

                      {/* {img && (
                    <Row
                      justify="center"
                      align="center"
                      className="rounded-[50%] w-[40px] h-[40px] bg-[#999999] mx-[2px]"
                      onClick={() => {
                        openCrop();
                      }}
                    >
                      <FontAwesomeIcon icon={faCropSimple} color={theme.colors.white as string} />
                    </Row>
                  )} */}
                    </>
                  )}
                  {type !== TypeImageInput.AVATAR && (
                    <>
                      <label htmlFor={reference}>
                        <Row
                          justify="center"
                          align="center"
                          className="rounded-[50%] w-[40px] h-[40px] bg-[#999999] mx-[2px] cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faImage} color={theme.colors.white as string} />
                        </Row>
                      </label>

                      {img && (
                        <>
                          <Row
                            justify="center"
                            align="center"
                            className="rounded-[50%] w-[40px] h-[40px] bg-[#999999] mx-[2px] cursor-pointer"
                            onClick={() => {
                              openCrop();
                            }}
                          >
                            <FontAwesomeIcon icon={faCropSimple} color={theme.colors.white as string} />
                          </Row>
                          <Row
                            justify="center"
                            align="center"
                            className="rounded-[50%] w-[40px] h-[40px] bg-[#999999] mx-[2px] cursor-pointer"
                            onClick={() => {
                              handleFile('');
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} color={theme.colors.white as string} />
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </div>
            )}
          </label>
        </>
      )}

      {(helpText || error) && (
        <span
          className={classNames('block text-[0.73rem] mt-[0.4rem] ml-[1rem]', {
            'bg-[#FF4658]': !!error,
            'bg-[#858585]': !error
          })}
        >
          {error || helpText}
        </span>
      )}
    </div>
  );
};

const Button = styled.div<{ styles?: CSSProp; disabled?: boolean }>`
  ${({ styles }) => styles};
`;
