import { FunctionComponent, useState } from 'react';
import { signinBanner } from '@/static/images';
import { SignIn, ForgotPassword } from './components';
import usePageTracking from '@/hooks/usePageTracking';
import { Column, Row } from '@/components';

export const Login: FunctionComponent = () => {
  usePageTracking();
  const [isSignInOpen, setIsSignInOpen] = useState(true);

  const switchView = () => setIsSignInOpen(!isSignInOpen);

  return (
    <Row className="w-full h-[100svh]">
      <Column
        as="section"
        align="center"
        className="font-medium pt-8 bg-[#fafafa] hidden md:block md:w-3/5 h-[100svh] lg:flex"
      >
        <div className="w-[550px] aspect-auto">
          <img src={signinBanner} alt="signin" className="w-[90%] aspect-auto" />
        </div>
      </Column>
      <section className="w-full md:w-2/5 h-screen shadow-[0_4px_23px_0_rgba(0,0,0,0.30)]">
        {isSignInOpen ? (
          <SignIn navigateToForgotPassword={switchView} />
        ) : (
          <ForgotPassword navigateToSignIn={switchView} />
        )}
      </section>
    </Row>
  );
};
