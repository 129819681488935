import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner } from '..';

type SessionLoaderProps = {
  isOnboardingFinished: boolean | undefined;
  children: JSX.Element;
};

export const SessionLoader: FunctionComponent<SessionLoaderProps> = ({ isOnboardingFinished, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !isOnboardingFinished &&
      !location.pathname.includes('onboarding') &&
      !location.pathname.includes('/mercado-pago/callback')
    ) {
      navigate('/onboarding');
    }
  }, [isOnboardingFinished, location, location.pathname]);

  return isOnboardingFinished === undefined ? (
    <Flex>
      <Spinner />
    </Flex>
  ) : (
    children
  );
};

const Flex = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
