import { Icon } from '@/components';
import { theme } from '@/components/App';
import { useAuth } from '@/contexts';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { formatCurrency } from '@/utils/money';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface SubscriptionItemProps {
  name: string;
  subscriptionName?: string;
  avatar?: string;
  price?: number;
  expirationDate?: string;
  last?: boolean;
}

export const SubscriptionItem: FunctionComponent<SubscriptionItemProps> = ({
  avatar,
  name,
  price,
  expirationDate,
  subscriptionName,
  last
}) => {
  const { session } = useAuth();

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;
  return (
    <SubscriptionItemContainer last={last}>
      <AvatarWithName>
        {avatar ? (
          <Avatar src={avatar} alt={`${name} avatar`} />
        ) : (
          <Icon
            name="user_circle"
            width="30px"
            height="30px"
            marginRight="15px"
            color={theme.colors.grey[700]}
          />
        )}
        {name}
      </AvatarWithName>
      <Column flex={'2'} hide>
        {subscriptionName}
      </Column>
      <Column>{price && formatCurrency(price, country)}</Column>
      <Column hide>
        <ClearText>Vencimiento</ClearText> {dayjs(expirationDate).format('DD/MM/YYYY')}
      </Column>
    </SubscriptionItemContainer>
  );
};

const AvatarWithName = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  font-size: 15px;
  line-height: 19px;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Column = styled.p<{ flex?: string; hide?: boolean }>`
  display: flex;
  align-items: center;
  flex: ${({ flex }) => flex || 1};
  font-size: 15px;
  line-height: 19px;
  padding: 0px 10px;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 14px;
    line-height: 18px;
    ${({ hide }) => hide && `display: none`};
    justify-content: flex-end;
  }
`;

const SubscriptionItemContainer = styled.div<{ last?: boolean }>`
  padding: 18px 0px 18px 0px;
  display: flex;
  ${({ theme, last }) => !last && `border-bottom: 1px solid ${theme.colors.grey[500]}`};
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    padding: 18px 19px 18px 27px;
  }
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  object-position: center;
  object-fit: cover;
`;

const ClearText = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-right: 20px;
`;
