import React, { FunctionComponent } from 'react';
import { Row } from '@/components';
import { theme } from '@/components/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';

interface StatusOfflinePaymentPropsI {
  isConfirmed: boolean;
  onClick: () => void;
}

export const StatusOfflinePayment: FunctionComponent<StatusOfflinePaymentPropsI> = ({
  isConfirmed,
  onClick
}) => {
  return (
    <>
      {isConfirmed ? (
        <FontAwesomeIcon icon={faCheck} size="lg" color={theme.suscription.active} />
      ) : (
        <Row align="center" justify="center" className="rounded-full bg-[#0072FB] w-8 h-8 cursor-pointer p-2">
          <FontAwesomeIcon
            icon={faCircleDollarToSlot}
            size="sm"
            fixedWidth
            color="#ffffff"
            onClick={onClick}
            beatFade
          />
        </Row>
      )}
    </>
  );
};
