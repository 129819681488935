import * as yup from 'yup';

export const MIN_USERNAME_LENGTH = 4;

export const COMMON_DOMAINS = ['com', 'co', 'red'];
export const SPECIAL_CHARS_REGEX = /[^A-Za-z0-9._]/;
export const TILDE_REGEX = /[áéíóúÁÉÍÓÚñÑ]/;
export const INSTAGRAM_REGEX = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^\/?#]+)/;
export const GENERIC_URL_REGEX = /(?:https?:\/\/)?(?:www\.)?([^\/?#]+)/;

const isNotDomain = (value: string) => {
  const parts = value.split('.');
  if (parts.length > 1) {
    const lastPart = parts[parts.length - 1].toLowerCase();
    return !COMMON_DOMAINS.includes(lastPart);
  }
  return true;
};

export const getSchema = (usernameIsAvailable: boolean) =>
  yup.object().shape({
    storefront_name: yup.string().required('Nombre es un campo requerido'),

    profession: yup
      .string()
      .required('Descripción es un campo requerido')
      .max(150, 'Debe contener máximo 150 caracteres'),
    bio: yup.string(),
    username: yup
      .string()
      .required('Nombre de usuario es un campo requerido')
      .test('no-tildes', 'El nombre de usuario no puede contener tildes', (value) => {
        if (!value) return true;
        return !TILDE_REGEX.test(value);
      })
      .test('no-consecutive-dots', 'No puede contener puntos consecutivos', (value) => {
        if (!value) return true;
        return !value.includes('..');
      })
      .test('no-start-end-dots', 'No puede comenzar ni terminar con punto', (value) => {
        if (!value) return true;
        return !value.startsWith('.') && !value.endsWith('.');
      })
      .test('is-not-url', 'El nombre de usuario no puede ser una URL', (value) => {
        if (!value) return true;
        return isNotDomain(value);
      })
      .test('no-special-chars', 'Solo puede contener letras, números, punto y guion bajo', (value) => {
        if (!value) return true;
        return !SPECIAL_CHARS_REGEX.test(value);
      })
      .test('username-available', 'Este nombre de usuario no está disponible', () => usernameIsAvailable)
      .min(MIN_USERNAME_LENGTH, `Debe contener mínimo ${MIN_USERNAME_LENGTH} caracteres`),

    phone: yup
      .string()
      .required('Teléfono de contacto es un campo requerido')
      .matches(/^\d+$/, 'Debe contener solo números')
      .transform((value) => value?.replace(/\D/g, '')) // Remove non-digits
  });
