import { Icon, IconName } from '@/components';
import { theme } from '@/components/App';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface ButtonSocialMediaPropsI {
  icon: IconName;
  colorIcon: string;
  selected?: boolean;
  originalColor?: boolean;
  onClick: () => void;
}

export const ButtonSocialMedia: FunctionComponent<ButtonSocialMediaPropsI> = ({
  icon,
  colorIcon,
  selected,
  originalColor,
  onClick
}) => {
  return (
    <Container selected={selected} onClick={onClick}>
      <div className="btn-icon">
        <Icon
          name={icon}
          width="22px"
          height="22px"
          color={selected ? colorIcon : theme.colors.grey[700]}
          originalColor={selected ? originalColor : false}
        />
      </div>
    </Container>
  );
};

const Container = styled.div<{ selected?: boolean }>`
  .btn-icon {
    cursor: pointer;
    max-width: 80px;
    height: 40px;
    border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.blue[500] : theme.colors.grey[200])};
    margin: auto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
