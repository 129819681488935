import { v4 as uuidv4 } from 'uuid';
import { ZoneType, AreaColorsType } from './types';
import { theme } from '@/components/App';

export const areaColors: AreaColorsType = {
  blue: theme.colors.blue[500],
  red: theme.colors.red[200],
  green: theme.colors.green[200],
  yellow: theme.colors.yellow[200]
};

export const defaultZone: ZoneType = {
  name: 'Zona 1',
  color: areaColors.blue,
  type: 'polygon',
  data: undefined,
  isSaved: false,
  id: uuidv4()
};
