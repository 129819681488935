import { useState } from 'react';
import { ServiceType } from '../types';

type UseServicesReturnType = {
  services: ServiceType[];
  handleServices: (service: ServiceType[]) => void;
  isEmptyServices: boolean;
};

export const useServices = (): UseServicesReturnType => {
  const [services, setServices] = useState<ServiceType[]>([]);
  const handleServices = (service: ServiceType[]) => setServices(service);

  const isEmptyServices = services.length === 0;

  return { services, handleServices, isEmptyServices };
};
