import React, { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useClient } from '@/hooks';
import {
  EmptyObject,
  ErrorI,
  GetCategoriesI,
  GetVendorInfoI,
  PostVendorInfoI,
  UserWithVendorI,
  VerifyUsernameI
} from '@/types/cyclone/requests';
import { toast } from 'react-toastify';
import { RefetchOptions, useMutation, UseMutationResult, useQuery } from 'react-query';
import { Spinner } from '@/components';
import { CategoryI, SubCategoryI } from '@/types/cyclone/models';
import { trackGenericEvent } from '@/analytics';
import { Control, DeepMap, FieldError, FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSchema, MIN_USERNAME_LENGTH, SPECIAL_CHARS_REGEX, TILDE_REGEX } from '../utils';
import { getBase64FromUrl } from '@/utils';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { updateIntercomPhone } from '@/services/intercom';

type OnboardingContextType = {
  user: UserWithVendorI | undefined;
  mutation?: UseMutationResult<Partial<PostVendorInfoI>, ErrorI, void, unknown>;
  refetch: (options?: RefetchOptions | undefined) => void;
  avatarImage?: string;
  handleAvatarImage: (image: string) => void;
  step: number;
  setStep: (step: number) => void;
  setValue: (name: string, value: any) => void;
  loadingState: boolean;
  //LOCATION----------------------------------------
  country: string | null;
  setCountry: (country: string) => void;
  state: string | null;
  setState: (state: string) => void;
  timezone: string | null;
  setTimeZone: (timezone: string) => void;
  selectedPlace?: string;
  handleSelectPlace: (place?: string) => void;
  notPlaceSelected: boolean;
  handleNotPlaceSelected: () => void;
  onSubmitLocation: () => void;
  addressDetails: string | null;
  setAddressDetails: (details: string) => void;
  //CATEGORIES-------------------------------------
  categories: CategoryI[] | undefined;
  handleFatherCategory: (category: CategoryI) => void;
  fatherCategory: CategoryI | undefined;
  handleChildCategory: (subCategory: SubCategoryI) => void;
  childCategories: SubCategoryI[];
  onSubmitChildCategory: () => void;
  //SITE DATA--------------------------------------
  onSubmitSiteData: (data: FieldValues) => void;
  username: string;
  control: Control<FieldValues>;
  usernameIsAvailable: boolean;
  fullSubmitSiteData: () => void;
  errors: DeepMap<FieldValues, FieldError>;
  togglePrivacyPolicies: () => void;
  privacyPolicies: boolean;
  hasValidationError: boolean;
  validationChecks: Record<string, boolean>;
  //PHONE------------------------------------------
  phone: string;
  onChangePhone: (phone: string) => void;
  onSubmitPhone: () => void;
};

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

interface OnBoardingProviderPropsI {
  children?: React.ReactNode;
}

export const OnboardingProvider: FunctionComponent<OnBoardingProviderPropsI> = ({ children }) => {
  const { client } = useClient();
  const [step, setStep] = useState<number>(1);

  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  const stepParam = queryParams.get('step');

  React.useEffect(() => {
    if (window.location.pathname.includes('wizard')) {
      queryParams.set('step', step.toString());
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
    }
  }, [step, queryParams]);

  React.useLayoutEffect(() => {
    if (stepParam) {
      setStep(parseInt(stepParam));
    }
  }, []);

  const { data: user, refetch } = useQuery(
    ['me', 'info'],
    async () =>
      await client<GetVendorInfoI>(`me/vendor/info`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const mutation = useMutation<Partial<PostVendorInfoI>, ErrorI>(
    (vendor) =>
      client(`me/vendor/info`, 'PUT', {
        isAuthRequired: true,
        data: vendor
      }),
    {
      onSuccess: () => {
        toast.success('Datos guardados');
        refetch();
        if (step === 1 && fatherCategory?.name !== 'Belleza y Estética') setStep(3);
        else setStep(step + 1);
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  // ----------CATEGORIES--------------------------------------------------------------------------------

  const [fatherCategory, setFatherCategory] = useState<CategoryI>();
  const [childCategories, setChildCategories] = React.useState<SubCategoryI[]>([]);

  const handleFatherCategory = (category: CategoryI) => {
    setFatherCategory(category);
    if (category.name === 'Belleza y Estética') {
      setStep(step + 1);
    } else {
      mutation.mutate({ category_ids: [category.id] } as any);
    }
  };

  React.useEffect(() => {
    //Clean state
    if (step === 1) setFatherCategory(undefined);
  }, [step]);

  const handleChildCategory = (subCategory: SubCategoryI) => {
    const isSubCategorySelected = childCategories.some(
      (selectedSubCategory) => selectedSubCategory.name === subCategory.name
    );

    if (isSubCategorySelected) {
      const updatedCategories = childCategories.filter(
        (selectedSubCategory) => selectedSubCategory.name !== subCategory.name
      );
      setChildCategories(updatedCategories);
    } else {
      setChildCategories([...childCategories, subCategory]);
    }
  };

  const onSubmitChildCategory = () => {
    if (childCategories.length === 0) {
      setStep(step + 1);
      // toast.error('Debes elegir al menos una categoria');
    } else {
      const childCategoriesIds = () => {
        const childCategoriesIds = childCategories.map((childCategory) => childCategory.id);
        return childCategoriesIds;
      };
      trackGenericEvent('Button Selfonboard Category Clicked', {
        categories: childCategories
      });
      mutation.mutate({ category_ids: childCategoriesIds() } as any);
    }
  };

  const { data: categories } = useQuery(
    ['categories'],
    async () =>
      await client<GetCategoriesI>(`categories?level=1`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: step === 1 || step === 2
    }
  );

  // ----------LOCATIONS--------------------------------------------------------------------------------

  const [selectedPlace, setSelectedPlace] = useState<string | undefined>(undefined);
  const [notPlaceSelected, setNotPlaceSelected] = useState<boolean>(false);
  const [country, setCountry] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [timezone, setTimeZone] = useState<string | null>(null);
  const [addressDetails, setAddressDetails] = useState<string | null>(null);

  const handleSelectPlace = (place?: string) => {
    if (place) {
      setSelectedPlace(place);
    } else {
      setSelectedPlace(undefined);
    }
  };

  React.useEffect(() => {
    setCountry(user?.vendor.country || null);
    setTimeZone(user?.vendor.timezone || null);
    setState(user?.vendor.state || null);
    setAddressDetails(user?.vendor.address_details || null);
    setSelectedPlace(user?.vendor.address || undefined);
  }, [user]);

  const handleNotPlaceSelected = () => {
    if (notPlaceSelected) {
      setNotPlaceSelected(false);
    } else {
      setSelectedPlace(undefined);
      setNotPlaceSelected(true);
    }
  };

  const onSubmitLocation = () => {
    if (!country) return toast.error('Debes seleccionar un país');
    if (!timezone) return toast.error('Debes seleccionar una zona horaria');

    trackGenericEvent('Button Selfonboard Location Clicked', {
      location: selectedPlace,
      country,
      timezone
    });
    if (selectedPlace) {
      mutation.mutate({
        address: selectedPlace,
        country,
        timezone,
        state,
        address_details: addressDetails
      } as any);
    } else {
      mutation.mutate({ country, timezone, state } as any);

      setNotPlaceSelected(true);
      setTimeout(() => {
        setStep(step + 1);
      }, 500);
    }
  };

  //----------------------SITE DATA --------------------------------------------------------------

  const [usernameIsAvailable, setUsernameIsAvailable] = useState<boolean>(true);
  const [privacyPolicies, setPrivacyPolicies] = useState<boolean>(false);
  const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined);
  const [validationChecks, setValidationChecks] = React.useState({
    length: false,
    noTildes: false,
    noSpecialChars: false,
    noConsecutiveDots: false,
    noStartEndDots: false,
    notUrl: false
  });

  const hasValidationError = Object.values(validationChecks).some((check) => !check);

  const handleAvatarImage = (image: string) => setAvatarImage(image);

  const togglePrivacyPolicies = () => setPrivacyPolicies(!privacyPolicies);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({ resolver: yupResolver(getSchema(usernameIsAvailable)) });

  const username: string = useWatch({ name: 'username', control });

  React.useEffect(() => {
    if (username) {
      setValidationChecks({
        length: username.length >= MIN_USERNAME_LENGTH,
        noTildes: !TILDE_REGEX.test(username),
        noSpecialChars: !SPECIAL_CHARS_REGEX.test(username),
        noConsecutiveDots: !username.includes('..'),
        noStartEndDots: !username.startsWith('.') && !username.endsWith('.'),
        notUrl: !username.split('.').some((part) => ['com', 'co', 'red'].includes(part.toLowerCase()))
      });
    }
  }, [username]);

  const mutationCheckUsername = useMutation<EmptyObject, ErrorI, VerifyUsernameI>(
    (data) =>
      client<EmptyObject>(`vendors/verify_username`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: (data) => {
        if (user?.vendor.username === username) {
          setUsernameIsAvailable(true);
        } else {
          setUsernameIsAvailable(!data.exist);
        }
      }
    }
  );

  React.useEffect(() => {
    if (username) {
      const sanitizedValue = username.replace(/[^a-zA-Z0-9_.-]/g, '');
      const normalizedValue = sanitizedValue.toLowerCase().trim();
      setValue('username', normalizedValue, { shouldValidate: true });
    }
  }, [username, setValue]);

  React.useEffect(() => {
    if (username && username.length > MIN_USERNAME_LENGTH) mutationCheckUsername.mutate({ username });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [username]);

  React.useEffect(() => {
    setValue('profession', user?.vendor.professional_title || '');
    setValue('username', user?.vendor.username || '');
    setValue('storefront_name', user?.vendor.vendor_storefront?.name || '');
    setValue('phone', user?.phone || '');
  }, [user, setValue]);

  const onSubmitSiteData: SubmitHandler<FieldValues> = async (data) => {
    if (hasValidationError) {
      toast.error('Por favor revisa tu nombre de usuario');
      return;
    } else if (!privacyPolicies) {
      toast.error('Por favor acepta los términos y condiciones');
      return;
    } else {
      const avatarImageBase64 = avatarImage ? ((await getBase64FromUrl(avatarImage)) as string) : '';

      const request: Partial<PostVendorInfoI> = {
        professional_title: data.profession,
        username: data.username,
        storefront_name: data.storefront_name,
        avatar_url: avatarImageBase64
      };

      trackGenericEvent('Button Selfonboard Site Data Clicked', {
        username: data.username,
        profession: data.profession,
        storefront_name: data.storefront_name
      });

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      mutation.mutate(request as any);
    }
  };

  const fullSubmitSiteData = () => {
    handleSubmit(onSubmitSiteData, (e) => {
      if (e) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const errors = Object.values(e).map((error) => error?.message);
        toast.error(errors.join('\r\n'));
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        toast.error('Error al guardar los datos');
      }
    })();
  };

  // ---------------------PHONE-------------------------------------------------------------------

  const [phone, setPhone] = useState<string>('');

  const onChangePhone = (phone: string) => {
    setPhone(phone);
  };

  React.useEffect(() => {
    setPhone(user?.phone || '');
  }, [user]);

  const onSubmitPhone = () => {
    const isARValid = parsePhoneNumber(phone.toString(), { regionCode: 'AR' });
    const isUYValid = parsePhoneNumber(phone.toString(), { regionCode: 'UY' });
    const isCLValid = parsePhoneNumber(phone.toString(), { regionCode: 'CL' });
    const isValid = isARValid.valid || isUYValid.valid || isCLValid.valid;

    if (!isValid) {
      return toast.error('El número de teléfono no es válido');
    } else {
      trackGenericEvent('Button Selfonboard Phone Clicked', {
        phone
      });
      updateIntercomPhone(phone);
      mutation.mutate({ phone } as any);
    }
  };

  // ---------------------------------------------------------------------------------------------------

  const [loadingState, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    const isLoading = mutation.isLoading || mutationCheckUsername.isLoading;
    setLoadingState(isLoading);
  }, [mutation.isLoading, mutationCheckUsername.isLoading]);

  if (!user) return <Spinner />;

  return (
    <OnboardingContext.Provider
      value={{
        user,
        mutation,
        refetch,
        setValue,
        step,
        setStep,
        loadingState,
        //LOCATIONS-----------
        handleSelectPlace,
        country,
        setCountry,
        state,
        setState,
        timezone,
        setTimeZone,
        onSubmitLocation,
        notPlaceSelected,
        handleNotPlaceSelected,
        selectedPlace,
        addressDetails,
        setAddressDetails,
        //CATEGORIES-----------
        categories,
        handleFatherCategory,
        fatherCategory,
        handleChildCategory,
        childCategories,
        onSubmitChildCategory,
        //SITE DATA-----------
        onSubmitSiteData,
        username,
        control,
        usernameIsAvailable,
        avatarImage,
        handleAvatarImage,
        fullSubmitSiteData,
        privacyPolicies,
        togglePrivacyPolicies,
        errors,
        hasValidationError,
        validationChecks,
        //PHONE----------------
        phone,
        onChangePhone,
        onSubmitPhone
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
