import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BookingStatusT } from '@/types/cyclone/models';

type ItemTableProps = {
  status: BookingStatusT;
  name: string;
  img: string;
  activity: string;
  date: string;
  price?: string;
};
export const ItemTable: FunctionComponent<ItemTableProps> = ({
  status,
  name,
  img,
  activity,
  date,
  price
}) => {
  return (
    <Container>
      <Top>
        <NameContainer>
          <Image src={img} />
          <Name>{name}</Name>
        </NameContainer>
        {price && <Price>{price == '0' ? <FreeText>¡Sin costo!</FreeText> : `$${price}`}</Price>}
      </Top>
      <Middle>{activity}</Middle>
      <Bottom>
        <DateContainer>
          <Date>{date}</Date>
        </DateContainer>
        <span>{status}</span>
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 20px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Price = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
`;

const NameContainer = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 14px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  object-position: center;
  object-fit: cover;
`;

const Name = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;

const Middle = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 12px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateContainer = styled.div`
  display: flex;
  > :first-child {
    margin-right: 32px;
  }
`;

const Date = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
`;

const FreeText = styled.span`
  color: ${({ theme }) => theme.colors.blue[500]};
`;
