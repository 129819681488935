import dayjs from 'dayjs';
import { DAYS_OF_WEEK } from '@/constants';
import { CalendarOptions } from '@fullcalendar/react';
import { UniqueEventScheduleI } from '@/types/cyclone/models';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { VirtualSchedulesAvailabilityI } from '@/types/cyclone/requests';

dayjs.extend(timezone);
dayjs.locale('es');
dayjs.extend(utc);

export const formatDay = (day: number): number => (day === 0 ? 6 : day - 1);

export const getDateDayName = (date: string): string => dayjs(date).locale('es').format('DD [de] MMMM');

/**
 * Combines a date and time, returns a date.
 * The DB that consumes cyclone most of the schemas has separate columns for date and times,
 * use this function if you want to know the result date of this cases.
 */
export const getDayWithTime = (date: string, time: string): Date => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  return dayjs(date).add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes').toDate();
};
// dd

/**
 * The time in Javascript has seconds, use this function when you want to remove the seconds
 */
export const getTime = (time: string): string => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  return `${hours}:${minutes}`;
};

/**
 * Given an hour string, it returns an hour string rounded of to the
 * closer previous or following five minutes interval
 */
export const roundToNearestFiveMinutes = (hour: string): string => {
  const coeff = 1000 * 60 * 5;
  const currentDate = new Date();
  currentDate.setHours(Number(hour.split(':')[0]), Number(hour.split(':')[1].split(' ')[0]));
  const rounded = new Date(Math.round(currentDate.getTime() / coeff) * coeff);

  return dayjs(rounded.toISOString()).format('HH:mm');
};

/**
 * Use this function to change the interval for Event Instances.
 */
export const getDatesInterval = ({
  startDate,
  interval
}: {
  startDate?: Date;
  interval?: number;
}): [string, string] => {
  const start = dayjs(startDate).format('YYYY-MM-DD');
  if (!interval) {
    return [start, start];
  }

  const end = dayjs(start).add(interval, 'days').format('YYYY-MM-DD');
  return [start, end];
};

/**
 * Get current time subtracting 15minutes to get lapse for the vendor to see the events
 */
export const getTimesInterval = (): [string, string] => {
  const today = new Date();
  const time = today.getHours() + ':' + today.getMinutes();
  const endTime = '20:00'; // The end time will be always at 20:00pm

  return [time, endTime];
};

export const getDayWeekName = (date: string | Date): string => {
  const dateFormat = dayjs(date);

  return DAYS_OF_WEEK[formatDay(dateFormat.day())];
};

export const fullCalendarConfig: CalendarOptions = {
  height: '100%',
  headerToolbar: false,
  contentHeight: 'auto',
  selectOverlap: true,
  slotEventOverlap: true,
  eventOverlap: true,
  aspectRatio: 1,
  locale: 'es',
  dayHeaderFormat: { weekday: 'long', day: 'numeric', omitCommas: true },
  allDaySlot: false,
  firstDay: 1,
  listDaySideFormat: false,
  listDayFormat: {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    omitCommas: true
  },
  slotDuration: '01:00:00',
  slotMinTime: '07:00:00',
  slotMaxTime: '24:00:00',
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    meridiem: false,
    hour12: false
  },
  views: {
    timeGridOneDay: {
      type: 'timeGrid',
      duration: { days: 1 }
    },
    timeGridThreeDay: {
      type: 'timeGrid',
      duration: { days: 3 }
    },
    listView: {
      type: 'list',
      duration: { days: 7 }
    }
  }
};

// Util function used to filter out repeated elements from array
const removeDuplicatesHours = (value: string, index: number, self: string[]): boolean => {
  return self.indexOf(value) === self.lastIndexOf(value);
};

// Parse hour string and convert it to Date
export const parseHour = (hour: string): Date => {
  const date_time = `1997-08-13 ${hour}`;
  const date = new Date();
  date.setHours(Number(date_time.substring(11, 13)));
  date.setMinutes(Number(date_time.substring(14, 16)));

  return date;
};

// Add minutes to an hour string
export const addMinutesToHour = (hour: string, minutes: number): string => {
  const date = parseHour(hour);
  date.setMinutes(date.getMinutes() + minutes);
  return date.toTimeString().substring(0, 5) + ':00';
};

// Generate hours intervals between an start hour and an end hour
export const generateIntervals = (
  startTime: string,
  endTime: string,
  options?: {
    inclusiveCheck?: boolean;
    intervalMinuteLeap?: number;
  }
): string[] => {
  const inclusiveCheck = options?.inclusiveCheck || false;
  const intervalMinuteLeap = options?.intervalMinuteLeap || 10;

  const getTimeIntervals = (time1: Date, time2: Date) => {
    const timeIntervals = [];
    while (inclusiveCheck ? time1 <= time2 : time1 < time2) {
      timeIntervals.push(time1.toTimeString().substring(0, 5) + ' hs');
      time1.setMinutes(time1.getMinutes() + intervalMinuteLeap);
    }
    return timeIntervals;
  };

  return getTimeIntervals(parseHour(startTime), parseHour(endTime));
};

// Generate object hours intervals to be used on UI Selects
export const getHoursIntervals = (
  schedules: {
    start_time: string;
    end_time: string;
  }[],
  intervalMinuteLeap?: number
): {
  label: string;
  value: string;
}[] => {
  const defaultStartHour = '07:00:00';
  const defaultEndHour = '24:00:00';

  const intervals = schedules.map((schedule) => {
    if (schedule.start_time && schedule.end_time) {
      return generateIntervals(schedule.start_time, schedule.end_time, { intervalMinuteLeap });
    } else if (schedule.start_time && !!!schedule.end_time) {
      return generateIntervals(schedule.start_time, defaultEndHour, { intervalMinuteLeap });
    } else {
      return generateIntervals(defaultStartHour, defaultEndHour, { intervalMinuteLeap });
    }
  });

  return intervals
    .reduce((acc, val) => acc.concat(val), [])
    .filter(removeDuplicatesHours)
    .map((hour) => ({
      label: hour,
      value: hour.split(' ')[0]
    }));
};

// Validate that all the schedules on a single day don't overlap each other
export const validateSchedules = (
  schedules: {
    start_time: {
      label: string;
      value: string;
    };
    end_time: {
      label: string;
      value: string;
    };
  }[]
): {
  error: boolean;
  message: string;
} => {
  let validation = {
    error: false,
    message: ''
  };

  const filteredSchedules = schedules
    .filter((schedule) => {
      return schedule.start_time.value && schedule.end_time.value;
    })
    .map((schedule) => {
      return {
        start_time: schedule.start_time.value,
        end_time: schedule.end_time.value
      };
    });

  if (filteredSchedules.length > 1) {
    const allIntervals = filteredSchedules
      .map((schedule) => {
        return generateIntervals(schedule.start_time, schedule.end_time);
      })
      .reduce((acc, val) => acc.concat(val), []);

    const uniqueIntervals = allIntervals.filter(removeDuplicatesHours);

    if (allIntervals.length !== uniqueIntervals.length) {
      validation = {
        error: true,
        message: 'Los horarios seleccionados no pueden sobreponerse entre si.'
      };
    }
  }

  return validation;
};

// Given a date, returns the dates of the days of the week in which the
// given date exists, starting from sunday
export const getWeekDaysByDate = (dateParam: Date) => {
  // Clone the date to avoid mutations
  const currentDate = new Date(dateParam.getTime());

  // Adjust for timezone offset
  currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset());

  const weekDays = [];

  for (let i = 0; i < 7; i++) {
    // Calculate the date for the current weekday
    const first = currentDate.getDate() - currentDate.getDay() + i;

    // Create a new date object to avoid mutations
    const dayDate = new Date(currentDate.getTime());
    dayDate.setDate(first);

    // Convert to ISO string and slice to get the date part
    const day = dayDate.toISOString().slice(0, 10);

    weekDays.push(day);
  }

  return weekDays;
};

export const getMinutesBetweenDates = (date1: Date, date2: Date): number => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 60));
};

export const isBetweenDates = (compareDate: Date, lowestDate: Date, highestDate: Date): boolean => {
  return compareDate >= lowestDate && compareDate <= highestDate;
};

export const getFormatDuration = (duration: number, short?: boolean) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hourText = hours > 0 ? `${hours}${short ? 'h' : ` hora${hours > 1 ? 's' : ''}`}` : '';
  const minuteText = minutes > 0 ? `${minutes}${short ? 'min' : ` minuto${minutes > 1 ? 's' : ''}`}` : '';

  if (hours > 0 && minutes > 0) {
    return `${hourText} ${minuteText}`;
  } else if (hours > 0) {
    return hourText;
  } else {
    return minuteText;
  }
};

// Get upcoming event date from a list of schedules
export const getUpcomingEventDate = (schedules: UniqueEventScheduleI[]): UniqueEventScheduleI => {
  const today = new Date();

  const sortedSchedules = schedules.sort((a, b) => {
    const dateA = new Date(a.start_at);
    const dateB = new Date(b.start_at);
    return dateA.getTime() - dateB.getTime();
  });
  const upcomingEvent = sortedSchedules.find((schedule) => {
    const scheduleDate = new Date(schedule.start_at);
    return scheduleDate >= today;
  });

  return upcomingEvent || schedules[0];
};

export const getTimezoneOffset = (timezone: string): number => {
  const now = new Date();
  const utcOffset = -now.getTimezoneOffset() / 60;
  const timezoneOffset = getTimezoneOffsetFromString(timezone);
  return utcOffset - timezoneOffset;
};

export const getTimezoneOffsetFromString = (timezone: string): number => {
  const match = timezone.match(/([+-])(\d{2}):(\d{2})/);
  if (match) {
    const sign = match[1] === '+' ? 1 : -1;
    const hours = parseInt(match[2], 10);
    const minutes = parseInt(match[3], 10);
    return sign * (hours + minutes / 60);
  }
  return 0;
};

export const convertToSpecifiedTimezone = (
  events: VirtualSchedulesAvailabilityI[],
  timezone = 'America/Argentina/Buenos_Aires'
): VirtualSchedulesAvailabilityI[] => {
  return events.map((event) => {
    // Convert UTC date & time to the specified timezone
    const startDateTimeInSpecifiedTimezone = dayjs.utc(`${event.start_at}T${event.start_time}`).tz(timezone);
    const endDateTimeInSpecifiedTimezone = dayjs.utc(`${event.end_at}T${event.end_time}`).tz(timezone);

    return {
      ...event,
      start_at: startDateTimeInSpecifiedTimezone.format('YYYY-MM-DD'),
      end_at: endDateTimeInSpecifiedTimezone.format('YYYY-MM-DD'),
      start_time: startDateTimeInSpecifiedTimezone.format('HH:mm'),
      end_time: endDateTimeInSpecifiedTimezone.format('HH:mm')
    };
  });
};

export const getGMTOffset = (timezone: string): string => {
  const offset = dayjs().tz(timezone).utcOffset() / 60;
  const sign = offset >= 0 ? '+' : '-';
  const absOffset = Math.abs(offset);
  return `GMT${sign}${absOffset}`;
};
