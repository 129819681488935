import { FunctionComponent } from 'react';
import { useNavbarItems } from '@/hooks';
import { Row } from '../Row';

export const Footer: FunctionComponent = () => {
  const { footerItems } = useNavbarItems();

  return (
    <footer className="h-14 w-full bg-gray-100">
      <div className="max-w-screen m-auto w-[90%] h-14">
        <Row align="center" className="justify-center w-full h-full lg:justify-between">
          <p className="text-sm text-gray-500">{''}</p>
          <Row className="hidden lg:block">
            {footerItems.map(({ label, link }) => (
              <a
                className="text-sm text-gray-500 mr-4 last:mr-0 hover:text-blue transition-colors duration-200"
                key={label}
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                {label}
              </a>
            ))}
          </Row>
        </Row>
      </div>
    </footer>
  );
};
