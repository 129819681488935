import React, { FunctionComponent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { NavbarItem } from '..';
import { Row } from '@/components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/DropdownMenu';

type ProfileDropdownMenuProps = {
  avatar?: string;
  dropdownItems: NavbarItem[];
};

export const ProfileDropdownMenu: FunctionComponent<ProfileDropdownMenuProps> = ({
  dropdownItems,
  avatar
}) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);

  const toggleDropdown = () => setShowDropdownMenu(!showDropdownMenu);

  const closeDropdownMenu = useCallback(() => {
    if (showDropdownMenu) setShowDropdownMenu(false);
  }, [showDropdownMenu]);

  const handleItemClick = (callback: () => void, onClick?: () => void) => {
    callback();
    onClick?.();
  };

  return (
    <DropdownMenu open={showDropdownMenu} onOpenChange={(open) => setShowDropdownMenu(open)}>
      <DropdownMenuTrigger onClick={() => setShowDropdownMenu(!showDropdownMenu)}>
        <button onClick={toggleDropdown} className="flex items-center text-inherit">
          {avatar ? (
            <img src={avatar} className="w-8 h-8 mr-2.5 rounded-full object-cover object-center" />
          ) : (
            <FontAwesomeIcon icon={faUserCircle} className="w-8 h-8 mr-2.5 text-gray-300" />
          )}
          <FontAwesomeIcon icon={faChevronDown} size="xs" color="#4A4A4A" />
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent side="bottom" sideOffset={10} align="end" alignOffset={10}>
        {dropdownItems.map(({ label, onClick, icon, link }) => {
          return (
            <DropdownMenuItem
              key={label}
              className={classNames('cursor-pointer !text-[#212121] hover:bg-blue hover:!text-white')}
              asChild
            >
              <Link to={link} key={label} onClick={() => handleItemClick(closeDropdownMenu, onClick)}>
                <Row align="center" gap={8} className="cursor-pointer">
                  {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
                  {label}
                </Row>
              </Link>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
