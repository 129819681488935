import { Icon, IconName } from '@/components';
import { theme } from '@/components/App';
import React from 'react';
import styled from 'styled-components';

type SetDrawingTool = {
  onClick: () => void;
  height: number;
  label: string;
  icon: string;
  isSelected: boolean;
  disabled?: boolean;
};

export const SetDrawingTool: React.FC<SetDrawingTool> = ({
  onClick,
  height,
  label,
  icon,
  isSelected,
  disabled = false
}) => {
  return (
    <DrawingTool onClick={onClick} disabled={disabled}>
      <DrawingToolLabel>{label}</DrawingToolLabel>
      <DrawingIconBackground selected={isSelected}>
        <Icon
          name={icon as IconName}
          height={`${height}px`}
          color={isSelected ? (theme.colors.white as string) : (theme.colors.blue[500] as string)}
        />
      </DrawingIconBackground>
    </DrawingTool>
  );
};

const DrawingTool = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  margin-right: 1rem;
`;

const DrawingIconBackground = styled.div<{ selected: boolean }>`
  height: 40px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.blue[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.blue[500] : theme.colors.white)};
  transition: background-color 0.2s ease-in-out;
  > svg {
    stroke: ${({ theme }) => theme.colors.white};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[500]};
    > svg > path {
      fill: ${({ theme }) => theme.colors.white};
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

const DrawingToolLabel = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 6px;
`;
