import React, { FunctionComponent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { WeekDays, Icon, Row } from '@/components';
import { scrollBarTW, scrollCSS, theme } from '@/components/App';
import { ScheduleStatus, ScheduleType } from '@/containers/Agenda/types';
import { DialogFooter } from '@/components/Dialog';

type ScheduleProps = {
  recurringDays: number[];
  schedules: ScheduleType[];
  handleSchedule: (schedule: ScheduleType) => void;
  handleSaveSchedules: (schedules: ScheduleType[]) => void;
  closeModal: () => void;
  handleInitialSchedules: (schedule: ScheduleType[]) => void;
};

export const Schedule: FunctionComponent<ScheduleProps> = ({
  recurringDays,
  schedules,
  handleSchedule,
  handleSaveSchedules,
  closeModal,
  handleInitialSchedules
}) => {
  const preSelectedSchedules = schedules.filter(({ status }) => status === ScheduleStatus.SELECTED);

  const handleSubmit = () => {
    handleSaveSchedules(preSelectedSchedules);
    closeModal();
  };

  const disabledScheduleStatus = [ScheduleStatus.TAKEN, ScheduleStatus.DOUBLE_TAKEN, ScheduleStatus.OTHER];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleInitialSchedules(schedules), [handleInitialSchedules]);

  return (
    <>
      <div className="flex-1 pb-2">
        <RecurringContainer>
          <span>Para los días</span>
          <WeekDays daysIn={recurringDays} />
        </RecurringContainer>
        <Row
          className={`w-full flex-nowrap pb-4 mb-4 border-b border-b-[#ECEEF0] max-h-[57px] min-h-[57px] overflow-x-auto ${scrollBarTW}`}
        >
          {preSelectedSchedules.length === 0 ? (
            <EmptySchedules>No tienes horarios seleccionados</EmptySchedules>
          ) : (
            preSelectedSchedules.map((schedule, i) => (
              <Bullet key={i}>
                <span>{schedule.label}</span>
                <ButtonDeleteSchedule onClick={() => handleSchedule(schedule)}>
                  <Icon name="close" width="12px" height="12px" color={theme.colors.white as string} />
                </ButtonDeleteSchedule>
              </Bullet>
            ))
          )}
        </Row>
        <SchedulesContainer>
          {schedules?.map((schedule, i) => (
            <ScheduleItem
              key={i}
              onClick={() => handleSchedule(schedule)}
              status={schedule.status}
              disabled={disabledScheduleStatus.includes(schedule.status)}
            >
              {schedule.label}
            </ScheduleItem>
          ))}
        </SchedulesContainer>
      </div>
      <DialogFooter>
        <button
          className="rounded-[5px] h-12 bg-[#0072FB] text-white font-medium w-full"
          onClick={handleSubmit}
        >
          Guardar
        </button>
      </DialogFooter>
    </>
  );
};

const RecurringContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[700]};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  > span {
    margin-right: 1rem;
    font-size: 0.9rem;
  }
`;

const EmptySchedules = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 0.9rem;
`;

const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.blue[300]};
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.35rem 0.5rem;
  font-size: 0.8rem;
  margin: 5px;
`;

const ButtonDeleteSchedule = styled.button`
  line-height: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  margin-left: 8px;
  padding: 3px;
`;

const SchedulesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  max-height: 200px;
  overflow-y: auto;
  ${scrollCSS};
  ${({ theme }) => theme.breakpoint('md')} {
    max-height: none;
    overflow-y: visible;
  }
`;

const ScheduleItem = styled.button<{ status: ScheduleStatus }>`
  padding: 5px 11px;
  border-radius: 4px;
  font-size: 0.9rem;
  ${({ status, theme }) => {
    if (status === ScheduleStatus.FREE)
      return css`
        color: ${theme.colors.grey[700]};
        border: 1px solid transparent;
      `;
    if (status === ScheduleStatus.SELECTED)
      return css`
        background: ${theme.colors.blue[100]};
        color: ${theme.colors.black};
        border: 1px solid ${theme.colors.blue[300]};
      `;
    if (status === ScheduleStatus.TAKEN || status === ScheduleStatus.DOUBLE_TAKEN)
      return css`
        background: ${theme.colors.grey[100]};
        color: ${theme.colors.grey[700]};
        border: 1px solid transparent;
      `;
    if (status === ScheduleStatus.OTHER)
      return css`
        background: ${theme.colors.pink};
        color: ${theme.colors.grey[700]};
        border: 1px solid transparent;
      `;
  }}
`;
