import { Column, Grid, Row } from '@/components';
import { InputDatepicker, InputTimePicker, InputV2 } from '@/components/Input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FunctionComponent, useCallback } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { FormValues } from './ModalDuplicate';

type Props = {
  dates: UseFormGetValues<FormValues>;
  setDates: (data: RepeatDate[]) => void;
  setDisabled: (value: boolean) => void;
};

export type RepeatDate = { date: Date; start: string; end: string };

const REPEAT_PERIODS = ['Diario', 'Semanal', 'Mensual'];
const REPEAT_DAYS = [
  { label: 'L', value: 1 },
  { label: 'M', value: 2 },
  { label: 'M', value: 3 },
  { label: 'J', value: 4 },
  { label: 'V', value: 5 },
  { label: 'S', value: 6 },
  { label: 'D', value: 0 }
];
const REPEAT_TIMES = ['Hasta', 'Durante'];

export const Repeated: FunctionComponent<Props> = ({ setDates, dates, setDisabled }) => {
  const dateValues = dates('dates');
  const [repeatedDates, setRepeatedDates] = React.useState<RepeatDate[]>([
    {
      date: dateValues[dateValues.length - 1].date,
      start: dateValues[dateValues.length - 1].start,
      end: dateValues[dateValues.length - 1].end
    }
  ]);
  const [selectedRepeatPeriod, setSelectedRepeatPeriod] = React.useState<string>(REPEAT_PERIODS[1]);
  const [selectedRepeatDay, setSelectedRepeatDay] = React.useState<number[]>([1]);
  const [selectedRepeatTime, setSelectedRepeatTime] = React.useState<string>(REPEAT_TIMES[1]);
  const [repeatTimes, setRepeatTimes] = React.useState<number>(1);
  const [selectedRepeatEndDate, setSelectedRepeatEndDate] = React.useState<Date>(
    dayjs(dateValues[dateValues.length - 1].date)
      .add(1, 'day')
      .toDate()
  );

  const buildRepeatDates = useCallback(
    (firstDate: Date) => {
      const newDates = [{ ...repeatedDates[0] }]; // Preserve the first date
      const period =
        selectedRepeatPeriod === 'Mensual' ? 'month' : selectedRepeatPeriod === 'Semanal' ? 'week' : 'day';
      const times = repeatTimes || 1;

      const getDaysRecurrence = (days: number[]) => {
        const daysRecurrence = [];
        for (let i = 0; i < days.length; i++) {
          daysRecurrence.push(days[i]);
          if (i < days.length - 1) {
            daysRecurrence.push(daysRecurrence[daysRecurrence.length - 1] + 1);
          }
        }
        return daysRecurrence;
      };

      if (selectedRepeatTime === 'Hasta' && selectedRepeatEndDate) {
        let currentDate = dayjs(firstDate);

        if (selectedRepeatPeriod === 'Semanal') {
          const daysRecurrence = getDaysRecurrence(selectedRepeatDay);

          while (currentDate.isBefore(selectedRepeatEndDate, 'day')) {
            for (const day of daysRecurrence) {
              const nextDate = currentDate.day(day).toDate();
              if (nextDate <= selectedRepeatEndDate) {
                newDates.push({ date: nextDate, start: newDates[0].start, end: newDates[0].end });
              }
            }
            currentDate = currentDate.add(1, 'week');
          }
        } else {
          while (currentDate.isBefore(selectedRepeatEndDate, 'day')) {
            currentDate = currentDate.add(1, period);
            newDates.push({ date: currentDate.toDate(), start: newDates[0].start, end: newDates[0].end });
          }
        }

        return newDates;
      } else {
        for (let i = 1; i <= times; i++) {
          // Start at 1 to not repeat the first date
          if (selectedRepeatPeriod === 'Semanal') {
            const daysRecurrence = getDaysRecurrence(selectedRepeatDay);

            for (const day of daysRecurrence) {
              const nextDate = dayjs(firstDate).add(i, 'week').day(day).toDate();
              newDates.push({ date: nextDate, start: newDates[0].start, end: newDates[0].end });
            }
          } else {
            const nextDate = dayjs(firstDate).add(i, period).toDate();
            newDates.push({ date: nextDate, start: newDates[0].start, end: newDates[0].end });
          }
        }
        return newDates;
      }
    },
    [
      repeatTimes,
      selectedRepeatPeriod,
      selectedRepeatTime,
      selectedRepeatEndDate,
      selectedRepeatDay,
      repeatedDates
    ]
  );

  React.useEffect(() => {
    const firstDate = repeatedDates[0]?.date;
    if (!firstDate) return;

    const generatedDates = buildRepeatDates(firstDate);

    if (JSON.stringify(generatedDates) !== JSON.stringify(repeatedDates)) {
      setRepeatedDates(generatedDates);
    }
  }, [
    repeatTimes,
    selectedRepeatPeriod,
    selectedRepeatTime,
    selectedRepeatEndDate,
    selectedRepeatDay,
    buildRepeatDates
  ]);

  React.useEffect(() => {
    if (JSON.stringify(dates) !== JSON.stringify(repeatedDates)) {
      setDates(repeatedDates);
    }
  }, [repeatedDates, setDates]);

  React.useEffect(() => {
    setDisabled(repeatTimes > 20 || repeatedDates.length > 20);
  }, [repeatTimes, repeatedDates, setDisabled]);

  return (
    <Column className="w-full">
      <span className="text-[#868686] text-sm">A partir del</span>
      <Column gap={16} className="py-2">
        <Row align="center" gap={8} justify="space-between">
          <div className="w-[30%] flex items-center">
            <InputDatepicker
              setDate={(date) => setRepeatedDates([{ ...repeatedDates[0], date }])}
              date={repeatedDates[0].date}
            />
          </div>
          <div className="w-[70%] flex items-center">
            <span className="mr-2 text-[#868686] text-sm">de</span>
            <InputTimePicker
              initialValue={repeatedDates[0].start}
              onTimeChange={(time) => {
                setRepeatedDates([{ ...repeatedDates[0], start: time }]);
              }}
            />
            <span className="mx-2 text-[#868686] text-sm">a</span>
            <InputTimePicker
              initialValue={repeatedDates[0].end}
              onTimeChange={(time) => {
                setRepeatedDates([{ ...repeatedDates[0], end: time }]);
              }}
            />
          </div>
        </Row>

        <Row align="center" gap={16}>
          <span className="whitespace-nowrap text-[#868686] text-sm">Se repite</span>
          <div className="w-[40%]">
            <Select
              defaultValue={selectedRepeatPeriod}
              onValueChange={(value) => setSelectedRepeatPeriod(value)}
            >
              <SelectTrigger className="bg-white cursor-pointer rounded border-[#212121]/40 hover:border-[#0072FB]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {REPEAT_PERIODS.map((period, i) => (
                  <SelectItem key={i} value={period}>
                    {period}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </Row>

        {selectedRepeatPeriod === 'Semanal' && (
          <Column>
            <span className="text-[#868686] text-sm mb-2">Día de la semana</span>
            <Row align="center" gap={16} className="w-full">
              {REPEAT_DAYS.map(({ label, value }, i) => (
                <div
                  key={i}
                  className={classNames(
                    'rounded-full w-8 h-8 flex items-center justify-center cursor-pointer',
                    {
                      'bg-[#0072FB] text-white': selectedRepeatDay.includes(value),
                      'text-[#868686] bg-[#F0F0F0]': !selectedRepeatDay.includes(value)
                    }
                  )}
                  onClick={() =>
                    setSelectedRepeatDay(
                      selectedRepeatDay.includes(value)
                        ? selectedRepeatDay.filter((day) => day !== value)
                        : [...selectedRepeatDay, value]
                    )
                  }
                >
                  <span>{label}</span>
                </div>
              ))}
            </Row>
          </Column>
        )}

        <Row align="center" gap={8} className="w-full">
          <div role="tablist" className="tabs tabs-boxed">
            {REPEAT_TIMES.map((time, i) => (
              <a
                key={i}
                role="tab"
                className={classNames('tab', {
                  'tab-active !text-white': selectedRepeatTime === time,
                  '!text-[#868686]': selectedRepeatTime !== time
                })}
                onClick={() => setSelectedRepeatTime(time)}
              >
                {time}
              </a>
            ))}
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="sm" fixedWidth color="#868686" />
          <div className="w-[40%]">
            {selectedRepeatTime === 'Hasta' ? (
              <InputDatepicker
                setDate={setSelectedRepeatEndDate}
                date={selectedRepeatEndDate || new Date()}
              />
            ) : (
              <InputV2
                id="repeat-times"
                type="number"
                onChange={(e) => setRepeatTimes(+e.target.value)}
                value={repeatTimes}
                postfix={
                  selectedRepeatPeriod === 'Semanal'
                    ? ' semanas'
                    : selectedRepeatPeriod === 'Mensual'
                    ? ' meses'
                    : ' dias'
                }
              />
            )}
          </div>
        </Row>
      </Column>
      <Grid as="div" gap={8} columns={7} className="border border-dashed rounded-md w-full my-4 p-2">
        {repeatedDates.length > 7 ? (
          <>
            {repeatedDates.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className="h-20 flex flex-col items-center justify-center w-full border rounded-md"
              >
                <span className="text-[#868686] text-xs">{dayjs(item.date).format('MMM')}</span>
                <span className="text-[#212121] text-lg">{dayjs(item.date).date()}</span>
                <span className="text-[#868686] text-xs">{dayjs(item.date).format('ddd')}</span>
              </div>
            ))}
            <div className="h-20 flex flex-col items-center justify-center w-full">
              <span className="text-[#212121] text-sm">{`+${repeatedDates.length - 6}`}</span>
            </div>
            {repeatedDates.slice(-3).map((item, index) => (
              <div
                key={index}
                className="h-20 flex flex-col items-center justify-center w-full border rounded-md"
              >
                <span className="text-[#868686] text-xs">{dayjs(item.date).format('MMM')}</span>
                <span className="text-[#212121] text-lg">{dayjs(item.date).date()}</span>
                <span className="text-[#868686] text-xs">{dayjs(item.date).format('ddd')}</span>
              </div>
            ))}
          </>
        ) : (
          repeatedDates.map((item, index) => (
            <div
              key={index}
              className="h-20 flex flex-col items-center justify-center w-full border rounded-md"
            >
              <span className="text-[#868686] text-xs">{dayjs(item.date).format('MMM')}</span>
              <span className="text-[#212121] text-lg">{dayjs(item.date).date()}</span>
              <span className="text-[#868686] text-xs">{dayjs(item.date).format('ddd')}</span>
            </div>
          ))
        )}
      </Grid>
    </Column>
  );
};
