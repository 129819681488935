import { Column } from '@/components';
import { InputPhone } from '@/components/Input';
import { useOnboarding } from '@/containers/SelfOnboarding/contexts';
import { useWindowResize } from '@/hooks';
import { SupportedCountriesE } from '@/types/cyclone/models';
import classNames from 'classnames';
import React from 'react';
import { NextStepButton } from '../components/Navbar/Navbar';

export const Phone: React.FC = () => {
  const { phone, onChangePhone, country } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)] max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
          !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-3xl mt-16 text-center text-balance">
        Teléfono de contacto de tu negocio
      </h2>
      <p className="text-base text-[#626262] font-medium mt-2 text-center text-balance">
        Indicalo para que tus clientes te contacten por Whatsapp
      </p>
      <Column
        gap={16}
        className="shadow-[0_0_10px_0_#62626233] rounded-lg md:w-1/2 w-full py-4 pb-6 px-6 mt-16"
      >
        <p className="font-medium text-lg">Contacto</p>

        <InputPhone
          inputPhoneProps={{
            value: phone,
            onChange: (formattedValue: string) => onChangePhone(formattedValue),
            countryCodeEditable: false
          }}
          code={
            country === SupportedCountriesE.ARGENTINA
              ? 'ar'
              : country === SupportedCountriesE.CHILE
              ? 'cl'
              : 'uy'
          }
        />
        <NextStepButton fullWidth />
      </Column>
    </Column>
  );
};
