import React, { FunctionComponent, useState } from 'react';
import { Icon, Row, Table } from '@/components';
import { theme } from '@/components/App';
import { ActivityUser, Image, ProfileImageContainer } from '@/containers/Activities/styles';
import { BookingI, PaymentStatusE, UserI, VirtualEventInstancesI } from '@/types/cyclone/models';
import styled from 'styled-components';
import { ContactModal } from '@/containers/EventDetails/components';
import { RescheduleModal } from '@/containers/EventDetails/components';
import { IconPayment } from './IconPayment';
import { UserCard } from './UserCard';
import { useMutation } from 'react-query';
import { DeleteBookingEventInstanceI, ErrorI } from '@/types/cyclone/requests';
import { useClient, useWindowResize } from '@/hooks';
import { toast } from 'react-toastify';
import { useAuth, useConfirmation } from '@/contexts';
import { StatusOfflinePayment } from '@/containers/Activities/components/StatusOfflinePayment';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const headers = ['Usuario', 'Medio de pago', '¿Pagado?', '¿Asistió?', 'Acciones'];

type TableUsersProps = {
  bookings: BookingI[];
  refetch: () => void;
  eventInstance: VirtualEventInstancesI;
};

type EventInstanceStatus = {
  name: string;
  color: string;
};

const bookingStatus: Record<string, EventInstanceStatus> = {
  confirmed: {
    name: 'Confirmada',
    color: theme.suscription.active
  },
  canceled: {
    name: 'Cancelada',
    color: theme.suscription.expired
  },
  refunded: {
    name: 'Devolución',
    color: theme.suscription.expired
  },
  pending: {
    name: 'Pendiente',
    color: theme.suscription.pending
  }
};

type EmptyObject = {
  [K in string]: never;
};

export const TableUsers: FunctionComponent<TableUsersProps> = ({ bookings, refetch, eventInstance }) => {
  const { client } = useClient();
  const { confirm } = useConfirmation();
  const { session } = useAuth();
  const { isMobileSize } = useWindowResize();
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState<UserI | undefined>(undefined);
  const [selectedBooking, setSelectedBooking] = useState<BookingI | undefined>(undefined);
  const [showModalReschedule, setShowModalReschedule] = useState<boolean>(false);

  const isOnDemand = eventInstance.event.service.is_on_demand;

  const mutationConfirmPayment = useMutation<EmptyObject, ErrorI, { id: number }>(
    (data) =>
      client(`bookings/${data.id}/confirm`, 'POST', {
        isAuthRequired: true,
        data: { status: PaymentStatusE.CONFIRMED }
      }),
    {
      onSuccess: () => {
        toast.success('Pago confirmado exitosamente');
        refetch();
      }
    }
  );

  const mutation = useMutation<EmptyObject, ErrorI, { message: string }>(
    (message) =>
      client(`me/vendor/contact/${selectedClient?.id}`, 'POST', {
        isAuthRequired: true,
        data: message
      }),
    {
      onSuccess: () => {
        toast.success(`Mensaje enviado a ${selectedClient?.first_name}`);
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const mutationDelete = useMutation<EmptyObject, ErrorI, DeleteBookingEventInstanceI>(
    (data) =>
      client(`bookings/event_instances/${data.id}`, 'DELETE', {
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        toast.success('Se ha cancelado la reserva exitosamente');
        setTimeout(() => {
          refetch();
        }, 2000);
      }
    }
  );

  const mutationConfirmAssistance = useMutation<EmptyObject, ErrorI, { id: number; has_assisted: boolean }>(
    (data) =>
      client(`bookings/${data.id}/assistance`, 'POST', {
        isAuthRequired: true,
        data: { has_assisted: data.has_assisted }
      }),
    {
      onSuccess: () => {
        toast.success('Asistencia confirmada exitosamente');
        refetch();
      }
    }
  );

  const handleOpenModal = (client: UserI | undefined) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const handleDelete = (id: number) =>
    confirm({
      status: 'danger',
      content: '¿Estas seguro de cancelar la reserva? Este proceso puede llevar un par de segundos',
      title: 'Cancelar reserva',
      confirmText: 'Si, cancelar',
      onConfirm: () => mutationDelete.mutate({ id })
    });

  const handleOpenModalReschedule = (client: UserI | undefined, booking: BookingI) => {
    setSelectedClient(client);
    setSelectedBooking(booking);
    setShowModalReschedule(true);
  };

  const handleContact = (message: string) => mutation.mutate({ message });

  const handleConfirmPayment = (id: number) => mutationConfirmPayment.mutate({ id });

  const body = bookings.map((booking, i) => {
    const { status, booking_event_instance, user, payments } = booking;

    if (!booking_event_instance) return [];

    const userName = `${user?.first_name} ${user?.last_name}`;
    const payment = payments && payments[0]?.type;

    return [
      <ProfileImageContainer key={i}>
        {user?.avatar_url ? (
          <Image src={user?.avatar_url} />
        ) : (
          <Icon
            name="user_circle"
            width="30px"
            height="30px"
            marginRight="15px"
            color={theme.colors.grey[700]}
          />
        )}
        <ActivityUser bold={isMobileSize}>
          {userName}
          {/* <Email>{user?.email}</Email> */}
        </ActivityUser>
      </ProfileImageContainer>,
      <div key={i}>
        <IconPayment type={payment} />
      </div>,
      <>
        {!isMobileSize ? (
          <ContentStatus key={i} color={bookingStatus[status].color}>
            {payments &&
            (payments[0].is_offline_payment_confirmed !== null
              ? !payments[0].is_offline_payment_confirmed
              : false) ? (
              <ConfirmPayment key={i + status} onClick={() => handleConfirmPayment(booking.id)}>
                Confirmar pago
              </ConfirmPayment>
            ) : (
              <p key={i + status}>{bookingStatus[status].name}</p>
            )}
          </ContentStatus>
        ) : (
          <>
            {payments && (
              <StatusOfflinePayment
                key={i}
                isConfirmed={
                  payments[0].is_offline_payment_confirmed !== null
                    ? payments[0].is_offline_payment_confirmed!
                    : true
                }
                onClick={() => handleConfirmPayment(booking.id)}
              />
            )}
          </>
        )}
      </>,
      <Row key={i} gap={4}>
        {booking.booking_event_instance?.has_assisted === null ? (
          <>
            <FontAwesomeIcon
              onClick={() => mutationConfirmAssistance.mutate({ id: booking.id, has_assisted: true })}
              icon={faCircleCheck}
              size="xl"
              color="#0072FB"
              className="cursor-pointer hover:text-[#0072FB]/80"
            />
            <FontAwesomeIcon
              onClick={() => mutationConfirmAssistance.mutate({ id: booking.id, has_assisted: false })}
              icon={faCircleXmark}
              size="xl"
              color="#868686"
              className="cursor-pointer hover:text-[#868686]/80"
            />
          </>
        ) : (
          <div
            className={classNames({
              'font-medium': true,
              'text-sm': !isMobileSize,
              'text-xs': isMobileSize,
              'text-[#09D5A1]': booking.booking_event_instance?.has_assisted,
              'text-[#FF4658]': !booking.booking_event_instance?.has_assisted
            })}
          >
            {booking.booking_event_instance?.has_assisted ? 'Si' : 'No'}
          </div>
        )}
      </Row>,
      !session?.is_staff ? (
        <ContentActions key={i}>
          <ContactButton onClick={() => handleOpenModal(user)}>
            <Icon name="comments" width="14px" height="14px" color={theme.colors.white as string} />
          </ContactButton>
          <div className="mt-1 ml-4 cursor-pointer" onClick={() => handleOpenModalReschedule(user, booking)}>
            <Icon
              name="calendar_clock"
              width="30px"
              height="30px"
              marginRight="7px"
              color={theme.colors.blue[500]}
            />
          </div>
          <div
            className="mt-1 ml-4 cursor-pointer"
            onClick={() => handleDelete(booking.booking_event_instance!.id)}
          >
            <Icon name="trash" width="20px" height="20px" marginRight="7px" color={'red'} />
          </div>
        </ContentActions>
      ) : null
    ];
  });
  return (
    <>
      {showModal && (
        <ContactModal
          setShowModal={setShowModal}
          onSubmit={handleContact}
          contactTo={selectedClient?.first_name || 'todos'}
        />
      )}
      {showModalReschedule && (
        <RescheduleModal
          showModal={showModalReschedule}
          closeModal={() => setShowModalReschedule(false)}
          eventInstance={eventInstance}
          user={selectedClient}
          booking={selectedBooking}
        />
      )}
      <Container>
        <H2>{isOnDemand ? 'Detalles de la reserva' : 'Participantes'}</H2>
        {!isMobileSize && !isOnDemand ? (
          <>
            <ContainerActions>
              <SubContainerLeft>
                <EventActionsContainer>
                  <Icon
                    name="message"
                    width="21px"
                    height="21px"
                    marginRight="7px"
                    color={theme.colors.blue[500] + '80'}
                  />
                  <button onClick={() => handleOpenModal(undefined)}>Contactar a todos</button>
                </EventActionsContainer>
              </SubContainerLeft>
              {/* <SubContainerRight>
                <div style={{ width: 290 }}>
                  <Input
                    rightIcon="search"
                    inputId="search-bar"
                    placeholder="Buscar por usuarios"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                    shadow
                    fullWidth
                  />
                </div>
              </SubContainerRight> */}
            </ContainerActions>
            <Table headers={headers} body={body} />{' '}
          </>
        ) : (
          body.map((item, i) => (item.length ? <UserCard items={item} key={i} /> : 'test'))
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 40px;
`;

const H2 = styled.h2`
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 40px;
`;

const ContentActions = styled.div`
  display: flex;
  max-width: 130px;

  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;
  }
`;

const ContentStatus = styled.div<{ color: string }>`
  p {
    color: ${({ color }) => color};
    font-weight: bold;
  }
`;

const ContactButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  ${({ theme }) => theme.breakpoint('md')} {
    margin-top: 10px;
  }
`;

const ContainerActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const SubContainerLeft = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 30px;
  }
`;

const EventActionsContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: 89px;
  align-items: center;
  height: 25px;
  button {
    font-weight: ${({ theme }) => theme.font.weight.medium};
    margin-right: 34px;
    font-size: 18px;
    line-height: 23px;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.blue[500]};
  }
  /* button:first-of-type {
    color: ${({ theme }) => theme.colors.red[200]};
  } */
  ${({ theme }) => theme.breakpoint('md')} {
    display: none;
  }
`;

const ConfirmPayment = styled.button`
  background: ${({ theme }) => theme.colors.blue[500]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  width: 176px;
  height: 38px;
`;
