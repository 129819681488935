import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavbarItems } from '@/hooks';
import { Navbar } from '@/components';
import { useAuth } from '@/contexts';

// .
export const Header: FunctionComponent = () => {
  const { session } = useAuth();
  const path = useLocation().pathname;
  const { mainItems, dropdownItems } = useNavbarItems(path);

  if (!session)
    return <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-gray-200 z-50" />;

  return (
    <Navbar
      onHome={session.is_staff ? '/agenda' : '/'}
      avatar={`${session.avatar_url}_250.webp`}
      mainItems={mainItems}
      dropdownItems={dropdownItems}
    />
  );
};
