import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/components/App';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TIMES_DROPDOWN = 300;

export type DropdownItem = {
  label: string;
  onClick?: () => void;
  icon: IconDefinition;
  link: string;
};

type DropdownProps = {
  dropdownItems: DropdownItem[];
  closeDropdownMenu: () => void;
};

export const Dropdown: FunctionComponent<DropdownProps> = ({ dropdownItems, closeDropdownMenu }) => {
  const handleItemClick = (callback: () => void, onClick?: () => void) => {
    callback();
    onClick?.();
  };

  return (
    <Container showDropdownMenu={true}>
      {dropdownItems.map(({ label, onClick, icon, link }) => (
        <Item
          {...(link && { href: link })}
          key={label}
          onClick={() => handleItemClick(closeDropdownMenu, onClick)}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              color={theme.colors.grey[700]}
              className="mr-2"
              width="14px"
              height="14px"
            />
          )}
          {label}
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.nav<{ showDropdownMenu: boolean }>`
  position: absolute;
  top: 40px;
  right: 0px;
  border-radius: 5px;
  width: 230px;
  padding: 10px 10px;
  border: solid 1px ${({ theme }) => theme.colors.grey[300]};
  z-index: 100;
  background: ${({ theme }) => theme.navbar.dropdown.background};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  transition: opacity ${TIMES_DROPDOWN}ms ease, transform ${TIMES_DROPDOWN}ms ease;
  ${({ showDropdownMenu }) =>
    showDropdownMenu
      ? css`
          pointer-events: auto;
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transform: translateY(-10%);
        `};
`;

const Item = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  color: ${({ theme }) => theme.navbar.dropdown.font};
`;
