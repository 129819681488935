import { Column } from '@/components';
import { Service } from '@/containers/Agenda/components/CreateEventModal/SelectService/Service';
import { ServiceI } from '@/types/cyclone/models';
import { GetServicesI } from '@/types/cyclone/requests';
import React, { Dispatch, SetStateAction, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface ListServicePropsI {
  nextStep: () => void;
  setItemSelected: Dispatch<SetStateAction<ServiceI | undefined>>;
  services: GetServicesI;
}

export const ListServices: React.FC<ListServicePropsI> = ({ services, setItemSelected, nextStep }) => {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter and sort services
  const filteredServices = useMemo(() => {
    return [...services]
      .filter((service) => service.name.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [services, searchQuery]);

  return (
    <Column className="min-h-96 w-full gap-4">
      {/* Search Bar */}
      <div className="relative w-full">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar servicio..."
          className="w-full pl-10 pr-4 py-2 border rounded-lg"
        />
        <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
      </div>

      {/* Services List */}
      {filteredServices.length === 0 ? (
        <div className="text-center text-gray-500 py-8">No se encontraron servicios</div>
      ) : (
        filteredServices.map((service) => (
          <Service
            key={service.id}
            service={service}
            selectService={() => {
              setItemSelected(service);
              nextStep();
            }}
          />
        ))
      )}
    </Column>
  );
};
