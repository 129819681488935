import { Column, Grid } from '@/components';
import { RoundedE, ThemeButtonI, TypeButtonsE } from '@/containers/StoreFrontBuilder/context/storefront';
import useScreenSize from '@/hooks/useScreenSize';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

const buttonsTheme = [
  {
    title: 'Sólidos',
    buttons: [
      {
        class: 'h-[42px] w-full bg-[#868686]',
        styled: {},
        type: TypeButtonsE.SOLID,
        rounded: RoundedE.ROUNDED_0
      },
      {
        class: 'h-[42px] w-full bg-[#868686] rounded-[4px]',
        styled: {},
        type: TypeButtonsE.SOLID,
        rounded: RoundedE.ROUNDED_10
      },
      {
        class: 'h-[42px] w-full bg-[#868686] rounded-[20px]',
        styled: {},
        type: TypeButtonsE.SOLID,
        rounded: RoundedE.ROUNDED_20
      }
    ]
  },
  {
    title: 'Contornos',
    buttons: [
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686]',
        styled: {},
        type: TypeButtonsE.OUTLINE,
        rounded: RoundedE.ROUNDED_0
      },
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686] rounded-[4px]',
        styled: {},
        type: TypeButtonsE.OUTLINE,
        rounded: RoundedE.ROUNDED_10
      },
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686] rounded-[20px]',
        styled: {},
        type: TypeButtonsE.OUTLINE,
        rounded: RoundedE.ROUNDED_20
      }
    ]
  },
  {
    title: 'Sombra sólida',
    buttons: [
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686] bg-[#F8F8F8]',
        styled: { boxShadow: '4px 4px 0px #868686' },
        type: TypeButtonsE.DARK_SHADOW,
        rounded: RoundedE.ROUNDED_0
      },
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686] rounded-[4px] bg-[#F8F8F8]',
        styled: { boxShadow: '4px 4px 0px #868686' },
        type: TypeButtonsE.DARK_SHADOW,
        rounded: RoundedE.ROUNDED_10
      },
      {
        class: 'h-[42px] w-full border-2 border-solid border-[#868686] rounded-[20px] bg-[#F8F8F8]',
        styled: { boxShadow: '4px 4px 0px #868686' },
        type: TypeButtonsE.DARK_SHADOW,
        rounded: RoundedE.ROUNDED_20
      }
    ]
  },
  {
    title: 'Sombra suave',
    buttons: [
      {
        class: 'h-[42px] w-full bg-[#F8F8F8]',
        styled: { boxShadow: '3px 3px 13px -6px rgba(0,0,0,0.75)' },
        type: TypeButtonsE.SOFT_SHADOW,
        rounded: RoundedE.ROUNDED_0
      },
      {
        class: 'h-[42px] w-full rounded-[4px] bg-[#F8F8F8]',
        styled: { boxShadow: '3px 3px 13px -6px rgba(0,0,0,0.75)' },
        type: TypeButtonsE.SOFT_SHADOW,
        rounded: RoundedE.ROUNDED_10
      },
      {
        class: 'h-[42px] w-full rounded-[20px] bg-[#F8F8F8]',
        styled: { boxShadow: '3px 3px 13px -6px rgba(0,0,0,0.75)' },
        type: TypeButtonsE.SOFT_SHADOW,
        rounded: RoundedE.ROUNDED_20
      }
    ]
  }
];

interface ButtonsThemePropsI {
  handlerButtonTheme: (buttonTheme: ThemeButtonI) => void;
  buttonTheme: ThemeButtonI | undefined;
}

export const ButtonsTheme: FunctionComponent<ButtonsThemePropsI> = ({ handlerButtonTheme, buttonTheme }) => {
  const { isSm } = useScreenSize();
  return (
    <div className="mb-[30px]">
      <p className="text-[#212121] mb-5 text-base font-medium">Botones</p>

      {buttonsTheme.map((buttons) => (
        <div key={buttons.title}>
          <p>{buttons.title}</p>
          <Grid as="div" columns={isSm ? 3 : 2} className="mt-2 mb-4">
            {buttons.buttons.map((item, indexButtons) => (
              <>
                <Column
                  className={classNames('p-2 border rounded', {
                    ' border-blue': item.rounded === buttonTheme?.rounded && item.type === buttonTheme.type,
                    'border-transparent':
                      item.rounded !== buttonTheme?.rounded || item.type !== buttonTheme.type
                  })}
                  key={`${buttons.title}-${indexButtons}`}
                >
                  <button
                    className={item.class}
                    style={item.styled}
                    onClick={() => handlerButtonTheme({ type: item.type, rounded: item.rounded })}
                  ></button>
                </Column>
              </>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};
