import { trackGenericEvent } from '@/analytics';
import { Button, Column, ModalV2 } from '@/components';
import { useAuth } from '@/contexts';
import { AgoraLinkTypeE, AgoraPlanNameE } from '@/types/cyclone/models';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';

interface LinksShowModalPropsI {
  show: boolean;
  onClose: () => void;
  type?: AgoraLinkTypeE;
}

export const LinksShowModal: FunctionComponent<LinksShowModalPropsI> = ({ show, onClose, type }) => {
  const { session } = useAuth();
  const navigate = useNavigate();

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;

  if (!type) return null;

  const buildModalContent = () => {
    switch (type) {
      case AgoraLinkTypeE.SERVICE:
        if (!session?.has_services) {
          return {
            title: 'No hay Servicios activos',
            body: 'Para poder habilitar el link deberás crear un servicio y su disponibilidad.',
            button: 'Crear Servicio',
            onClick: () => {
              trackGenericEvent('Button Create New Asset Clicked', { type: 'Service' }),
                navigate('/servicio/crear');
            }
          };
        }
      case AgoraLinkTypeE.SUBSCRIPTION:
        if (!session?.has_subscriptions) {
          return {
            title: 'No hay Planes mensuales activos',
            body: 'Para poder habilitar el link deberás crear un plan mensual primero.',
            button: 'Crear plan mensual',
            onClick: () => {
              trackGenericEvent('Button Create New Asset Clicked', { type: 'Subscription' }),
                navigate('/planesmensuales/crear');
            }
          };
        }
      case AgoraLinkTypeE.EVENT:
        if (!session?.has_events) {
          return {
            title: isEventPlan ? 'No hay Eventos activos' : 'No hay Capacitaciones activas',
            body: 'Para poder habilitar el link deberás crear un evento primero.',
            button: isEventPlan ? 'Crear Evento' : 'Crear Capacitación',
            onClick: () => {
              trackGenericEvent('Button Create New Asset Clicked', { type: 'Unique Event' }),
                navigate('/eventos/crear');
            }
          };
        }
    }
  };

  return (
    <ModalV2 show={show} onClose={onClose}>
      <Column gap={25} align="center" className="h-full">
        <Column align="center" gap={10}>
          <h1 className="text-lg font-semibold">{buildModalContent()?.title}</h1>
          <p className="text-base text-center font-normal">{buildModalContent()?.body}</p>
        </Column>
        <Button onClick={buildModalContent()?.onClick}>{buildModalContent()?.button}</Button>
      </Column>
    </ModalV2>
  );
};
