import React from 'react';
import { Category, Location, Phone, PlanSelection, SiteData, SubCategory } from './steps';
import { useOnboarding } from './contexts';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export const SelfOnboarding: React.FC = () => {
  const { step } = useOnboarding();

  const renderSteps = (stepIndex: number) => {
    switch (stepIndex) {
      case 1:
        return <Category />;
      case 2:
        return <SubCategory />;
      case 3:
        return <Location />;
      case 4:
        return <Phone />;
      case 5:
        return <SiteData />;
      case 6:
        return <PlanSelection />;
    }
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={step}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        classNames="fade"
      >
        {renderSteps(step)}
      </CSSTransition>
    </SwitchTransition>
  );
};
