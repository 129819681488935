import classNames from 'classnames';
import React from 'react';
import { ImageManagerV2, TypeImageInput } from '../ImageManagerV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

type InputImageProps = {
  img?: string;
  handleImg: (img: string) => void;
  type: TypeImageInput;
  width?: string;
  height?: string;
};

export const InputImage: React.FC<InputImageProps> = ({ img, type, width, height, handleImg }) => {
  const emptyImage = () => {
    switch (type) {
      case TypeImageInput.AVATAR:
        return (
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faCircleUser}
            color="#DADCE0"
            style={{ width, height }}
          />
        );
      case TypeImageInput.BANNER:
        return <div className={classNames('bg-[#DADCE0]')} style={{ width, height }} />;
      default:
        break;
    }
  };

  return (
    <ImageManagerV2 type={type} img={img} handleImg={handleImg}>
      {img ? (
        <div
          className={classNames('bg-cover bg-no-repeat bg-center bg-[#FFF] cursor-pointer', {
            'rounded-[50%]': type === TypeImageInput.AVATAR,
            'rounded-[6px]': type === TypeImageInput.BANNER,
            'rounded-[10px] ': type === TypeImageInput.SERVICE
          })}
          style={{ backgroundImage: `url(${img})`, width, height }}
        />
      ) : (
        emptyImage()
      )}
    </ImageManagerV2>
  );
};
