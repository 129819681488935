import React, { FunctionComponent, ChangeEvent } from 'react';
import styled, { CSSProp } from 'styled-components';
import { toast } from 'react-toastify';

type InputFileProps = {
  reference: string;
  handleFile: (avatar: File) => void;
  styles?: CSSProp;
  validExts?: string[];
  helpText?: string;
  error?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const InputFile: FunctionComponent<InputFileProps> = ({
  children,
  reference,
  handleFile,
  styles,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  helpText,
  error,
  disabled
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const type = file.name.split('.').pop();
    const isValidFile = type && validExts.includes(type);
    if (isValidFile) handleFile(file);
    else toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
    // Had to this because you couldn't select the same image twice
    // SO link to solution: https://stackoverflow.com/a/56258902
    e.target.value = '';
  };

  return (
    <div>
      <Button htmlFor={reference} styles={styles} disabled={disabled}>
        {children}
        <Input id={reference} type="file" onChange={onChange} disabled={disabled} />
      </Button>
      {(helpText || error) && <BotomText isError={!!error}>{error || helpText}</BotomText>}
    </div>
  );
};

const Button = styled.label<{ styles?: CSSProp; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ styles }) => styles};
`;

const Input = styled.input`
  display: none;
`;

const BotomText = styled.span<{ isError: boolean }>`
  display: block;
  color: ${({ theme, isError }) => (isError ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-top: 0.4rem;
  margin-left: 1rem;
`;
