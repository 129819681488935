import React, { ChangeEvent, FunctionComponent, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClient, useDebounce, useOnClickOutside } from '@/hooks';
import { Input } from './Input';
import { GetPlacesI } from '@/types/cyclone/requests';
import { useQuery } from 'react-query';
import { PlaceType } from '@/types';
import { LocationDropdown } from './LocationDropdown';

interface InputLocationProps {
  onChange: (location: string) => void;
  selectedPlace?: PlaceType;
  setSelectedPlace: (place?: PlaceType) => void;
  locationValue: string;
  error?: string;
  disabled?: boolean;
}

export const InputLocation: FunctionComponent<InputLocationProps> = ({
  onChange,
  locationValue,
  error,
  selectedPlace,
  setSelectedPlace,
  disabled
}) => {
  const { client } = useClient();
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [fetchLocations, setFetchLocations] = useState<boolean>(false);

  const toggleFetchLocations = () => setFetchLocations(!fetchLocations);
  const locationDebounce = useDebounce(toggleFetchLocations, 300);
  const closeLocationDropdown = useCallback(() => {
    if (isLocationDropdownOpen) setIsLocationDropdownOpen(false);
  }, [isLocationDropdownOpen]);

  const onChangeLocationInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPlace) {
      setSelectedPlace(undefined);
      onChange('');
    } else onChange(e.target.value);

    locationDebounce();
  };

  const locationRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(locationRef, closeLocationDropdown);

  const { data: locationResponses, isFetching: isLoadingPlaces } = useQuery(
    ['location', fetchLocations],
    async () => await client<GetPlacesI>(`google/places/${locationValue}`),
    {
      enabled: !!locationValue,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleSelectPlace = (place: PlaceType) => {
    onChange(place.address);
    setSelectedPlace(place);
  };

  const places: PlaceType[] =
    locationResponses?.map(({ description }) => ({
      address: description
    })) || [];

  const onLocationInputClick = () => {
    if (!isLocationDropdownOpen) setIsLocationDropdownOpen(true);
  };

  return (
    <LocationContainer ref={locationRef}>
      <Input
        inputId="service-location"
        placeholder="Dirección"
        helpText="Ubicación donde sucederá el servicio"
        value={locationValue}
        onChange={onChangeLocationInput}
        onClick={onLocationInputClick}
        disabled={disabled}
        reset={() => {
          setSelectedPlace(undefined);
          onChange('A definir');
        }}
        error={error}
      />
      {isLocationDropdownOpen && (
        <LocationDropdown
          places={places}
          handleSelectPlace={handleSelectPlace}
          isLoading={isLoadingPlaces}
          closeDropdown={closeLocationDropdown}
        />
      )}
    </LocationContainer>
  );
};

const LocationContainer = styled.div`
  position: relative;
`;
