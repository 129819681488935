import React, { FunctionComponent } from 'react';
import { AgoraThemeColorI, AgoraThemeE } from '@/types/cyclone/models';
import styled from 'styled-components';
import { Icon, IconName } from '@/components';
import { MediaI } from '@/containers/StoreFrontBuilder/context/storefront';

type SocialProps = {
  themeType: AgoraThemeE | undefined;
  socialMedias: MediaI[] | undefined;
  colors: AgoraThemeColorI;
  isMobile?: boolean;
};

export const IconsMediaFrame: FunctionComponent<SocialProps> = ({
  themeType,
  socialMedias,
  colors,
  isMobile
}) => {
  const getSocialMediaJSX = () =>
    socialMedias
      ? socialMedias.map((socialMedia) => (
          <Button as="a" key={socialMedia.icon}>
            <Icon
              name={
                socialMedia.icon != 'facebook'
                  ? (socialMedia.icon.replace('circle', 'not_bg') as IconName)
                  : 'facebook_not_bg'
              }
              width={isMobile ? '15px' : '25px'}
              height={isMobile ? '15px' : '25px'}
              color={colors.title}
            />
          </Button>
        ))
      : [];

  if (themeType === AgoraThemeE.CLEAN) {
    return (
      <Container colors={colors}>
        <SocialMedia colors={colors}>{getSocialMediaJSX()}</SocialMedia>
      </Container>
    );
  } else if (themeType === AgoraThemeE.SOHO) {
    return (
      <Container colors={colors}>
        <SocialMedia colors={colors}>{getSocialMediaJSX()}</SocialMedia>
      </Container>
    );
  } else {
    return (
      <Container colors={colors}>
        <SocialMedia colors={colors}>{getSocialMediaJSX()}</SocialMedia>
      </Container>
    );
  }
};

const Container = styled.div<{ colors: AgoraThemeColorI }>`
  margin-top: 20px;
  background-color: ${({ colors }) => colors.background};
`;

const SocialMedia = styled.div<{ colors: AgoraThemeColorI }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > a {
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  ${({ theme }) => theme.breakpoint('md')} {
    > a > svg {
      width: 21px;
      height: 21px;
    }
  }
`;

const Button = styled.button`
  display: block;
  line-height: 0;
`;
