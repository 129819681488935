import React, { FunctionComponent, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import styled, { CSSProp } from 'styled-components';

type InputMultipleFileProps = {
  reference: string;
  handleFile: (file: File[]) => void;
  styles?: CSSProp;
  validExts?: string[];
  helpText?: string;
  error?: string;
  disabled?: boolean;
  multiple?: boolean;
  children?: React.ReactNode;
};

export const InputMultipleFile: FunctionComponent<InputMultipleFileProps> = ({
  children,
  reference,
  handleFile,
  styles,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  helpText,
  error,
  disabled,
  multiple
}) => {
  const convertImageToSquare = async (file: File): Promise<File> => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        const maxSize = Math.max(img.width, img.height);

        canvas.width = maxSize;
        canvas.height = maxSize;

        // Centrar y dibujar la imagen en el lienzo
        const offsetX = (maxSize - img.width) / 2;
        const offsetY = (maxSize - img.height) / 2;
        ctx.drawImage(img, offsetX, offsetY);

        // Convertir el lienzo a Blob y crear un nuevo archivo
        canvas.toBlob((blob) => {
          const newFile = new File([blob!], file.name, {
            type: file.type,
            lastModified: Date.now()
          });
          resolve(newFile);
        }, file.type);
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = Object.values(e.target.files);
    const convertedFiles: File[] = [];

    for (const file of files) {
      const type = file.name.split('.').pop();
      const isValidFile = type && validExts.includes(type);

      if (isValidFile) {
        const newFile = await convertImageToSquare(file);
        convertedFiles.push(newFile);
      } else {
        toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
      }
    }

    handleFile(convertedFiles);
  };

  return (
    <div>
      <Button htmlFor={reference} styles={styles} disabled={disabled}>
        {children}
        <Input multiple={multiple} id={reference} type="file" onChange={onChange} disabled={disabled} />
      </Button>
      {(helpText || error) && <BotomText isError={!!error}>{error || helpText}</BotomText>}
    </div>
  );
};

const Button = styled.label<{ styles?: CSSProp; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ styles }) => styles};
`;

const Input = styled.input`
  display: none;
`;

const BotomText = styled.span<{ isError: boolean }>`
  display: block;
  color: ${({ theme, isError }) => (isError ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-top: 0.4rem;
  margin-left: 1rem;
`;
