import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useClient } from '@/hooks';
import { Input, Select, Spinner } from '@/components';
import { buildSelectOptions } from '@/components/Select';
import { LabelValue } from '@/types';
import { GetServicesI } from '@/types/cyclone/requests';
import { ServiceType } from '../../types';
import { ServiceStatusE } from '@/types/cyclone/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { theme } from '@/components/App';

type SelectsProps = {
  name: LabelValue | undefined;
  handleName: React.Dispatch<React.SetStateAction<LabelValue | undefined>>;
  limit: string | undefined;
  handleLimit: React.Dispatch<React.SetStateAction<string | undefined>>;
  isAddingService: boolean;
  prevSelectedServices: ServiceType[];
  addService: () => void;
  handleIsLimited: React.Dispatch<React.SetStateAction<boolean>>;
  isLimited: boolean;
};

export const Selects: FunctionComponent<SelectsProps> = ({
  name,
  handleName,
  limit,
  handleLimit,
  isAddingService,
  prevSelectedServices,
  addService,
  handleIsLimited,
  isLimited
}) => {
  const { client } = useClient();

  const { data: services } = useQuery(
    ['services'],
    async () =>
      await client<GetServicesI>(`me/vendor/services`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  if (!services)
    return (
      <ContainerSpinner>
        <Spinner size={20} />
      </ContainerSpinner>
    );

  const getOptionsServices = (): LabelValue[] => {
    const activeServices = services.filter((s) => s.status === ServiceStatusE.ACTIVE);
    const servicesOptions = activeServices.map((service) => ({
      label: service.name,
      value: service.id.toString(),
      key: 'service'
    }));

    return servicesOptions.filter(
      (optionService) =>
        !prevSelectedServices.find((prevService) => prevService.serviceId.toString() === optionService.value)
    );
  };

  return (
    <Container>
      {isAddingService ||
        (true && ( //TODO: Change true to WLK flag
          <InnerContainer>
            <Select
              placeholder="Servicios"
              value={name?.label}
              options={buildSelectOptions(getOptionsServices(), name, handleName)}
              helpText="Selecciona el servicio que querés incluir"
            />
            <Input
              pattern="\d*"
              inputId="subscription-availability"
              placeholder="Cantidad de reservas"
              helpText="Indicá las reservas disponibles"
              value={isLimited ? limit : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLimit(e.target.value)}
              checkboxLabel="Abono libre"
              handleCheckboxClick={() => {
                if (isLimited) {
                  handleLimit('0');
                }
                handleIsLimited(!isLimited);
              }}
              showCheckboxToDisable
              disabled={!isLimited}
            />
            {!name || name?.key === 'service_exception' || (isLimited && limit === '0') || !limit ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#cacaca"
                size="lg"
                fixedWidth
                style={{ cursor: 'pointer', marginTop: 10, width: 28, height: 28, marginLeft: 10 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={theme.colors.blue[500]}
                size="lg"
                onClick={() => addService()}
                beatFade
                fixedWidth
                style={{ cursor: 'pointer', marginTop: 10, width: 26, height: 26, marginLeft: 12 }}
              />
            )}
          </InnerContainer>
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 1rem;
  ${({ theme }) => theme.breakpoint('xl')} {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  > div {
    margin-right: 1rem;
    width: 50%;
    &:last-child {
      margin-right: 0;
    }
  }
  ${({ theme }) => theme.breakpoint('xl')} {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    > div {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
      > div {
        width: 100%;
      }
    }
  }
`;

const ContainerSpinner = styled.div`
  width: 85%;
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.breakpoint('xl')} {
    width: 100%;
  }
`;
