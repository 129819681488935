import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Column, Input, Row } from '@/components';
import { ServiceI } from '@/types/cyclone/models';
import { LabelValue } from '@/types';
import { useQuery } from 'react-query';
import { useClient } from '@/hooks';
import { PostAssignBookingEventInstanceI, GetClientBaseI } from '@/types/cyclone/requests';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { InputAutocomplete, InputPhone } from '@/components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { DialogFooter } from '@/components/Dialog';
import { useAuth } from '@/contexts';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface ClientFormPropsI {
  service: ServiceI;
  onSave: (data: PostAssignBookingEventInstanceI) => void;
  isLoading?: boolean;
  venueId?: string;
  saveInfo?: FieldValues | undefined;
}

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
};

export const ClientForm: FunctionComponent<ClientFormPropsI> = ({
  service,
  isLoading,
  onSave,
  venueId,
  saveInfo
}) => {
  const { client } = useClient();
  const { session } = useAuth();

  const [customer, setCustomer] = useState<LabelValue | undefined>(undefined);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    trigger
  } = useForm<FormValues>({ mode: 'onBlur' });

  // Watch for changes in the form values
  const firstName = watch('first_name');
  const lastName = watch('last_name');
  const email = watch('email');

  useEffect(() => {
    if (customer) {
      const selectedClient = clients?.find((client) => client.email === customer?.value);
      const isFirstNameChanged = firstName !== selectedClient?.firstName;
      const isLastNameChanged = lastName !== selectedClient?.lastName;
      const isEmailChanged = email !== selectedClient?.email;

      if (isFirstNameChanged || isLastNameChanged || isEmailChanged) {
        // Clear the selected client if the form values have been changed
        setCustomer(undefined);
      }
    }
  }, [firstName, lastName, email]);

  const { data: _clients } = useQuery(
    ['client_base'],
    async () =>
      await client<GetClientBaseI>(`me/vendor/client_base`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
  const clients =
    _clients?.map((client) => ({
      id: client.id,
      firstName: client.user?.first_name,
      lastName: client.user?.last_name,
      email: client.user?.email,
      phone: client.user?.phone
    })) || [];

  const clientOptions = clients.map((client) => ({
    label: `${client.firstName} ${client.lastName} - ${client.email ? client.email : `+${client.phone}`}`,
    value: client.id.toString()
  }));

  const validatePhone = (value: string): boolean => {
    if (!value) return true; // Allow empty value
    const phoneNumber = parsePhoneNumberFromString('+' + value);
    if (phoneNumber?.country === 'UY' && phoneNumber?.nationalNumber.length <= 7) {
      return false;
    }
    return phoneNumber?.isValid() || false;
  };

  const onFormSave = async (data: FormValues) => {
    if (data.phone && !validatePhone(data.phone)) {
      setPhoneError('Número inválido, por favor ingrese un número válido');
      return;
    }
    setPhoneError(null);
    // @ts-ignore
    onSave(data);
  };

  useEffect(() => {
    if (saveInfo) {
      setValue('first_name', saveInfo.first_name || '');
      setValue('last_name', saveInfo.last_name || '');
      setValue('email', saveInfo.email || '');
      setValue('phone', saveInfo.phone || '');
    }
  }, [saveInfo]);

  useEffect(() => {
    if (customer) {
      const selectedClient = clients?.find((client) => client.id.toString() === customer?.value);
      setValue('first_name', selectedClient?.firstName || '');
      setValue('last_name', selectedClient?.lastName || '');
      setValue('email', selectedClient?.email || '');
      setValue('phone', selectedClient?.phone || '');

      trigger();
    }
  }, [customer]);

  const getStaffName = () => {
    const staff = session?.vendor?.venue?.vendors?.filter((v) => v.id.toString() === venueId);
    if (!staff || staff.length === 0) return '';
    return `${staff[0].user.first_name} ${staff[0].user.last_name}`;
  };

  return (
    <>
      <Column className="py-4 gap-0">
        <span className="text-[#868686] text-sm sm:text-base">
          Servicio: <span className="text-black truncate w-[90%]">{service.name}</span>
        </span>
        {venueId && (
          <span className="text-[#868686] text-sm sm:text-base">
            con <span className="text-black">{getStaffName()}</span>
          </span>
        )}
      </Column>
      <div className="divider text-xs sm:text-sm text-[#868686] pb-2">
        <FontAwesomeIcon icon={faSearch} className="text-xs sm:text-sm" />
        Seleccioná un cliente de tu base de clientes
      </div>
      <div className="mb-4">
        <InputAutocomplete
          // @ts-ignore
          value={customer?.value}
          onChange={(selectedItem) => {
            if (selectedItem) {
              setCustomer(selectedItem);
            } else {
              setCustomer(undefined);
            }
          }}
          options={clientOptions}
          placeholder="Cliente"
        />
      </div>
      <div className="divider text-xs sm:text-sm text-[#868686] pb-2">
        ó ingresá manualmente los datos del cliente
      </div>
      <Row gap={12} className="w-full mb-4">
        <div className="w-1/2">
          <Controller
            name="first_name"
            control={control}
            rules={{ required: 'Campo requerido' }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={onChange}
                inputId="first_name"
                placeholder="Nombre"
                error={errors?.first_name?.message}
              />
            )}
          />
        </div>
        <div className="w-1/2">
          <Controller
            name="last_name"
            control={control}
            rules={{ required: 'Campo requerido' }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={onChange}
                inputId="last_name"
                placeholder="Apellido"
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>
      </Row>
      <div className="mb-4">
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <Input
              inputId="email"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const formattedValue = e.target.value.trim().toLowerCase();
                onChange(formattedValue);
              }}
              placeholder="Email"
              error={errors?.email?.message as string}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputPhone
              inputPhoneProps={{
                value: value,
                onChange: onChange,
                countryCodeEditable: false
              }}
            />
          )}
        />
        {phoneError && <span className="text-red-500 text-sm">{phoneError}</span>}
      </div>
      <DialogFooter className="mb-4">
        <Button rounded fullWidth disabled={isLoading} onClick={handleSubmit(onFormSave)}>
          Continuar
        </Button>
      </DialogFooter>
    </>
  );
};
