import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClient, useLocalStorage, useSessionStorage } from '@/hooks';
import { Spinner } from '@/components';
import usePageTracking from '@/hooks/usePageTracking';
import { useMutation } from 'react-query';
import { EmptyObject } from 'react-hook-form';
import { ErrorI, PostConfirmInvitation } from '@/types/cyclone/requests';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '@/contexts';

export const NewUser: FunctionComponent = () => {
  usePageTracking();
  const { user } = useAuth0();
  const { session, reloadSession } = useAuth();
  const navigate = useNavigate();
  const { client } = useClient();
  const { storedValue, deleteValue } = useLocalStorage('redirectUri', {});
  const invitedByLocalStorage = useSessionStorage('invitedBy', {});

  const mutation = useMutation<EmptyObject, ErrorI>(
    () =>
      client<PostConfirmInvitation>(`invitations/confirm`, 'POST', {
        isAuthRequired: true,
        data: { user_invitee_email: user?.email, vendor_username: invitedByLocalStorage.storedValue }
      }),
    {
      onSuccess: () => {
        reloadSession();
      }
    }
  );

  const redirectToDashboard = () => {
    if (Object.keys(invitedByLocalStorage.storedValue).length !== 0) {
      invitedByLocalStorage.deleteValue('invitedBy');
      mutation.mutate();
    }

    if (Object.keys(storedValue).length !== 0) {
      navigate(storedValue);
      return deleteValue('redirectUri');
    }

    if (session?.is_staff) {
      return navigate('/agenda');
    }
    return navigate('/');
  };

  useEffect(() => {
    setTimeout(() => redirectToDashboard(), 2000);
  }, []);

  return <Spinner />;
};
