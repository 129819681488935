import React, { FunctionComponent } from 'react';
import { AgoraThemeColorI, VendorHeaderInfoI } from '@/types/cyclone/models';
import styled, { css } from 'styled-components';

type HeaderMixtapeProps = {
  colors: AgoraThemeColorI;
  vendor: VendorHeaderInfoI;
  isMobile?: boolean;
};

export const HeaderMixtape: FunctionComponent<HeaderMixtapeProps> = ({ colors, vendor, isMobile }) => {
  const { cover_url, name, professional_title } = vendor;
  const formattedName = name.split(' ');
  const secondRowName = name.replace(formattedName[0], '');
  return (
    <div>
      <Background imageUrl={cover_url} isMobile={isMobile} />
      <Container colors={colors}>
        <Flex>
          <NameContainer isMobile={isMobile}>
            <Username colors={colors} isMobile={isMobile}>
              {formattedName[0]}
            </Username>
            <Username colors={colors} isMobile={isMobile}>
              {secondRowName}
            </Username>
            <VendorTitle colors={colors}>{professional_title}</VendorTitle>
          </NameContainer>
        </Flex>
      </Container>
    </div>
  );
};

const Container = styled.div<{ colors: AgoraThemeColorI }>`
  background-color: ${({ colors }) => colors.background};
`;

const Background = styled.div<{ imageUrl: string; isMobile?: boolean }>`
  background: ${({ theme }) => theme.colors.grey[600]};
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ theme }) => theme.breakpoint('md')} {
    height: 24vh;
  }
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        height: 124px;
      `;
    } else {
      return css`
        height: 200px;
      `;
    }
  }}
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NameContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin-top: -30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        margin-top: -24px;
      `;
    }
  }}
`;

const Username = styled.div<{ colors: AgoraThemeColorI; isMobile?: boolean }>`
  font-size: 2rem;
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.black};
  color: ${({ colors }) => colors.title};
  text-transform: uppercase;
  text-align: center;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        font-size: 1.5rem;
        line-height: 1.5rem;
      `;
    }
  }}
`;

const VendorTitle = styled.div<{ colors: AgoraThemeColorI }>`
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ colors }) => colors.title};
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.8rem;
`;
