import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type BurgerProps = {
  openMobileMenu: () => void;
  isOpen: boolean;
  closeMobileMenu: () => void;
};

export const Burger: FunctionComponent<BurgerProps> = ({ openMobileMenu, isOpen, closeMobileMenu }) => (
  <StyledBurger onClick={isOpen ? closeMobileMenu : openMobileMenu} open={isOpen}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

const StyledBurger = styled.button<{ open: boolean }>`
  display: none;
  line-height: 0;
  padding: 4px 8px;
  align-self: center;
  ${({ theme }) => theme.breakpoint('lg')} {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    &:focus {
      outline: none;
    }
    div {
      width: 1.5rem;
      height: 2px;
      background: ${({ theme }) => theme.colors.grey[800]};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
      :first-child {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      }
      :nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
        transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
      }
      :nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      }
    }
  }
`;
