import React, { FunctionComponent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { RgbaStringColorPicker } from 'react-colorful';
import hexToRgba from 'hex-to-rgba';
import rgbHex from 'rgb-hex';
import { Row } from '@/components';
const TIMES_DROPDOWN = 300;

interface DropdownColorPropsI {
  show: boolean | undefined;
  reference: React.RefObject<HTMLDivElement>;
  onChange: (value: string) => void;
  value: string;
  title?: string;
  closeDropdown: () => void;
}
export const DropdownColor: FunctionComponent<DropdownColorPropsI> = ({
  show,
  reference,
  onChange,
  value,
  title = '',
  closeDropdown
}) => {
  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.keyCode === 13) closeDropdown();
    };
    if (show) {
      window.addEventListener('keydown', handleEnter);
    }

    return () => window.removeEventListener('keydown', handleEnter);
  }, [show]);

  if (!show) return <></>;
  return (
    <Dropdown showDropdownMenu={show} ref={reference}>
      <p>{title}</p>
      <RgbaStringColorPicker
        className="m-auto"
        color={hexToRgba(value)}
        onChange={(value) => onChange(rgbHex(value))}
      />
      <Row justify="space-around" align="center" className="mt-5">
        <p className="text-xs font-medium">Hex</p>
        <input
          className="border border-solid border-[#E5E8E8] rounded-lg w-[100px] py-[3px] px-[5px] text-center"
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          maxLength={9}
        />
      </Row>
    </Dropdown>
  );
};

const Dropdown = styled.div<{ showDropdownMenu: boolean }>`
  position: absolute;
  top: 40px;
  right: 0;
  border-radius: 5px;
  width: 250px;
  padding: 20px 20px;
  border: solid 1px ${({ theme }) => theme.colors.grey[300]};
  z-index: 1;
  background: ${({ theme }) => theme.navbar.dropdown.background};
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  transition: opacity ${TIMES_DROPDOWN}ms ease, transform ${TIMES_DROPDOWN}ms ease;
  left: 0;
  ${({ showDropdownMenu }) =>
    showDropdownMenu
      ? css`
          pointer-events: auto;
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transform: translateY(-10%);
        `};
`;
