import { useState } from 'react';

interface UseSessionStorageI<T> {
  storedValue: T;
  setValue: (value: T | ((val: T) => T)) => void;
  deleteValue: (key: string) => void;
}

export const useSessionStorage = <T>(key: string, initialValue: T): UseSessionStorageI<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Failed to retrieve item from sessionStorage:', error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Failed to set item in sessionStorage:', error);
    }
  };

  const deleteValue = (key: string) => {
    window.sessionStorage.removeItem(key);
  };

  return { storedValue, setValue, deleteValue };
};
