import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  UseFormHandleSubmit,
  FieldError,
  DeepMap,
  Control,
  FieldValues,
  UseFormReset
} from 'react-hook-form';
import { SubscriptionI } from '@/types/cyclone/models';

const schema = yup.object().shape({
  name: yup.string().required('Campo requerido'),
  price: yup
    .number()
    .positive()
    .integer()
    .required('Campo requerido')
    .typeError('Debe ser un numero positivo')
  // availability: yup
  //   .number()
  //   .positive()
  //   .integer()
  //   .required('Campo requerido')
  //   .typeError('Debe ser un numero positivo')
});

type UseAboutReturnType = {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  control: Control<FieldValues>;
  coverImage: File | undefined;
  handleCoverImage: (image?: File) => void;
  aboutErrors: Record<string, boolean>;
  checkErrors: (subscription: SubscriptionI | undefined) => void;
  reset: UseFormReset<FieldValues>;
  description: string;
  handleDescription: (description: string) => void;
};

export const useAbout = (): UseAboutReturnType => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({ resolver: yupResolver(schema) });

  const [description, setDescription] = useState<string>('');

  const handleDescription = (description: string) => setDescription(description);

  const [coverImage, setCoverImage] = useState<File | undefined>(undefined);
  const handleCoverImage = (image?: File) => setCoverImage(image);

  const [aboutErrors, setAboutErrors] = useState<Record<string, boolean>>({
    coverImage: false,
    description: false
  });

  const checkErrors = (subscription: SubscriptionI | undefined) => {
    const newErrors = {
      coverImage: !coverImage && !subscription?.cover_url,
      description: !description || description === ''
    };

    setAboutErrors(newErrors);
  };

  useEffect(() => {
    if (coverImage) setAboutErrors({ ...aboutErrors, coverImage: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImage]);

  useEffect(() => {
    if (description || description.trim() !== '') {
      setAboutErrors({ ...aboutErrors, description: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return {
    handleSubmit,
    errors,
    control,
    coverImage,
    handleCoverImage,
    aboutErrors,
    checkErrors,
    reset,
    description,
    handleDescription
  };
};
