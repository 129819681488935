import React, { FunctionComponent } from 'react';
import { ModalV2 } from '../ModalV2';
import { Column } from '../Column';
import classNames from 'classnames';
import { Row } from '../Row';

interface ConfirmationModalProps {
  status?: 'info' | 'warning' | 'danger';
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  setShowConfirmation: (value: boolean) => void;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  status = 'info',
  title,
  content,
  message,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  setShowConfirmation
}) => {
  const handleOnConfirm = () => {
    onConfirm?.();
    setShowConfirmation(false);
  };

  const handleOnClose = () => {
    onClose?.();
    setShowConfirmation(false);
  };

  return (
    <ModalV2 isForConfirmation show onClose={handleOnClose}>
      {title && (
        <Row justify="center" className="flex-1">
          <p className="text-lg font-bold text-[#212121]">{title}</p>
        </Row>
      )}
      <Column gap={25} justify="space-between">
        <Column align="center" className="text-center">
          {content && <p className="text-sm text-[#868686]">{content}</p>}
          {message && <span className="mt-4">{message}</span>}
        </Column>
        <Column gap={6} className="w-full">
          <button
            className={classNames('w-full h-12 text-center font-semibold text-base rounded', {
              'bg-[#0072FB] text-white': status === 'info',
              'bg-yellow-500 text-black': status === 'warning',
              'bg-red-500 text-white': status === 'danger'
            })}
            onClick={handleOnConfirm}
          >
            {confirmText}
          </button>
          <button
            className={classNames('w-full h-12 text-center font-semibold text-base', {
              'text-[#0072FB]': status === 'info',
              'text-black': status === 'warning' || status === 'danger'
            })}
            onClick={handleOnClose}
          >
            {cancelText}
          </button>
        </Column>
      </Column>
    </ModalV2>
  );
};
