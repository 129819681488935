import { Row } from '@/components';
import React, { FunctionComponent, ReactNode } from 'react';

interface CollapsibleItemPropsI {
  children?: ReactNode;
  icon?: JSX.Element;
  title?: string;
  description?: string;
  handlerOpen: () => void;
  isOpen: boolean;
  id?: string;
}

export const CollapsibleItem: FunctionComponent<CollapsibleItemPropsI> = ({
  children,
  icon,
  title,
  description,
  handlerOpen,
  isOpen,
  id
}) => {
  return (
    <div
      id={id}
      className="collapse collapse-arrow sm:shadow-[0_0_4px_0px_rgba(0,0,0,0.25)] rounded-none sm:rounded-md sm:p-2 py-2 border-b-2 border-black sm:border-none sm:mb-8 mb-4 transition-all duration-200"
    >
      <input checked={isOpen} type="checkbox" onClick={() => handlerOpen()} />
      <div className="collapse-title flex flex-col">
        <Row align="center">
          {icon}
          <p className="text-xl font-medium ml-3">{title}</p>
        </Row>
        <p className="text-[#757575] text-sm mt-3">{description}</p>
      </div>
      <div className="collapse-content">{children}</div>
    </div>
  );
};
