import { useState, useEffect } from 'react';

type UseWindowResizeReturnType = {
  isMobileSize: boolean;
};

export const useWindowResize = (size = 768): UseWindowResizeReturnType => {
  const [isMobileSize, setIsMobileSize] = useState<boolean>(window.innerWidth <= size);

  useEffect(() => {
    const updateWindowWidth = () => {
      if (!isMobileSize && window.innerWidth <= size) setIsMobileSize(true);
      else if (isMobileSize && window.innerWidth > size) setIsMobileSize(false);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, [isMobileSize, size]);

  return { isMobileSize };
};
