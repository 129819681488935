import { SelectProps } from '@/components';
import { useAuth } from '@/contexts';
import { useClient, useDebounce } from '@/hooks';
import {
  LabelI,
  MeetTypeE,
  ServiceI,
  ServiceModalityE,
  VendorI,
  VendorStatusE,
  WalletTypeE
} from '@/types/cyclone/models';
import { GetPlacesI, GetServiceI, PostServiceI, ErrorI, GetLabelsI } from '@/types/cyclone/requests';
import { blobToBase64, urlImageToFile } from '@/utils';
import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormWatch
} from 'react-hook-form';
import useLog from '@/hooks/useLog';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ZoneType } from '../containers/EditService/components/CoverageArea/types';
import { defaultZone } from '../containers/EditService/components/CoverageArea/utils';
import {
  DEFAULT_AGORA_IMAGE,
  EditServiceFormStateI,
  FAQItemI,
  getSchema,
  Image,
  MINUTES_OPTIONS,
  PackI,
  PlaceType,
  TabE
} from '../utils';
import { CustomQuestionI } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { trackGenericEvent } from '@/analytics';

type ServiceContextType = {
  setIsOnDemand: (value: boolean) => void;
  isOnDemand: boolean;
  setIsCoverageArea: (value: boolean) => void;
  isCoverageArea: boolean;
  setModality: (value: ServiceModalityE) => void;
  modality: ServiceModalityE | null;
  coverImage: string;
  setCoverImage: (value: string) => void;
  addPack: (pack: PackI) => void;
  vendorPacks: PackI[] | undefined;
  setVendorPacks: React.Dispatch<React.SetStateAction<PackI[] | []>>;
  deletePack: (position: number) => void;
  isStripeConnected: boolean;
  handleLocationValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSelectPlace: (place: PlaceType) => void;
  locationValue: string;
  setLocationValue: React.Dispatch<React.SetStateAction<string>>;
  selectedPlace: PlaceType | undefined;
  places: PlaceType[];
  isLoadingPlaces: boolean;
  activeTab: TabE;
  handleActiveTab: (tab: TabE) => void;
  editFormControl: Control<EditServiceFormStateI>;
  editFormErrors: DeepMap<FieldValues, FieldError>;
  meetType: MeetTypeE | null;
  setMeetType: (value: MeetTypeE) => void;
  isForSubscriptionEnabled: boolean;
  setIsForSubscriptionEnabled: (value: boolean) => void;
  serviceDescription: string;
  setServiceDescription: (value: string) => void;
  faqs: FAQItemI[];
  setFaqs: (value: FAQItemI[]) => void;
  toggleCancellationPolicy: boolean;
  setToggleCancellationPolicy: (value: boolean) => void;
  toggleRefundAvailable: boolean;
  setToggleRefundAvailable: (value: boolean) => void;
  watch: UseFormWatch<EditServiceFormStateI>;
  selectedCancellation: SelectProps;
  onSelectionCancellationChange: (option: SelectProps) => void;
  selectedBookingUntil: SelectProps;
  onSelectionBookingUntilChange: (option: SelectProps) => void;
  fullSubmit: () => void;
  handleIsCoverageArea: () => void;
  zones: ZoneType[];
  setZones: React.Dispatch<React.SetStateAction<ZoneType[]>>;
  imageGallery: Image[];
  setImageGallery: React.Dispatch<React.SetStateAction<Image[]>>;
  isFetchingService: boolean;
  isMutationLoading: boolean;
  toggleIsUserPhoneRequired: boolean;
  setToggleIsUserPhoneRequired: (value: boolean) => void;
  selectedStaffs: number[];
  setSelectedStaffs: React.Dispatch<React.SetStateAction<number[]>>;
  labelOptions: string[];
  handleSelectLabel: (label?: string) => void;
  label: string | undefined;
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  toggleDownPaymentAvailable: boolean;
  setToggleDownPaymentAvailable: (value: boolean) => void;
  downPaymentType: SelectProps;
  setDownPaymentType: (value: SelectProps) => void;
  downPaymentAvailable: string[];
  setDownPaymentAvailable: React.Dispatch<React.SetStateAction<string[]>>;
  downPaymentAmount: number | null;
  setDownPaymentAmount: React.Dispatch<React.SetStateAction<number | null>>;
  downPaymentDiscount: number | null;
  setDownPaymentDiscount: React.Dispatch<React.SetStateAction<number | null>>;
  downPaymentAvailableOptions: string[];
  setDownPaymentAvailableOptions: React.Dispatch<React.SetStateAction<string[]>>;
  vendors: VendorI[] | undefined;
  serviceSlug?: string;
  toggleBufferTime: boolean;
  bufferTime: string;
  setBufferTime: React.Dispatch<React.SetStateAction<string>>;
  handleToggleBufferTime: (bool: boolean) => void;
  bufferOptions: SelectProps[];
  aboutErrors: Record<string, string>;
  handleErrors: () => boolean;
};

const ServiceContext = createContext<ServiceContextType>({} as ServiceContextType);

type Props = {
  children: React.ReactNode;
  serviceId?: string;
};

export const downPaymentOptions: SelectProps[] = [
  {
    label: 'Monto fijo',
    value: 'amount'
  },
  {
    label: 'Porcentaje',
    value: 'percentage'
  }
];

export enum DownpaymentOption {
  MERCADO_PAGO = 'mercado_pago',
  CASH = 'cash',
  BANK_TRANSFER = 'bank_transfer'
}

const bufferOptions: SelectProps[] = [
  { label: '5 minutos', value: '5' },
  { label: '10 minutos', value: '10' },
  { label: '15 minutos', value: '15' },
  { label: '30 minutos', value: '30' },
  { label: '45 minutos', value: '45' },
  { label: '1 hora', value: '60' }
];

const initialSelectErrors: Record<string, string> = {
  location: '',
  eventDate: '',
  eventName: '',
  price: '',
  duration: '',
  participants: '',
  downPaymentAmount: ''
};

export const ServiceProvider: FunctionComponent<Props> = ({ children, serviceId }) => {
  const { client } = useClient();
  const { session } = useAuth();
  const { logAndNotify } = useLog();
  const navigate = useNavigate();
  const isEdit = !!serviceId;

  const [isOnDemand, setIsOnDemand] = useState(false);
  const [isCoverageArea, setIsCoverageArea] = useState(false);
  const [coverImage, setCoverImage] = useState<string>(DEFAULT_AGORA_IMAGE);
  const [modality, setModality] = useState<ServiceModalityE | null>(null);
  const [meetType, setMeetType] = useState<MeetTypeE | null>(null);
  const [serviceDescription, setServiceDescription] = useState<string>('');
  const [isForSubscriptionEnabled, setIsForSubscriptionEnabled] = useState(false);
  const [faqs, setFaqs] = useState<FAQItemI[]>([]);
  const [toggleBufferTime, setToggleBufferTime] = useState(false);
  const [toggleCancellationPolicy, setToggleCancellationPolicy] = useState(false);
  const [toggleRefundAvailable, setToggleRefundAvailable] = useState(true);
  const [toggleDownPaymentAvailable, setToggleDownPaymentAvailable] = useState(false);
  const [toggleIsUserPhoneRequired, setToggleIsUserPhoneRequired] = useState(true);
  const [selectedCancellation, setSelectedCancellation] = useState<SelectProps>(MINUTES_OPTIONS[0]);
  const [selectedBookingUntil, setSelectedBookingUntil] = useState<SelectProps>(MINUTES_OPTIONS[0]);
  const [vendorPacks, setVendorPacks] = useState<PackI[] | []>([]);
  const [assetLabels, setAssetLabels] = useState<LabelI[]>([]);
  const [label, setLabel] = useState<string | undefined>();
  const [selectedStaffs, setSelectedStaffs] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState(TabE.MAIN);
  const [zones, setZones] = useState<ZoneType[]>([defaultZone]);
  const [downPaymentType, setDownPaymentType] = useState<SelectProps>(downPaymentOptions[0]);
  const [downPaymentAvailable, setDownPaymentAvailable] = useState<string[]>([]);
  const [downPaymentAvailableOptions, setDownPaymentAvailableOptions] = useState<string[]>([]);
  const [downPaymentAmount, setDownPaymentAmount] = useState<number | null>(null);
  const [downPaymentDiscount, setDownPaymentDiscount] = useState<number | null>(null);
  const [bufferTime, setBufferTime] = useState<string>('5');
  const [aboutErrors, setAboutErrors] = useState<Record<string, string>>(initialSelectErrors);

  const handleActiveTab = (tab: TabE) => setActiveTab(tab);
  const onSelectionCancellationChange = (option: SelectProps) => {
    setSelectedCancellation(option);
  };
  const onSelectionBookingUntilChange = (option: SelectProps) => {
    setSelectedBookingUntil(option);
  };

  const handleToggleBufferTime = (bool: boolean) => {
    setToggleBufferTime(bool);
    if (!toggleBufferTime) {
      setBufferTime('5');
    }
  };

  /* Service Location */
  const [locationValue, setLocationValue] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | undefined>(undefined);
  const [fetchLocations, setFetchLocations] = useState<boolean>(false);

  const toggleFetchLocations = () => setFetchLocations(!fetchLocations);
  const locationDebounce = useDebounce(toggleFetchLocations, 300);

  const handleIsCoverageArea = () => {
    setIsCoverageArea(!isCoverageArea);
    setSelectedPlace(undefined);
    setLocationValue('');
  };

  const handleLocationValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPlace) {
      setSelectedPlace(undefined);
      setLocationValue('');
    } else setLocationValue(e.target.value);

    locationDebounce();
  };

  const handleSelectPlace = (place: PlaceType) => {
    setLocationValue(place.address);
    setSelectedPlace(place);
  };

  const { data: locationResponses, isFetching: isLoadingPlaces } = useQuery(
    ['location', fetchLocations],
    async () =>
      await client<GetPlacesI>(`google/places/${locationValue}?country=${session?.vendor?.country}`),
    {
      enabled: !!locationValue,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const places: PlaceType[] =
    locationResponses?.map(({ description }) => ({
      address: description
    })) || [];

  useEffect(() => {
    if (!coverImage) setCoverImage(DEFAULT_AGORA_IMAGE);
  }, [coverImage]);

  const getItems = (count: number): Image[] =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`
    }));
  const [imageGallery, setImageGallery] = useState<Image[]>(getItems(5));
  const handleImages = (images?: string[]) => {
    if (images) {
      const presetImages = imageGallery.map((position, index) => ({
        ...position,
        url: images[index]
      }));

      setImageGallery(presetImages);
    }
  };

  /* End of Service Location */

  const isStripeConnected =
    session?.vendor?.payment_gateways?.some((pg) => pg === WalletTypeE.STRIPE) || false;

  const addPack = (pack: PackI) => {
    setVendorPacks([...(vendorPacks || []), pack]);
  };

  const deletePack = (position: number) => {
    const newList = vendorPacks?.filter((pack, index) => position !== index);
    setVendorPacks([...(newList || [])]);
  };

  /* Edit Service Form */

  const {
    control: editFormControl,
    reset: handleEditFormValues,
    handleSubmit,
    formState: { errors: editFormErrors },
    getValues,
    watch
  } = useForm<EditServiceFormStateI>({
    resolver: yupResolver(getSchema(modality, isCoverageArea)),
    defaultValues: {
      questions: [{ question: '', isSave: false, is_required: false }]
    }
  });

  useQuery<GetLabelsI>(
    ['labels'],
    async () =>
      await client<GetLabelsI>(`me/vendor/labels`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: LabelI[]) => {
        setAssetLabels(data);
      }
    }
  );

  const labelOptions = assetLabels.map((label) => label.title);

  const handleSelectLabel = (label?: string) => {
    if (label) {
      setLabel(label);
    } else {
      setLabel(undefined);
    }
  };

  const { data: _vendors } = useQuery(
    ['venues', 'staffs'],
    async () =>
      await client<VendorI[]>(`venues/staff`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: session?.is_venue
    }
  );

  const vendors = _vendors?.filter((vendor) => vendor.status === VendorStatusE.ACTIVE);

  const { data: service, isFetching: isFetchingService } = useQuery(
    ['service', serviceId],
    async () =>
      await client<GetServiceI>(`me/vendor/services/${serviceId}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEdit,
      onSuccess: (data: ServiceI) => {
        const {
          name,
          custom_questions,
          is_on_demand,
          local_slots,
          virtual_slots,
          modality,
          meet_type,
          duration,
          address,
          meet_link,
          price,
          price_usd,
          is_for_subscription,
          cover_url,
          description,
          faqs,
          policy_refund_mins,
          booking_until_at_minutes,
          packs,
          coverage_areas,
          images_gallery,
          is_refund_policies_enabled,
          address_details,
          is_user_phone_required,
          is_refund_available,
          assets_labels,
          multi_vendors,
          buffer_after
        } = data;

        if (packs) {
          setVendorPacks(packs.map(({ price, limit, price_usd }) => ({ limit, price, price_usd })));
        }

        if (buffer_after) {
          setToggleBufferTime(true);
          setBufferTime(buffer_after.toString());
        }

        handleEditFormValues({
          participants: local_slots || virtual_slots,
          name: name,
          duration: duration,
          details:
            address_details && address_details !== 'null' && address_details !== 'undefined'
              ? address_details
              : '',
          questions:
            custom_questions?.map((item) => ({
              id: item.id,
              question: item.question,
              isSave: true,
              is_required: item.is_required
            })) || [],
          price: price ?? 0,
          price_usd: price_usd || 0,
          ...(meet_link && { meetLink: meet_link })
        });
        handleSelectPlace({
          address: address
        });
        setSelectedCancellation(
          MINUTES_OPTIONS.find((option) => parseInt(option.value as string) == policy_refund_mins) ||
            MINUTES_OPTIONS[0]
        );
        setSelectedBookingUntil(
          MINUTES_OPTIONS.find((option) => parseInt(option.value as string) == booking_until_at_minutes) ||
            MINUTES_OPTIONS[0]
        );
        setIsOnDemand(is_on_demand);
        setModality(modality);
        if (modality === ServiceModalityE.VIRTUAL) setMeetType(meet_type!);
        setIsForSubscriptionEnabled(is_for_subscription);
        setCoverImage(cover_url);
        setServiceDescription(description);
        handleImages(images_gallery);
        setToggleIsUserPhoneRequired(is_user_phone_required);
        setToggleCancellationPolicy(is_refund_policies_enabled);
        setToggleRefundAvailable(is_refund_available);

        if (data.down_payment) {
          const {
            amount,
            amount_discount,
            is_bank_transfer_available,
            is_cash_available,
            is_mercado_pago_available
          } = data.down_payment;
          setToggleDownPaymentAvailable(true);
          setDownPaymentAmount(amount);
          setDownPaymentDiscount(amount_discount ? amount_discount * 100 : null);
          setDownPaymentType(amount ? downPaymentOptions[0] : downPaymentOptions[1]);
          setDownPaymentAvailable([
            ...(is_bank_transfer_available ? [DownpaymentOption.BANK_TRANSFER] : []),
            ...(is_cash_available ? [DownpaymentOption.CASH] : []),
            ...(is_mercado_pago_available ? [DownpaymentOption.MERCADO_PAGO] : [])
          ]);
        }

        if (faqs) {
          setFaqs(faqs);
        }

        if (assets_labels && assets_labels.length > 0) {
          const firstLabel = assets_labels[0].label;
          setLabel(firstLabel.title);
        }

        if (coverage_areas && coverage_areas.length > 0) {
          handleIsCoverageArea();
        }
        if (is_on_demand && modality === ServiceModalityE.LOCAL) {
          const coverageAreas: ZoneType[] =
            coverage_areas?.map(({ color, name, id, area, shape }) => ({
              color,
              name,
              id: id.toString(),
              data: area,
              type: shape,
              isSaved: true
            })) || [];
          setZones(coverageAreas);
        }
        if (multi_vendors) {
          const selectedStaffs = multi_vendors.map((vendor) => vendor.vendor_id);
          setSelectedStaffs(selectedStaffs);
        }
      }
    }
  );

  const serviceSlug = useMemo(() => service?.slug, [service]);

  useEffect(() => {
    // Logic to handle which payment methods the vendor has to filter which can be used on the down payment
    const downPaymentAvailable = [];
    if (session?.vendor?.payment_gateways) {
      const isMPAvailable = session.vendor.payment_gateways.includes(WalletTypeE.MERCADO_PAGO);
      if (isMPAvailable) downPaymentAvailable.push(DownpaymentOption.MERCADO_PAGO);
    }
    if (session?.vendor?.is_payment_cash_enabled) {
      downPaymentAvailable.push(DownpaymentOption.CASH);
    }
    if (session?.vendor?.is_payment_bank_transfer_enabled) {
      downPaymentAvailable.push(DownpaymentOption.BANK_TRANSFER);
    }
    setDownPaymentAvailableOptions(downPaymentAvailable);
  }, [session]);

  const onSubmit: SubmitHandler<EditServiceFormStateI> = async (data) => {
    const { name, price, participants, duration, details, meetLink, price_usd, questions } = data;

    const isBlob = coverImage?.includes('blob');
    const coverUrl = isBlob ? await urlImageToFile(coverImage) : coverImage;

    const savedZones = zones.filter((zone) => zone.isSaved);

    const imageGalleryBase64 = [];

    for (let i = 0; i < imageGallery.length; i++) {
      const imageBlob = imageGallery[i].blob;

      if (imageBlob) {
        const imageBase64 = await blobToBase64(imageBlob);
        const image = {
          ...imageGallery[i],
          blob: imageBase64
        };
        imageGalleryBase64.push(image);
      } else if (imageGallery[i].url) {
        imageGalleryBase64.push({
          ...imageGallery[i]
        });
      }
    }

    const isDownPaymentPercentage = downPaymentType.value === 'percentage';

    const submitData: PostServiceI = {
      name,
      price: price || 0,
      price_usd: price_usd || 0,
      modality: modality!,
      cover_url: coverUrl,
      ...(details && { address_details: details }),
      ...(modality === ServiceModalityE.LOCAL &&
        !isCoverageArea && {
          address: selectedPlace!.address
        }),
      duration,
      participants: isOnDemand ? 1 : participants,
      ...(modality === ServiceModalityE.VIRTUAL && {
        meet_type: meetType || MeetTypeE.GOOGLE_MEET,
        meet_link: meetLink
      }),
      packs: JSON.stringify(vendorPacks),

      image_gallery: imageGalleryBase64,
      ...(zones && { coverage_area: JSON.stringify(savedZones) }),
      ...(faqs && { faqs: JSON.stringify(faqs) }),

      description: serviceDescription,
      is_for_subscription: isForSubscriptionEnabled,
      is_on_demand: participants === 1,
      is_refund_policies_enabled: toggleCancellationPolicy,
      is_user_phone_required: toggleIsUserPhoneRequired,
      is_refund_available: toggleRefundAvailable,
      booking_until_at_minutes: selectedBookingUntil.value as string,
      policy_refund_mins: parseInt(selectedCancellation.value as string),
      vendor_ids: JSON.stringify(selectedStaffs),
      label,
      questions: JSON.stringify(
        questions.map((item: CustomQuestionI, index: number) => ({ ...item, order: index }))
      ),
      // @ts-ignore

      down_payment_amount: !isDownPaymentPercentage ? downPaymentAmount : undefined,
      down_payment_discount:
        isDownPaymentPercentage && downPaymentDiscount ? downPaymentDiscount / 100 : undefined,
      ...(label && { label: label }),
      down_payment_methods: JSON.stringify(downPaymentAvailable),
      is_down_payment_available: toggleDownPaymentAvailable,
      buffer_after: toggleBufferTime ? parseInt(bufferTime) : 0
    };
    mutationEdit.mutate(submitData);
  };

  const handleErrors = (): boolean => {
    const { duration, name, meetLink, participants, price } = watch();

    const errors = [
      {
        meetLink: {
          boolean: modality === ServiceModalityE.VIRTUAL && !meetLink,
          message: 'Ingresá el link del encuentro'
        }
      },
      {
        name: {
          boolean: !name || name.length < 10,
          message: 'Ingresá el nombre del servicio (mínimo 10 caracteres)'
        }
      },
      {
        duration: {
          boolean: !duration || duration <= 0,
          message: 'Ingresá la duración del servicio'
        }
      },
      {
        participants: {
          boolean: !isOnDemand && (!participants || participants <= 0),
          message: 'Ingresá la cantidad de participantes'
        }
      },
      {
        address: {
          boolean: modality === ServiceModalityE.LOCAL && !locationValue,
          message: 'Ingresá la dirección del servicio'
        }
      }
    ];

    const errorsObject = errors.reduce((acc, error) => {
      const key = Object.keys(error)[0];
      const { boolean, message } = Object.values(error)[0];
      return {
        ...acc,
        [key]: boolean ? message : ''
      };
    }, initialSelectErrors);

    setAboutErrors(errorsObject);

    const firstErrorKey = Object.keys(errorsObject).find((key) => errorsObject[key]);
    if (firstErrorKey) {
      const element = document.getElementById(firstErrorKey);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    const hasErrors = Object.values(errorsObject).some((message) => message !== '');

    if (hasErrors) {
      const errorMessage = Object.values(errorsObject)
        .filter((message) => message)
        .join('\r\n');
      toast.error(errorMessage);
      logAndNotify(`Error al editar un servicio - ${errorMessage}`);
      return true;
    } else {
      // Check down payment conditions if applicable
      if (toggleDownPaymentAvailable) {
        if (!price || price === 0) {
          toast.error('El precio del servicio no puede ser cero y estar activa la seña');
          logAndNotify(`Error al editar un servicio - El precio del servicio es cero y esta activa la seña`);
        }
        if (!downPaymentAmount && !downPaymentDiscount) {
          toast.error('El monto de la seña es requerido');
          logAndNotify(`Error al editar un servicio - El monto de la seña es requerido`);
          return true;
        }

        if (downPaymentAvailable.length === 0) {
          toast.error('Debe haber al menos un medio de pago habilitado para la seña');
          logAndNotify(
            `Error al editar un servicio - Debe haber al menos un medio de pago habilitado para la seña`
          );
          return true;
        }

        if (downPaymentType.value === 'percentage') {
          if (!downPaymentDiscount) {
            toast.error('El porcentaje de la seña es requerido');
            logAndNotify(`Error al editar un servicio - El porcentaje de la seña es requerido`);
            return true;
          }
          if (downPaymentDiscount >= 91) {
            toast.error('El porcentaje de la seña no puede ser mayor a 90%');
            logAndNotify(`Error al editar un servicio - El porcentaje de la seña no puede ser mayor a 90%`);
            return true;
          }
        }

        if (downPaymentType.value === 'amount') {
          if (!downPaymentAmount) {
            toast.error('El monto de la seña es requerido');
            logAndNotify(`Error al editar un servicio - El monto de la seña es requerido`);
            return true;
          }
          if (downPaymentAmount <= 0) {
            toast.error('El monto de la seña no puede ser menor o igual a 0');
            logAndNotify(`Error al editar un servicio - El monto de la seña no puede ser menor o igual a 0`);
            return true;
          }
          if (downPaymentAmount > 0.91 * price) {
            toast.error('La seña no puede superar el 90% del precio total del servicio');
            logAndNotify(
              `Error al editar un servicio - La seña no puede superar el 90% del precio total del servicio`
            );
            return true;
          }
        }
      }
      return false;
    }
  };

  const fullSubmit = () => {
    trackGenericEvent('Button Save Asset Clicked', { type: 'Service' });

    if (handleErrors()) {
      return;
    }

    handleSubmit(onSubmit, (e) => {
      if (e) {
        const errors = Object.values(e).map((error) => error.message);
        toast.error(errors.join('\r\n'));
        const firstErrorKey = Object.keys(e)[0];
        const element = document.getElementById(firstErrorKey);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        logAndNotify(
          `Error al editar/guardar un servicio: Error al guardar el servicio, no se pudo identificar el error, revisar ASAP, the values of the form are ${JSON.stringify(
            getValues()
          )}`
        );
        toast.error('Error al guardar el servicio');
      }
    })();
  };

  const mutationEdit = useMutation<ServiceI, ErrorI, PostServiceI>(
    (service) =>
      client(`services/${serviceId}`, 'PUT', {
        isAuthRequired: true,
        isMultipartForm: true,
        data: service
      }),
    {
      onSuccess: () => {
        toast.success('Servicio guardado');
        navigate('/servicios');
      },
      onError: (e: any) => {
        if (e.code && e.code === 'VE-002') {
          toast.error('Ha excedido su limite de 8 servicios');
        } else {
          logAndNotify(`Error al editar un servicio - ${JSON.stringify(e)}`);
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const isMutationLoading = mutationEdit.isLoading;

  /* End of Edit Service Form */

  return (
    <ServiceContext.Provider
      value={{
        modality,
        setModality,
        isOnDemand,
        setIsOnDemand,
        isCoverageArea,
        setIsCoverageArea,
        isStripeConnected,
        addPack,
        coverImage,
        setCoverImage,
        vendorPacks,
        deletePack,
        selectedStaffs,
        setSelectedStaffs,
        setVendorPacks,
        handleLocationValue,
        locationValue,
        handleSelectPlace,
        setLocationValue,
        selectedPlace,
        places,
        isLoadingPlaces,
        activeTab,
        handleActiveTab,
        editFormControl,
        editFormErrors,
        meetType,
        setMeetType,
        isForSubscriptionEnabled,
        setIsForSubscriptionEnabled,
        serviceDescription,
        setServiceDescription,
        faqs,
        setFaqs,
        toggleCancellationPolicy,
        setToggleCancellationPolicy,
        toggleRefundAvailable,
        setToggleRefundAvailable,
        toggleDownPaymentAvailable,
        setToggleDownPaymentAvailable,
        watch,
        selectedCancellation,
        onSelectionCancellationChange,
        selectedBookingUntil,
        onSelectionBookingUntilChange,
        fullSubmit,
        handleIsCoverageArea,
        zones,
        setZones,
        imageGallery,
        setImageGallery,
        isFetchingService,
        isMutationLoading,
        toggleIsUserPhoneRequired,
        setToggleIsUserPhoneRequired,
        labelOptions,
        handleSelectLabel,
        label,
        setLabel,
        vendors,
        downPaymentType,
        setDownPaymentType,
        downPaymentAvailable,
        setDownPaymentAvailable,
        downPaymentAmount,
        setDownPaymentAmount,
        downPaymentDiscount,
        setDownPaymentDiscount,
        downPaymentAvailableOptions,
        setDownPaymentAvailableOptions,
        serviceSlug,
        toggleBufferTime,
        bufferOptions,
        bufferTime,
        setBufferTime,
        handleToggleBufferTime,
        aboutErrors,
        handleErrors
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = (): ServiceContextType => useContext(ServiceContext);
