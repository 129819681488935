import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getTime } from '@/utils';
import { ServiceModalityE } from '@/types/cyclone/models';
import { useAuth } from '@/contexts';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TodayItemProps {
  eventInstanceId: string;
  name: string;
  participants: number;
  startTime: string;
  endTime: string;
  modality: ServiceModalityE;
  onStartMeet: (id: string, participants: number) => void;
}

export const TodayItem: FunctionComponent<TodayItemProps> = ({
  eventInstanceId,
  name,
  participants,
  startTime,
  endTime,
  modality,
  onStartMeet
}) => {
  const { session } = useAuth();
  const timezone = session?.vendor?.timezone || 'America/Argentina/Buenos_Aires';

  const shouldShowCameraActivated = (): boolean => {
    const todayDate = dayjs().tz(timezone).add(15, 'minutes').toDate();

    const eventEndHour = endTime.split(':')[0];
    const eventEndMinutes = endTime.split(':')[1];
    const eventStartHour = startTime.split(':')[0];
    const eventStartMinutes = startTime.split(':')[1];

    const eventEndDate = dayjs()
      .set('date', todayDate.getDate())
      .set('hours', parseInt(eventEndHour))
      .set('minutes', parseInt(eventEndMinutes))
      .toDate();
    const eventStartDate = dayjs()
      .set('date', todayDate.getDate())
      .set('hours', parseInt(eventStartHour))
      .set('minutes', parseInt(eventStartMinutes))
      .toDate();

    return todayDate >= eventStartDate && todayDate <= eventEndDate;
  };

  const handleShowCameraIcon = () => {
    const shouldShowCamera = shouldShowCameraActivated();
    if (participants >= 0 && modality !== ServiceModalityE.LOCAL)
      return shouldShowCamera ? (
        <IconButton onClick={() => onStartMeet(eventInstanceId, participants)}>
          <Icon
            name={'videocam'}
            width={'21'}
            height={'17'}
            marginRight={'6px'}
            color={theme.colors.red[200]}
          />
        </IconButton>
      ) : (
        <Icon
          name={'videocam_slash'}
          width={'21'}
          height={'17'}
          marginRight={'6px'}
          color={theme.colors.grey[700] + '80'}
        />
      );
    else <></>;
  };

  return (
    <TodayItemContainer>
      <Column>
        <Icon
          name={'clock'}
          width={'19'}
          height={'17'}
          marginRight={'6px'}
          color={theme.colors.blue[500] + '80'}
        />
        {getTime(startTime)}
      </Column>
      <Column>
        <Icon
          name={'users'}
          width={'19'}
          height={'17'}
          marginRight={'6px'}
          color={theme.colors.blue[500] + '80'}
        />
        {` ${participants}`}
      </Column>
      <Column flex={'2'}>{name}</Column>

      {/* FIXME: gray videocam does not exist */}
      {handleShowCameraIcon()}
    </TodayItemContainer>
  );
};

const Column = styled.p<{ flex?: string }>`
  display: flex;
  align-items: center;
  flex: ${({ flex }) => flex || 1};
  font-size: 15px;
  line-height: 19px;
  padding: 0px 10px;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const TodayItemContainer = styled.div`
  padding: 40px 37px 24px 26px;
  display: flex;
  flex-direction: row;
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.grey[500]}`};
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    padding: 23px 15px 20px 12px;
    margin-right: 0px;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
`;
