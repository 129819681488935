const ENV = import.meta.env.VITE_NODE_ENV;

const domain = ENV !== 'local' ? '.agora.red' : 'localhost';

// reads a cookie according to the given name
export const readCookie = (name: string): any => {
  let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result = result != null ? JSON.parse(result[1]) : [];
  return result;
};

export const deleteCookie = (name: string): void => {
  document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=', domain].join('');
};
