import { Column, Grid } from '@/components';
import { useOnboarding } from '@/containers/SelfOnboarding/contexts';
import { useWindowResize } from '@/hooks';
import classNames from 'classnames';
import React from 'react';
import { BeautyIcon, HealthIcon, OtherIcon, WellnessIcon } from '../components';

export const Category: React.FC = () => {
  const { categories, handleFatherCategory } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  const renderIconByCategory = (category: string) => {
    switch (category) {
      case 'Belleza y Estética':
        return <BeautyIcon />;
      case 'Medicina y Salud':
        return <HealthIcon />;
      case 'Bienestar':
        return <WellnessIcon />;
      case 'Otros':
        return <OtherIcon />;
      default:
        break;
    }
  };

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)] max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
          !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-3xl mt-16 text-center text-balance">Datos de tu negocio</h2>
      <p className="text-base text-[#626262] font-medium mt-2 text-center text-balance">
        Seleccioná la categoría que mejor represente a tu negocio:
      </p>
      <Grid as="div" columns={2} className="gap-4 mt-16 w-full sm:w-auto">
        {categories ? (
          <>
            {categories.map((category, i) => (
              <Column
                key={i}
                align="center"
                gap={10}
                justify={isMobileSize ? 'center' : 'flex-start'}
                className="text-center bg-white shadow-[0_0_10px_0_#62626233] border sm:flex-row transition-colors rounded cursor-pointer w-full h-36 sm:w-64 sm:h-20 py-2 px-4 hover:shadow-[0_0_10px_0_#0072FB33] hover:border-blue/20"
                onClick={() => handleFatherCategory(category)}
              >
                {renderIconByCategory(category.name)}
                <span className="text-xl">{category.name}</span>
              </Column>
            ))}
          </>
        ) : (
          <div className="bg-white shadow-[0_0_10px_0_#62626233] border transition-colors rounded cursor-pointer w-full h-20 sm:w-64 sm:h-20 py-2 px-4 hover:shadow-[0_0_10px_0_#0072FB33] hover:border-blue/20" />
        )}
      </Grid>
    </Column>
  );
};
