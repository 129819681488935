import React, { FunctionComponent } from 'react';
import { AgoraThemeColorI, VendorHeaderInfoI } from '@/types/cyclone/models';
import styled, { css } from 'styled-components';

type HeaderCleanProps = {
  colors: AgoraThemeColorI;
  vendor: VendorHeaderInfoI;
  isMobile?: boolean;
};

export const HeaderClean: FunctionComponent<HeaderCleanProps> = ({ colors, vendor, isMobile }) => {
  const { cover_url, name, professional_title, avatar_url } = vendor;

  return (
    <>
      <Background imageUrl={cover_url} isMobile={isMobile} />
      <Container colors={colors}>
        <Flex>
          <Avatar colors={colors} avatarUrl={`${avatar_url}`} isMobile={isMobile} />
          <Username colors={colors}>{name}</Username>
          <VendorTitle colors={colors}>{professional_title}</VendorTitle>
        </Flex>
      </Container>
    </>
  );
};

const Container = styled.div<{ colors: AgoraThemeColorI }>`
  background-color: ${({ colors }) => colors.background};
`;

const Background = styled.div<{ imageUrl: string; isMobile?: boolean }>`
  background: ${({ theme }) => theme.colors.grey[600]};
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ theme }) => theme.breakpoint('md')} {
    height: 24vh;
  }

  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        height: 124px;
      `;
    } else {
      return css`
        height: 200px;
      `;
    }
  }}
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Avatar = styled.div<{ avatarUrl?: string; colors: AgoraThemeColorI; isMobile?: boolean }>`
  width: 130px;
  height: 130px;
  background: ${({ theme }) => theme.colors.whiteSmoke};
  background-image: ${({ avatarUrl }) => `url(${avatarUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 300px;
  margin-top: -6rem;
  border: 5px solid ${({ colors }) => colors.background};
  ${({ theme }) => theme.breakpoint('md')} {
    width: 150px;
    height: 150px;
    margin-top: -4.5rem;
  }
  ${({ theme }) => theme.breakpoint('xs')} {
    width: 125px;
    height: 125px;
    margin-top: -4rem;
  }
  ${({ theme }) => theme.breakpoint('xxs')} {
    width: 115px;
    height: 115px;
  }

  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        width: 106px;
        height: 106px;
        margin-top: -3rem;
      `;
    } else {
      return css``;
    }
  }}
`;

const Username = styled.div<{ colors: AgoraThemeColorI }>`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 5px;
  padding: 0px 20px;
  text-align: center;
  color: ${({ colors }) => colors.title};
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.breakpoint('xs')} {
    font-size: 1.5rem;
  }
  ${({ theme }) => theme.breakpoint('xxs')} {
    font-size: 1.3rem;
  }
`;

const VendorTitle = styled.div<{ colors: AgoraThemeColorI }>`
  font-size: 1rem;
  margin-top: 10px;
  font-weight: bold;
  color: ${({ colors }) => colors.title};
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.8rem;
`;
